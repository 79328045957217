import React, { FunctionComponent, useEffect, useState, useRef, Suspense } from 'react';
import { Row, Col } from 'react-bootstrap';
import BaseLayout from '../functionalComponents/baseLayout/BaseLayout';
import ReactMarkdown from 'react-markdown';
import '../../assets/css/prism.css';
import '../../assets/css/contentdoc.css';
import Accesos_EN from '../../newViews/APIs/1_tools/0_Accesos_EN/Accesos_EN';
import { useDispatch, useSelector } from 'react-redux';
import { updateName, updateShowContentInDocs } from '../../redux/slices/KnowDivInScreen';
import Introduction from '../../newViews/Docs/0_Introduction';
import Billing_System from '../../newViews/Docs/1_Billing_System';
import AVAP_CLI  from "../../assets/img/logos/AVAP_CLI.svg";
import AVAP_CLI_W from "../../assets/img/logos/AVAP_CLI-negativo.svg";

import AVAP_DS  from "../../assets/img/logos/AVAP_DS.svg";
import AVAP_DS_W from "../../assets/img/logos/AVAP_DS_white.svg";

import AVAP_Lenguaje  from "../../assets/img/logos/AVAP_Lenguaje.svg";
import AVAP_Lenguaje_W from "../../assets/img/logos/AVAP_Lenguaje_white.svg";

import AVAP_VS  from "../../assets/img/logos/AVAP_VS.svg";
import AVAP_VS_W from "../../assets/img/logos/AVAP_VS_white.svg";

import AVAP_Gateway  from "../../assets/img/developerPortalIMG/AVAP_Gateway.png";
import AVAP_Gateway_W from "../../assets/img/logos/AVAP_Gateway_white.svg";

import AVAP_CollaborativeN  from "../../assets/img/logos/AVAP_CollaborativeN.svg";
import AVAP_CollaborativeN_W from "../../assets/img/logos/AVAP_Collaborative_white.svg";

import AVAP_Cloud  from "../../assets/img/logos/AVAP_Cloud.svg";
import AVAP_Cloud_W from "../../assets/img/logos/AVAP_Cloud_white.svg";


import icono_Cloud  from "../../assets/img/developerPortalIMG/icono_Cloud.png";
import icono_education  from "../../assets/img/developerPortalIMG/icono_education.png";
import icono_DS  from "../../assets/img/developerPortalIMG/icono_DS.png";
import icono_AI  from "../../assets/img/developerPortalIMG/icono_AI.png";
import icono_CollaborativeN  from "../../assets/img/developerPortalIMG/icono_CollaborativeN.png";
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/DisclaimerDP';
import { useHistory, useLocation } from 'react-router-dom';

type ContentProps = {
    doc: string,
    sider: any,
    cat: string,
    section?: string
};

const Content: FunctionComponent<ContentProps> = props => {
    const [currentTheme, setCurrentTheme] = useState(localStorage.getItem('theme') || 'light');
    const { doc, cat, sider, section } = props;
    const location = useLocation();
    // console.log('!!!!!!!   ', section)
    // const md = require(`../../assets/docs/${doc}.md`);
    const objVistas = [{ id: "Accesos_EN", componente: <Accesos_EN /> }];

    // console.log(doc);//download_101cli
    // console.log(sider.menus);

    const componenteSeleccionado = objVistas.find(vista => vista.id === doc);

    const [alldivs, setAllDivs] = useState([]);

    const divRefs = useRef<Array<HTMLDivElement | null>>([]);
    let sendToNameSection;
    if (section) {
        sendToNameSection = useSelector((state: any) => section)

    } else {
        sendToNameSection = useSelector((state: any) => state.nameDiv.sendToNameSection)
    }
    // console.log('sendToNameSection', sendToNameSection);
    const sendToNameSubSection = useSelector((state: any) => state.nameDiv.sendToNameSubSection)
    // console.log('sendToNameSubSection', sendToNameSubSection);
    const getToSectionAlgolia = useSelector((state: any) => state.nameDiv.getToSectionAlgolia)
    const dispatch = useDispatch();
    const currentName = useSelector((state: any) => state.nameDiv.name)
    const showContentInDocs = useSelector((state: any) => state.nameDiv.showContentInDocs) //when is view default
    const history = useHistory();


    useEffect(() => {
        // Importamos prism cada vez que cambia el markdown file y ejecutamos el highlightAll
        const prism = require('../../assets/js/prism.js');
        prism.highlightAll();

        let divs = [];
        let menus = [...sider.menus];
        let showFirst = sider.showFirst
        if (showFirst) {
            const name_section = showFirst.id;
            const sections = showFirst.links;

            sections.forEach(items => {
                let path = items.path;
                divs.push(
/*    ESTILO DE DOCUMENTOS       */<div style={{ paddingTop:'0.9vw' }} className='border-bottom' key={name_section + "-" + path} id={name_section + "-" + path} ref={ref => divRefs.current.push(ref)}>
                        {items.view}
                    </div>
                )
            });
        }

   

        for (let index = 0; index < menus.length; index++) {
            const name_section = menus[index].id;
            const sections = menus[index].links;
            // debugger
            // console.log(sections);

            sections.forEach(items => {
                let path = items.path;
                // const docUbication = require(`../../assets/docs/${path}.md`);
                divs.push(
                    <div className='py-5 border-bottom' key={name_section + "-" + path} id={name_section + "-" + path} ref={ref => divRefs.current.push(ref)}>
                        {items.view}
                    </div>
                )
            });
        }

        setAllDivs(divs);

    }, [doc, sider]);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;

            for (let i = 0; i < divRefs.current.length; i++) {

                const div = divRefs.current[i];
                if (!div) continue;

                const rect = div.getBoundingClientRect();
                if (rect.top <= windowHeight / 8 && rect.bottom >= windowHeight / 8) {
                    const newName = div.id;
                    // console.log(newName, currentName);

                    if (newName !== currentName) {
                        dispatch(updateName(newName));
                    }
                    break;
                } else if (currentName !== "") {
                    dispatch(updateName(""));
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        //Use when clicked in new navbar
        // Ensure this code runs after the component has been rendered
        setTimeout(() => {
            for (let index = 0; index < divRefs.current.length; index++) {
                const divId = divRefs.current[index].id;
                const div = divRefs.current[index];

                if (divId.includes(sendToNameSection)) {
                    div.scrollIntoView({ behavior: 'auto' });
                    break;
                }
            }
        }, 0);
    }, [sendToNameSection]);


    useEffect(() => {

        for (let index = 0; index < divRefs.current.length; index++) {
            const divId = divRefs.current[index].id;
            const div = divRefs.current[index];

            if (divId === sendToNameSubSection) {
                div.scrollIntoView({ behavior: 'auto' });
                break;
            }
        }
    }, [sendToNameSubSection]);



    useEffect(() => {
        //To send sections when use ALGOLIA
        for (let index = 0; index < divRefs.current.length; index++) {
            const divId = divRefs.current[index].id;
            const div = divRefs.current[index];

            if (divId.includes(getToSectionAlgolia)) {
                div.scrollIntoView({ behavior: 'auto' });
                break;
            }
        }
    }, [getToSectionAlgolia]);


     // Efecto para escuchar cambios en el tema
     useEffect(() => {
        // Función para actualizar el tema desde localStorage
        const handleThemeChange = () => {
          const theme = localStorage.getItem('theme') || 'light';
          setCurrentTheme(theme);
        };
    
        // Escuchar el evento storage para detectar cambios en localStorage desde otras ventanas
        window.addEventListener('storage', handleThemeChange);
        
        // Crear un evento personalizado para detectar cambios de tema dentro de la misma ventana
        window.addEventListener('themeChanged', handleThemeChange);
        
        // Verificar el tema actual al montar el componente
        handleThemeChange();
    
        return () => {
          window.removeEventListener('storage', handleThemeChange);
          window.removeEventListener('themeChanged', handleThemeChange);
        };
      }, []); // Eliminamos la dependencia de currentTheme para evitar re-renders innecesarios


    const changeValue = () => {
        dispatch(updateShowContentInDocs(!showContentInDocs)); //when is view default
    }

    useEffect(() => {
      // Comprobar si hay un hash en la URL
      if (location.hash) {
        // Quitamos el '#' del inicio
        const id = decodeURIComponent(location.hash.substring(1));
        console.log("ContentDoc: Attempting to scroll to element with ID:", id);
        
        // Esperamos a que todos los elementos estén renderizados
        setTimeout(() => {
          // Primero intentamos encontrar un elemento con el ID exacto
          let element = document.getElementById(id);
          
          // Si no encontramos un elemento con ID exacto, intentamos buscar por ID parcial
          if (!element) {
            // Buscar todos los divs en los refs
            for (let i = 0; i < divRefs.current.length; i++) {
              const div = divRefs.current[i];
              if (div && div.id.includes(id)) {
                element = div;
                break;
              }
            }
          }
          
          if (element) {
            console.log("ContentDoc: Element found! Scrolling to:", element.id);
            element.scrollIntoView({ behavior: 'auto' });
          } else {
            console.warn(`ContentDoc: Element with ID "${id}" not found for scrolling.`);
          }
        }, 500); // Un retraso mayor para asegurar que todo esté renderizado
      }
    }, [location.hash, alldivs]); // Dependencias: hash y alldivs (para asegurar que el contenido está cargado)

    return (
        <>
            <BrowserView>
                <BaseLayout sider={sider} active={cat}>
                    <Row id='contentDoc' className="justify-content-md-center contentMarginX " style={{lineHeight:'line-height: 1.8', paddingTop: '0.9vw'}}>
                        <Col md={{ span: 12 }}>
                            {/* when is view default */}
                            {(cat === 'default' || cat === 'developer') &&

                                (
                                    <div>
                                        <div className={` ${showContentInDocs == false ? "" : "hiddenContentDocs"}`}>
                                            <div className='text-bold dev_portal_text_dashboard dev_portal_gray1' style={{ textAlign: 'start' }}></div>
                                            <div className='d-flex'>
                                                <div className='row m-0 p-0 container-fluid'>
                                                    <div className='col-md-6 p-0 d-flex flex-column ' style={{ textAlign: 'start' }}>
                                                        <div className='d-flex flex-column text-bold ' style={{ marginBottom: "46px" }}>
                                                            <div className='dev_portal_font1 dev_portal_gray1 textBold' style={{ marginBottom: '20px' }} >
                                                                Start
                                                            </div>
                                                            <div style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view_id-GET STARTED_DisplayLanguage/0')}} className='dev_portal_green1 dev_portal_font2'>
                                                                Create your AVAP ID
                                                            </div>
                                                            <div style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view12-GET STARTED_Downloads/0')}} className='dev_portal_green1 dev_portal_font2'>
                                                                Download basic software
                                                            </div>
                                                            <div style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view_aefmvsc_cli-GET STARTED_Dev_Token/0')}} className='dev_portal_green1 dev_portal_font2'>
                                                                Create your developer Token
                                                            </div>
                                                            <div style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view_cloud-GET STARTED_Projects/0')}} className='dev_portal_green1 dev_portal_font2'>
                                                                Create your first API Project
                                                            </div>
                                                        </div>

                                                        <div className='d-flex flex-column'>
                                                            <div className='text-bold dev_portal_font1 dev_portal_gray1'>
                                                                Get Started
                                                            </div>
{/*over_view_aefmvsc_cli-GET STARTED_Dev_Cli*/}
                                                            <div className='dev_portal_logos_left' style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view_aefmvsc_cli-GET STARTED_Dev_Cli/0')}}>
                                                                <div>
                                                                    <img src={currentTheme === 'dark' ? AVAP_CLI_W : AVAP_CLI} alt="" height={"100%"} />
                                                                </div>
                                                            </div>
{/*over_view_aefmvsc-GET STARTED_DisplayLanguage */}
                                                <div style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view_aefmvsc-GET STARTED_DisplayLanguage/0')}} className='dev_portal_logos_left'>
                                                    <div>
                                                        <img src={currentTheme === 'dark' ? AVAP_DS_W : AVAP_DS} alt=""   height={"100%"}/>
                                                    </div>
                                                </div>

                                                <div className='dev_portal_logos_left' style={{cursor:'pointer'}}  onClick={()=>{history.push('/docs')}} >
                                                    <div>
                                                        <img src={currentTheme === 'dark' ? AVAP_Lenguaje_W : AVAP_Lenguaje} alt=""   height={"100%"}/>
                                                    </div>
                                                </div>
{/**over_view13-GET STARTED_AVS */}
                                                <div style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view13-GET STARTED_AVS/0')}} className='dev_portal_logos_left'>
                                                    <div>
                                                        <img src={currentTheme === 'dark' ? AVAP_VS_W : AVAP_VS} alt=""   height={"100%"}/>
                                                    </div>
                                                </div>

{/*}

                                                <div className='dev_portal_logos_left'>
                                                    <div>
                                                        <img src={AVAP_Gateway} alt=""   height={"100%"}/>
                                                    </div>
                                                </div>
*/}
{/*over_view_cn-GET STARTED_DisplayLanguage*/}
                                                <div className='dev_portal_logos_left' style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view_cn-GET STARTED_DisplayLanguage/0')}}>
                                                    <div>
                                                        <img src={currentTheme === 'dark' ? AVAP_CollaborativeN_W : AVAP_CollaborativeN} alt=""   height={"100%"}/>
                                                    </div>
                                                </div>

{/*over_view_cloud-GET STARTED_Cloud */}

                                                <div className='dev_portal_logos_left' style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view_cloud-GET STARTED_Cloud/0')}}>
                                                    <div>
                                                        <img src={currentTheme === 'dark' ? AVAP_Cloud_W : AVAP_Cloud} alt=""   height={"100%"}/>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                        {/**over_view_cloud-GET STARTED_Cloud */}
                                        <div  className='col-md-6 p-0 d-flex flex-column rs-mt' style={{textAlign:'start'}}>
                                            <div className='dev_portal_font1 dev_portal_gray1 textBold' style={{marginBottom:'20px'}} >
                                            Walkthroughs
                                            </div>


                                            <div style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view_cloud-GET STARTED_Cloud/0')}} className='d-flex flex-column dev_portal_cards_right'>
                                                <div className='d-flex flex-row dev_portal_cards_header dev_portal_cards_padding'>
                                                    <div>
                                                        <div className='dev_portal_cards_size_log'>
                                                            <img src={icono_Cloud} alt="" height={"100%"} width={"100%"}/>
                                                        </div>
                                                    </div>
                                                    <div className='text-bold dev_portal_font1 dev_portal_cards_margin'>Get started with AVAP Cloud</div>
                                                    <div></div>
                                                </div>
                                                <div className='dev_portal_cards_padding'>Access to a complete solution for API development, publishing, administration, and consumption based in the cloud.</div>
                                                
                                            </div>
{/*}
                                            <div className='d-flex flex-column dev_portal_cards_right'>
                                                <div className='d-flex flex-row  dev_portal_cards_padding'>
                                                    <div>
                                                        <div className='dev_portal_cards_size_log'>
                                                            <img src={icono_education} alt="" height={"100%"} width={"100%"}/>
                                                        </div>
                                                    </div>
                                                    <div className='text-bold dev_portal_font1 dev_portal_cards_margin'>Learn the fundamentals</div>
                                                    <div></div>
                                                </div>
                                                
                                            </div>
*/}
                                            <div style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view_cloud-GET STARTED_Products/0')}} className='d-flex flex-column dev_portal_cards_right'>
                                                <div className='d-flex flex-row dev_portal_cards_header dev_portal_cards_padding'>
                                                    <div>
                                                        <div className='dev_portal_cards_size_log'>
                                                            <img src={icono_DS} alt="" height={"100%"} width={"100%"}/>
                                                        </div>
                                                    </div>
                                                    <div className='text-bold dev_portal_font1 dev_portal_cards_margin'>Begin your journey with API Products</div>
                                                    <div></div>
                                                </div>
                                                <div className='dev_portal_cards_padding'>Try our own IDE with AVAP Dev Studio for project management, API creation, code maintenance, and API publication.</div>
                                                
                                            </div>

{/*}
                                            <div className='d-flex flex-column dev_portal_cards_right'>
                                                <div className='d-flex flex-row dev_portal_cards_header dev_portal_cards_padding'>
                                                    <div>
                                                        <div className='dev_portal_cards_size_log'>
                                                            <img src={icono_AI} alt="" height={"100%"} width={"100%"}/>
                                                        </div>
                                                    </div>
                                                    <div className='text-bold dev_portal_font1 dev_portal_cards_margin'>Embrace AI</div>
                                                    <div></div>
                                                </div>
                                                <div className='dev_portal_cards_padding'>Artificial Intelligence integrated at the core of all our AVAP products that optimizes and enhances efficiency in programming and API development.</div>
                                                
                                            </div>
*/}
{/*over_view_cn-GET STARTED_DisplayLanguage*/}
                                            <div style={{cursor:'pointer'}} onClick={()=>{history.push('/wiki/over_view_cn-GET STARTED_DisplayLanguage/0')}}  className='d-flex flex-column dev_portal_cards_right'>
                                                <div className='d-flex flex-row dev_portal_cards_header dev_portal_cards_padding'>
                                                    <div>
                                                        <div className='dev_portal_cards_size_log'>
                                                            <img src={icono_CollaborativeN} alt="" height={"100%"} width={"100%"}/>
                                                        </div>
                                                    </div>
                                                    <div className='text-bold dev_portal_font1 dev_portal_cards_margin'>Unleash your potential</div>
                                                    <div></div>
                                                </div>
                                                <div className='dev_portal_cards_padding'>AVAP Collaborative Network is the 1st global and free peer-to-peer network for API development and deployment.</div>
                                                
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>

                            </div>


                                <div className={` ${showContentInDocs == false ? "hiddenContentDocs" : ""}`}>

                                    <Suspense fallback={<div>Cargando...</div>}>
                                        {alldivs}
                                    </Suspense>
                                </div>
                            </div>
                        )
                    }

                    {/* vier normal */}
                    {(cat !== 'default' && cat !== 'developer') &&

                        (
                            <Suspense fallback={<div>Cargando...</div>}>
                                {alldivs}
                            </Suspense>

                        )
                    }






                        </Col>
                    </Row>
                </BaseLayout>
            </BrowserView>
            <MobileView>
                <Disclaimer />
            </MobileView>
        </>

    )
}

export default Content;