import React, { Fragment } from 'react';
import GettingStarted_AVAPDeveloperTeams from '../../assets/img/developerPortal/GettingStarted_AVAPDeveloperTeams.png';

const AVAP_Teams = () => (
  <Fragment>
         <div className="body-md">
      <h1>Getting Started with AVAP Developer Teams</h1>
      
      <h2>AVAP Dev Teams</h2>
      <p>
        In AVAP Framework, development teams are essential for effective and organized management of API Projects. Teams allow the account owner or manager to group programmers, facilitating work related to access and service utilization within a project. This guide provides details on how to create, manage, and use development teams in AVAP Framework to optimize API project work.
      </p>

      <h2>Creation and Management of Development Teams</h2>
      <h3>1. Definition of Development Teams</h3>
      <p><strong>Description:</strong> A development team in AVAP Framework is a group of programmers working together on API Projects. These teams facilitate collaboration, task assignment, and project management.</p>
      
      <h4>Advantages:</h4>
      <ul>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          <strong>Collaboration:</strong> Enables team members to work together in a coordinated manner.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          <strong>Project Management:</strong> Facilitates task assignment and project progress tracking.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          <strong>Security:</strong> Controls access to resources and projects in a specific and efficient manner.
        </li>
      </ul>

      <h3>2. Creating Development Teams</h3>
      <h4>Process:</h4>
      <ol>
        <li><strong>Access AVAP Cloud or Your Deployment's Developer Portal:</strong> Log in via the web portal.</li>
        <li><strong>Select Developer Teams:</strong> Go to the team management section within the portal.</li>
        <li><strong>Create New Team:</strong>
          <ul className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
            <li><strong>Name:</strong> Assign a name to the team.</li>
            <li><strong>Description:</strong> Provide a brief description of the team’s responsibilities and objectives.</li>
            <li><strong>Add Members:</strong> Select and add programmers to the team.</li>
            <li><strong>Configure Permissions:</strong> Define the permissions and access the team will have over projects and resources.</li>
          </ul>
        </li>
      </ol>

      <p>
        Programmers, through the IDE, whether Microsoft Visual Studio Code or AVAP Dev Studio, can view the development teams they belong to, as shown in the image below.
      </p>

      <br />
      <div style={{ textAlign: 'center' }}>
        <img
          src={GettingStarted_AVAPDeveloperTeams}
          alt="Figure 1.1 Developer Teams Section in AVAP Extension for Microsoft Visual Studio Code"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
        <small>*Figure 1.1 Developer Teams Section in AVAP Extension for Microsoft Visual Studio Code*</small>
      </div>
      <br />

      <h2>Best Practices for Development Teams</h2>
      <ul>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          <strong>Clearly Define Roles and Responsibilities:</strong> Ensure each team member understands their role and responsibilities.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          <strong>Facilitate Communication:</strong> Promote open and effective communication among team members.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          <strong>Use Project Management Tools:</strong> Employ management tools to track progress and coordinate tasks.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          <strong>Review and Optimize Processes:</strong> Periodically review team processes to identify areas for improvement and optimization.
        </li>
      </ul>

      <p>
        Effective management of development teams in AVAP Framework is crucial for the success of API projects. Grouping programmers into teams allows for more efficient collaboration, better organization, and increased security in API development. By following this guide, you will be able to create and manage development teams effectively in AVAP Framework, enhancing productivity and control in your projects.
      </p>

      <p>
        For more information related to AVAP Developer Teams, visit AVAP Alexandria at (
        <a href="http://alexandria.avapframework.com/" target="_blank" rel="noopener noreferrer">
          alexandria.avapframework.com
        </a>
        ) and connect with the user community at (
        <a href="http://communities.avapframework.com/" target="_blank" rel="noopener noreferrer">
          communities.avapframework.com
        </a>
        ).
      </p>
    </div>
  </Fragment>
);
export default AVAP_Teams;
