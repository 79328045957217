import React, { FunctionComponent, useState } from 'react';
import { Row, Col, Card, Button, Spinner, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle, faInfo, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import UpgradePlanModal from '../../Modals/UpgradePlanModal';
import { useHistory } from 'react-router-dom';

///Cloud card componente 


type ConsumptionProps = {
  title: string,
  value: string,
  vlabel?: string,
  svlabel?: string,
  valueSecondary?: string,
  plus?: boolean,
  action?: () => void,
  loading: boolean,
  available: boolean,
  info: boolean
  currentSuscription: number,
  NoPayment: boolean,
  rightLine?: boolean,
  upgradeLabel?: string,
  grid?: boolean, // controlan los estilos
  cloud?: boolean,
}

const ConsumptionCard: FunctionComponent<ConsumptionProps> = (props: ConsumptionProps) => {
  const history = useHistory();
  const { title, value, valueSecondary, vlabel, svlabel, plus, action, loading, available, info, currentSuscription, NoPayment, upgradeLabel, grid = false, cloud = false, } = props;
  const [showModal, setShowModal] = useState(false);

  const textModal = 'To advance upgrade your plan'
  const accion = () => {
    if (title == 'Income') { history.push('/income'); } else {
      if (available) action();
      else handleUpgradeModal();
    }
  }

  const modalClasses = 'addons-dialog themeDialog w-75 obex-dialog' +
  (cloud && grid ? ' cloudModal isGrid' :
    cloud ? ' cloudModal' :
      grid ? ' isGrid' : '');

  const handleUpgradeModal = () => { setShowModal(true) }
  const onCloseModal = () => { setShowModal(false) }
  const lineaRight = props.rightLine != undefined ? props.rightLine : false;
  return (
    <div className='itemsConsumption'>

      <div className="comsumption-box" style={{ width:'100%' }}>

        <div className="comsumption-title pl-1">
          <span>{title}

            {info ?
              <Tooltip style={{ marginLeft: '-3em' }} title="In some cases this can be because timeout" id={'info'}>
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  //className={loading ? `btn-comsumption-grey ml-1` :`btn-comsumption ml-1`}
                  size="2x" />
              </Tooltip>
              :
              <span></span>
            }</span>


          <div id={`${title}-button`} className="upgradeWrapper" onClick={loading ? null : accion}>
            <span>{available ? 'Add':  upgradeLabel}</span>
            <FontAwesomeIcon
              icon={plus ? faPlusCircle : faQuestionCircle}
              className={loading ? 'btn-comsumption-grey ml-1' : 'btn-comsumption ml-1'}
              size="1x"

            />

          </div>
        </div>


        <div className="comsumption-text pl-2 mb-2">
          {info ?
            <span >
              <FontAwesomeIcon icon={faInfo} size="1x" /></span>
            :
            <span></span>
          }
          {loading ?
            <Spinner style={{ marginLeft: '+0.3em' }} animation="border" size="sm" />
            :
            <div className="valueWrapper">
              <div className="leftValue">
                <span className='cardlabel'><span className="dot green"></span> {vlabel}</span>
                <span className="value">{value}</span>
              </div>
              <div className="rightValue">
                <span className='cardlabel'><span className="dot red"></span>{svlabel}</span>
                <span className="value"> {valueSecondary.replace('Gb', ' Gb')}</span>
              </div>
            </div>
          }
        </div>

        <div className="bottomInfo">
          {(((Number(valueSecondary.replace('K', '').replace('Gb', '').replace(',', ''))/Number(value.replace('K', '').replace('Gb', '').replace(',', '')))*100).toFixed(2)).replace('NaN', '0')}% Capacity of {title}
        </div>

      </div>

      <UpgradePlanModal
        showModal={showModal && !NoPayment}
        text={textModal}
        onCloseModal={onCloseModal}
        onCancelModal={onCloseModal}
        currentSuscription={currentSuscription}
        onSuccess={(onCloseModal)}
      />
    </div>
  )
}

export default ConsumptionCard;