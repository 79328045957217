import React, { useState, useEffect } from "react";
import { Table, Row, Col, Form, Spinner, Pagination, Button } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import devIcon from '../../assets/img/ui_icons/cld_org-s.svg'
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import Nodata from "./components/Nodata";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";


const DeploymentView = () => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [permissionsPerPage] = useState(10);

 
const history = useHistory();

  const fetchDeployments = async () =>{
    try {
      setPermissions([]);
      const cookies = new Cookies();
      const userInfo = cookies.get('101Obex');
      const response = await ObexRequestHandler.get(`/get_deployments_location?avap_id=${userInfo.avap_id}`);
      if (response.data) {
        const deployments_ = response.data;
        setPermissions(deployments_);
    } else {
        console.error('Error in the request:', response.data.error);
    }
    } catch (error) {
      console.error('Error at fetching deployments', error);
  
  }
  history.push('/deployments')
  
  }


  

  useEffect(() => {
    fetchDeployments()
  }, []);

  // Manejo de búsqueda
  const handleSearch = (e) => setSearch(e.target.value);

  const filteredPermissions = permissions.filter(
    (permission) =>
      permission.name.toLowerCase().includes(search.toLowerCase()) ||
      permission.description.toLowerCase().includes(search.toLowerCase())  ||
      permission.org_location_region.toLowerCase().includes(search.toLowerCase())
  );

  // Paginación
  const indexOfLastPermission = currentPage * permissionsPerPage;
  const indexOfFirstPermission = indexOfLastPermission - permissionsPerPage;
  const currentPermissions = filteredPermissions.slice(indexOfFirstPermission, indexOfLastPermission);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <DashLayout sider={DashRouters} active={'deployments'} sublinks={[]}>
      <Row id='topHeader'>
            <Col md='12' >
              <div className='d-flex flex-column'>
                <div className="cld_wrapperTitle">
                  <img src={devIcon} alt="icon dashboard" />
                  <span className="cld_title w-100 margin-y-24px">
                  <span style={{color:'#949498', fontSize:'14px'}}>IAM </span>/ Deployments 
                    </span>
                </div>

              </div>
            </Col>
            <Col>
              <div className="customBorder"></div>
            </Col>
          </Row>



      {loading ? (
        <Spinner animation="border" />
      ) : (
        <><div className="mt-5"></div>
        <div className="cld_borderWrapper">

         
        <span className='cld_sectionTitle'>Deployments</span>

        <div className="searchContainer">
              
                <Form.Control id='buscador'
                  type="text"
                  placeholder="Search deployments..."
                  value={search}
                  onChange={handleSearch} />
                  <Button className="customButton-transparent px-4"
                      onClick={handleSearch}><FontAwesomeIcon icon={faSearch} size="1x" />
                    </Button>
             
            </div>


            <Table className="cld_tableWrapper" responsive>
              <thead>
                <tr className="headerRow">
                  <th className="textBold">Name</th>
                  <th className="textBold">Description</th>
                  <th className="textBold">Location</th>
                </tr>
              </thead>
              <tbody>
                {currentPermissions.length === 0 ? (
                  <tr>
                    <td colSpan={3}>
                      <Nodata />
                    </td>
                  </tr>
                ) : (
                  currentPermissions.map((permission) => (
                    <tr className="tableRow" key={permission.id}>
                      <td>{permission.name}</td>
                      <td>{permission.description}</td>
                      <td>{permission.org_location_region}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
{/*}
            <Pagination className="mt-3">
              {filteredPermissions.length > 0 &&
                Array.from(
                  { length: Math.max(1, Math.ceil(filteredPermissions.length / permissionsPerPage)) },
                  (_, number) => (
                    <Pagination.Item
                      key={number + 1}
                      active={number + 1 === currentPage}
                      onClick={() => paginate(number + 1)}
                    >
                      {number + 1}
                    </Pagination.Item>
                  )
                )}
            </Pagination>
*/}

          </div></>
      )}
    </DashLayout>
  );
};

export default DeploymentView;
