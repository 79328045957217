import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col, Spinner, Dropdown, Form, Alert, Image } from 'react-bootstrap';
import { faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import Drawer from "./Drawer";
import getIcon from '../../assets/img/methods/boton-get.svg';
import postIcon from '../../assets/img/methods/boton-post.svg';
import putIcon from '../../assets/img/methods/boton-put.svg';
import optionIcon from '../../assets/img/methods/boton-options.svg';
import deleteIcon from '../../assets/img/methods/boton-delete.svg';
import patchIcon from '../../assets/img/methods/boton-patch.svg'

import ObexRequestHandler from '../../handlers/ObexRequestsHandler';

// Importar íconos 
import ProxIcon from '../../assets/img/ui_icons/cld_proxy.svg'
import { useHistory } from "react-router-dom";

const ApiManagement = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [formData, setFormData] = useState({
    endpoint: "",
    application: "",
    api: "",
    method: "",
    limits: [],
    preprocess: false,
    preprocessValue: "",
    postprocess: false,
    postprocessValue: "",
    project: '',
    apiProduct: '',
    editingId: null,
  });
  const [currentLimit, setCurrentLimit] = useState("");
  const [filtro, setFiltro] = useState('');
  const [apiProducts, setApiProducts] = useState([]);
  const [loadingApiProducts, setLoadingApiProducts] = useState(false);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [loading, setLoading] = useState(true);
const history = useHistory();
  const [tableData, setTableData] = useState([
    {
      id: 1,
      endpoint: "/api/products",
      application: "AVAP API",
      api: "API 1",
      method: "GET",
      limits: ["Month", "Day"],
      preprocess: true,
      preprocessValue: "Validate Inputs",
      postprocess: true,
      postprocessValue: "Format Response",
      apiProduct: "Product A",
    },
    {
      id: 2,
      endpoint: "/api/users",
      application: "External API",
      api: "API 2",
      method: "POST",
      limits: ["Day"],
      preprocess: false,
      preprocessValue: "",
      postprocess: true,
      postprocessValue: "Log Changes",
      apiProduct: "Product Test",
    },
    {
      id: 3,
      endpoint: "/api/orders",
      application: "Null",
      api: "API 3",
      method: "DELETE",
      limits: ["Hour"],
      preprocess: false,
      preprocessValue: "",
      postprocess: false,
      postprocessValue: "",
      apiProduct: "Product A",
    },
    {
      id: 4,
      endpoint: "/api/customers",
      application: "AVAP API",
      api: "API 1",
      method: "PUT",
      limits: ["Minute"],
      preprocess: true,
      preprocessValue: "Check Permissions",
      postprocess: false,
      postprocessValue: "",
      apiProduct: "Product B",
    },
    {
      id: 5,
      endpoint: "/api/reports",
      application: "External API",
      api: "API 2",
      method: "PATCH",
      limits: [],
      preprocess: false,
      preprocessValue: "",
      postprocess: true,
      postprocessValue: "Send Notifications",
      apiProduct: "Product A",
    },
  ]);

  const applications = ["AVAP API", "External API", "Null"];
  const methods = ["GET", "POST", "DELETE", "PUT", "PATCH"];
  const limitTypes = ["Month", "Day", "Hours", "Minute"];
  const [filteredTableData, setFilteredTableData] = useState(tableData);

  const toggleDrawer = () => {
    
    setIsDrawerOpen(!isDrawerOpen);
    history.push('/apiproxy');
  };

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const addLimit = () => {
    if (currentLimit) {
      setFormData((prevData) => ({
        ...prevData,
        limits: [...prevData.limits, currentLimit],
      }));
      setCurrentLimit("");
    }
  };

  const removeLimit = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      limits: prevData.limits.filter((_, i) => i !== index),
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const newEntry = { ...formData, id: Date.now() };
    const updatedTableData = [...tableData, newEntry];
    setTableData(updatedTableData);
    setFilteredTableData(updatedTableData); // Sincroniza los datos filtrados
    resetForm();
    toggleDrawer();
  };

  const resetForm = () => {
    setFormData({
      endpoint: "",
      application: "",
      api: "",
      method: "",
      limits: [],
      preprocess: false,
      preprocessValue: "",
      postprocess: false,
      postprocessValue: "",
      project: '',
      apiProduct: '',
      editingId: null,
    });
  };

  const handleEdit = (id: number) => {
    const entry = tableData.find((item) => item.id === id);
    if (entry) {
      setFormData({
        ...entry,
        project: '',
        apiProduct: '',
        editingId: entry.id,
      });
      toggleDrawer();
    }
  };

  const handleDelete = (id) => {
    const updatedTableData = tableData.filter((item) => item.id !== id);
    setTableData(updatedTableData);
    setFilteredTableData(updatedTableData); // Sincroniza los datos filtrados
  };

  const filterTable = () => {
    if (filtro.trim() === "") {
      setFilteredTableData(tableData); 
      return;
    }
    const filtered = tableData.filter(item =>
      item.endpoint.toLowerCase().includes(filtro.toLowerCase()) ||
      item.application.toLowerCase().includes(filtro.toLowerCase()) ||
      item.api.toLowerCase().includes(filtro.toLowerCase())
    );
    setFilteredTableData(filtered);
  };

  const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(e.target.value);
  };

  // Estado para las APIs disponibles según el API Product seleccionado
  const [fetchedApis, setFetchedApis] = useState([]);
  const [loadingApis, setLoadingApis] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === 'project') {
      setFormData((prevData) => ({ ...prevData, project: value, apiProduct: '', api: '' }));
      setLoadingApiProducts(true);
      setFetchedApis([]); // Limpiar APIs al cambiar el proyecto
      try {
        const response = await ObexRequestHandler.get(
          `/publishing/get_publish_validated_products_by_session?obex_project_id=${value}`,
          {},
          true
        );
        const publishedApis = response.data || {};
        const products = publishedApis.result || [];

        if (products.length === 0) {
          console.warn('No API Products found for the selected project.');
        }
        setApiProducts(products.map((product) => ({ id: product.api_product_id, name: product.api_product_name })));
        //setApiProducts(products.map((product) => ({ id: product.id, name: product.name })));
      } catch (error) {
        console.error('Error fetching API Products:', error);
        setApiProducts([]);
      } finally {
        setLoadingApiProducts(false);
      }
    } else if (name === 'apiProduct') {
      // Al seleccionar un apiProduct, traer las APIs
      setFormData((prevData) => ({ ...prevData, apiProduct: value, api: '' }));
      if (value) {
        setLoadingApis(true);
        setFetchedApis([]);
        try {
          const response = await ObexRequestHandler.get(`/publishing/get_apis_by_product?product_id=${value}`, {}, true);
          const data = response.data || [];
          setFetchedApis(data);
        } catch (error) {
          console.error("Error fetching APIs:", error);
          setFetchedApis([]);
        } finally {
          setLoadingApis(false);
        }
      } else {
        setFetchedApis([]);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const fetchApiData = async () => {
    try {
      setLoading(true);
      // Obtener proyectos disponibles
      const projectsResponse = await ObexRequestHandler.get(`/projects`, {}, true);
      const projectsData = projectsResponse.data || [];
      
      // Agregamos la opción "pets (ID: 59)" hardcodeada
      // const hardcodedProject = { id: 59, description: 'pets (ID: 59)' };
      const updatedProjects = [...projectsData];

      setAvailableProjects(updatedProjects);
      console.log('Projects Response:', updatedProjects);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApiData();
  }, []);

  return (
    <DashLayout sider={DashRouters} active={'api'} sublinks={[]}>
      <Row id="topHeader">
        <Col md="10">
          <div className="cld_wrapperTitle">
            <img src={ProxIcon} alt="icon dashboard" />
            <span className="cld_title w-100 margin-y-24px">
            <span style={{ color:'#949498', fontSize:'14px' }}>Gateway Manager </span>/ API Wrappers
            </span>
          </div>
        </Col>
        <Col md="2" className="align-content-center">
          <Button
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right  resBtn"
            onClick={()=>{resetForm(); toggleDrawer()}}>
            Add API Wrapper
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <Row id="table" className="mt-5">
        <Col>
          <div className="cld_borderWrapper">
            <span className='cld_sectionTitle'>API Wrappers</span>

            <div className="searchContainer">
              <Form.Control id='buscador' required type="text" value={filtro} placeholder="Search by Endpoint"
                onChange={changeFilter} />
              <Button
                className="customButton-transparent px-4"
                onClick={filterTable}>
                <FontAwesomeIcon icon={faSearch} size="1x" />
              </Button>
            </div>

            <Table className="cld_tableWrapper">
              <thead>
                <tr className="headerRow">
                  <th style={{ width: '20%' }} >Endpoint</th>
                  <th style={{ width: '10%' }} >Application</th>
                  <th style={{ width: '10%' }} >API</th>
                  <th style={{ width: '10%' }} >Method</th>
                  <th style={{ width: '10%' }} >Limits</th>
                  <th style={{ width: '20%' }} >Preprocess</th>
                  <th style={{ width: '15%' }} >Postprocess</th>
                  <th style={{ width: '5%', textAlign: 'end' }} className='textBold'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredTableData.map((item) => (
                  <tr key={item.id} className="tableRow">
                    <td className="td_centered">{item.endpoint}</td>
                    <td className="td_centered">{item.application}</td>
                    <td className="td_centered">{item.api}</td>
                    <td className="td_centered">

                    <div style={{marginLeft:'auto', marginRight:'auto', paddingLeft:'10px'}}>
                      { item.method == 'GET' && <Image height='20px' id='' src={getIcon}></Image> }

                      {item.method == 'POST' && <Image height='20px' id='' src={postIcon}></Image>}
                      {item.method == 'DELETE' && <Image height='20px' id='' src={deleteIcon}></Image>}
                      {item.method == 'OPTION' && <Image height='20px' id='' src={optionIcon}></Image>}

                      {item.method == 'PUT' && <Image height='20px' id='' src={putIcon}></Image>}
                      {item.method == 'PATCH' && <Image height='20px' id='' src={patchIcon}></Image>}
                      {item.method == undefined && <Image height='20px' id='' src={getIcon}></Image>}
                      </div>

                    </td>
                    <td className="td_centered">{item.limits.join(", ")}</td>
                    <td className="td_centered">{item.preprocess ? item.preprocessValue : "None"}</td>
                    <td className="td_centered">{item.postprocess ? item.postprocessValue : "None"}</td>
                    <td>
                      <div className="actionsWrapper">
                        <Dropdown className="my-auto" style={{ width:'100%', marginRight: '0px' }}>
                          <Dropdown.Toggle className="organizations">
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item key={1} onClick={() => handleEdit(item.id)}>
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item key={1} onClick={() => handleDelete(item.id!)}>
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>

            </Table>
          </div>
        </Col>
      </Row>

      <Drawer index="api_wrappers" isOpen={isDrawerOpen} toggleSidebar={toggleDrawer} name={formData.editingId ? 'Edit API Wrapper' : 'Add API Wrapper'}>
        <Form onSubmit={handleFormSubmit}>
          {/* Select de API Projects */}


          <Form.Group>
            <Form.Label>Application</Form.Label>
            <Form.Control
            required
              as="select"
              name="application"
              value={formData.application}
              onChange={handleFormChange}
            >
              <option value="">Select application</option>
              {applications.map((app) => (
                <option key={app} value={app}>
                  {app}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

        { (formData.application === 'External API' || formData.application === 'AVAP API')  && (

        
          availableProjects.length === 0 ? (
            <Alert variant="danger">No API Projects available. Please create one first.</Alert>
          ) : (
            <Form.Group controlId="project">
              <Form.Label>Available Projects</Form.Label>
              <Form.Control
              required
                as="select"
                name="project"
                value={formData.project}
                onChange={handleChange}
              >
                <option value="">Select a project</option>
                {availableProjects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )
        )
        }

          {/* Select de API Products */}
          {formData.project && (
            <Form.Group controlId="apiProduct">
              <Form.Label>Available API Products</Form.Label>
              {loadingApiProducts ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : apiProducts.length === 0 ? (
                <Alert variant="warning">No API Products found for this project.</Alert>
              ) : (
                <Form.Control
                required
                  as="select"
                  name="apiProduct"
                  value={formData.apiProduct}
                  onChange={handleChange}
                >
                  <option value="">Select an API Product</option>
                  {apiProducts.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </Form.Control>
              )}
            </Form.Group>
          )}







          {/* Select de APIs provenientes del apiProduct */}
          {(formData.apiProduct) && (
            <Form.Group>
              <Form.Label>Available APIs</Form.Label>
              {loadingApis ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : fetchedApis.length === 0 ? (
                <Alert variant="warning">no apis disponibles</Alert>
              ) : (
                <Form.Control
                  as="select"
                  name="api"
                  value={formData.api}
                  onChange={handleFormChange}
                  required
                >
                  <option value="">Select API</option>
                  {fetchedApis.map((api) => (

                    (api.link ? 
                      (formData.application === 'External API' &&
                    <option key={api.id} value={api.name}>
                      {api.link ? api.endpoint + ' '+ api.name : api.name}
                    </option>
                      )
                    :
                      (formData.application === 'AVAP API' &&
                    <option key={api.id} value={api.name}>
                      {api.link ? api.endpoint + ' '+ api.name : api.name}
                    </option>
                      )
                    )
                  ))}
                </Form.Control>
              )}
            </Form.Group>
          )}


{ formData.application === 'Null' &&

          <><Form.Group>
              <Form.Label>Endpoint</Form.Label>
              <Form.Control
                type="text"
                name="endpoint"
                value={formData.endpoint}
                onChange={handleFormChange}
                placeholder="Enter API endpoint" />
            </Form.Group><Form.Group>
                <Form.Label>Method</Form.Label>
                <Form.Control
                  as="select"
                  name="method"
                  value={formData.method}
                  onChange={handleFormChange}
                >
                  <option value="">Select method</option>
                  {methods.map((method) => (
                    <option key={method} value={method}>
                      {method}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group></>
}
          <Form.Group>
            <Form.Label>Limit</Form.Label>
            <div className="d-flex">
              <Form.Control
                as="select"
                value={currentLimit}
                onChange={(e) => setCurrentLimit(e.target.value)}
              >
                <option value="">Select limit type</option>
                {limitTypes.map((limit) => (
                  <option key={limit} value={limit}>
                    {limit}
                  </option>
                ))}
              </Form.Control>
              <Button variant="secondary" onClick={addLimit} className="ml-2">
                Add
              </Button>
            </div>
            {formData.limits.map((limit, index) => (
              <div key={index} className="d-flex justify-content-between align-items-center mt-2">
                <span>{limit}</span>
                <Button variant="danger" size="sm" onClick={() => removeLimit(index)}>
                  Remove
                </Button>
              </div>
            ))}
          </Form.Group>

          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Enable Preprocess"
              name="preprocess"
              checked={formData.preprocess}
              onChange={handleFormChange}
            />
            {formData.preprocess && (
              <Form.Control
                type="text"
                name="preprocessValue"
                value={formData.preprocessValue}
                onChange={handleFormChange}
                placeholder="Preprocess"
              />
            )}
          </Form.Group>

          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Enable Postprocess"
              name="postprocess"
              checked={formData.postprocess}
              onChange={handleFormChange}
            />
            {formData.postprocess && (
              <Form.Control
                type="text"
                name="postprocessValue"
                value={formData.postprocessValue}
                onChange={handleFormChange}
                placeholder="Postprocess"
              />
            )}
          </Form.Group>

          <Row>
            <Col md='6'>
              <Button type="submit" variant="primary" className="w-100">
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </DashLayout>
  );
};

export default ApiManagement;
