import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Spinner, Dropdown, Form } from 'react-bootstrap';
import { faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import Drawer from "./Drawer"; // Asegúrate de importar el componente Drawer correctamente
import EnvironmentForm from "./ambientForm"; // El formulario
import DashRouters from "../../configs/routers/dashboard-routers.json";
import ProxIcon from '../../assets/img/ui_icons/cld_proxy.svg'
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";
import { useHistory } from "react-router-dom";
import ConfirmDeletetModal from "../Modals/ConfirmDeleteModal";
import Nodata from "./components/Nodata";



type Environment = {
  active: any;
  opened: any;
  id: string;
  ambient: {id: number, name: string};
  port: string;
  visibility: boolean;
  whiteListEnabled: boolean;
  whiteList: string[];
  apiProducts: any[];
  alias: string;
  ambiente: string;
  ambiente_id: any;
};

const AMBIENT_LABELS = {
  1: { name: 'Live', color: '#28a745' },        // Verde
  2: { name: 'Live Clone', color: '#CE9C06' }, // Amarillo
  3: { name: 'Live', color: '#28a745' }, // Amarillo
  Active: { name: 'Active', color: '#28a745' },        // Verde
  Disabled: { name: 'Disabled', color: 'red' },  
};

const getAmbientLabel = (ambientId: number) => {
  const ambient = AMBIENT_LABELS[ambientId];
  if (!ambient) return null;

  return (
    <span 
      style={{
        backgroundColor: ambient.color,
        color: '#fff',
        padding: '4px 8px',
        borderRadius: '4px',
        fontSize: '12px',
      }}
    >
      {ambient.name}
    </span>
  );
};

const EnvironmentManager = () => {
  const [filteredEnvironments, setFilteredEnvironments] = useState<Environment[]>([]);
  const [apiProducts, setAPIProducts] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [sel_products, set_sel_products] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limitEnvironments, setLimitEnvironments] = useState(0)
  const [initialized, setInitialized] = useState(false);

  const AMBIENT_LABELS = {
    Visible: { name: 'Visible', color: '#28a745' },        // Verde
    Private: { name: 'Private', color: 'red' }, // Amarillo
    No:  { name: 'No', color: 'red' },
    Yes: { name: 'Yes', color: '#28a745' },
    //3: { name: 'Live', color: '#28a745' }, // Amarillo
    Active: { name: 'Active', color: '#28a745' },        // Verde
    Disabled: { name: 'Disabled', color: 'red' },
  
  };
  
  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;
  
    return (
      <span 
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth:'100px',
          display:'flex',
          width:'65px',
          justifyContent:'center',
          marginLeft:'auto',
          marginRight:'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };

  const getGatewayAPIProducts = async () => {
    setLoading(true);
    console.log('[ENV] getGatewayAPIProducts')

    const apiproduct_configs = await ObexRequestHandler.get(`/gateway/get_gateway_products?organization=${SessionService.ORGANIZATION}`)

    const apiproduct = apiproduct_configs.data.map((prox) => ({ 
      id: prox.id, 
      name: prox.avap_api_product_name,
      type: prox.api_type_name
    }));

    console.log(apiproduct);

    setAPIProducts(apiproduct);

    history.push('/ambients')
    setLoading(false)

  };



  const [environments, setEnvironments] = useState<Environment[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editingEnvironment, setEditingEnvironment] = useState<Environment | null>(null);
  const history = useHistory();
  const handleSaveEnvironment = (newEnvironment: Environment) => {
    console.log('[ENV] handleSaveEnvironment')

    /*
    if (editingEnvironment) {
      // Update existing environment
      setEnvironments(
        environments.map((env) =>
          env.id === editingEnvironment.id ? { ...newEnvironment, id: editingEnvironment.id } : env
        )
      );
      
    } else {
      // Add new environment
      setEnvironments([
        ...environments,
        { ...newEnvironment, id: Math.random().toString(36).substr(2, 9) },
      ]);
    }
*/
   // getEnvironments();
   // getGatewayAPIProducts();

   // setDrawerOpen(false);
   getEnvironments();
   getGatewayAPIProducts();

    setDrawerOpen(false);
  };

const handleCloseDrawer = (newEnvironment: Environment) =>{
  getEnvironments();
   getGatewayAPIProducts();
    console.log('[ENV] handleClose')
    setDrawerOpen(false);
}

  const [recovered_tokens, set_recovered_tokens] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selDeveloper, setSelDeveloper] = useState({id:'' })
  const [selTeam, setselTeam] = useState({id:0, type:'', name:''});

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  }



  const handleDisableEnvironment = async (id) => {
    
    //handleShowDelete();
    await ObexRequestHandler.get(`/gateway/disable_gateway_ambient?environment_id=${id.id}&organization=${SessionService.ORGANIZATION}`);

    getEnvironments();
    getGatewayAPIProducts();
  
};

const handleEnableEnvironment = async (id) => {
    
  //handleShowDelete();
  await ObexRequestHandler.get(`/gateway/enable_gateway_ambient?environment_id=${id.id}&organization=${SessionService.ORGANIZATION}`);

  getEnvironments();
  getGatewayAPIProducts();

};



  const handleDelete = async (developer) => {
    setShowDelete(true);
    setSelDeveloper(developer);
    setselTeam(developer);

  }




  const handleDeleteEnvironment = async (id: string) => {
    
      handleShowDelete();
      await ObexRequestHandler.post(`/gateway/delete_ambients?ambient_id=${id}`);

      getEnvironments();
      getGatewayAPIProducts();
    
  };

  const handleOpenDrawer = async (environment?: Environment) => {
    if (environment) {
      let gg = await ObexRequestHandler.get(`/gateway/get_ambient_data?ambient_id=${environment.id}`);
      console.log('.,.,.,.,.,.,.,.,.,,----0---.-.-.-.-.-.-.-.-')
      console.log(gg);
      console.log(gg.data);
      console.log(gg.data[0]);
      console.log('.,.,.,.,.,.,.,.,.,,----1---.-.-.-.-.-.-.-.-')
      console.log(gg.data[0].api_products);
      set_sel_products(gg.data[0].api_products)
      console.log(environment)
      console.log(gg.data[0].white_list_enabled)
      console.log('.,.,.,.,.,.,.,.,.,,----2---.-.-.-.-.-.-.-.-')
      try{
      environment.whiteListEnabled = gg.data[0].white_list_enabled
      } catch{ 
        console.log("XXXX ERROR ZZZ")
      }
      setEditingEnvironment(environment);
    } else {
      setEditingEnvironment(null);
    }
    setDrawerOpen(true);
    history.push('/ambients')
    
  };


  const getEnvironments = async () => {
    console.log('[ENV] getEnviroments')
    try{
    setLoading(true);
    const envio = await ObexRequestHandler.get(`/gateway/get_gateway_ambients?organization=${SessionService.ORGANIZATION}`);
    const dataAmbients = envio.data
    
    console.log('Respuesta de ambientes:', {
        respuestaOriginal: envio,
        dataAmbients: dataAmbients,
    });

    const finalEnviroment = dataAmbients.map((envi)=>({
      active: envi.active,
      alias: envi.alias,
      id: envi.id,
      ambient: envi.alias,
      port: envi.port,
      visibility: envi.visibility,
      whiteListEnabled: envi.white_list_enabled,
      whiteList: envi.whitelisted_ips || [],
      ambiente: envi.ambient_name,
      ambiente_id: envi.ambient_id
    }))

    console.log('Datos procesados para la tabla:', finalEnviroment);

    setEnvironments(finalEnviroment);
    set_recovered_tokens(finalEnviroment);

    const filtered = finalEnviroment.filter(env =>
      env.ambient.toLowerCase().includes(filtro.toLowerCase())
    );
    setFilteredEnvironments(filtered);
    setLoading(false);
    setInitialized(true);
  } catch{
    setInitialized(true);
  }
  };
  const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(e.target.value);
    filterProjects(e.target.value);
  };


  const filterProjects = async (filtro) => {

    const filter_projects = recovered_tokens.filter(element =>
      element.ambient.toLowerCase().includes(filtro.toLowerCase())
    );
    setEnvironments(filtro === '' ? recovered_tokens : filter_projects);
  
  }

  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/ambients'){
      history.push('/ambients')
      SessionService.updating = SessionService.updating -3;
      getEnvironments();
      getGatewayAPIProducts();
    window.scrollTo(0, 0);
    history.push('/ambients')
    }
  
  }, false);


  useEffect(() => {

    try{
    SessionService.loginData.limits.forEach(ll =>{
      if (ll.org_id==SessionService.ORGANIZATION){
        setLimitEnvironments(ll.subscription_type.environments || 0)
      }
    })
  } catch {}

    getEnvironments();
    getGatewayAPIProducts();


    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };



 
  }, []);


  return (
    <DashLayout sider={DashRouters} active="environments" sublinks={[]}>
      <Row id='topHeader'>
        <Col md="10">
          <div className="cld_wrapperTitle">
            <img src={ProxIcon} alt="icon dashboard" />
            <span className="cld_title w-100 margin-y-24px">
            <span style={{ color:'#949498', fontSize:'14px' }}>Gateway Manager </span>/ Environments 
              
            </span>
          </div>
        </Col>
        <Col md="2" className="align-content-center">
          <Button
            disabled={environments.length>=limitEnvironments}
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right  resBtn"
            onClick={() => handleOpenDrawer()}>
            Add Environment
          </Button>
        </Col>
        <Col> <div className="customBorder"></div></Col>
      </Row>

      <Row id="table" className="mt-5">
        <Col>
        {loading ? (
                <Spinner animation="border" />
              ) : (
          <div className="cld_borderWrapper">
            <span className='cld_sectionTitle'>Environments</span>

            <div className="searchContainer">
              <Form.Control 
                id='buscador' 
                required 
                type="text" 
                value={filtro} 
                placeholder="Search by Environments"
                onChange={changeFilter} 
              />
              <Button
                className="customButton-transparent px-4"
                onClick={getEnvironments}>
                <FontAwesomeIcon icon={faSearch} size="1x" />
              </Button>
            </div>
            {(environments.length === 0 && initialized)? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                <Nodata />
              </div>
            ) : (
            <Table className="cld_tableWrapper">
              <thead>
                <tr className="tableRow">
                  <th style={{ width: '20%' }} className='textBold'>Environment</th>
                  <th style={{ width: '15%' }} className='textBold'>Mode</th>
                  <th style={{ width: '15%' }} className='textBold'>Port</th>
                  <th style={{ textAlign:'center', width: '15%' }} className='textBold'>Status</th>
                  <th style={{ textAlign:'center', width: '15%' }} className='textBold'>Visibility</th>
                  <th style={{ textAlign:'center', width: '15%' }} className='textBold'>White List Enabled</th>
                  <th style={{ width: '15%' }} className='textBold'>White List Items</th>
                  <th style={{ width: '5%', textAlign: 'end' }} className='textBold'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {(environments.length > 0 ? environments : environments).map((env) => (
                  <tr className="tableRow" key={env.id}>
                    <td className="td_centered">{env.ambient}</td>
                    <td className="td_centered">{getAmbientLabel(env.ambiente_id)}</td>
                    <td className="td_centered">{env.port}</td>
                    <td className="td_centered">{env.active ? getAmbientLabel('Active') : getAmbientLabel('Disabled')}</td>
                    <td style={{ textAlign:'center' }} className="td_centered">{env.visibility ? getAmbientLabel('Visible') : getAmbientLabel('Private')}</td>
                    <td style={{ textAlign:'center' }}className="td_centered">{env.whiteListEnabled ? getAmbientLabel('Yes') : getAmbientLabel('No')}</td>
                    <td className="td_centered">{env.whiteList && env.whiteList.length > 0 ? (env.whiteList.join(", ") || '-') : 'N/A'}</td>
                    <td>
                      <div className="actionsWrapper">
                        <Dropdown className="my-auto" style={{ width:'100', marginRight: '0px' }}>
                          <Dropdown.Toggle className="organizations">
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item key={1} onClick={() => handleOpenDrawer(env)}>Edit</Dropdown.Item>
                            <Dropdown.Item key={2} onClick={() => handleDelete(env)}>Delete</Dropdown.Item>
                          { env.active ?
                            <Dropdown.Item key={3} onClick={() => handleDisableEnvironment(env)}>Disable</Dropdown.Item> :
                            <Dropdown.Item key={4} onClick={() => handleEnableEnvironment(env)}>Enable</Dropdown.Item>
                          }
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}

              </tbody>

            </Table>
                )}
          </div>)}
        </Col>
      </Row>

      <Drawer
        isOpen={drawerOpen}
        index="ambients"
        toggleSidebar={() => {
          setDrawerOpen(false); 
          history.push('/ambients');
          // getEnvironments();
          // getGatewayAPIProducts();
        }}
        name={editingEnvironment ? 'Edit Environment' : 'Add Environment'}
      >
        <EnvironmentForm
        onClose={handleCloseDrawer}
          alias={editingEnvironment?.ambient}
          products={sel_products}
          opened={drawerOpen}
          id={editingEnvironment?.id}
          ambient={editingEnvironment?.ambient || { id:0, name:'' }}
          port_id={0}
          port={editingEnvironment?.port || ''}
          visibility={editingEnvironment?.visibility || false}
          whiteListEnabled={editingEnvironment?.whiteListEnabled || false}
          whiteList={editingEnvironment?.whiteList || []}
          onSubmit={handleSaveEnvironment}
          apiProducts={apiProducts}
          editing={editingEnvironment}
          ambiente={editingEnvironment?.ambiente || ''}
          ambiente_id={editingEnvironment?.ambiente_id || 0}
        />

      </Drawer>

      <ConfirmDeletetModal 
          handleSubmit={()=>handleDeleteEnvironment(selDeveloper.id)} 
          showModal={showDelete}
          onCloseModal={handleShowDelete}
          loading={false}
          error={''}
          success={''}
          name_to_check={'DELETE'}
          title_to_show={`Confirm delete environmet ${selTeam.name}`}
        />



    </DashLayout>
  );
};

export default EnvironmentManager;
