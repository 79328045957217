import React, { useState } from "react";
import { Accordion, Card } from 'react-bootstrap';

import icn_item from '../../../assets/img/ui_icons/cld_learn.svg';
import chevron from '../../../assets/img/ui_icons/chevron.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faGraduationCap, faSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquarespace } from "@fortawesome/free-brands-svg-icons";



interface LearningProps {
  information: any[]
}

const LearningDrawer: React.FC<LearningProps> = ({ information }) => {
    /*
    const [openIndex, setOpenIndex] = useState(null); // Estado para almacenar el índice del Accordion abierto

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Cambia el estado para abrir/cerrar el índice específico
    };

*/
    const [openIndex, setOpenIndex] = useState(null); // Guardar el índice abierto

    const toggleAccordion = (index) => {
      // Si el acordeón ya está abierto, ciérralo; si no, ábrelo
      setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const [selected, setSelected] = useState(-1)


    const cierraAccor = (indice) =>{

        const element = document.getElementById(`acordeon${indice}`);
        if (element) {
          element.classList.remove('show');
        }
    }

    return (
        <div
        style={{
            maxWidth: '100%',
            marginLeft:'0px',
            //minWidth: '272px',
            //height:'96%',
            //marginRight:'-1vw',
            //minHeight:'400px',
            //marginTop:'-7px'

        }}
        id='w_learn' className="item h-type-4">
            <div className="title">
                <span><img style={{height:'15px'}} src={icn_item} alt="" /></span>
                <p>Learning</p>
            </div>
            <div className="flex-full mt-3 accordionWrapper">
                <Accordion className="customAccordion">
                 
                        <Accordion.Toggle
                            id="acordeon1"
                            as={Card.Header}
                            eventKey="0"
                            className="customHeader"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {toggleAccordion(0)}}
                        >
                            <div>
                                <span style={{marginRight:'0px'}}>{/*<FontAwesomeIcon icon={faSquare}/>*/}</span>
                                <span>{information[0].title}</span>
                            </div>
                            <span className="ms-auto">
                                <img
                                    src={chevron}
                                    alt=""
                                    className={`chevron-icon ${openIndex === 0 ? 'rotate' : ''}`}
                                />
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0" in={openIndex === 0}>
                            <Card.Body>{information[0].content}</Card.Body>
                        </Accordion.Collapse>
          
                </Accordion>
                { information[1]!=undefined &&
                <Accordion className="customAccordion">
             
                        <Accordion.Toggle
                            as={Card.Header}
                            id="acordeon2"
                            eventKey="1"
                            className="customHeader"
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleAccordion(1)}
                        >
                            <div>
                            <span style={{marginRight:'0px'}}>{/*<FontAwesomeIcon icon={faSquare}/>*/}</span>
                                <span>{information[1].title}</span>
                            </div>
                            <span className="ms-auto">
                                <img
                                    src={chevron}
                                    alt=""
                                    className={`chevron-icon ${openIndex === 1 ? 'rotate' : ''}`}
                                />
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1" in={openIndex === 1}>
                            <Card.Body>{information[1].content}</Card.Body>
                        </Accordion.Collapse>
   
                </Accordion>
}
                { information[2]!=undefined &&
                <Accordion className="customAccordion">
      
                        <Accordion.Toggle
                            as={Card.Header}
                            id="acordeon2"
                            eventKey="2"
                            className="customHeader"
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleAccordion(2)}
                        >
                            <div>
                            <span style={{marginRight:'0px'}}>{/*<FontAwesomeIcon icon={faSquare}/>*/}</span>
                                <span>{information[2].title}</span>
                            </div>
                            <span className="ms-auto">
                                <img
                                    src={chevron}
                                    alt=""
                                    className={`chevron-icon ${openIndex === 2 ? 'rotate' : ''}`}
                                />
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2" in={openIndex === 2}>
                            <Card.Body>{information[2].content}</Card.Body>
                        </Accordion.Collapse>

                </Accordion>
}
{ information[3]!=undefined &&
                <Accordion className="customAccordion">
    
                        <Accordion.Toggle
                            as={Card.Header}
                            id="acordeon3"
                            eventKey="3"
                            className="customHeader"
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleAccordion(3)}
                        >
                            <div>
                            <span style={{marginRight:'0px'}}>{/*<FontAwesomeIcon icon={faSquare}/>*/}</span>
                                <span>{information[3].title}</span>
                            </div>
                            <span className="ms-auto">
                                <img
                                    src={chevron}
                                    alt=""
                                    className={`chevron-icon ${openIndex === 3 ? 'rotate' : ''}`}
                                />
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3" in={openIndex === 3}>
                            <Card.Body>
                            {information[3].content}
                            </Card.Body>
                        </Accordion.Collapse>
                   
                </Accordion>
}
            </div>
            <div className="bottom"></div>
        </div>
    );
}

export default LearningDrawer;
