import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import { Row, Col, Card, Form, InputGroup, FormControl, Button, Container, Spinner, Table, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck, faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';
import Nodata from '../DashboardComponents/components/Nodata';

type ApiKeysProps = {
};

const ApiKeysContent:FunctionComponent<ApiKeysProps> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [projects, setProjects] = useState([]);
  const [stillLoaded, SetStillLoaded] = useState(true);
  const [Copied, setCopied] = useState('');
  const [filter, setFilter] = useState('');
  const [apiKeys, setApiKeys] = useState([]);

  const getProjects = async () => {
    try {
      setLoading(true);
      const p = (await ObexRequestsHandler.get('/projects')).data;
      setProjects(p);
    } catch (error) {
      console.error('ERROR FETCHING LOGS ', error);
      setError(error);
    }
    setLoading(false);
    SetStillLoaded(false);
  }

  const alertaCopy = (identificador) =>{
    setCopied(identificador);
    setTimeout(() => { 
      setCopied('');
    }, 2000);

  }

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const fetchApiKeys = async (filter) => {
    try {
      setLoading(true);
      const response = await ObexRequestsHandler.get('/api-keys', { params: { filter } });
      setApiKeys(response.data);
    } catch (error) {
      console.error('ERROR FETCHING API KEYS ', error);
      setError(error);
    }
    setLoading(false);
  };

  const handleDownloadKey = (key) => {
    // Implementation of handleDownloadKey
  };

  const handleRevokeKey = (id) => {
    // Implementation of handleRevokeKey
  };

  useEffect(() => {
    getProjects();
  }, [])

  return (
    <>
      <BrowserView>
    <DashLayout sider={DashRouters} active="developers">
      {stillLoaded ?

        <Container className="vh-100 d-flex flex-column vw-100"> 
            <Row className="h-100" >

            
          <Col md="1" className="mx-auto d-flex align-items-center">
            <Spinner animation="border"/>
          </Col>

        </Row>
        </Container>
          :
      <Row>
        <Col md="9" className="mx-auto">
          <h1>API Keys</h1>
          <Row>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <div className="cld_borderWrapper">
                <span className='cld_sectionTitle'>API Keys</span>

                <div className="searchContainer">
                  <Form.Control
                    id="buscador"
                    required
                    type="text"
                    value={filter}
                    placeholder="Search by API Key"
                    onChange={handleFilterChange}
                  />
                  <Button
                    className="customButton-transparent px-4"
                    onClick={() => fetchApiKeys(filter)}
                  >
                    <FontAwesomeIcon icon={faSearch} size="1x" />
                  </Button>
                </div>

                <Table className="cld_tableWrapper">
                  <thead>
                    <tr className='tableRow'>
                      <th className="textBold">Key</th>
                      <th className="textBold">Description</th>
                      <th className="textBold">Created</th>
                      <th className="textBold">Expires</th>
                      <th className="textBold">Status</th>
                      <th className="textBold" style={{ textAlign: 'end' }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apiKeys.length === 0 ? (
                      <tr>
                        <td colSpan={12}>
                          <div className="nodata-wrapper">
                            <Nodata />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      apiKeys.map((key) => (
                        <tr className='tableRow' key={key.id}>
                          <td className='td_centered'>{key.key}</td>
                          <td className='td_centered'>{key.description}</td>
                          <td className='td_centered'>{key.created.split('T')[0]}</td>
                          <td className='td_centered'>{key.expires.split('T')[0]}</td>
                          <td className='td_centered'>{key.status ? 'Active' : 'Inactive'}</td>
                          <td className='td_centered text-right'>
                            <div className="actionsWrapper">
                              <Dropdown className='my-auto' style={{ width: '100%', marginRight: '-15px' }}>
                                <Dropdown.Toggle className="organizations">
                                  <FontAwesomeIcon icon={faEllipsisV} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item key={1} onClick={() => { handleDownloadKey(key) }}>Download</Dropdown.Item>
                                  <Dropdown.Item key={2} onClick={() => handleRevokeKey(key.id)}>Revoke</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            )}
          </Row>
        </Col>
      </Row>

      

    }

    </DashLayout>
    </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default ApiKeysContent;