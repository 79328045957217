import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Button, Row, Col, Form, Alert, Spinner, Dropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import SessionService from '../../services/SessionsService';

interface Region {
  id: number;
  region: string;
  server: string;
  code: string;
}

interface Developers {
  dev_id: number,
  email: string,
}

type EditTeamProps = {
  isOpen:boolean,
  organization?: number;
  organization_name?: string;
  organization_description?: string;
  organization_location_name?: string;
  organization_location_id?: number;
  organization_location?: {region_name: string, region_id: number};
  organization_responsible?: string;
  organization_responsible_id?: number;
  organization_devs?: Developers[];
  regions?: Region[];
  handleClose: () => void;
};

const EditOrganizationForm: FunctionComponent<EditTeamProps> = (props) => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [location, setLocation] = useState('');
  const [locationID, setLocationID] = useState(11);
  const [responsible, setResponsible] = useState('');
  const [responsibleID, setResponsibleID] = useState(0);
  const [developers, setDevelopers] = useState([]); // Lista de desarrolladores
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [enfocado, setEnfocado] = useState(false);

  const yearInputRef = useRef(null);

  const changeCardNumber = (e) => {
    const newCardNumber = e.target.value;
    setCardNumber(newCardNumber);
    validateForm(newCardNumber, cardholder);
  };

  const changeCardholder = (e) => {
    const newCardholder = e.target.value;
    setCardholder(newCardholder);
    validateForm(cardNumber, newCardholder);
  };

  const changeLocation = (e) => {

    //console.log(e.target.value)
    let selectedRegionID = 11;
    props.regions.forEach((reg)=>{
      //console.log(reg)
      if (reg.region == e.target.value) selectedRegionID = reg.id;
      
      })

    
    setLocation(e.target.value); // No afecta la validación ni el envío
    setLocationID(selectedRegionID);


    validateForm(cardNumber, 'newCardholder');
  };

  const changeResponsible = (e) => {
    setResponsible(e.target.value); // Cambia el valor del responsable seleccionado


    let selectedResposibleID = 0;
    props.organization_devs.forEach((dev)=>{
      //console.log(dev)
      if (dev.email == e.target.value) selectedResposibleID = dev.dev_id;
      
      })



    setResponsibleID(selectedResposibleID);
    validateForm(cardNumber, 'newCardholder');
  };

  const validateForm = (desc, name) => {
    console.log('[DRAWER] Validate form', submitDisabled)
    // setSubmitDisabled(false);
    
    if (desc.length > 1 && name.length > 1) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const fetchDevelopers = async () => {
    try {
      const response = await ObexRequestHandler.get(`/organizations/${props.organization}/developers`);
      setDevelopers(response.data || []);
    } catch (err) {
      console.error('Error fetching developers:', err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    // Crear payload solo con los campos obligatorios
    const payload = {
      organization: props.organization,
      description: cardNumber || props.organization_description,
      name: cardholder || props.organization_name,
      location: locationID || props.organization_location_id,
      responsible: responsibleID || props.organization_responsible_id, // Enviar el ID del desarrollador seleccionado como responsable
    };
    //console.log(payload)

    
    try {
      const result = await ObexRequestHandler.post('/organizations', payload);

      //console.log(result);

      const projectos = (await ObexRequestHandler.get('/organizations', {}, true)).data || [];
      SessionService.ORGANIZATIONS = projectos;

      if (result.success) {
        setSuccess(result.data);
        setTimeout(() => {
          setSuccess('');
          setSubmitDisabled(true);
          props.handleClose();
    //      props.handleClose();

    const event = new Event('organizationsChange');

// Disparar el evento en el documento
document.dispatchEvent(event);

        }, 1000);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('An error occurred while updating the organization.');
    } finally {
      setLoading(false);
    }
  
  };

  useEffect(()=>{
    setSubmitDisabled(true);
    setCardholder(props.organization_name);
    setCardNumber(props.organization_description)
    setLocation(props.organization_location_name.replace('Sillicon Valley', 'Silicon Valley'))
    setResponsibleID(props.organization_responsible_id);
    setResponsible(props.organization_responsible);
    console.log('[ORG] ', props.organization_devs )
  }, [props.organization, props.organization_name, props.isOpen])
  
  useEffect(() => {

    //console.log(props)


    if (!enfocado) {
      document.getElementById('entrada').focus();
      setEnfocado(true);
    }
    if (cardNumber && cardholder && location) {
      console.log('[DRAWER] ->',cardNumber,  props.organization_description, cardholder ,props.organization_name, location,props.organization_location_name, responsibleID , props.organization_responsible_id)

      if ((cardNumber && cardNumber != props.organization_description) || (cardholder && cardholder !=props.organization_name) ||
       (location && location !=props.organization_location_name.replace('Sillicon Valley', 'Silicon Valley') ) || (responsibleID && responsibleID != props.organization_responsible_id)) {
        setSubmitDisabled(false);

      }
    }
    //fetchDevelopers(); // Cargar los desarrolladores al montar el componente
  }, [enfocado, cardNumber, cardholder, responsibleID, location]);

  return (
    <Row>
      <Col md="12" className="mx-auto">
        <Form className="obex-form payment-method-form" onSubmit={handleSubmit}>
          <Form.Row className="mb-3">
            <Form.Label style={{fontSize:'16px'}} column sm="12" className="obex-form-label">
              Organization Name
            </Form.Label>
            <Form.Control
              id="entrada"
              required
              type="text"
              placeholder="Name"
              value={cardholder /*|| props.organization_name*/}
              onChange={changeCardholder}
            />
          </Form.Row>
          <Form.Row className="mb-3">
            <Form.Label column sm="12" className="obex-form-label">
              Organization Description
            </Form.Label>
            <Col md="12">
              <Form.Control
                required
                type="text"
                maxLength={40}
                value={cardNumber /*|| props.organization_description*/}
                placeholder="Description"
                onChange={changeCardNumber}
              />
            </Col>
          </Form.Row>
          <Form.Row className="mb-3">
            <Form.Label column sm="12" className="obex-form-label">
              Location
            </Form.Label>
            <Col md="12">
            {/*}
              <Form.Control
                type="text"
                maxLength={40}
                value={location}
                placeholder="Location"
                onChange={changeLocation}
                
              />*/}

              <Form.Control
              disabled
                as="select"
                value={location /*|| props.organization_location_name*/ }
                onChange={changeLocation}
                >
                <option value="">Select a location</option>
                {props.regions.map((reg) => (
                  <option key={reg.id} value={reg.region}>
                    {reg.region}
                  </option>
                ))}
              </Form.Control>


            </Col>
          </Form.Row>
          <Form.Row className="mb-3">
            <Form.Label column sm="12" className="obex-form-label">
              Responsible
            </Form.Label>
            <Col md="12">
              <Form.Control
                as="select"
                value={responsible || props.organization_responsible}
                onChange={changeResponsible}
              >
                <option value="">Select a responsible developer</option>
                {props.organization_devs.map((dev) => (
                  <option key={dev.dev_id} value={dev.email}>
                    {dev.email}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Row>
          <Form.Row className="my-3">
            <Col md="6" >
              <Button disabled={submitDisabled} type="submit" variant='primary' className="w-100">
                Update {success && <FontAwesomeIcon icon={faCheck} />}
                {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
              </Button>
            </Col>
          </Form.Row>
        </Form>
        {error && <Alert variant="danger">{error}</Alert>}
      </Col>
    </Row>
  );
};

export default EditOrganizationForm;
