import React from 'react';
import LearningDrawer from '../../newViews/avapCloud/widgets/LearningDrawer';

interface DrawerProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  name?: string; // Nueva prop opcional `name`
  subtitle?: string;
  children: React.ReactNode;
  index: string;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, toggleSidebar, name, children, subtitle, index }) => {

  
  let information = [{ title:'What is an organization', content: 'An organization is the highest-level entity used to structure and manage information within the platform. ' }]

  if (index=='organizations'){}
  if (index=='organizations'){
    information = [
      { title:'What is an organization', content: 'An organization is the highest-level entity used to structure and manage information within the platform. ' },
      { title:'Location of an organization', content: 'An organization\'s location determines the location of the primary advanced gateway and main proxy. ' },
      { title:'Responsible of an organization', content: 'When an organization is created, an owner is assigned to manage and oversee it within the platform.' },
      { title:'Changes in an organizarton', content: 'An owner can update an organization\'s basic information as needed. ' }
    ]
  }
  if (index=='users'){
    information = [
      { title:'Types of users', content: 'There are preconfigured user types, each assigned to specific teams with defined roles and permissions.' },
      { title:'Rol assignment', content: 'A user can have multiple roles, gaining access to the combined privileges of all assigned roles. ' },
      { title:'User deletion', content: 'Users can be removed at any time from the list. ' },
    ]
  }
  if (index=='teams'){
    information = [
      { title:'What is a team', content: 'A team is a group of users to whom roles are assigned.' },
      { title:'Public and private teams', content: 'A team can be public, accessible to all organizations in a deployment, or private, exclusive to one organization. ' },
      { title:'Responsible of an organization', content: 'When an organization is created, an owner is assigned to manage and oversee it within the platform.' },
      { title:'Team elements', content: 'When creating a team, you define the developers, select permissions, and assign roles to the team.' }
    ]
  }
  if (index=='roles'){
    information = [
      { title:'What is a role', content: 'A role defines a set of privileges within the platform.' },
      { title:'Types of roles', content: 'There are several predefined roles available within the platform.' },
      { title:'Custom roles', content: 'A manager can create custom roles and assign specific permissions to each one.' },
     
    ]
  }
  if (index=='permits'){
    information = [
      { title:'What is a permit', content: 'A permit defines a privilege, with one permit for each action within the platform.' },
      { title:'Types of permits', content: 'There are different permit types, organizing privileges within the IAM system for better management.' },
      { title:'Permision details', content: 'In the Permissions section of the Developer Portal, you can find detailed information about each permit and its operational specifics. ' },
     
    ]
  }

  if (index=='tokens'){
    information = [
      { title:'Developer token', content: 'It is the credential that identifies a developer within the platform.' },
      { title:'Types of developer tokens', content: 'The following tokens can be created: Developer Token, JWT, and SAML.' },
      { title:'Creating a token with avapcli', content: 'Run avapcli init despliegue to create a token using avapcli.' },
     
    ]
  }

  if (index=='certificates'){
    information = [
      { title:'What is a Certificate', content: 'Certificates are used to control access to proxies.' },
      { title:'Uses and Application of Certificates', content: 'Certificates are used for securing access, authentication, and controlling communication with proxies.' },   
    ]
  }
  if (index=='keys'){
    information = [
      { title:'What is a consumption Key', content: 'A consumption key allows access to and usage of APIs. ' },
      { title:'How to use a consumption key', content: 'Include the key in API requests to authenticate and access authorized services. ' },   
    ]
  }
  if (index=='projects'){
    information = [
      { title:'What is an API project', content: 'A consumption key allows access to and usage of APIs. ' },
      { title:'Location of an API project', content: 'Include the key in API requests to authenticate and access authorized services. ' },   
      { title:'API projects database', content: 'You can choose to integrate your own database for API data management. ' },   
    ]
  }
  if (index=='publisher'){
    information = [
      { title:'OpenAPI specs', content: 'Add a spec file to standardize your API according to international standards.' },
    ]
  }

  if (index=='developer_portal'){
    information = [
      { title:'What is a Developer Portal', content: 'A Developer Portal is a centralized hub for managing, testing, and consuming APIs. ' },
      { title:'Elements of a Developer Portal', content: 'Includes basepath, name, project assignment, and API product assignment.' },
    ]
  }

  if (index=='dev_portal_users'){
    information = [
      { title:'Access to a Developer Portal', content: 'Grant users access to an organization\'s Developer Portal for API management and collaboration.' },
      { title:'How to add users', content: 'Users can be added using only their email and password.' },
    ]
  }

  if (index=='doc_index'){
    information = [
      { title:'What are document indexes', content: 'Document indexes organize and optimize data retrieval for efficient searches within the platform.' },
    ]
  }

  if (index=='docs'){
    information = [
      { title:'What are documents', content: 'Documents store structured or unstructured data for APIs, enabling efficient access and management. ' },
      { title:'Select indexes and Developer Portals', content: 'Assign document indexes and link APIs to the appropriate Developer Portals for accessibility.' },
    ]
  }

  if (index=='proxies'){
    information = [
      { title:'What are proxies', content: 'Proxies act as intermediaries, managing API traffic and enforcing security policies within the gateway.' },
      { title:'Add proxies', content: 'Configure GA list, certificates, and load balancer environments for efficient traffic management.' },
    ]
  }
  if (index=='ambients'){
    information = [
      { title:'Select an ambient', content: 'Choose from local, test, or live environments, with predefined settings for each.' },
      { title:'Assign a port to an ambient', content: 'Allocate a specific port to each ambient (local, test, live) for proper routing.' },
      { title:'Visibility of an ambient', content: 'Set ambient visibility to private or network and enable a whitelist for access control.' },
    ]
  }

  if (index=='product_warppers'){
    information = [
      { title:'What is an API Product Wrapper?', content: 'A layer that adapts APIs to REST, SOAP, GraphQL, or RPC for seamless integration.' },
    ]
  }

  if (index=='api_wrappers'){
    information = [
      { title:'What is an API Wrapper?', content: 'A configuration layer that allows selecting applications, setting limits, and enabling pre/post-processing.' },
    ]
  }



  return (
    <>
      {/* Overlay de fondo oscuro */}
      {isOpen && <div className="overlay" onClick={
        ()=>{
          console.log('CERRANDO');
          toggleSidebar();
      }}></div>}

      <div className={`lateral drawer overflowX ${isOpen ? 'open' : ''}`}>

      <div className='drawer-content-card'>
        <div className="drawer-header">
          <div className="drawerWrapper">
          <span>{'' || ''}</span>
            <span style={{ fontSize:'18px', fontWeight:700 }}>{name || 'Drawer'}</span>
          </div>
          {false && <button style={{ marginTop:'20px' }} className="close-btn" onClick={toggleSidebar}>✕</button>}
        </div>

        {/* Contenido dinámico */}
        <div className="drawer-content">
          {children}
          </div>
        </div>
        <div style={{ height:'10px' }}></div>





        <LearningDrawer information={information} />
      </div>
    </>
  );
};

export default Drawer;
