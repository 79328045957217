import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import MarkdownEditor from "../../../components/DashboardComponents/components/EasyMDE ";
import ObexRequestHandler from "../../../handlers/ObexRequestsHandler";
import { DynamicButton } from "./DynamicButton";

interface DocDrawerProps {
    documenta?: string;
    obex_project_id: string;
    docInfo: {
        project_id: string;
        method: string;
        endpoint: string;
        gateway_id: string | null;
    };
    api_product_id: string;
    portalStyles?: {
        primaryColor: string;
        backgroundColor: string;
        textColor: string;
    };
}

const DocDrawer: React.FC<DocDrawerProps> = ({ 
    documenta, 
    obex_project_id, 
    docInfo, 
    api_product_id,
    portalStyles = { primaryColor: '#000', backgroundColor: '#fff', textColor: '#000' }
}) => {
    const [documentationContent, setDocumentationContent] = useState("");

    const handleSave = async () => {
        const payload = {
            documentation: documentationContent
        };
        
        await ObexRequestHandler.post(
            `/unittest/documentation?obex_project_id=${docInfo.project_id}&method=${docInfo.method}&endpoint=${docInfo.endpoint}&link=${docInfo.gateway_id != null}&element_id=${api_product_id}`, 
            payload
        );
    };

    useEffect(() => {
        if (documenta) setDocumentationContent(documenta);
    }, [documenta]);

    return (
        <Form>
            <Form.Group controlId="documentationEditor" className="mb-4">
                <Form.Label>API Documentation</Form.Label>
                <MarkdownEditor
                    value={documentationContent}
                    onChange={setDocumentationContent}
                />
            </Form.Group>
            <DynamicButton 
                text="Save" 
                onClick={handleSave}
                theme={{ primaryColor: portalStyles.primaryColor }}
            />
        </Form>
    );
};

export default DocDrawer;
