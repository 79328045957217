import React, { useState, useEffect, useRef } from "react";
import { Alert, Image, Row, Col, Button, Form, Tabs, Tab, Nav, Spinner, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faComments, faInfoCircle, faBook, faTimes, faSearch, faEllipsisV, faCog, faKey, faNetworkWired, faPlus, faSave, faRedo, faCogs, faThumbsUp, faThumbsDown, faCopy, faShare, faCaretDown, faVolumeUp, faStop, faPencilAlt, faExpand, faCompress, faCode, faBars } from '@fortawesome/free-solid-svg-icons';
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import BrunixIcon from '../../assets/img/logos/ia-menu.png';
import SessionService from "../../services/SessionsService";
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import brunix_icon from '../../assets/img/logos/icono_AI.svg';
import Learning from "../../newViews/avapCloud/widgets/Learning";
import Cookies from "universal-cookie";

//import Logo from '../../../assets/img/ui_icons/pngegg_w.png'
import Logo from '../../assets/img/ui_icons/pngegg_w.png'
import Drawer from "./Drawer";
import ConfirmDeletetModal from '../Modals/ConfirmDeleteModal';
import ReactMarkdown from 'react-markdown';


//pool de iconos para las sesiones //hay que cambiar los iconos por los que se vayan a usar
import api_icon from '../../assets/img/ui_icons/cld_apiCat.svg';
import merge_icon from '../../assets/img/ui_icons/cld_book.svg';
import server_icon from '../../assets/img/ui_icons/cld_storage.svg';
import database_icon from '../../assets/img/ui_icons/cld_storage.svg';
import security_icon from '../../assets/img/ui_icons/credentials.png';
import testing_icon from '../../assets/img/ui_icons/fa-doc.svg';
import deploy_icon from '../../assets/img/ui_icons/icono-organization.svg';
import { json } from "d3";

//import Logo from '../../../assets/img/ui_icons/pngegg_w.png'
//import Logo from '../../assets/img/ui_icons/pngegg_w.png'
//import Drawer from "./Drawer";
// Component for chat message
interface ChatMessage {
  id: string;
  text: string;
  sender: 'user' | 'ai';
  timestamp: Date;
}

// Component for conversation
interface Conversation {
  id: string;
  title: string;
  messages: ChatMessage[];
  createdAt: Date;
  type?: keyof typeof SESSION_ICONS;
  summary: string
}

interface Conversation2 {
  id: string;
  title: string;
  messages: ChatMessage[];
  createdAt: any;
  type?: keyof typeof SESSION_ICONS;
  summary: string
}

// Type for sidebar tabs
type TabType = 'conversations' | 'context' | 'documentation';

// Predefined message suggestions
const SUGGESTED_MESSAGES = [
  "How can I set up a new proxy?",
  "Explain how environments work",
  "What are the best practices for API security?",
  "Help me troubleshoot connectivity issues"
];


// Opciones de tarjetas para la pantalla de inicio
const CARD_OPTIONS = [
  {
    id: 'proxy',
    title: 'Set up a new proxy configuration',
    icon: faCog
  },
  {
    id: 'auth',
    title: 'Manage authentication tokens',
    icon: faKey
  },
  {
    id: 'debug',
    title: 'Debug API connectivity issues',
    icon: faNetworkWired
  }
];

// Modelos de IA disponibles
const AI_MODELS = [
  { id: 'gpt-3.5-turbo', name: 'Brunix The Architect' },
  { id: 'gpt-4', name: 'GPT-4' },
  { id: 'claude-3-opus', name: 'Claude 3 Opus' },
  { id: 'claude-3-sonnet', name: 'Claude 3 Sonnet' },
  { id: 'llama-3', name: 'Llama 3' }
];

// Pool de iconos para diferentes tipos de sesiones
const SESSION_ICONS = {
  default: brunix_icon,
  api: api_icon,      // Icono para sesiones relacionadas con APIs
  merge: merge_icon,    // Icono para sesiones de merge/git
  server: server_icon,   // Icono para sesiones relacionadas con el servidor
  database: database_icon, // Icono para sesiones de base de datos
  security: security_icon, // Icono para sesiones de seguridad
  testing: testing_icon,  // Icono para sesiones de testing
  deploy: deploy_icon,   // Icono para sesiones de deployment
};

// Función para determinar el tipo de sesión basado en el título o contenido
const getSessionType = (conversation: Conversation): keyof typeof SESSION_ICONS => {
  // Si la conversación ya tiene un tipo asignado, lo usamos
  if (conversation.type) {
    return conversation.type;
  }

  const title = conversation.title.toLowerCase();
  const lastMessage = conversation.messages[conversation.messages.length - 1]?.text.toLowerCase() || '';
  
  if (title.includes('api') || lastMessage.includes('api')) return 'api';
  if (title.includes('merge') || title.includes('git') || lastMessage.includes('merge')) return 'merge';
  if (title.includes('server') || lastMessage.includes('server')) return 'server';
  if (title.includes('database') || title.includes('db') || lastMessage.includes('database')) return 'database';
  if (title.includes('security') || lastMessage.includes('security')) return 'security';
  if (title.includes('test') || lastMessage.includes('test')) return 'testing';
  if (title.includes('deploy') || lastMessage.includes('deploy')) return 'deploy';
  
  return 'default';
};

// Código de ejemplo para la configuración de proxy
const PROXY_CONFIG_EXAMPLE = `
\`\`\`javascript
// Configuración de proxy para API Gateway
const proxyConfig = {
  target: 'https://api.example.com',
  changeOrigin: true,
  secure: true,
  pathRewrite: {
    '^/api/': '/'  // Reescribe /api/x a /x
  },
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  // Opciones avanzadas
  timeout: 30000,
  proxyTimeout: 31000,
  followRedirects: true,
  // Manejo de errores
  onError: (err, req, res) => {
    console.error('Proxy error:', err);
    res.writeHead(500, {
      'Content-Type': 'application/json'
    });
    res.end(JSON.stringify({ error: 'Proxy error', details: err.message }));
  }
};

module.exports = proxyConfig;
\`\`\`

### Pasos para implementar:

1. Instala las dependencias necesarias:
\`\`\`bash
npm install http-proxy-middleware express --save
\`\`\`

2. Crea un archivo \`proxy-config.js\` con la configuración anterior
3. Implementa el proxy en tu servidor Express:

\`\`\`javascript
const express = require('express');
const { createProxyMiddleware } = require('http-proxy-middleware');
const proxyConfig = require('./proxy-config');

const app = express();

// Aplica el middleware de proxy
app.use('/api', createProxyMiddleware(proxyConfig));

// Inicia el servidor
app.listen(3000, () => {
  console.log('Proxy server running on port 3000');
});
\`\`\`
`;

const Ai_View: React.FC = () => {
  // State for conversations
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [stored_conversations, setStoredConversations] = useState<any[]>([]);

  const [stored_topics, setStoredTopics] = useState<any[]>([]);

  // State for current conversation
  const [currentConversation, setCurrentConversation] = useState<Conversation | null>(null);
  // State for current message
  const [currentMessage, setCurrentMessage] = useState('');
  const [currentMessageTip, setCurrentMessageTip] = useState('');
  // State for active sidebar tab
  const [activeTab, setActiveTab] = useState<TabType>('conversations');
  // State to control if sidebar is visible
  const [sidebarVisible, setSidebarVisible] = useState(true);
  // State to indicate if loading
  const [loading, setLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState<string>('gpt-3.5-turbo');
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  const [chatMessage, setMessages] = useState<ChatMessage>();

  const [actualID, setActualID] = useState(undefined);

  // Reference for messages container (for auto-scroll)
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const socket = useRef(null);

  const formularioRef = useRef(null);

  let mm = '';

  let currentMessageTipE = '';

  const [get_personal_info, setGet_personal_info] = useState<any>({});

  const [error, setError] = useState<string | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [likedMessages, setLikedMessages] = useState<Set<string>>(new Set());
  const [dislikedMessages, setDislikedMessages] = useState<Set<string>>(new Set());
  const [copiedMessageId, setCopiedMessageId] = useState<string | null>(null);

      const [isAddCertDrawerOpen, setIsAddCertDrawerOpen] =  useState(false)

  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [newSessionPending, setNewSessionPending] = useState<boolean>(false);
    const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
    const [selectedVoice, setSelectedVoice] = useState<string>("");
    const [isPlaying, setIsPlaying] = useState<string | null>(null);

  const [editingTitle, setEditingTitle] = useState<string | null>(null);
  const [tempTitle, setTempTitle] = useState<string>('');
  const [expandedSummary, setExpandedSummary] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [conversationToDelete, setConversationToDelete] = useState<Conversation | null>(null);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(window.innerWidth <= 900);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleSpeak = (messageId: string, text: string) => {
    if (isPlaying === messageId) {
      window.speechSynthesis.cancel();
      setIsPlaying(null);
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    const voice = voices.find((v) => v.name === selectedVoice);
    if (voice) utterance.voice = voice;
    
    utterance.onend = () => {
      setIsPlaying(null);
    };

    window.speechSynthesis.cancel(); // Cancela cualquier reproducción anterior
    setIsPlaying(messageId);
    window.speechSynthesis.speak(utterance);
  };

  const getPersonalInformation = async () => {
    try {
      // debugger
      const result = (await ObexRequestHandler.get('/get_personal_info')).data;

      setGet_personal_info(result);
      // console.log(result.image);



    } catch { }
  }

  const forzarSubmit = () => {
    if (formularioRef.current) {
      formularioRef.current.dispatchEvent(new Event('submit'));
    }
  };

  useEffect(() => { 

    const loadVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
      if (availableVoices.length > 0) {
        setSelectedVoice(availableVoices[18].name); 
      }
    };

    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;
    

    if (socket.current){

    socket.current.onopen = () =>{
      console.log("OPENED")
      
      const cookies = new Cookies();
      const userInfo = cookies.get('101Obex');

      let yy = SessionService.userM;
      console.log(SessionService.userM)
      console.log(conversations);
      console.log(currentConversation)
      if (SessionService.userM){
        const message = {
          token: `id_owner_user:${userInfo.user_id}`,
          prompt: SessionService.userM.text, // "Dada la informacion de mi cuenta, hay algun valor que presente alerta con respecto de los limites establecidos? hazme un resumen de los datos principales consumos con respecto de los limites de mi cuenta, por favor.",
          context: '101obex',
          api: 'chatcompletion',
          model: 'gpt-3.5-turbo',
          openai_api_token: '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'
        };
    
        
    
        socket.current.send(JSON.stringify(message));
      }
    }
    socket.current.onmessage = (event) => {
      setLoading(true);
  //    setMessages((prevMessages) => prevMessages + event.data);
      mm = mm +  event.data;
  
      if (event.data.includes('END')){
  
  
  
          const aiMessage: ChatMessage = {
            id: (Date.now() + 1).toString(),
            text: `${mm.replace('END', '')}`,
            sender: 'ai',
            timestamp: new Date()
          };
  
  
          const updatedConversation = {
            ...currentConversation,
            messages: [...currentConversation.messages, SessionService.userM]
          };
    
          const finalConversation = {
            ...currentConversation,
            messages: [...currentConversation.messages, aiMessage]
          };
    
          // Update current conversation
          setCurrentConversation(finalConversation);
    
          // Update conversations list
          setConversations(conversations.map(conv =>
            conv.id === currentConversation.id ? finalConversation : conv
          ));
    
          setLoading(false);
  
          mm = '';
          document.getElementById('entrada').focus()
      }
  
  
    };
  
    socket.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    socket.current.onclose = () => {
      console.log('WebSocket disconnected');
    };
  }

  }, [currentConversation, chatMessage, currentMessage, stored_conversations?.length])

  // Effect to create a default conversation if none exists
  useEffect(() => {
    if (conversations.length === 0) {
      const randomType = getRandomSessionType();
      const defaultConversation: Conversation = {
        id: Date.now().toString(),
        title: `New ${randomType} session`,
        messages: [],
        createdAt: new Date(),
        type: randomType,
        summary: ''
      };
      setConversations([defaultConversation]);
      setCurrentConversation(defaultConversation);
    }
  }, [conversations.length, stored_conversations.length]);

  // Effect to scroll to the last message
  useEffect(() => {
    if (messagesEndRef.current && currentConversation?.messages?.length > 0) {
    //messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });

    messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

    // Ajustar el scroll para dejar un espacio al final
    messagesEndRef.current.scrollTop -= 50; // Ajusta es

    }
  }, [currentConversation?.messages?.length, stored_conversations.length]);


const getWorkSessions = async () => {
  try{

    const cookies = new Cookies();
    const userInfo = cookies.get('101Obex');
     
    const yy = await ObexRequestHandler.get(`/brunix/get_worksessions?obex_user_id=${userInfo.user_id}&obex_org_id=${SessionService.ORGANIZATION}`, {}, true);
    
    console.log(yy.data[0].data);

    let almacenado = yy.data[0].data;
    console.log(almacenado);
    let alma_p = JSON.parse(almacenado)
    console.log(alma_p);
    setStoredConversations(alma_p);
    //setStoredConversations(JSON.parse(yy));
    console.log(yy)
  } catch(error) {
    console.log(error)
  }

}

const getTopics = async () => {
  try{

    const cookies = new Cookies();
    const userInfo = cookies.get('101Obex');
     
    const yy = await ObexRequestHandler.get(`/brunix/get_topics?owner_user_id=${userInfo.user_id}`, {}, true);
    
    console.log(yy.data.total_presets);

    const almacenado = yy.data.total_presets;

/*
    const CARD_OPTIONS = [
      {
        id: 'proxy',
        title: 'Set up a new proxy configuration',
        icon: faCog
      },
      {
        id: 'auth',
        title: 'Manage authentication tokens',
        icon: faKey
      },
      {
        id: 'debug',
        title: 'Debug API connectivity issues',
        icon: faNetworkWired
      }
    ];*/
    const alma =[]
    almacenado.forEach(element => {
      alma.push({
        id:element.slice(0, 3),
        title:element,
        icon: faCog
      })
    });

    setStoredTopics(alma);

  } catch(error) {
    console.log(error)
  }

}

useEffect(() => {
  getTopics();
  getPersonalInformation();

  getWorkSessions();


}, [])

  // Function to send a message
  const handleSendMessage = () => {
    //if (!currentMessage.trim() || !currentConversation) return;
    
    if (currentMessage==''){
      if (currentMessageTipE == '**project**'){
        console.log("X")
      }
      else if (currentMessageTipE == '**team**'){
        console.log("X")
      }
    }

    const messageToSend = currentMessage;
    setCurrentMessage('');
    setLoading(true);
    setError(null);

    const userMessage: ChatMessage = {
      id: Date.now().toString(),
      text: messageToSend,
      sender: 'user',
      timestamp: new Date()
    };

    // Update conversation with user message
    const updatedConversation = {
      ...currentConversation,
      messages: [...currentConversation.messages, userMessage]
    };

    //userM = userMessage;

    SessionService.userM = userMessage;
    setMessages(userMessage)

    setCurrentConversation(updatedConversation);
    setConversations(conversations.map(conv =>
      conv.id === currentConversation.id ? updatedConversation : conv
    ));

    // Clear input
    setCurrentMessage('');

    // Simulación de respuesta para "Set up a new proxy configuration"
    if (messageToSend === 'Set up a new proxy configuration') {
      setTimeout(() => {
        const aiResponse: ChatMessage = {
          id: Date.now().toString(),
          text: PROXY_CONFIG_EXAMPLE,
          sender: 'ai',
          timestamp: new Date()
        };

        const updatedWithAiResponse = {
          ...updatedConversation,
          messages: [...updatedConversation.messages, aiResponse]
        };

        setCurrentConversation(updatedWithAiResponse);
        setConversations(conversations.map(conv =>
          conv.id === currentConversation.id ? updatedWithAiResponse : conv
        ));
        setLoading(false);
      }, 1500);
      return;
    }

    // Simulate AI response after a brief delay
    const message = {
      token: `id_owner_user:${SessionService.loginData.user_id}`,
      prompt: userMessage.text,
      context: '101obex',
      api: 'chatcompletion',
      model: 'gpt-3.5-turbo',
      openai_api_token: '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'
    };

    

    //socket.current.send(JSON.stringify(message));

  };

  // Función para obtener un tipo de sesión aleatorio
  const getRandomSessionType = (): keyof typeof SESSION_ICONS => {
    const sessionTypes = Object.keys(SESSION_ICONS).filter(type => type !== 'default') as Array<keyof typeof SESSION_ICONS>;
    const randomIndex = Math.floor(Math.random() * sessionTypes.length);
    return sessionTypes[randomIndex];
  };

  // Función para crear una nueva sesión
  const createNewSession = () => {
    const randomType = getRandomSessionType();
    const newConversation: Conversation = {
      id: Date.now().toString(),
      title: 'New session',
      messages: [],
      createdAt: new Date(),
      type: randomType,
      summary: ''
    };

    setConversations(prevConversations => [newConversation, ...prevConversations]);
    setCurrentConversation(newConversation);
    setNewSessionPending(false);
  };

  // Función para mostrar el modal de confirmación
  const confirmNewSession = () => {
    if (currentConversation && currentConversation.messages.length > 0) {
      setShowSaveModal(true);
      setNewSessionPending(true);
    } else {
      createNewSession();
    }
  };

  // Función para guardar la sesión actual y crear una nueva
  const saveAndCreateNewSession = async (e) => {
    // Aquí iría la lógica para guardar la sesión actual si es necesario


    e.preventDefault();
                          if (currentConversation.messages.length === 0) return;
                          console.log(actualID)

                          const new_stored_conversations = stored_conversations;

                          if (actualID === undefined) {
                          
                          currentConversation.id=(Date.now() + 1).toString()
                          new_stored_conversations.push(currentConversation)
                          } else {
                            new_stored_conversations.forEach(coo=>{
                              if (coo.id === actualID) coo.messages = currentConversation.messages
                            })
                          }

                          setStoredConversations(new_stored_conversations.map(conv =>
                            conv.id === currentConversation.id ? currentConversation : conv
                          ));

                          const cookies = new Cookies();
                          const userInfo = cookies.get('101Obex');

                          const payload = {
                            obex_user_id: userInfo.user_id, 
                            data: JSON.stringify(stored_conversations), 
                            obex_org_id: SessionService.ORGANIZATION,
                            conversation_id: currentConversation.id
                          }
                          const stor = await ObexRequestHandler.post('/brunix_worksessions', payload)
                         // setActualID(currentConversation.id)
                         // if (stored_conversations) setStoredConversations([stored_conversations])


                         getWorkSessions();


                         try{
                          document.getElementById('entrada').focus()
                         } catch{

                         }



    setShowSaveModal(false);
    createNewSession();
  };

  // Función para descartar la sesión actual y crear una nueva
  const discardAndCreateNewSession = () => {
    setShowSaveModal(false);
    createNewSession();
  };

  // Función para cancelar la creación de una nueva sesión
  const cancelNewSession = () => {
    setShowSaveModal(false);
    setNewSessionPending(false);
  };

  // Reemplazar la función handleNewConversation existente
  const handleNewConversation = () => {
    confirmNewSession();
    setActualID(undefined);
  };

  // Function to select a conversation
  const handleSelectConversation = (conversation: Conversation) => {
    setCurrentConversation(conversation);
    setActualID(conversation.id);
    document.querySelectorAll('.dropdown-menu.show').forEach(dropdown => {
      dropdown.classList.remove('show');
    });
  };

  // Función para cerrar todos los dropdowns cuando se hace clic en cualquier parte del documento
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as Element).closest('.dropdown')) {
        document.querySelectorAll('.dropdown-menu.show').forEach(dropdown => {
          dropdown.classList.remove('show');
        });
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Function to change the active tab
  const handleTabChange = (tab: TabType) => {
    setActiveTab(tab);
  };

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // Function to use a suggested message
  const handleSuggestedMessage = (message: string) => {
    setCurrentMessage(message);

    // We need a timeout to ensure the state is updated before sending
    setTimeout(() => {
      if (!currentConversation) return;

      setLoading(true);

      const userMessage: ChatMessage = {
        id: Date.now().toString(),
        text: message,
        sender: 'user',
        timestamp: new Date()
      };

      SessionService.userM = userMessage;

      // Update conversation with user message
      const updatedConversation = {
        ...currentConversation,
        messages: [...currentConversation.messages, userMessage]
      };

      setCurrentConversation(updatedConversation);
      setConversations(conversations.map(conv =>
        conv.id === currentConversation.id ? updatedConversation : conv
      ));

      // Clear input
      setCurrentMessage('');

      // Simulate AI response after a brief delay

      socket.current = new WebSocket('wss://staging.avapcloud.com:3001');

      const message2 = {
      token: `id_owner_user:${SessionService.loginData.user_id}`,
      prompt: userMessage.text, // "Dada la informacion de mi cuenta, hay algun valor que presente alerta con respecto de los limites establecidos? hazme un resumen de los datos principales consumos con respecto de los limites de mi cuenta, por favor.",
      context: '101obex',
      api: 'chatcompletion',
      model: 'gpt-3.5-turbo',
      openai_api_token: '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'
    };

    socket.current.send(JSON.stringify(message2));

      /*
      setTimeout(() => {
        const aiMessage: ChatMessage = {
          id: (Date.now() + 1).toString(),
          text: `Response to: ${message}`,
          sender: 'ai',
          timestamp: new Date()
        };

        const finalConversation = {
          ...updatedConversation,
          messages: [...updatedConversation.messages, aiMessage]
        };

        // Update current conversation
        setCurrentConversation(finalConversation);

        // Update conversations list
        setConversations(conversations.map(conv =>
          conv.id === currentConversation.id ? finalConversation : conv
        ));

        setLoading(false);
      }, 1500);
      */
    }, 0);
  };

  // Función para manejar el like de un mensaje
  const handleLike = (messageId: string) => {
    setLikedMessages(prev => {
      const newLiked = new Set(prev);
      if (newLiked.has(messageId)) {
        newLiked.delete(messageId);
      } else {
        newLiked.add(messageId);
        // Si estaba disliked, lo quitamos de esa lista
        if (dislikedMessages.has(messageId)) {
          setDislikedMessages(prev => {
            const newDisliked = new Set(prev);
            newDisliked.delete(messageId);
            return newDisliked;
          });
        }
      }
      return newLiked;
    });
  };

  // Función para manejar el dislike de un mensaje
  const handleDislike = (messageId: string) => {
    setDislikedMessages(prev => {
      const newDisliked = new Set(prev);
      if (newDisliked.has(messageId)) {
        newDisliked.delete(messageId);
      } else {
        newDisliked.add(messageId);
        // Si estaba liked, lo quitamos de esa lista
        if (likedMessages.has(messageId)) {
          setLikedMessages(prev => {
            const newLiked = new Set(prev);
            newLiked.delete(messageId);
            return newLiked;
          });
        }
      }
      return newDisliked;
    });
  };

  // Función para copiar el texto de un mensaje
  const handleCopy = (messageId: string, text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        // Mostrar notificación visual
        setCopiedMessageId(messageId);
        // Ocultar la notificación después de 2 segundos
        setTimeout(() => {
          setCopiedMessageId(null);
        }, 2000);
      })
      .catch(err => {
        console.error('Error al copiar texto: ', err);
      });
  };

  // Función para manejar la edición del título
  const handleTitleEdit = async (conversation: Conversation, newTitle: string) => {
    const updatedConversation = { ...conversation, title: newTitle };

    const hcon = stored_conversations.map(conv =>
      conv.id === conversation.id ? updatedConversation : conv
    )
    setStoredConversations(stored_conversations.map(conv =>
      conv.id === conversation.id ? updatedConversation : conv
    ));
    setEditingTitle(null);
    setTempTitle('');

    const cookies = new Cookies();
    const userInfo = cookies.get('101Obex');


    try{
      const payload = {
        obex_user_id: userInfo.user_id, 
        data: JSON.stringify(hcon), 
        obex_org_id: SessionService.ORGANIZATION,
        conversation_id: currentConversation.id
      }
      await ObexRequestHandler.post('/brunix_worksessions', payload)
      getWorkSessions();
    } catch {

    }

  };

  // Función para manejar la eliminación de una conversación
  const handleDeleteConversation = (conversation: Conversation) => {
    setConversationToDelete(conversation);
    setShowDeleteModal(true);
  };

  // Función para confirmar la eliminación
  const confirmDelete = async () => {
    let updatedConversations;
    if (conversationToDelete) {
      updatedConversations = stored_conversations.filter(conv => conv.id !== conversationToDelete.id);
      setStoredConversations(updatedConversations);
     /*
      if (currentConversation?.id === conversationToDelete.id) {
        setCurrentConversation(updatedConversations[0] || null);
      }*/
    }
    setShowDeleteModal(false);
    setConversationToDelete(null);


    const cookies = new Cookies();
    const userInfo = cookies.get('101Obex');


    try{
      const payload = {
        obex_user_id: userInfo.user_id, 
        data: JSON.stringify(updatedConversations), 
        obex_org_id: SessionService.ORGANIZATION,
        conversation_id: currentConversation.id
      }
      await ObexRequestHandler.post('/brunix_worksessions', payload)
      getWorkSessions();
    } catch {

    }
  };

  // Modificar la renderización de los mensajes para soportar Markdown
  const renderMessageContent = (text: string) => {
    // Verificar si el texto contiene bloques de código (```...)
    if (text.includes('```')) {
      return (
        <ReactMarkdown
          source={text}
          renderers={{
            code: ({ language, value }) => (
              <div className="code-block-wrapper">
                <div className="code-block-header">
                  <span>{language}</span>
                  <Button
                    variant="link"
                    size="sm"
                    className="copy-code-button"
                    onClick={() => handleCopy(Date.now().toString(), value)}
                  >
                    <FontAwesomeIcon icon={faCopy} /> Copiar
                  </Button>
                </div>
                <pre className="code-block">
                  <code className={`language-${language}`}>
                    {value}
                  </code>
                </pre>
              </div>
            )
          }}
        />
      );
    }
    
    // Si no contiene bloques de código, mostrar como texto normal
    return <p>{text}</p>;
  };

  // Función para manejar el clic fuera del sidebar
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current && 
        !sidebarRef.current.contains(event.target as Node) && 
        !isSidebarCollapsed && 
        window.innerWidth <= 900
      ) {
        setIsSidebarCollapsed(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarCollapsed]);

  // Efecto para manejar el cambio de tamaño de la ventana
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 900) {
        setIsSidebarCollapsed(false);
      } else {
        setIsSidebarCollapsed(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <DashLayout sider={DashRouters} active="environments" sublinks={[]}>
      <Row id='topHeader'>
        <Col md="12">
        <div className="d-flex justify-content-between w-100">
          <div style={{ width:'80%' }} className="cld_wrapperTitle">
            <img style={{ width:'24px' }} src={BrunixIcon} alt="icon dashboard" />
            <span className="cld_title w-100 margin-y-24px">
              {/*<span style={{ color: '#949498', fontSize: '14px' }}>Brunix </span>/ */}Brunix
            </span>
          </div>
          

          <div className="d-flex justify-content-end">
          <div className="dshb-footer m-0">
                    <img src={brunix_icon} alt="brunix_icon" />
                    <p>LLM Brunix The Architect v1.03</p>
                </div>

          </div>
          </div>
        </Col>
        <Col md="12"> <div className="customBorder"></div></Col>
      </Row>

      {currentConversation && (
        <div className="ai-chat-container-wrapper">
          <div className="ai-chat-container">
            {/* Main content */}
            <div className="ai-main-content">
              {/* Vista de chat activa */}
              <div className="ai-chat-view">
                {/* Header fijo con botones de acción */}
                <div className="ai-chat-header">
                  <div className="ai-header-actions">
                    {currentConversation.messages.length !== 0 ?
                      <Button
                        variant="outline-light"
                        className="new-chat-button"
                        onClick={handleNewConversation}
                      >
                        <FontAwesomeIcon icon={faPlus} /> New Work Session
                      </Button> : <div></div>
                    }

                    <div className="header-right-actions">
                      <button 
                        className="sidebar-toggle"
                        onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                      >
                        <FontAwesomeIcon icon={faBars} />
                      </button>

                      <Dropdown alignRight>
                        <Dropdown.Toggle variant="link" id="dropdown-menu" className="options-button">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="options-dropdown">
                          <Dropdown.Item 
                          disabled={currentConversation.messages.length === 0}
                          onClick={async (e)=>{


                            e.preventDefault();
                            if (currentConversation.messages.length === 0) return;
                            console.log(actualID)

                            const new_stored_conversations = stored_conversations;

                            if (actualID === undefined) {
                            
                            currentConversation.id=(Date.now() + 1).toString()
                            new_stored_conversations.push(currentConversation)
                            } else {
                              new_stored_conversations.forEach(coo=>{
                                if (coo.id === actualID) coo.messages = currentConversation.messages
                              })
                            }

                            setStoredConversations(new_stored_conversations.map(conv =>
                              conv.id === currentConversation.id ? currentConversation : conv
                            ));

                            const cookies = new Cookies();
                            const userInfo = cookies.get('101Obex');

                            let payload = {
                              obex_user_id: userInfo.user_id, 
                              data: JSON.stringify(stored_conversations), 
                              obex_org_id: SessionService.ORGANIZATION,
                              conversation_id: currentConversation.id
                            }
                            let stor = await ObexRequestHandler.post('/brunix_worksessions', payload)
                            getWorkSessions();
                            setActualID(currentConversation.id)
                           // if (stored_conversations) setStoredConversations([stored_conversations])
                           try{
                            document.getElementById('entrada').focus()
                           } catch{

                           }
                          }}>
                            <FontAwesomeIcon icon={faSave} className="me-2" /> Save Work Session
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => {
                                setIsAddCertDrawerOpen(true)
                              } }>
                          <Image onClick={() => {
                                setIsAddCertDrawerOpen(true)
                              } 
                              }style={{ width:'15px', marginBottom:'3px' }} src={Logo} fluid/>
                          <span className="me-2">    Settings</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                {/* Área de chat con scroll */}
                <div className="ai-chat-area">
                  <div className="ai-chat-messages-container">
                    <div className={`ai-chat-messages ${currentConversation.messages.length === 0 ? 'empty-chat' : ''}`}>
                      {/* Pantalla de bienvenida - Solo se muestra cuando no hay mensajes */}
                      {currentConversation.messages.length === 0 && (
                        <>
                          <div className="ai-welcome-header">
                            <h1>Hi! <span className="user-name">{!get_personal_info && 'User'}{get_personal_info && get_personal_info.first_name} {get_personal_info && get_personal_info.middle_name} {get_personal_info && get_personal_info.last_name}</span></h1>
                            <p className="ai-welcome-subtitle">Let's start a work session! </p>
                            <p className="ai-welcome-description">
                            Our API platform is running. We'll manage and optimize our ecosystem together.
                            </p>
                          </div>

                          <div className="ai-options-grid">
                            {stored_topics.map((option) => (
                              <div
                                key={option.id}
                                className="ai-option-card"
                                onClick={
                                  (e) => {  
                                    currentMessageTipE = '**project**';
                                    e.preventDefault(); 
                                    
                                    setCurrentMessage(option.title); 
                                    
                                    //handleSendMessage();

                                    const retraso = setTimeout(() => {
                                      handleSendMessage();
                                      forzarSubmit();
                                      setCurrentMessageTip('**project**')
                                    document.getElementById('sendMessage').click();
                                    clearTimeout(retraso);
                                    }, 200); 
  
                                   }
                                }
                              >
                                <div className="ai-option-icon-wrapper">
                                  <FontAwesomeIcon icon={option.icon} />
                                </div>
                                <div className="ai-option-title">{option.title}</div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}

                      {/* Mensajes del chat */}
                      {currentConversation?.messages.map((message) => (
                        <div
                          key={message.id}
                          className={`ai-chat-message ${message.sender === 'user' ? 'user-message' : 'ai-message'}`}
                        >
                          <div className="message-content">
                            {message.sender === 'user' ? (
                              <p>{message.text}</p>
                            ) : (
                              renderMessageContent(message.text)
                            )}
                            <span className="message-time">
                              {message.timestamp.toString() /*.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) ||message.timestamp */}
                            </span>
                          </div>
                          {message.sender === 'ai' && (
                            <div className="ai-message-toolbar">
                              <button
                                className={`toolbar-button ${likedMessages.has(message.id) ? 'active' : ''}`}
                                title="Me gusta"
                                onClick={() => handleLike(message.id)}
                              >
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </button>
                              <button
                                className={`toolbar-button ${dislikedMessages.has(message.id) ? 'active' : ''}`}
                                title="No me gusta"
                                onClick={() => handleDislike(message.id)}
                              >
                                <FontAwesomeIcon icon={faThumbsDown} />
                              </button>
                              <button
                                className="toolbar-button"
                                title={copiedMessageId === message.id ? '¡Copied!' : 'Copy'}
                                onClick={() => handleCopy(message.id, message.text)}
                              >
                                <FontAwesomeIcon icon={faCopy} />
                                {copiedMessageId === message.id && (
                                  <span className="copy-notification">¡Copiado!</span>
                                )}
                              </button>
                              <button
                                className="toolbar-button"
                                title="Speak"
                                onClick={()=>{if (window.speechSynthesis.speaking) {  window.speechSynthesis.cancel();} else handleSpeak(message.id, message.text)}}
                              >
                                <FontAwesomeIcon icon={ window.speechSynthesis.speaking ? faStop : faVolumeUp} />
                                
                              </button>
                              { currentConversation.messages[currentConversation.messages.length-1].id === message.id &&
                              <div className="model-selector">
                                
                                <FontAwesomeIcon style={{cursor:'pointer'}} icon={ faRedo} onClick={
                                  (e)=>{

                                  currentConversation.messages.pop();
                                  const lastMessageID = currentConversation.messages[currentConversation.messages.length-1].id
                                  const lastMessage = currentConversation.messages[currentConversation.messages.length-1].text
                                  console.log(message)
                                  setCurrentMessage(lastMessage);
                                  socket.current = new WebSocket('wss://staging.avapcloud.com:3001');
                                  e.preventDefault(); 
                                  handleSendMessage();

                                }
                               
                                }/>
                              
                              </div>
}
                            </div>
                          )}
                        </div>
                      ))}

                      {loading && (
                        <div className="ai-chat-message ai-message">
                          <div className="message-content">
                            <div className="ai-typing-indicator">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </div>
                          <div className="ai-message-toolbar">
                            <button className="toolbar-button" title="Me gusta" disabled>
                              <FontAwesomeIcon icon={faThumbsUp} />
                            </button>
                            <button className="toolbar-button" title="No me gusta" disabled>
                              <FontAwesomeIcon icon={faThumbsDown} />
                            </button>
                            <button className="toolbar-button" title="Copiar" disabled>
                              <FontAwesomeIcon icon={faCopy} />
                            </button>
                            <button className="toolbar-button" title="Audio (no implementado)" disabled>
                              <FontAwesomeIcon icon={faVolumeUp} />
                            </button>
                            <div className="model-selector">
                            <FontAwesomeIcon icon={ faRedo }/>
                            </div>
                          </div>
                        </div>
                      )}

                      {error && (
                        <div className="ai-chat-message ai-message error-message">
                          <div className="message-content">
                            <p>{error}</p>
                          </div>
                          <div className="ai-message-toolbar">
                            <button className="toolbar-button" title="Me gusta">
                              <FontAwesomeIcon icon={faThumbsUp} />
                            </button>
                            <button className="toolbar-button" title="No me gusta">
                              <FontAwesomeIcon icon={faThumbsDown} />
                            </button>
                            <button className="toolbar-button" title="Copiar">
                              <FontAwesomeIcon icon={faCopy} />
                            </button>
                            <button className="toolbar-button" title="Audio (no implementado)">
                              <FontAwesomeIcon icon={faVolumeUp} />
                            </button>
                            <div className="model-selector">
                              <Dropdown
                                show={openDropdownId === 'error-message'}
                                onToggle={(isOpen) => setOpenDropdownId(isOpen ? 'error-message' : null)}
                                onMouseLeave={() => setOpenDropdownId(null)}
                              >
                                <Dropdown.Toggle variant="link" id="dropdown-model" className="model-dropdown-toggle">
                                  <span className="model-name">{AI_MODELS.find(m => m.id === selectedModel)?.name}</span>
                                  <FontAwesomeIcon icon={faCaretDown} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="model-dropdown-menu">
                                  {AI_MODELS.map(model => (
                                    <Dropdown.Item
                                      key={model.id}
                                      onClick={() => {
                                        setSelectedModel(model.id);
                                        setOpenDropdownId(null);
                                      }}
                                      active={selectedModel === model.id}
                                    >
                                      {model.name}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      )}

                      <div ref={messagesEndRef} />
                    </div>
                  </div>

                  {/* Input del chat */}
                  <div className="ai-chat-input">
                    <Form id='formularioRef' onSubmit={(e) => { socket.current = new WebSocket('wss://staging.avapcloud.com:3001');e.preventDefault(); handleSendMessage(); }}>
                      <div className="input-wrapper">
                        <Form.Control
                          type="text"
                          placeholder="Tell me what you'd like us to work on..."
                          value={currentMessage}
                          onChange={(e) => setCurrentMessage(e.target.value)}
                          disabled={loading}
                          id='entrada'
                        />
                        <Button
                          id='sendMessage'
                          variant="primary"
                          className="send-button"
                          onClick={handleSendMessage}
                          disabled={!currentMessage.trim() || loading}
                        >
                          <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            {/* Overlay para cerrar al hacer clic fuera */}
            <div 
              className={`sidebar-overlay ${!isSidebarCollapsed ? 'visible' : ''}`}
              onClick={() => setIsSidebarCollapsed(true)}
            />

            {/* Sidebar */}
            <div 
              ref={sidebarRef}
              className={`ai-sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}
            >
              {/* Sidebar header with tabs */}
              <div className="ai-sidebar-header">
                <h3>
                  {activeTab === 'conversations' && 'Work Sessions'}
                  {activeTab === 'context' && 'Context'}
                  {activeTab === 'documentation' && 'Learning'}
                </h3>
                <div className="ai-sidebar-tabs">
                  <button 
                    className="close-sidebar"
                    onClick={() => setIsSidebarCollapsed(true)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                  <div
                    className={`ai-tab-item ${activeTab === 'conversations' ? 'active' : ''}`}
                    onClick={() => handleTabChange('conversations')}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </div>
                  <div
                    className={`ai-tab-item ${activeTab === 'documentation' ? 'active' : ''}`}
                    onClick={() => handleTabChange('documentation')}
                  >
                    <FontAwesomeIcon icon={faBook} />
                  </div>
                </div>
              </div>

              {/* Sidebar content */}
              <div className="ai-sidebar-content">
                {activeTab === 'conversations' && (
                  <div className="ai-conversations-list">
                    {stored_conversations.map((conversation) => (
                      <div
                        key={conversation.id}
                        className={`ai-conversation-item ${currentConversation?.id === conversation.id ? 'active' : ''}`}
                        onClick={() => handleSelectConversation(conversation)}
                      >
                        <div className="conversation-header">
                          <div className="conversation-avatar">
                            <img 
                              src={SESSION_ICONS[getSessionType(conversation)]} 
                              alt="Session Icon" 
                              title={`${getSessionType(conversation).charAt(0).toUpperCase() + getSessionType(conversation).slice(1)} Session`}
                            />
                          </div>
                          <div className="conversation-info">
                            <div className="title-container">
                              {editingTitle === conversation.id ? (
                                <>
                                  <Form.Control
                                    type="text"
                                    value={tempTitle === undefined ? conversation.title : tempTitle}
                                    onChange={(e) => setTempTitle(e.target.value)}
                                    onBlur={() => {
                                      if (tempTitle) {
                                        handleTitleEdit(conversation, tempTitle);
                                      }
                                      setEditingTitle(null);
                                      setTempTitle('');
                                    }}
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') {
                                        handleTitleEdit(conversation, tempTitle || conversation.title);
                                      }
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    autoFocus
                                  />
                                  <div className="title-actions">
                                    <FontAwesomeIcon
                                      icon={faPencilAlt}
                                      className="action-icon"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setEditingTitle(null);
                                        setTempTitle('');
                                      }}
                                      title="Cancelar edición"
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <h4>{conversation.title}</h4>
                                  <div className="title-actions">









                                  <div className="dropdown">
                                      <button 
                                        className="btn btn-link p-0 text-muted dropdown-toggle-no-caret"
                                        type="button"
                                        id={`dropdown-${conversation.id}`}
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // Abrir el dropdown manualmente
                                          const dropdown = document.getElementById(`dropdown-menu-${conversation.id}`);
                                          if (dropdown) {
                                            dropdown.classList.toggle('show');
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faEllipsisV} />
                                      </button>
                                      <div 
                                        className="dropdown-menu dropdown-menu-right" 
                                        id={`dropdown-menu-${conversation.id}`}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <button 
                                          className="dropdown-item" 
                                          type="button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setEditingTitle(conversation.id);
                                            setTempTitle(conversation.title);
                                            // Cerrar el dropdown
                                            const dropdown = document.getElementById(`dropdown-menu-${conversation.id}`);
                                            if (dropdown) {
                                              dropdown.classList.remove('show');
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                            Edit title
                                        </button>
                                        <button 
                                          className="dropdown-item" 
                                          type="button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleSpeak(
                                              conversation.id,
                                              conversation.messages[conversation.messages.length - 1]?.text || ''
                                            );
                                            // Cerrar el dropdown
                                            const dropdown = document.getElementById(`dropdown-menu-${conversation.id}`);
                                            if (dropdown) {
                                              dropdown.classList.remove('show');
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon 
                                            icon={isPlaying === conversation.id ? faStop : faVolumeUp} 
                                            className="mr-2" 
                                          />
                                          {isPlaying === conversation.id ? "Stop audio" : "Play audio"}
                                        </button>
                                        <button 
                                          className="dropdown-item" 
                                          type="button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setExpandedSummary(expandedSummary === conversation.id ? null : conversation.id);
                                            // Cerrar el dropdown
                                            const dropdown = document.getElementById(`dropdown-menu-${conversation.id}`);
                                            if (dropdown) {
                                              dropdown.classList.remove('show');
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon 
                                            icon={expandedSummary === conversation.id ? faCompress : faExpand} 
                                            className="mr-2" 
                                          />
                                          {expandedSummary === conversation.id ? "Collapse" : " Expand"}
                                        </button>
                                        <div className="dropdown-divider"></div>
                                        <button 
                                          className="dropdown-item text-danger" 
                                          type="button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteConversation(conversation);
                                            // Cerrar el dropdown
                                            const dropdown = document.getElementById(`dropdown-menu-${conversation.id}`);
                                            if (dropdown) {
                                              dropdown.classList.remove('show');
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                          Delete conversation
                                        </button>
                                      </div>
                                    </div>











{/*}
                                    
                                    
                                    <FontAwesomeIcon
                                      icon={faPencilAlt}
                                      className="action-icon"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setEditingTitle(conversation.id);
                                        setTempTitle(conversation.title);
                                      }}
                                      title="Editar título"
                                    />
                                    <FontAwesomeIcon
                                      icon={isPlaying === conversation.id ? faStop : faVolumeUp}
                                      className="action-icon"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleSpeak(
                                          conversation.id,
                                          conversation.messages[conversation.messages.length - 1]?.text || ''
                                        );
                                      }}
                                      title={isPlaying === conversation.id ? "Detener audio" : "Reproducir audio"}
                                    />
                                    <FontAwesomeIcon
                                      icon={expandedSummary === conversation.id ? faCompress : faExpand}
                                      className="action-icon"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setExpandedSummary(expandedSummary === conversation.id ? null : conversation.id);
                                      }}
                                      title={expandedSummary === conversation.id ? "Contraer" : "Expandir"}
                                    />
                                    <FontAwesomeIcon
                                      icon={faTimes}
                                      className="action-icon"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteConversation(conversation);
                                      }}
                                      title="Eliminar conversación"
                                    />
                                    */}
                                  </div>
                                </>
                              )}
                            </div>
                            {editingTitle !== conversation.id && (
                              <span className="conversation-date">
                                {conversation.createdAt.toString()/*.toLocaleDateString()*/}
                              </span>
                            )}
                          </div>
                        </div>
                        <p className="conversation-summary">
                          {conversation.messages.length > 0
                            ? conversation.summary// messages[conversation.messages.length - 1].text
                            : 'No messages'}
                        </p>
                        {expandedSummary === conversation.id && (
                          <div className="expanded">
                            {conversation.summary}
                            {/*
                            {conversation.messages.map((msg, index) => (
                              <div key={msg.id}>
                                <strong>{msg.sender === 'user' ? 'You' : 'Brunix'}:</strong>
                                <p>{msg.text}</p>
                                {index < conversation.messages.length - 1 && <hr />}
                              </div>
                            ))}
                            */}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {activeTab === 'context' && (
                  <div className="ai-context-tab">
                    <h3>Context</h3>
                    <div className="ai-context-content">
                      <p>Here you can view and edit the context of the current conversation.</p>
                    </div>
                  </div>
                )}

                {activeTab === 'documentation' && (
                  <div className="ai-documentation-tab">
                    <div className="ai-documentation-content">
                      <p>Access helpful documentation and guides.</p>
                    </div>
                    <Learning />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de confirmación para nueva sesión */}
      <Modal
        show={showSaveModal}
        onHide={cancelNewSession}
        centered
        className="ai-save-session-modal"
        container={document.getElementById('dashboardWrapper')}
      >
        <Modal.Header closeButton>
          <Modal.Title>Save current session?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Save current work session before creating a new one?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelNewSession}>
            Cancel
          </Button>
          
          <Button variant="danger" onClick={discardAndCreateNewSession}>
            Discard
          </Button>
          <Button variant="primary" onClick={saveAndCreateNewSession}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de confirmación de eliminación */}
      <ConfirmDeletetModal 
        handleSubmit={confirmDelete}
        showModal={showDeleteModal}
        onCloseModal={() => setShowDeleteModal(false)}
        loading={false}
        error={''}
        success={''}
        name_to_check={'DELETE'}
        title_to_show={`Confirm delete work session ${conversationToDelete?.title}`}
      />

<Drawer
            isOpen={isAddCertDrawerOpen}
            toggleSidebar={() => setIsAddCertDrawerOpen(!isAddCertDrawerOpen)}
            name="Session Configuration"
            index=""
        >
                <div>
                    {/* <h3>Add a New Certificate</h3> */}
                    <Form
                        onSubmit={async (e) => {
                          e.preventDefault();
                          /*
                            

                            const payload = {
                                data:
                                    {
                                        projects: Number(projectT),
                                        apis: Number(apisT),
                                        requests: Number(RequestsT),
                                        storage: Number(StorageT),
                                        teams: Number(TeamsT),
                                        proxies: Number(ProxiesT)
                                    },
                                obex_user_id: SessionService.loginData.user_id
                            }

                            console.log(payload);
                            await ObexRequestHandler.post('/brunix_threshold', payload)
                */        }
                             }
                    >

            <Form.Row className="mb-3">
              <Form.Label column sm="12">Voices</Form.Label>

            <Form.Control
                as="select"
                value={selectedVoice}
                onChange={(e)=>setSelectedVoice(e.target.value)}
                >
                <option value="">Select a voice</option>
                {voices.map((reg) => (
                  <option key={reg.name} value={reg.name}>
                    {reg.name}
                  </option>
                ))}
              </Form.Control>

                </Form.Row>

                        <Row>
                            <Col md='6'>
                                <Button
                                    variant="primary"
                                    disabled={false}
                                    className="w-100" type="submit">
                                    Save
                                    {loading && <Spinner animation="border" size="sm" />}
                                </Button>
                            </Col>
                        </Row>
                        {/*}
                        {error.length > 0 &&
                            <Alert variant='danger'>
                                {error}
                            </Alert>}
*/}
                    </Form>
                </div>
            </Drawer>

    </DashLayout>
  );
};

export default Ai_View;