import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, Form, Spinner, Dropdown, Alert } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import Drawer from "./Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSearch } from "@fortawesome/free-solid-svg-icons";
import devIcon from '../../assets/img/ui_icons/devportal.png';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";

import UnitTestForm from "./Publisher_unitTest";
import DocumentationForm from "./Publisher_documentation";
import FileUpload from "./components/uploadFile";
import { useHistory } from "react-router-dom";
import ConfirmDeletetModal from "../Modals/ConfirmDeleteModal";
import Nodata from "./components/Nodata";

const DevPortalUsers = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [SelectedPProductId, setSelectedPProductId] = useState('');
  const [apis, setApis] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [apiProducts, setApiProducts] = useState([]);
  const [loadingApiProducts, setLoadingApiProducts] = useState(false);
  const [isDocumentationDrawerOpen, setIsDocumentationDrawerOpen] = useState(false);
  const [isUnittestDrawerOpen, setIsUnittestDrawerOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [selectedUserType, setselectedUserType] = useState('app_builder')

  const [jsonContent, setJsonContent] = useState(null);
  const [selectedApiForUnittest, setSelectedApiForUnittest] = useState(null);
  const [error, setError] = useState('');


  const [localEmail, setEmail] = useState('');
  const [localPassword, setPassword] = useState('');
  const [mustChange, setMustChange] = useState(false);
const history = useHistory();
  ///custon upload file
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (file) => {
    setSelectedFile(file); 
};


const handleChangeType = (e)=>{
  console.log(e.target.value);
    setselectedUserType(e.target.value);
}
  const openDocumentationDrawer = () => {
    setIsDocumentationDrawerOpen(true);
  };

  const closeDocumentationDrawer = () => {
    setIsDocumentationDrawerOpen(false);
  };

  const openUnittestDrawer = () => {
    setIsUnittestDrawerOpen(true);
  };

  const closeUnittestDrawer = () => {
    setIsUnittestDrawerOpen(false);
  };

  const fetchProjects = async () => {
    setLoading(true);
    setProjects([]);
    try {
      const response = await ObexRequestHandler.get(`/gateway/get_dev_portal_by_org?org_id=${SessionService.ORGANIZATION}`, {}, true)
      const projectsInfo = response.data || [];
      setProjects(projectsInfo);
      
      // Recuperar el ID guardado o usar el primer proyecto
      const savedProjectId = localStorage.getItem('selectedDevPortalId');
      const projectIdToUse = savedProjectId || (projectsInfo[0]?.id || '');
      
      setSelectedProjectId(projectIdToUse);
      if (projectIdToUse) {  // Solo si tenemos un ID válido
        await fetchAPIProducts(projectIdToUse);
      }
      
    } catch (error) {
      console.error('Error fetching projects:', error);
      
    }
    setLoading(false);
    history.push('/dev_portals_users')
    
  };



  const AMBIENT_LABELS = {
    accepted: { name: 'Accepted', color: '#28a745' },        // Verde
    pending: { name: 'Pending', color: '#CE9C06' }, // Amarillo
    //3: { name: 'Live', color: '#28a745' }, // Amarillo
  
  };
  
  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;
  
    return (
      <span 
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth:'100px',
          display:'flex',
          width:'65px',
          justifyContent:'center',
          marginLeft:'auto',
          marginRight:'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };



  const fetchAPIProducts = async (projects_id) => {
    if (!projects_id) return; // Evitar llamada si no hay ID
    
    try {
      const response = await ObexRequestHandler.get(`/publishing/get_dev_portal_users/${projects_id}`, {}, true);
      const publishedApis = response.data || {};
      const products = publishedApis.Products || [];

      if (products.length === 0) {
        console.warn('No API Products found for the selected project.');
      }

      setApiProducts(products.map((product) => ({ id: product.id, name: product.name })));
    } catch (error) {
      console.error('Error fetching API Products:', error);
      setApiProducts([]);
    } finally {
      setLoadingApiProducts(false);
    }
  }


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (file && file.type === 'application/json') {
      const reader = new FileReader();

      reader.onload = () => {
        // Comprobamos si el resultado es una cadena
        const fileContent = reader.result;
        if (typeof fileContent === 'string') {
          try {
            const json = JSON.parse(fileContent);
            setJsonContent(json); // Asigna el contenido JSON a la variable
          } catch (error) {
            alert('El archivo no es un JSON válido');
          }
        } else {
          alert('El archivo no es un formato adecuado');
        }
      };

      reader.readAsText(file); // Lee el archivo como texto
    } else {
      alert('Por favor, seleccione un archivo JSON');
    }
  };



  const handleSubmitUser = async (e) => {
    e.preventDefault();

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const check = regex.test(localEmail)
    if (!check) { 
      setError('Not valid email'); 
      return;
    }
    const payload = {
      email: localEmail,
      table_dest: 'dev_portal_users',
      devportal_id: selectedProjectId,
      username: localEmail,
      password: localPassword,
      active: true,
      change: mustChange,
      type: selectedUserType
    }

    console.log('Formulario enviado con los siguientes datos:', {
      selectedProjectId,
      email: localEmail,
      password: localPassword,
      mustChange,
      userType: selectedUserType
    });
    
    console.log('Payload completo enviado al servidor:', payload);

    try {
      await ObexRequestHandler.post('/invite_external_user', payload);
      fetchApisByProject();
      closeDrawer();
    } catch (error) {
      console.error('Error invitando usuario:', error);
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Formulario enviado con el proyecto seleccionado:', selectedProjectId);
    console.log('Formulario enviado con el api product seleccionado:', SelectedPProductId);
    console.log('Contenido del archivo JSON:', jsonContent);

    //let url_service = `${cloud}/gateway/routing/add_gateway_link?obex_project_id=${SelectedProject}`

    //custom file
    const formData = new FormData();
    formData.append("file", selectedFile); // logica para subir el archivo

    const payload = {
      api_product_id: SelectedPProductId,
      my_api: jsonContent
      

    }

    await ObexRequestHandler.post(`/gateway/routing/add_gateway_link?obex_project_id=${selectedProjectId}`, payload)

    fetchApisByProject();

    closeDrawer();

  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setSelectedProjectId(value);
    localStorage.setItem('selectedDevPortalId', value); // Guardar el ID seleccionado
    await fetchAPIProducts(value);
  }

  const handleChangeProduct = async (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    setSelectedPProductId(value);
    console.log(name, value);
  }

  const handlePublish = async (api) =>{
 
    const payload = {
      gateway_id: api.gateway_id, 
      new_status: !api.is_published, 
      api_id: api.api_id, 
      api_product_id: api.api_product_id
    }

    await ObexRequestHandler.post('/update_portal_apis_status', payload);

    fetchApisByProject();

    //update_portal_apis_status
  }

  const fetchApisByProject = async () => {
    if (!selectedProjectId) return;

    setLoading(true);
    try {
      const response = await ObexRequestHandler.get(`/list_user_invited_hist?table=dev_portal_users&id=${selectedProjectId}`, {}, true);
      console.log('Respuesta completa de la API:', response);
      const data = response.data || [];

    
      const transformedData = data.map(user => ({
        username: user.email,
        type: user.type,
        apps: [], 
        status: user.status
      }));

      setApis(transformedData);
      setInitialized(true)
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
      setInitialized(true)
    }
    setLoading(false);
    setInitialized(true)

  };


  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/dev_portals_users'){
      history.push('/dev_portals_users')
      SessionService.updating = SessionService.updating -3;
      fetchProjects()
    window.scrollTo(0, 0);
    history.push('/dev_portals_users')
    }
  
  }, false);



  useEffect(() => {
    fetchProjects();
    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
  }, []);



  const openDrawer = (role) => {
    //setSelectedProjectId('');
    setEmail('');
    setPassword('');
    setMustChange(false);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleEditUnittest = (api) => {
    setSelectedApiForUnittest(api);
    openUnittestDrawer();
  };

  const handleRefresh = () => {
    fetchProjects();
    fetchApisByProject()
  }


  const [showDelete, setShowDelete] = useState(false);
  const [selDeveloper, setSelDeveloper] = useState({id:'' })
  const [selTeam, setselTeam] = useState({id:0, type:'', name:'', email:''});

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  }

  const handleDelete = async (developer) => {
    setShowDelete(true);
    setSelDeveloper(developer);
    setselTeam(developer);

  }




  const handleDeleteReal = async (user) => {
    console.log(user);
    handleShowDelete()
    const payload = {
      username: user.username,
      devportal_id: selectedProjectId
    }
    const response = await ObexRequestHandler.post('/publishing/del_dev_portal_user', payload);

    
    handleRefresh();
    //fetchAPIProducts();
    history.push('dev_portals_users')
    
  }

  useEffect(() => {
    if (selectedProjectId) {
      fetchApisByProject();
    } else {
      setApis([]);
    }
  }, [selectedProjectId]);

  const filteredApis = apis.filter(api =>
    api.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    api.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleResendInvitation = async (user) => {
    alert('Invitation resent successfully to ' + user.username);
  };

  return (
    <DashLayout sider={DashRouters} active={'api-publisher'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8' >
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={devIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px"><span style={{ color: '#949498', fontSize: '14px' }}>Dev Portal </span>/ Users </span>
            </div>

          </div>
        </Col>




        <Col md='4' className='align-content-center'>



          <Button disabled={!selectedProjectId} style={{ width: '225px' }} className="customButton-black btn px-4 float-right  resBtn" onClick={() => {selectedProjectId && openDrawer(null)}}>
           { !selectedProjectId  ? 'Select a Developer Portal':'Add User'}
          </Button>



          {/* Botón opcional  */}
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div className="cld_borderWrapper">
          <span className='cld_sectionTitle'>Select a Developer Portal</span>
          
          <div style={{ display:'flex' }}>
            <Form.Group className="my-4" style={{ width:'100%' }}>
              <Form.Control as="select" onChange={(e) => setSelectedProjectId(e.target.value)} value={selectedProjectId || ''}>
                <option value="">-- Select a developer portal --</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <div className="searchContainerHalf mb-4" style={{ position:'relative', marginLeft:'5%', width:'90%', marginTop:'24px' }}>
              <Form.Control
                id='buscador'
                required
                type="text"
                value={searchTerm}
                style={{ marginRight:'calc(-10% - 20px)' }}
                placeholder="Search by User Email"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button className="customButton-transparent-half px-4">
                <FontAwesomeIcon icon={faSearch} size="1x" />
              </Button>
            </div>
          </div>

          {((!selectedProjectId || apis.length === 0 || filteredApis.length === 0) && initialized)? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
              <Nodata />
            </div>
          ) : (
            <Table className="cld_tableWrapper">
              <tbody>
                <tr className="headerRow">
                  <th style={{ width: '20%' }} className="textBold">User Email</th>
                  <th style={{textAlign:'center', width: '15%'}} className="textBold">Type</th>
                  <th style={{ width: '15%' }} className="textBold">Apps</th>
                  <th style={{textAlign:'center', width: '15%'}} className="textBold">Status</th>
                  <th style={{ textAlign: 'end', width: '20px' }} className="textBold">Actions</th>
                </tr>
                {filteredApis.map((api, index) => (
                  <tr className="tableRow" key={index}>
                    <td className="td_centered">{api.username || '-'}</td>
                    <td style={{textAlign:'center'}}  className="td_centered">{api.type || '-'}</td>
                    <td className="td_centered">{api.apps.map((ap=>(ap.name ? ap.name : '-')))}</td>
                    <td style={{textAlign:'center'}} className="td_centered">
                      {api.status === 'pending' ? (
                        getAmbientLabel(api.status)
                      ) : (
                        getAmbientLabel(api.status || 'pending')
                      )}
                    </td>
                    <td>
                      <div className="actionsWrapper">
                        <Dropdown className='my-auto' style={{ textAlign: 'end', width: '100%', marginRight: '0px' }}>
                          <Dropdown.Toggle className="">
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              key={1}
                              onClick={() => handleDelete(api)}
                            >
                              Delete User
                            </Dropdown.Item>
                            <Dropdown.Item
                              key={2}
                              onClick={() => handleResendInvitation(api)}
                            >
                              Resend Invitation
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      )}


      <Drawer
        index="dev_portal_users"
        toggleSidebar={closeDrawer}
        isOpen={isDrawerOpen}
        name={'Add User to Dev Portal'}
        subtitle=''
      >


        <Form onSubmit={handleSubmitUser}>
          <Form.Group controlId="project">
            <Form.Label>Select User Type</Form.Label>
            <Form.Control
              as="select"
              name="project"
              value={selectedUserType}
              onChange={handleChangeType}
            >
              

                <option key={1} value={'app_builder'}>Apps Builder</option>
                <option key={2} value={'consumer'}>Consumer</option>

            </Form.Control>
          </Form.Group>



                <Form.Group controlId="basePath" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                  required
                    type="text"
                    placeholder="Enter email"
                    value={localEmail}
                    onChange={(e) => {setEmail(e.target.value);
                      setError('');
                    }}
                  />
                </Form.Group>



                <Form.Group controlId="basePath" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  
                  <Form.Control
                  required
                    type="password"
                    placeholder="Enter password"
                    value={localPassword}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>


        <Form.Check
          type="checkbox"
          label="Password must change"
          checked={mustChange}
          onChange={(e) => setMustChange(e.target.checked)}
        />
       <Row>
            <div style={{ height:'2em' }}></div>
       </Row>
                <Row>
                  <Col md='6'>
                    <Button 
                    disabled={!localEmail || !localPassword}
                    type="submit" variant="primary" className="w-100">
                      Create
                    </Button>
                  </Col>
                </Row>
             

          
        </Form>
        {error.length > 0 && (
                            <Alert style={{marginTop:'1em'}} variant='danger'>
                              {error}
                            </Alert>)}

      </Drawer>

      <Drawer index="dev_portal_users"
        toggleSidebar={closeDocumentationDrawer}
        isOpen={isDocumentationDrawerOpen}
        name="Edit Documentation"
        subtitle=""
      >
      <DocumentationForm api_product_id={''} documenta={''} obex_project_id={undefined} docInfo={undefined}></DocumentationForm>
      </Drawer>

      <Drawer index="dev_portal_users"
        toggleSidebar={closeUnittestDrawer}
        isOpen={isUnittestDrawerOpen}
        name="Edit Unittest"
        subtitle=""
      >
        	<UnitTestForm apiDetails={selectedApiForUnittest} onClose={closeUnittestDrawer} />
      </Drawer>


      <ConfirmDeletetModal 
          handleSubmit={
            ()=>handleDeleteReal(selDeveloper)
          } 
          showModal={showDelete}
          onCloseModal={handleShowDelete}
          loading={false}
          error={''}
          success={''}
          name_to_check={'DELETE'}
          title_to_show={`Confirm delete user ${selTeam.email}`}
        />



    </DashLayout>
  );
};

export default DevPortalUsers;
