import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Row, Col, Form, Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import Drawer from "./Drawer";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import ProxIcon from '../../assets/img/ui_icons/cld_proxy.svg';
import { useHistory } from "react-router-dom";
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';
import { Responsive as ResponsiveGridLayout, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faExpand, faCompress, faCog, faGripVertical, faSave, faCalendarAlt, faBrain, faTimes, faChartBar, faMagic } from '@fortawesome/free-solid-svg-icons';
import { DatePicker, Radio, Space } from 'antd';
import moment from 'moment';
import AiInterpretationDrawer from './AiInterpretationDrawer';

import lineChart from '../../assets/img/newAvapCloud/Graph.png';
import barChart from '../../assets/img/newAvapCloud/bargraph.png';
import pieChart from '../../assets/img/newAvapCloud/Pie Blue.png';
import doughnutChart from '../../assets/img/newAvapCloud/Pie Blue.png';
import icon_ai from '../../assets/img/logos/icono_AI.svg';

const ResponsiveGridLayoutWithWidth = WidthProvider(ResponsiveGridLayout);

// breakpoints y columnas siguiendo la lógica de Bootstrap
const BREAKPOINTS = {
  xxl: 1400,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 0
};

const COLS = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 8,
  sm: 6,
  xs: 4
};


interface Environment {
  id: string;
}

// Definir tipos de gráficos disponibles
type ChartType = 'line' | 'bar' | 'pie' | 'doughnut';

// Interfaz para el rango de fechas
interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

// Tipo para el selector de tiempo predefinido
type TimeRangeType = '1d' | '1w' | '1m' | '1y' | 'custom';

// Interfaz básica para los gráficos
interface ChartData {
  id: string;
  type: ChartType;
  title: string;
  description: string;
  imageUrl?: string;
  data: any;
  options: any;
  width: number; // 4, 6, o 12 para tamaños de columna
  dateRange?: DateRange;
  timeRangeType?: TimeRangeType;
}

// Interfaz para los elementos del layout
interface LayoutItem {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  static?: boolean;
}

// Categorías de gráficos
interface ChartCategory {
  name: string;
  charts: ChartData[];
}

// Hook para obtener el tamaño de la ventana
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 1200,
    height: typeof window !== 'undefined' ? window.innerHeight : 800
  });

  useEffect(() => {
    // Manejador para actualizar el estado
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }


    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

// Datos simulados para los gráficos
const lineChartData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June'],
  datasets: [
    {
      label: 'Project A',
      data: [12, 19, 3, 5, 2, 3],
      borderColor: 'rgb(25, 118, 210)',
      backgroundColor: 'rgba(25, 118, 210, 0.2)',
      fill: false,
    },
    {
      label: 'Project B',
      data: [3, 8, 1, 7, 4, 9],
      borderColor: 'rgb(66, 165, 245)',
      backgroundColor: 'rgba(66, 165, 245, 0.2)',
      fill: false,
    },
  ],
};

const barChartData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June'],
  datasets: [
    {
      label: 'API Calls',
      data: [65, 59, 80, 81, 56, 55],
      backgroundColor: 'rgba(30, 136, 229, 0.6)',
    },
  ],
};

const pieChartData = {
  labels: ['Project A', 'Project B', 'Project C'],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: [
        'rgba(13, 71, 161, 0.6)',
        'rgba(25, 118, 210, 0.6)',
        'rgba(66, 165, 245, 0.6)',
      ],
      borderColor: [
        'rgba(13, 71, 161, 1)',
        'rgba(25, 118, 210, 1)',
        'rgba(66, 165, 245, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

const doughnutChartData = {
  labels: ['Storage Used', 'Storage Free'],
  datasets: [
    {
      data: [70, 30],
      backgroundColor: [
        'rgba(25, 118, 210, 0.6)',
        'rgba(144, 202, 249, 0.6)',
      ],
      borderColor: [
        'rgba(25, 118, 210, 1)',
        'rgba(144, 202, 249, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

// Opciones para los gráficos 
const lineChartOptions = {
  responsive: true,
  title: {
    display: true,
    text: 'API Usage Over Time',
  },
  legend: {
    position: 'top',
  },
};

const barChartOptions = {
  responsive: true,
  title: {
    display: true,
    text: 'API Consumption by Month',
  },
  legend: {
    position: 'top',
  },
};

const pieChartOptions = {
  responsive: true,
  title: {
    display: true,
    text: 'Project Distribution',
  },
  legend: {
    position: 'top',
  },
};

const doughnutChartOptions = {
  responsive: true,
  title: {
    display: true,
    text: 'Storage Usage',
  },
  legend: {
    position: 'top',
  },
};

// Categorías con diferentes tipos de gráficos
const chartCategories: ChartCategory[] = [
  {
    name: 'API Usage',
    charts: [
      {
        id: 'line-chart-1',
        type: 'line',
        title: 'API Usage Over Time',
        description: 'Shows API usage evolution across different projects.',
        imageUrl: undefined,
        data: lineChartData,
        options: lineChartOptions,
        width: 4
      },
      {
        id: 'bar-chart-1',
        type: 'bar',
        title: 'API Consumption by Project',
        description: 'Compares API usage among projects.',
        imageUrl: undefined,
        data: barChartData,
        options: barChartOptions,
        width: 4
      },
    ],
  },
  {
    name: 'Storage',
    charts: [
      {
        id: 'pie-chart-1',
        type: 'pie',
        title: 'Resource Distribution by Project',
        description: 'Displays how resources (requests, storage, etc.) are allocated.',
        imageUrl: undefined,
        data: pieChartData,
        options: pieChartOptions,
        width: 4
      },
      {
        id: 'doughnut-chart-1',
        type: 'doughnut',
        title: 'Storage Usage',
        description: 'Shows the proportion of storage space used vs. available.',
        imageUrl: undefined,
        data: doughnutChartData,
        options: doughnutChartOptions,
        width: 4
      },
    ],
  },
];

// Función auxiliar para obtener los datos
const getChartDataAndOptions = (type: ChartType) => {
  const templateChart = chartCategories
    .reduce((acc: ChartData[], category) => [...acc, ...category.charts], [])
    .find(c => c.type === type);

  return {
    data: templateChart?.data || {},
    options: templateChart?.options || {}
  };
};

// Funciones auxiliares para el manejo de layouts
const getStandardHeight = (width: number): number => {
  const baseHeight = 8;
  const widthRatio = width / 4;
  return Math.round(baseHeight * widthRatio);
};

const getAdaptiveWidth = (chart: ChartData, cols: number): number => {
  // Si el ancho original es mayor que las columnas disponibles, ajustarlo
  if (chart.width > cols) {
    return cols;
  }
  // Si el ancho original es menor que la mitad de las columnas, mantenerlo
  if (chart.width <= cols / 2) {
    return chart.width;
  }
  // En otros casos, ajustar proporcionalmente
  return Math.floor(cols / 2);
};

const AiCharts = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editingEnvironment, setEditingEnvironment] = useState<Environment | null>(null);
  const [charts, setCharts] = useState<ChartData[]>(() => {
    try {
      const savedCharts = localStorage.getItem('dashboard_charts');
      if (savedCharts) {
        console.log("Cargando gráficos desde localStorage");
        const parsedCharts = JSON.parse(savedCharts);
        return parsedCharts.map((chart: any) => {
          console.log("Cargando gráfico:", chart.id, chart.type);
          const { data, options } = getChartDataAndOptions(chart.type);

          // Si hay datos de colores guardados, aplicarlos a los datasets
          if (chart.datasets && chart.datasets.length > 0) {
            console.log("Aplicando colores guardados para el gráfico:", chart.id);

            // Asegurarse de que no intentamos aplicar colores a más datasets de los que existen
            const datasetCount = Math.min(data.datasets.length, chart.datasets.length);

            // Aplicar los colores guardados a cada dataset
            for (let i = 0; i < datasetCount; i++) {
              // Crear una copia del dataset original
              const updatedDataset = { ...data.datasets[i] };

              // Aplicar backgroundColor (puede ser un string o un array)
              if (chart.datasets[i].backgroundColor !== undefined) {
                if (Array.isArray(chart.datasets[i].backgroundColor)) {
                  updatedDataset.backgroundColor = [...chart.datasets[i].backgroundColor];
                } else {
                  updatedDataset.backgroundColor = chart.datasets[i].backgroundColor;
                }
              }

              // Aplicar borderColor (puede ser un string o un array)
              if (chart.datasets[i].borderColor !== undefined) {
                if (Array.isArray(chart.datasets[i].borderColor)) {
                  updatedDataset.borderColor = [...chart.datasets[i].borderColor];
                } else {
                  updatedDataset.borderColor = chart.datasets[i].borderColor;
                }
              }

              // Actualizar el dataset en los datos
              data.datasets[i] = updatedDataset;
            }
          }

          return {
            ...chart,
            data,
            options: {
              ...options,
              title: {
                ...options.title,
                text: chart.title
              }
            }
          };
        });
      }
      return [];
    } catch (error) {
      console.error('Error loading charts from localStorage:', error);
      return [];
    }
  });
  const [layouts, setLayouts] = useState<{ [key: string]: LayoutItem[] }>(() => {
    try {
      const savedLayouts = localStorage.getItem('dashboard_layouts');
      if (savedLayouts) {
        const parsedLayouts = JSON.parse(savedLayouts);
        console.log('Cargando layouts desde localStorage:', parsedLayouts);


        const initialLayouts: { [key: string]: LayoutItem[] } = {};
        Object.keys(parsedLayouts).forEach(breakpoint => {
          if (parsedLayouts[breakpoint]) {
            initialLayouts[breakpoint] = parsedLayouts[breakpoint].map((item: LayoutItem) => ({
              ...item,
              static: false
            }));
          }
        });

        return initialLayouts;
      }

      // Si no hay layouts guardados, generar uno nuevo
      if (charts.length > 0) {
        const initialLayouts: { [key: string]: LayoutItem[] } = {};
        Object.keys(BREAKPOINTS).forEach(breakpoint => {
          const cols = breakpoint === 'xxl' || breakpoint === 'xl' ? 12 :
            breakpoint === 'lg' ? 9 :
              breakpoint === 'md' ? 6 :
                breakpoint === 'sm' ? 4 : 2;
          initialLayouts[breakpoint] = generateLayout(charts, cols);
        });
        return initialLayouts;
      }

      return {};
    } catch (error) {
      console.error('Error loading layouts from localStorage:', error);
      return {};
    }
  });
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const [editingChart, setEditingChart] = useState<ChartData | null>(null);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const history = useHistory();
  const { width } = useWindowSize();

  // Calcular el ancho del contenedor de GridLayout
  const gridWidth = width > 1400 ? 1200 : width - 200;

  const [selectedTimeRanges, setSelectedTimeRanges] = useState<{ [key: string]: TimeRangeType }>({});
  const [customDateRanges, setCustomDateRanges] = useState<{ [key: string]: [moment.Moment | null, moment.Moment | null] }>({});
  const [aiInterpretationDrawerOpen, setAiInterpretationDrawerOpen] = useState(false);
  const [currentChartForAi, setCurrentChartForAi] = useState<ChartData | null>(null);
  const [aiInterpretation, setAiInterpretation] = useState<string>('');
  const [aiLoading, setAiLoading] = useState(false);
  const [chartCreationMode, setChartCreationMode] = useState<'prefab' | 'ai'>('prefab');
  const [aiPrompt, setAiPrompt] = useState<string>('');
  const [isGeneratingAiChart, setIsGeneratingAiChart] = useState<boolean>(false);
  const [generatedAiChart, setGeneratedAiChart] = useState<ChartData | null>(null);

  // Actualizar el layout cuando cambian los gráficos
  useEffect(() => {
    if (charts.length > 0) {
      // Si ya existen layouts y solo estamos editando gráficos (no añadiendo ni eliminando),
      // preservamos los layouts existentes
      if (Object.keys(layouts).length > 0 &&
        charts.length === Object.values(layouts)[0].length) {
        return;
      }

      // Generar layouts para todos los breakpoints
      const newLayouts: { [key: string]: LayoutItem[] } = {};
      Object.keys(BREAKPOINTS).forEach(breakpoint => {
        const cols = COLS[breakpoint as keyof typeof COLS];
        newLayouts[breakpoint] = generateLayout(charts, cols);
      });

      // Actualizar el estado con los nuevos layouts
      setLayouts(newLayouts);
    } else {
      setLayouts({});
    }
  }, [charts]);

  useEffect(() => {
    if (Object.keys(layouts).length > 0) {
      const updatedLayouts: { [key: string]: LayoutItem[] } = {};

      Object.entries(layouts).forEach(([breakpoint, layout]) => {
        updatedLayouts[breakpoint] = layout.map(item => ({
          ...item,
          static: !editMode
        }));
      });

      setLayouts(updatedLayouts);
    }
  }, [editMode]);

  // Función para generar el layout basado en los gráficos
  const generateLayout = (chartItems: ChartData[], cols: number): LayoutItem[] => {
    const newLayout: LayoutItem[] = [];
    let y = 0;
    let x = 0;

    chartItems.forEach((chart) => {
      // Adaptar el ancho según el breakpoint
      const w = getAdaptiveWidth(chart, cols);

      // Si el elemento no cabe en la fila actual, moverlo a la siguiente fila
      if (x + w > cols) {
        x = 0;
        y += getStandardHeight(w);
      }

      // Obtener la altura estándar basada en el ancho
      const h = getStandardHeight(w);

      newLayout.push({
        i: chart.id,
        x,
        y,
        w,
        h,
        static: !editMode
      });

      // Actualizar la posición x para el siguiente elemento
      x += w;
      if (x >= cols) {
        x = 0;
        y += h;
      }
    });

    return newLayout;
  };

  // Funcionalidades drawer
  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  // Funcionalidad para añadir un gráfico seleccionado
  const handleAddChart = (chart: ChartData) => {
    // Generar un ID único simple
    const uniqueId = `chart-${Date.now()}`;

    // Crear una copia segura sin usar JSON.stringify para evitar errores con estructuras circulares //testear esto no soluciono el problema de los colores
    const safeCopy = {
      ...chart,
      id: uniqueId,
      data: {
        ...chart.data,
        datasets: chart.data.datasets.map(dataset => ({
          ...dataset,
          // Copiar solo las propiedades que necesitamos
          label: dataset.label,
          data: [...dataset.data],
          backgroundColor: dataset.backgroundColor,
          borderColor: dataset.borderColor,
          fill: dataset.fill
        }))
      },
      options: {
        ...chart.options,
        title: chart.options.title ? {
          ...chart.options.title
        } : undefined,
        legend: chart.options.legend ? {
          ...chart.options.legend
        } : undefined
      }
    };

    setCharts([...charts, safeCopy]);
    setDrawerOpen(false);
  };

  // Funcionalidad para eliminar un gráfico
  const handleDeleteChart = (chartId: string) => {
    setCharts(charts.filter(chart => chart.id !== chartId));
  };

  // Funcionalidad para cambiar el tamaño de un gráfico
  const handleResizeChart = (chartId: string, newWidth: number) => {
    console.log(`Cambiando tamaño del gráfico ${chartId} a ancho ${newWidth}`);

    // Actualizar el estado de los gráficos
    setCharts(charts.map(chart =>
      chart.id === chartId ? { ...chart, width: newWidth } : chart
    ));

    // Actualizar también el layout para reflejar el nuevo tamaño
    if (Object.keys(layouts).length > 0) {
      const updatedLayouts: { [key: string]: LayoutItem[] } = {};

      Object.entries(layouts).forEach(([breakpoint, layout]) => {
        const cols = COLS[breakpoint as keyof typeof COLS];

        updatedLayouts[breakpoint] = layout.map(item => {
          if (item.i === chartId) {
            // Usar la misma función getStandardHeight para mantener consistencia
            const getStandardHeight = (width: number): number => {
              const baseHeight = 8;
              const widthRatio = width / 4;
              return Math.round(baseHeight * widthRatio);
            };

            const newHeight = getStandardHeight(newWidth);

            return {
              ...item,
              w: newWidth,
              h: newHeight
            };
          }
          return item;
        });
      });

      // Actualizar los layouts
      setLayouts(updatedLayouts);
    }
  };

  // Funcionalidad para editar un gráfico
  const handleEditChart = (chart: ChartData) => {
    console.log("Editando gráfico:", chart.id, chart.type);

    // Buscar el gráfico actual en el estado para asegurarnos de tener la versión más actualizada
    const currentChart = charts.find(c => c.id === chart.id);

    if (currentChart) {
      console.log("Gráfico encontrado en el estado:", currentChart.id);

      // Crear una copia segura del gráfico actual sin usar JSON.stringify //testear esto no soluciono el problema de los colores
      const safeCopy = {
        ...currentChart,
        data: {
          ...currentChart.data,
          datasets: currentChart.data.datasets.map(dataset => {
            // Crear una copia base del dataset
            const datasetCopy: any = {
              ...dataset,
              // Copiar solo las propiedades que necesitamos
              label: dataset.label,
              data: [...dataset.data],
              fill: dataset.fill
            };

            // Copiar backgroundColor (puede ser un string o un array)
            if (Array.isArray(dataset.backgroundColor)) {
              datasetCopy.backgroundColor = [...dataset.backgroundColor];
            } else {
              datasetCopy.backgroundColor = dataset.backgroundColor;
            }

            // Copiar borderColor (puede ser un string o un array)
            if (Array.isArray(dataset.borderColor)) {
              datasetCopy.borderColor = [...dataset.borderColor];
            } else {
              datasetCopy.borderColor = dataset.borderColor;
            }

            return datasetCopy;
          })
        },
        options: {
          ...currentChart.options,
          title: currentChart.options.title ? {
            ...currentChart.options.title
          } : undefined,
          legend: currentChart.options.legend ? {
            ...currentChart.options.legend
          } : undefined
        }
      };

      console.log("Copia segura creada:", safeCopy.id);
      setEditingChart(safeCopy);
    } else {
      console.log("Gráfico no encontrado en el estado, usando el pasado como parámetro");

      // Si por alguna razón no encontramos el gráfico, usamos el que se pasó como parámetro
      const safeCopy = {
        ...chart,
        data: {
          ...chart.data,
          datasets: chart.data.datasets.map(dataset => {
            // Crear una copia base del dataset
            const datasetCopy: any = {
              ...dataset,
              // Copiar solo las propiedades que necesitamos
              label: dataset.label,
              data: [...dataset.data],
              fill: dataset.fill
            };

            // Copiar backgroundColor (puede ser un string o un array)
            if (Array.isArray(dataset.backgroundColor)) {
              datasetCopy.backgroundColor = [...dataset.backgroundColor];
            } else {
              datasetCopy.backgroundColor = dataset.backgroundColor;
            }

            // Copiar borderColor (puede ser un string o un array)
            if (Array.isArray(dataset.borderColor)) {
              datasetCopy.borderColor = [...dataset.borderColor];
            } else {
              datasetCopy.borderColor = dataset.borderColor;
            }

            return datasetCopy;
          })
        },
        options: {
          ...chart.options,
          title: chart.options.title ? {
            ...chart.options.title
          } : undefined,
          legend: chart.options.legend ? {
            ...chart.options.legend
          } : undefined
        }
      };

      setEditingChart(safeCopy);
    }

    setEditDrawerOpen(true);
  };

  // Funcionalidad para guardar los cambios de un gráfico
  const handleSaveChart = () => {
    if (editingChart) {
      console.log("Guardando cambios para el gráfico:", editingChart.id, editingChart.type);

      // Actualizar el gráfico manteniendo su tamaño actual
      const updatedCharts = charts.map(chart =>
        chart.id === editingChart.id ? editingChart : chart
      );

      // Actualizar el estado con los nuevos gráficos
      setCharts(updatedCharts);

      // Guardar en localStorage
      try {
        const chartsToSave = updatedCharts.map(chart => {
          // Extraer los datos de colores de los datasets
          const datasets = chart.data.datasets.map((dataset: any) => {
            // Objeto base con la etiqueta
            const datasetInfo: any = {
              label: dataset.label
            };

            // Guardar backgroundColor (puede ser un string o un array)
            if (Array.isArray(dataset.backgroundColor)) {
              datasetInfo.backgroundColor = [...dataset.backgroundColor];
            } else {
              datasetInfo.backgroundColor = dataset.backgroundColor;
            }

            // Guardar borderColor (puede ser un string o un array)
            if (Array.isArray(dataset.borderColor)) {
              datasetInfo.borderColor = [...dataset.borderColor];
            } else {
              datasetInfo.borderColor = dataset.borderColor;
            }

            return datasetInfo;
          });

          // Objeto a guardar en localStorage
          return {
            id: chart.id,
            type: chart.type,
            title: chart.title,
            description: chart.description,
            width: chart.width,
            datasets
          };
        });

        console.log("Guardando en localStorage:", chartsToSave);
        localStorage.setItem('dashboard_charts', JSON.stringify(chartsToSave));
      } catch (error) {
        console.error('Error saving charts to localStorage:', error);
      }

      setEditingChart(null);
      setEditDrawerOpen(false);
    }
  };

  // Función para depurar layouts
  const debugLayouts = () => {
    console.log('Current layouts:', layouts);
    console.log('Current charts:', charts);
    console.log('localStorage layouts:', JSON.parse(localStorage.getItem('dashboard_layouts') || '{}'));
    console.log('localStorage charts:', JSON.parse(localStorage.getItem('dashboard_charts') || '[]'));
  };

  // Toggle modo edición
  const toggleEditMode = () => {
    const newEditMode = !editMode;
    setEditMode(newEditMode);

    // Depurar layouts
    debugLayouts();

    // Forzar la actualización de los layouts inmediatamente
    if (Object.keys(layouts).length > 0) {
      const updatedLayouts: { [key: string]: LayoutItem[] } = {};

      Object.entries(layouts).forEach(([breakpoint, layout]) => {
        updatedLayouts[breakpoint] = layout.map(item => ({
          ...item,
          static: !newEditMode
        }));
      });

      setLayouts(updatedLayouts);

      // Si estamos saliendo del modo edición, actualizar los anchos de los gráficos //testear esto
      if (!newEditMode) {
        const currentBreakpoint = Object.keys(BREAKPOINTS).find(
          bp => window.innerWidth >= BREAKPOINTS[bp as keyof typeof BREAKPOINTS]
        ) || 'lg';

        const currentLayout = updatedLayouts[currentBreakpoint];
        if (currentLayout) {
          const updatedCharts = charts.map(chart => {
            const layoutItem = currentLayout.find(item => item.i === chart.id);
            if (layoutItem) {
              return {
                ...chart,
                width: layoutItem.w
              };
            }
            return chart;
          });

          setCharts(updatedCharts);

          // Guardar en localStorage
          try {
            const chartsToSave = updatedCharts.map(chart => ({
              id: chart.id,
              type: chart.type,
              title: chart.title,
              description: chart.description,
              width: chart.width
            }));
            localStorage.setItem('dashboard_charts', JSON.stringify(chartsToSave));
            localStorage.setItem('dashboard_layouts', JSON.stringify(updatedLayouts));
          } catch (error) {
            console.error('Error saving to localStorage:', error);
          }
        }
      }
    }
  };

  // Manejar cambios en el layout
  const handleLayoutChange = (currentLayout: LayoutItem[], allLayouts: { [key: string]: LayoutItem[] }) => {
    // Solo actualizar los layouts si estamos en modo edición
    if (editMode) {
      console.log('Layout cambiado:', currentLayout);

      // Obtener el breakpoint actual
      const currentBreakpoint = Object.keys(BREAKPOINTS).find(
        bp => window.innerWidth >= BREAKPOINTS[bp as keyof typeof BREAKPOINTS]
      ) || 'lg';

      // Obtener el número de columnas para el breakpoint actual
      const currentCols = COLS[currentBreakpoint as keyof typeof COLS];

      // Ajustar los anchos de los elementos según el nuevo breakpoint
      const adjustedLayout = currentLayout.map(item => {
        const chart = charts.find(c => c.id === item.i);
        if (chart) {
          const newWidth = getAdaptiveWidth(chart, currentCols);
          return {
            ...item,
            w: newWidth,
            h: getStandardHeight(newWidth)
          };
        }
        return item;
      });

      // Actualizar el layout actual manteniendo los demás layouts
      setLayouts(prevLayouts => ({
        ...prevLayouts,
        [currentBreakpoint]: adjustedLayout
      }));
    }
  };

  // Renderizar el gráfico según su tipo
  const renderChart = (chart: ChartData) => {
    // Opciones comunes para todos los gráficos para que respeten el contenedor
    const responsiveOptions = {
      responsive: true,
      maintainAspectRatio: false,
      ...chart.options
    };

    switch (chart.type) {
      case 'line':
        return <Line data={chart.data} options={responsiveOptions} />;
      case 'bar':
        return <Bar data={chart.data} options={responsiveOptions} />;
      case 'pie':
        return <Pie data={chart.data} options={responsiveOptions} />;
      case 'doughnut':
        return <Doughnut data={chart.data} options={responsiveOptions} />;
      default:
        return null;
    }
  };

  // Referencia para el contenedor de GridLayout
  const gridRef = useRef<HTMLDivElement>(null);

  // Función para manejar el inicio del arrastre
  const handleDragStart = useCallback((layout: LayoutItem[], oldItem: LayoutItem, newItem: LayoutItem, placeholder: LayoutItem, e: MouseEvent, element: HTMLElement) => {
    // Asegurarse de que el elemento tenga un estilo visual durante el arrastre
    if (element) {
      element.style.zIndex = '100';
    }
  }, []);

  // Función para manejar el fin del arrastre
  const handleDragStop = useCallback((layout: LayoutItem[], oldItem: LayoutItem, newItem: LayoutItem, placeholder: LayoutItem, e: MouseEvent, element: HTMLElement) => {
    // Restaurar el estilo del elemento después del arrastre
    if (element) {
      element.style.zIndex = '';
    }
  }, []);

  // Guardar charts en localStorage cuando cambien
  useEffect(() => {
    try {
      // Solo guardar los datos esenciales de los gráficos
      const chartsToSave = charts.map(chart => ({
        id: chart.id,
        type: chart.type,
        title: chart.title,
        description: chart.description,
        width: chart.width
      }));
      localStorage.setItem('dashboard_charts', JSON.stringify(chartsToSave));
    } catch (error) {
      console.error('Error saving charts to localStorage:', error);
    }
  }, [charts]);

  // Guardar layouts en localStorage cuando cambien
  useEffect(() => {
    try {
      localStorage.setItem('dashboard_layouts', JSON.stringify(layouts));
    } catch (error) {
      console.error('Error saving layouts to localStorage:', error);
    }
  }, [layouts]);

  // Función para manejar el cambio de rango de tiempo
  const handleTimeRangeChange = (chartId: string, timeRange: TimeRangeType) => {
    setSelectedTimeRanges(prev => ({
      ...prev,
      [chartId]: timeRange
    }));

    // Si seleccionamos un rango predefinido, actualizar los datos del gráfico
    if (timeRange !== 'custom') {
      // Generar fechas según el rango seleccionado
      const now = moment();
      let startDate: moment.Moment;

      switch (timeRange) {
        case '1d':
          startDate = moment().subtract(1, 'days');
          break;
        case '1w':
          startDate = moment().subtract(1, 'weeks');
          break;
        case '1m':
          startDate = moment().subtract(1, 'months');
          break;
        case '1y':
          startDate = moment().subtract(1, 'years');
          break;
        default:
          startDate = moment().subtract(1, 'months'); // Por defecto 1 mes
      }

      // Actualizar los datos del gráfico
      updateChartData(chartId, startDate, now);
    }
  };

  // Función para manejar el cambio de rango de fechas personalizado
  const handleCustomDateChange = (chartId: string, dates: any) => {
    setCustomDateRanges(prev => ({
      ...prev,
      [chartId]: dates
    }));

    // Actualizar los datos del gráfico con el rango personalizado
    if (dates && dates.length === 2) {
      updateChartData(chartId, dates[0], dates[1]);
    }
  };

  // Función para actualizar los datos del gráfico según el rango de fechas
  const updateChartData = (chartId: string, startDate: moment.Moment, endDate: moment.Moment) => {
    // Buscar el gráfico que queremos actualizar
    const chartIndex = charts.findIndex(chart => chart.id === chartId);
    if (chartIndex === -1) return;

    const chart = charts[chartIndex];
    let newData;

    // Generar datos según el tipo de gráfico
    switch (chart.type) {
      case 'line':
        // Generar datos para gráfico de línea
        const days = endDate.diff(startDate, 'days');
        const labels = [];

        // Determinar el formato de fecha según el rango
        let dateFormat = 'DD/MM';
        let step = 1;

        if (days > 60) {
          dateFormat = 'MMM YYYY';
          step = Math.ceil(days / 12); // Mostrar aproximadamente 12 puntos
        } else if (days > 30) {
          dateFormat = 'DD MMM';
          step = Math.ceil(days / 10); // Mostrar aproximadamente 10 puntos
        }

        // Generar las etiquetas
        for (let i = 0; i <= days; i += step) {
          labels.push(moment(startDate).add(i, 'days').format(dateFormat));
        }

        // Generar datos aleatorios para cada serie
        const data1 = labels.map(() => Math.floor(Math.random() * 20) + 5);
        const data2 = labels.map(() => Math.floor(Math.random() * 15) + 3);

        newData = {
          ...chart.data,
          labels,
          datasets: [
            {
              ...chart.data.datasets[0],
              data: data1,
            },
            {
              ...chart.data.datasets[1],
              data: data2,
            },
          ],
        };
        break;

      case 'bar':
        // Generar datos para gráfico de barras
        const barDays = endDate.diff(startDate, 'days');
        const barLabels = [];

        // Determinar el formato de fecha según el rango
        let barDateFormat = 'DD/MM';
        let barStep = 1;

        if (barDays > 60) {
          barDateFormat = 'MMM YYYY';
          barStep = Math.ceil(barDays / 8); // Mostrar aproximadamente 8 barras
        } else if (barDays > 30) {
          barDateFormat = 'DD MMM';
          barStep = Math.ceil(barDays / 6); // Mostrar aproximadamente 6 barras
        }

        // Generar las etiquetas
        for (let i = 0; i <= barDays; i += barStep) {
          barLabels.push(moment(startDate).add(i, 'days').format(barDateFormat));
        }

        // Generar datos aleatorios
        const barData = barLabels.map(() => Math.floor(Math.random() * 50) + 30);

        newData = {
          ...chart.data,
          labels: barLabels,
          datasets: [
            {
              ...chart.data.datasets[0],
              data: barData,
            },
          ],
        };
        break;

      case 'pie':
      case 'doughnut':
        // Para gráficos de tipo pie y doughnut, simplemente variamos un poco los valores
        const pieData = chart.data.datasets[0].data.map(() =>
          Math.floor(Math.random() * 100) + 20
        );

        newData = {
          ...chart.data,
          datasets: [
            {
              ...chart.data.datasets[0],
              data: pieData,
            },
          ],
        };
        break;

      default:
        return;
    }

    // Actualizar el estado
    const updatedCharts = [...charts];
    updatedCharts[chartIndex] = {
      ...chart,
      data: newData,
    };

    setCharts(updatedCharts);
  };

  // Función para abrir el drawer de interpretación de IA
  const handleOpenAiInterpretation = (chart: ChartData) => {
    setCurrentChartForAi(chart);
    setAiInterpretationDrawerOpen(true);
    setAiLoading(true);

    // Simular carga de la interpretación de IA
    setTimeout(() => {
      generateAiInterpretation(chart);
      setAiLoading(false);
    }, 1500);
  };

  // Función para cerrar el drawer de interpretación de IA
  const handleCloseAiInterpretation = () => {
    setAiInterpretationDrawerOpen(false);
    setCurrentChartForAi(null);
  };

  // Función para generar la interpretación de IA (simulada)
  const generateAiInterpretation = (chart: ChartData) => {
    const timeRange = selectedTimeRanges[chart.id] || '1m';
    let dateRangeText = '';

    switch (timeRange) {
      case '1d':
        dateRangeText = 'the last 24 hours';
        break;
      case '1w':
        dateRangeText = 'the last week';
        break;
      case '1m':
        dateRangeText = 'the last month';
        break;
      case '1y':
        dateRangeText = 'the last year';
        break;
      case 'custom':
        const customRange = customDateRanges[chart.id];
        if (customRange && customRange[0] && customRange[1]) {
          dateRangeText = `the period from ${customRange[0].format('MM/DD/YYYY')} to ${customRange[1].format('MM/DD/YYYY')}`;
        } else {
          dateRangeText = 'the selected period';
        }
        break;
    }

    let interpretation = '';

    switch (chart.type) {
      case 'line':
        interpretation = `
          <h4>Trend Analysis: ${chart.title}</h4>
          <p>During ${dateRangeText}, a ${Math.random() > 0.5 ? 'growing' : 'declining'} trend is observed in the analyzed data.</p>
          <p>Key points to highlight:</p>
          <ul>
            <li>Project A shows a ${Math.random() > 0.5 ? 'growth' : 'decline'} of ${Math.floor(Math.random() * 30) + 5}% compared to the previous period.</li>
            <li>Project B presents a ${Math.random() > 0.5 ? 'improvement' : 'decrease'} in performance of ${Math.floor(Math.random() * 25) + 10}%.</li>
            <li>An important inflection point is detected around ${moment().subtract(Math.floor(Math.random() * 20) + 5, 'days').format('MM/DD/YYYY')}.</li>
          </ul>
          <p>Recommendations:</p>
          <ul>
            <li>${Math.random() > 0.5 ? 'Increase' : 'Maintain'} investment in Project ${Math.random() > 0.5 ? 'A' : 'B'} to optimize results.</li>
            <li>Review the ${Math.random() > 0.5 ? 'marketing' : 'development'} strategy to improve overall performance.</li>
          </ul>
        `;
        break;

      case 'bar':
        interpretation = `
          <h4>Comparative Analysis: ${chart.title}</h4>
          <p>The analysis of ${dateRangeText} shows significant variations in API consumption.</p>
          <p>Main observations:</p>
          <ul>
            <li>The maximum consumption peak was recorded in ${['January', 'February', 'March', 'April', 'May', 'June'][Math.floor(Math.random() * 6)]} with ${Math.floor(Math.random() * 1000) + 500} calls.</li>
            <li>The average consumption is ${Math.floor(Math.random() * 500) + 300} calls per period.</li>
            <li>A ${Math.random() > 0.5 ? 'cyclical' : 'irregular'} pattern is detected in API usage.</li>
          </ul>
          <p>Recommendations:</p>
          <ul>
            <li>Optimize caching to reduce the number of calls during high demand periods.</li>
            <li>Implement a ${Math.random() > 0.5 ? 'throttling' : 'load balancing'} strategy to improve performance.</li>
          </ul>
        `;
        break;

      case 'pie':
      case 'doughnut':
        interpretation = `
          <h4>Distribution Analysis: ${chart.title}</h4>
          <p>The current distribution shows an imbalance that requires attention.</p>
          <p>Key findings:</p>
          <ul>
            <li>${chart.data.labels[0]} represents ${Math.floor(Math.random() * 30) + 40}% of the total, indicating a ${Math.random() > 0.5 ? 'excessive concentration' : 'expected distribution'}.</li>
            <li>${chart.data.labels[1]} shows a proportion ${Math.random() > 0.5 ? 'lower than expected' : 'within normal parameters'}.</li>
            <li>The relationship between components suggests an opportunity for ${Math.random() > 0.5 ? 'redistribution' : 'optimization'}.</li>
          </ul>
          <p>Recommendations:</p>
          <ul>
            <li>${Math.random() > 0.5 ? 'Redistribute' : 'Maintain'} allocated resources to achieve a better balance.</li>
            <li>Consider ${Math.random() > 0.5 ? 'expanding' : 'reducing'} capacity in ${chart.data.labels[Math.floor(Math.random() * chart.data.labels.length)]}.</li>
          </ul>
        `;
        break;
    }

    setAiInterpretation(interpretation);
  };

  // Función para generar un gráfico con IA (simulado)
  const generateAiChart = (prompt: string) => {
    setIsGeneratingAiChart(true);

    // Simulamos un tiempo de procesamiento
    setTimeout(() => {
      // Generar un ID único
      const uniqueId = `ai-chart-${Date.now()}`;

      // Determinar el tipo de gráfico basado en palabras clave en el prompt
      let chartType: ChartType = 'line';

      if (prompt.toLowerCase().includes('bar') || prompt.toLowerCase().includes('column')) {
        chartType = 'bar';
      } else if (prompt.toLowerCase().includes('pie') || prompt.toLowerCase().includes('distribution')) {
        chartType = 'pie';
      } else if (prompt.toLowerCase().includes('doughnut') || prompt.toLowerCase().includes('donut')) {
        chartType = 'doughnut';
      }

      // Generar un título basado en el prompt
      const titleWords = prompt.split(' ').filter(word => word.length > 3);
      const title = titleWords.length > 2
        ? titleWords.slice(0, 3).join(' ').charAt(0).toUpperCase() + titleWords.slice(0, 3).join(' ').slice(1)
        : 'Generated Chart';

      // Generar datos aleatorios según el tipo de gráfico
      let data;
      let options;

      switch (chartType) {
        case 'line':
          data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June'],
            datasets: [
              {
                label: 'Dataset 1',
                data: Array.from({ length: 6 }, () => Math.floor(Math.random() * 50) + 10),
                borderColor: 'rgb(25, 118, 210)',
                backgroundColor: 'rgba(25, 118, 210, 0.2)',
                fill: false,
              },
              {
                label: 'Dataset 2',
                data: Array.from({ length: 6 }, () => Math.floor(Math.random() * 50) + 10),
                borderColor: 'rgb(66, 165, 245)',
                backgroundColor: 'rgba(66, 165, 245, 0.2)',
                fill: false,
              },
            ],
          };
          options = {
            responsive: true,
            title: {
              display: true,
              text: title,
            },
            legend: {
              position: 'top',
            },
          };
          break;

        case 'bar':
          data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June'],
            datasets: [
              {
                label: 'Dataset 1',
                data: Array.from({ length: 6 }, () => Math.floor(Math.random() * 80) + 20),
                backgroundColor: 'rgba(30, 136, 229, 0.6)',
              },
            ],
          };
          options = {
            responsive: true,
            title: {
              display: true,
              text: title,
            },
            legend: {
              position: 'top',
            },
          };
          break;

        case 'pie':
        case 'doughnut':
          const labels = ['Category A', 'Category B', 'Category C', 'Category D'];
          data = {
            labels,
            datasets: [
              {
                data: Array.from({ length: 4 }, () => Math.floor(Math.random() * 100) + 20),
                backgroundColor: [
                  'rgba(13, 71, 161, 0.6)',
                  'rgba(25, 118, 210, 0.6)',
                  'rgba(66, 165, 245, 0.6)',
                  'rgba(144, 202, 249, 0.6)',
                ],
                borderColor: [
                  'rgba(13, 71, 161, 1)',
                  'rgba(25, 118, 210, 1)',
                  'rgba(66, 165, 245, 1)',
                  'rgba(144, 202, 249, 1)',
                ],
                borderWidth: 1,
              },
            ],
          };
          options = {
            responsive: true,
            title: {
              display: true,
              text: title,
            },
            legend: {
              position: 'top',
            },
          };
          break;
      }

      // Crear el objeto de gráfico generado
      const generatedChart: ChartData = {
        id: uniqueId,
        type: chartType,
        title,
        description: `AI generated chart based on prompt: "${prompt}"`,
        data,
        options,
        width: 4,
      };

      setGeneratedAiChart(generatedChart);
      setIsGeneratingAiChart(false);
    }, 2000);
  };

  // Función para añadir el gráfico generado por IA
  const handleAddAiChart = () => {
    if (generatedAiChart) {
      // Generar un ID único para asegurarnos de que sea completamente independiente
      const uniqueId = `ai-chart-${Date.now()}`;

      // Crear una copia segura sin usar JSON.stringify para evitar errores con estructuras circulares
      const safeCopy = {
        ...generatedAiChart,
        id: uniqueId,
        data: {
          ...generatedAiChart.data,
          datasets: generatedAiChart.data.datasets.map(dataset => ({
            ...dataset,
            // Copiar solo las propiedades que necesitamos
            label: dataset.label,
            data: [...dataset.data],
            backgroundColor: dataset.backgroundColor,
            borderColor: dataset.borderColor,
            fill: dataset.fill
          }))
        },
        options: {
          ...generatedAiChart.options,
          title: generatedAiChart.options.title ? {
            ...generatedAiChart.options.title
          } : undefined,
          legend: generatedAiChart.options.legend ? {
            ...generatedAiChart.options.legend
          } : undefined
        }
      };

      setCharts([...charts, safeCopy]);
      setDrawerOpen(false);
      setGeneratedAiChart(null);
      setAiPrompt('');
      setChartCreationMode('prefab');
    }
  };

  return (
    <DashLayout sider={DashRouters} active="ai_charts" sublinks={[]}>
      <Row id='topHeader'>
        <Col md="8">
          <div className="cld_wrapperTitle">
            <img src={ProxIcon} alt="icon dashboard" />
            <span className="cld_title w-100 margin-y-24px">
              {/*<span style={{ color: '#949498', fontSize: '14px' }}>AI Charts  </span>/ */}AI Analytics
            </span>
          </div>
        </Col>
        <Col md="4" className="align-content-center">
          <Button
            style={{ width: '225px' }}
            className="customButton-black btn px-4 resBtn float-right"
            onClick={() => handleOpenDrawer()}>
            Add Chart
          </Button>
        </Col>
        <Col> <div className="customBorder"></div></Col>
      </Row>

      {/* Botón Edit Mode movido debajo del header */}
      {charts.length >= 2 && (
        <Row className="my-3">
          <Col className="d-flex justify-content-end gap-2">
            <Button
              className="mx-2"
              variant={editMode ? "success" : "outline-secondary"}
              onClick={toggleEditMode}
            >
              <FontAwesomeIcon icon={faCog} className="mr-2" />
              {editMode ? "Edit Mode Active" : "Edit Dashboard"}
            </Button>
            {editMode && (
              <Button
                variant="primary"
                onClick={() => {
                  // Depurar layouts
                  debugLayouts();

                  // Obtener el breakpoint actual
                  const currentBreakpoint = Object.keys(BREAKPOINTS).find(
                    bp => window.innerWidth >= BREAKPOINTS[bp as keyof typeof BREAKPOINTS]
                  ) || 'lg';

                  // Obtener el layout actual
                  const currentLayout = layouts[currentBreakpoint];
                  if (currentLayout) {
                    // Actualizar los charts con los nuevos tamaños
                    const updatedCharts = charts.map(chart => {
                      const layoutItem = currentLayout.find(item => item.i === chart.id);
                      if (layoutItem) {
                        return {
                          ...chart,
                          width: layoutItem.w
                        };
                      }
                      return chart;
                    });

                    // Crear una copia de los layouts para guardar
                    const layoutsToSave = JSON.parse(JSON.stringify(layouts));

                    // Asegurarse de que todos los layouts tengan static: true para cuando se carguen
                    Object.keys(layoutsToSave).forEach(bp => {
                      if (layoutsToSave[bp]) {
                        layoutsToSave[bp] = layoutsToSave[bp].map(item => ({
                          ...item,
                          static: true
                        }));
                      }
                    });

                    // Guardar en localStorage
                    try {
                      // Guardar los datasets completos para mantener los colores
                      const chartsToSave = updatedCharts.map(chart => ({
                        id: chart.id,
                        type: chart.type,
                        title: chart.title,
                        description: chart.description,
                        width: chart.width,
                        // Guardamos solo la información de los datasets que necesitamos (colores)
                        datasets: chart.data.datasets.map((dataset: any) => ({
                          label: dataset.label,
                          backgroundColor: dataset.backgroundColor,
                          borderColor: dataset.borderColor
                        }))
                      }));

                      console.log('Guardando layouts:', layoutsToSave);
                      console.log('Guardando charts con colores:', chartsToSave);

                      localStorage.setItem('dashboard_charts', JSON.stringify(chartsToSave));
                      localStorage.setItem('dashboard_layouts', JSON.stringify(layoutsToSave));

                      // Actualizar el estado con los layouts guardados
                      setLayouts(layoutsToSave);
                      setCharts(updatedCharts);

                      // Desactivar el modo edición después de guardar
                      setEditMode(false);

                      // Verificar que se guardó correctamente
                      setTimeout(debugLayouts, 500);
                    } catch (error) {
                      console.error('Error saving to localStorage:', error);
                    }
                  }
                }}
              >
                <FontAwesomeIcon icon={faSave} className="mr-2" />
                Save Layout
              </Button>
            )}
          </Col>
        </Row>
      )}

      {/* Grid de gráficos */}
      <div className="charts-grid-container" ref={gridRef}>
        <ResponsiveGridLayoutWithWidth
          className="layout"
          layouts={layouts}
          breakpoints={BREAKPOINTS}
          cols={COLS}
          rowHeight={30}
          isDraggable={editMode}
          isResizable={false}
          onLayoutChange={handleLayoutChange}
          draggableHandle=".chart-drag-handle"
          margin={[15, 15]}
          useCSSTransforms={true}
          allowOverlap={false}
          preventCollision={false}
          compactType="vertical"
          onDragStart={handleDragStart}
          onDragStop={handleDragStop}
          containerPadding={[10, 10]}
          autoSize={true}
          measureBeforeMount={false}
          isBounded={true}
          onBreakpointChange={(newBreakpoint) => {
            console.log('Breakpoint changed to:', newBreakpoint);
            // No necesitamos generar un nuevo layout aquí ya que react-grid-layout maneja esto automáticamente
          }}
          onWidthChange={(width, margin, cols) => {
            console.log('Width changed:', width, 'cols:', cols);
          }}
        >
          {charts.map((chart) => (
            <div key={chart.id} className="chart-grid-item">
              <Card className={`chart-card h-100 ${editMode ? 'edit-mode' : ''}`}>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  {editMode && (
                    <div className="chart-drag-handle">
                      <FontAwesomeIcon icon={faGripVertical} />
                    </div>
                  )}
                  <div className={`flex-grow-1 ${editMode ? 'ml-2' : ''}`}>
                    {chart.title}
                  </div>
                  {!editMode && (
                    <div className="chart-date-controls d-flex align-items-center mr-2">
                      <Radio.Group
                        size="small"
                        value={selectedTimeRanges[chart.id] || '1m'}
                        onChange={(e) => handleTimeRangeChange(chart.id, e.target.value)}
                        className="mr-2"
                      >
                        <Radio.Button value="1d">1D</Radio.Button>
                        <Radio.Button value="1w">1S</Radio.Button>
                        <Radio.Button value="1m">1M</Radio.Button>
                        <Radio.Button value="1y">1A</Radio.Button>
                        <Radio.Button value="custom">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </Radio.Button>
                      </Radio.Group>

                      {selectedTimeRanges[chart.id] === 'custom' && (
                        <DatePicker.RangePicker
                          size="small"
                          value={customDateRanges[chart.id] || null}
                          onChange={(dates) => handleCustomDateChange(chart.id, dates)}
                          className="ml-2"
                          format="DD/MM/YYYY"
                        />
                      )}

                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="ai-interpret-btn ml-2"
                        onClick={() => handleOpenAiInterpretation(chart)}
                        title="Interpretación de IA"
                      >
                        {/*  <FontAwesomeIcon icon={faBrain} /> */}
                        <img src={icon_ai} alt="AI" className="icon-ai" />
                      </Button>
                    </div>
                  )}
                  {editMode && (
                    <div className="chart-controls">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-edit-${chart.id}`}>Edit</Tooltip>}
                      >
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => handleEditChart(chart)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-size-${chart.id}`}>
                          {chart.width === 4 ? 'Pequeño (1/3)' :
                            chart.width === 6 ? 'Mediano (1/2)' :
                              'Grande (Completo)'}
                        </Tooltip>}
                      >
                        <Dropdown className="d-inline-block">
                          <Dropdown.Toggle variant="link" size="sm">
                            {chart.width === 12 ? (
                              <FontAwesomeIcon icon={faCompress} />
                            ) : (
                              <FontAwesomeIcon icon={faExpand} />
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleResizeChart(chart.id, 4)}
                              active={chart.width === 4}
                            >
                              Small (1/3)
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleResizeChart(chart.id, 8)}
                              active={chart.width === 8}
                            >
                              Medium (2/3)
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleResizeChart(chart.id, 12)}
                              active={chart.width === 12}
                            >
                              Large (Full)
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-delete-${chart.id}`}>Delete</Tooltip>}
                      >
                        <Button
                          variant="link"
                          size="sm"
                          className="text-danger"
                          onClick={() => handleDeleteChart(chart.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  )}
                </Card.Header>
                <Card.Body>
                  {renderChart(chart)}
                </Card.Body>
              </Card>
            </div>
          ))}
        </ResponsiveGridLayoutWithWidth>
      </div>

      {/* Drawer para añadir gráfico */}
      <Drawer
        isOpen={drawerOpen}
        index="charts"
        toggleSidebar={() => {
          setDrawerOpen(false);
          setChartCreationMode('prefab');
          setSelectedCategory('');
          setAiPrompt('');
          setGeneratedAiChart(null);
        }}
        name="Add Chart"
      >
        <div className="p-3">
          <h2 className="mb-4">Select Chart Type</h2>

          {!selectedCategory && chartCreationMode === 'prefab' && !generatedAiChart ? (
            <Row className="chart-creation-options">
              <Col md={12} className="mb-4">
                <Card
                  className="chart-option-card"
                  onClick={() => setSelectedCategory('API Usage')}
                >
                  <Card.Body className=" p-4">
                    <div className="chart-option-icon mb-3">
                      <FontAwesomeIcon icon={faChartBar} size="3x" />
                    </div>
                    <h4>Prefab Charts</h4>
                    <p className="text-muted">Use pre-designed chart templates</p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12} className="mb-4">
                <Card
                  className="chart-option-card"
                  onClick={() => setChartCreationMode('ai')}
                >
                  <Card.Body className=" p-4">
                    <div className="chart-option-icon mb-3">
                      <img src={icon_ai} alt="AI" />
                    </div>
                    <h4>AI Charts</h4>
                    <p className="text-muted">Generate charts with Brunix AI</p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <>
              {chartCreationMode === 'prefab' && selectedCategory ? (
                <>
                  <div className="d-flex align-items-center mb-4">
                    <Button
                      variant="link"
                      className="p-0 mr-3"
                      onClick={() => setSelectedCategory('')}
                    >
                      <FontAwesomeIcon icon={faTimes} className="mr-2" />
                      Back
                    </Button>
                    <h4 className="mb-0">Prefab Charts</h4>
                  </div>

                  <Form.Group className="mb-4">
                    <Form.Label>Chart Category</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      {chartCategories.map((category) => (
                        <option key={category.name} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <div className="mt-4">
                    <p className="chart-section-title">Available Charts</p>
                    <Row>
                      {chartCategories
                        .find(cat => cat.name === selectedCategory)?.charts
                        .map(chart => (
                          <Col md="12" key={chart.id} className="mb-4">
                            <div className="chart-item">
                              <h4 className="chart-title">{chart.title}</h4>
                              <Card
                                className="chart-card-drawer"
                                onClick={() => handleAddChart(chart)}
                              >
                                <Card.Body className="p-0">
                                  <div className="chart-image-container">
                                    {chart.type === 'line' && (
                                      <img
                                        src={lineChart}
                                        alt="Line Chart"
                                        className="chart-placeholder-image"
                                      />
                                    )}
                                    {chart.type === 'bar' && (
                                      <img
                                        src={barChart}
                                        alt="Bar Chart"
                                        className="chart-placeholder-image"
                                      />
                                    )}
                                    {chart.type === 'pie' && (
                                      <img
                                        src={pieChart}
                                        alt="Pie Chart"
                                        className="chart-placeholder-image"
                                      />
                                    )}
                                    {chart.type === 'doughnut' && (
                                      <img
                                        src={doughnutChart}
                                        alt="Doughnut Chart"
                                        className="chart-placeholder-image"
                                      />
                                    )}
                                  </div>
                                  <div className="chart-info p-3">
                                    <div className="chart-type">{chart.type.charAt(0).toUpperCase() + chart.type.slice(1)} Chart</div>
                                    <p className="chart-description">{chart.description}</p>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                </>
              ) : chartCreationMode === 'ai' ? (
                <>
                  <div className="d-flex align-items-center mb-4">
                    <Button
                      variant="link"
                      className="p-0 mr-3"
                      onClick={() => {
                        setChartCreationMode('prefab');
                        setSelectedCategory('');
                        setAiPrompt('');
                        setGeneratedAiChart(null);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} className="mr-2" />
                      Back
                    </Button>
                    <h4 className="mb-0">AI Chart Generator</h4>
                  </div>

                  <div className="ai-chart-generator">
                    <Form.Group className="mb-4">
                      <Form.Label>Describe the chart you want to create</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Example: Create a line chart showing monthly sales data for the last 6 months"
                        value={aiPrompt}
                        onChange={(e) => setAiPrompt(e.target.value)}
                      />
                      <Form.Text className="text-muted">
                        Be specific about the type of chart (line, bar, pie, doughnut) and the data you want to visualize.
                      </Form.Text>
                    </Form.Group>

                    <div className="d-flex justify-content-end mb-4">
                      <Button
                        variant="primary"
                        onClick={() => generateAiChart(aiPrompt)}
                        disabled={!aiPrompt.trim() || isGeneratingAiChart}
                      >
                        {isGeneratingAiChart ? (
                          <>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Generating...
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faMagic} className="mr-2" />
                            Generate Chart
                          </>
                        )}
                      </Button>
                    </div>

                    {generatedAiChart && (
                      <div className="mt-4">
                        <h4>Generated Chart Preview</h4>
                        <Card className="mb-4">
                          <Card.Header>
                            <h5 className="mb-0">{generatedAiChart.title}</h5>
                          </Card.Header>
                          <Card.Body style={{ height: '300px' }}>
                            {generatedAiChart.type === 'line' && <Line data={generatedAiChart.data} options={generatedAiChart.options} />}
                            {generatedAiChart.type === 'bar' && <Bar data={generatedAiChart.data} options={generatedAiChart.options} />}
                            {generatedAiChart.type === 'pie' && <Pie data={generatedAiChart.data} options={generatedAiChart.options} />}
                            {generatedAiChart.type === 'doughnut' && <Doughnut data={generatedAiChart.data} options={generatedAiChart.options} />}
                          </Card.Body>
                        </Card>

                        <div className="d-flex justify-content-end">
                          <Button variant="outline-secondary" className="mr-2" onClick={() => setGeneratedAiChart(null)}>
                            Regenerate
                          </Button>
                          <Button variant="primary" onClick={handleAddAiChart}>
                            Add to Dashboard
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </Drawer>

      {/* Drawer para editar gráfico */}
      <Drawer
        isOpen={editDrawerOpen}
        index="edit-chart"
        toggleSidebar={() => {
          setEditDrawerOpen(false);
          setEditingChart(null);
        }}
        name="Edit Chart"
      >
        {editingChart && (
          <div className="p-3">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Chart Title</Form.Label>
                <Form.Control
                  type="text"
                  value={editingChart.title}
                  onChange={(e) => setEditingChart({
                    ...editingChart,
                    title: e.target.value,
                    options: {
                      ...editingChart.options,
                      title: {
                        ...editingChart.options.title,
                        text: e.target.value
                      }
                    }
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Dataset Colors</Form.Label>
                {editingChart.data.datasets.map((dataset: any, datasetIndex: number) => (
                  <div key={datasetIndex} className="mb-3">
                    <Form.Label className="small">{dataset.label || `Dataset ${datasetIndex + 1}`}</Form.Label>

                    {/* Para gráficos de tipo pie o doughnut, mostramos un selector de color para cada categoría */}
                    {(editingChart.type === 'pie' || editingChart.type === 'doughnut') && Array.isArray(dataset.backgroundColor) ? (
                      <div className="pie-colors-container">
                        {editingChart.data.labels.map((label: string, index: number) => (
                          <div key={index} className="pie-color-item mb-2">
                            <div className="d-flex gap-2 align-items-center">
                              <Form.Control
                                className="color-picker-chart"
                                type="color"
                                value={
                                  Array.isArray(dataset.backgroundColor) && index < dataset.backgroundColor.length
                                    ? dataset.backgroundColor[index]
                                    : '#1976d2'
                                }
                                onChange={(e) => {
                                  const newDatasets = [...editingChart.data.datasets];
                                  const newBackgroundColor = Array.isArray(dataset.backgroundColor)
                                    ? [...dataset.backgroundColor]
                                    : [];
                                  const newBorderColor = Array.isArray(dataset.borderColor)
                                    ? [...dataset.borderColor]
                                    : [];

                                  // Asegurarnos de que haya suficientes elementos en los arrays
                                  while (newBackgroundColor.length <= index) {
                                    newBackgroundColor.push('#1976d2');
                                  }
                                  while (newBorderColor.length <= index) {
                                    newBorderColor.push('#1976d2');
                                  }

                                  newBackgroundColor[index] = e.target.value;
                                  newBorderColor[index] = e.target.value;

                                  newDatasets[datasetIndex] = {
                                    ...dataset,
                                    backgroundColor: newBackgroundColor,
                                    borderColor: newBorderColor
                                  };

                                  setEditingChart({
                                    ...editingChart,
                                    data: {
                                      ...editingChart.data,
                                      datasets: newDatasets
                                    }
                                  });
                                }}
                              />
                              <span className="small">{label}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      // Para otros tipos de gráficos, mostramos un solo selector de color
                      <div className="d-flex gap-2 align-items-center">
                        <Form.Control
                          className="color-picker-chart"
                          type="color"
                          value={(() => {
                            // Para gráficos de línea, usamos borderColor
                            if (editingChart.type === 'line') {
                              if (typeof dataset.borderColor === 'string') {
                                return dataset.borderColor;
                              } else if (Array.isArray(dataset.borderColor) && dataset.borderColor.length > 0) {
                                return dataset.borderColor[0];
                              }
                            }
                            // Para otros tipos de gráficos, usamos backgroundColor
                            else {
                              if (typeof dataset.backgroundColor === 'string') {
                                return dataset.backgroundColor;
                              } else if (Array.isArray(dataset.backgroundColor) && dataset.backgroundColor.length > 0) {
                                return dataset.backgroundColor[0];
                              }
                            }
                            // Color por defecto si no se puede determinar
                            return '#1976d2'; // Azul por defecto
                          })()}
                          onChange={(e) => {
                            const newDatasets = [...editingChart.data.datasets];
                            if (editingChart.type === 'line') {
                              newDatasets[datasetIndex] = {
                                ...dataset,
                                borderColor: e.target.value,
                                backgroundColor: `${e.target.value}20`
                              };
                            } else {
                              newDatasets[datasetIndex] = {
                                ...dataset,
                                backgroundColor: e.target.value,
                                borderColor: e.target.value
                              };
                            }
                            setEditingChart({
                              ...editingChart,
                              data: {
                                ...editingChart.data,
                                datasets: newDatasets
                              }
                            });
                          }}
                        />
                        <span className="small">{dataset.label || `Dataset ${datasetIndex + 1}`}</span>
                      </div>
                    )}
                  </div>
                ))}
              </Form.Group>

              <div className="action-buttons">
                <Button
                  variant="secondary"
                  className="mr-2"
                  onClick={() => {
                    setEditDrawerOpen(false);
                    setEditingChart(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSaveChart}
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Drawer>

      {/* Usar el nuevo componente para la interpretación de IA */}
      <AiInterpretationDrawer
        isOpen={aiInterpretationDrawerOpen}
        onClose={handleCloseAiInterpretation}
        currentChart={currentChartForAi}
        aiInterpretation={aiInterpretation}
        isLoading={aiLoading}
      />
    </DashLayout>
  );
};

export default AiCharts;
