import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Spinner, Dropdown, Form } from 'react-bootstrap';
import { faCheck, faEllipsisV, faExternalLinkSquareAlt, faLink, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import Drawer from './Drawer';
import DevPortalForm from './DeveloperPortalForm';
import devIcon from '../../assets/img/ui_icons/devportal.png'
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Organizations from '../../newViews/avapCloud/organizations';
import SessionService from '../../services/SessionsService';
import config from '../../configs/appConfig.json';
import { useHistory } from 'react-router-dom';
import ConfirmDeletetModal from '../Modals/ConfirmDeleteModal';
import Nodata from './components/Nodata';
import UpgradePlanModal from '../Modals/UpgradePlanModal';


// Tipo para un Proxy
type DevPortal = {
  id?: string;
  basePath: string;
  name: string;
  description: string;
  products: any[];
  projects: any[];
  normal_access: boolean;
  config: any;
  environment: any;
  has_sandbox: any;
};

const DevPortalManager = () => {
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editingProxy, setEditingProxy] = useState<DevPortal | null>(null);
  const [filteredProxies, setFilteredProxies] = useState<DevPortal[]>([]);
  const [filtro, setFiltro] = useState('');
  const [certsT, setCertsT] = useState([]);
  const [limitDevPortals, setLimitDevPortal] = useState(0)
  const [initialized, setInitialized] = useState(false);
  const [currentSuscription, SetCurrentsuscripcion] = useState(0);

    const [showUpgrade, setshowUpgrade] = useState(false)

  const [selBackgroudColor, setSelBackgroudColor] = useState('#f8f9fa')
  const [selprimaryColor, setSelPrimaryColor] = useState('#007bff')
  const [selsecondaryColor, setSelSecondaryColor] = useState('#6c757d')
  const [selTextColor, setSelTextColor] = useState('#212529')
  const [selLogo, setSelLogo] = useState('https://ui-avatars.com/api/?name=Avanquia&background=007bff&color=fff&size=128')

  const [proxies, setProxies] = useState<DevPortal[]>([]);
  const [allproxies, setAllProxies] = useState<DevPortal[]>([]);

  const whenClosing = ()=>{
    setshowUpgrade(false);
    
    }

  const [portalConfig, setPortalConfig] = useState({});
  const history = useHistory();
  const handleSaveProxy = async (newProxy: DevPortal) => {
    try {
      // Asegurarse de que normal_access se envíe correctamente
      if (newProxy.config) {
        newProxy.config.normal_access = Boolean(newProxy.config.normal_access);
      }
      
      console.log('Saving proxy with config:', newProxy);
      setEditingProxy(null);
      setDrawerOpen(false);
      
      await Promise.all([
        getProxies(),
        getCerts()
      ]);
      
    } catch (error) {
      console.error('Error al guardar el proxy:', error);
    } finally {
      setDrawerOpen(false);
      setEditingProxy(null);
    }
  };

  const getConsumption = async (disableCache = false) => {
    const result = await ObexRequestHandler.get('/consumption', {}, !disableCache);
    const { success, data } = result;
    if (success) {
 
      SetCurrentsuscripcion(data.subscriptionInfo.subscription_id);

    }
  }

  const handleOpenDrawer = async (proxy?: DevPortal) => {
    if (proxy) { 
      const logo = await ObexRequestHandler.get(`/publishing/get_dev_portal_logo/${proxy.basePath.replace('/_', '')}`, {}, true)
      const config_h = await ObexRequestHandler.get(`/publishing/get_dev_portal_config/${proxy.basePath.replace('/_', '')}`, {}, true)
      
      const { data } = config_h;

      let config_theme = {
        backgroundColor: '#f8f9fa',
        primaryColor: '#007bff',
        secondaryColor: '#6c757d',
        textColor: '#212529',
        logo: '',
        layout: 1,
        visibility: false,
        normal_access: false,
        environment_id: 0
      }

      if (data && data.theme) {
        // Asegurarse de que normal_access se lea correctamente del servidor
        const normalAccess = data.theme.normal_access === true;
        
        config_theme = {
          ...config_theme,
          backgroundColor: data.theme.backgroundColor || config_theme.backgroundColor,
          primaryColor: data.theme.primaryColor || config_theme.primaryColor,
          secondaryColor: data.theme.secondaryColor || config_theme.secondaryColor,
          textColor: data.theme.textColor || config_theme.textColor,
          logo: logo.data.logo || config_theme.logo,
          visibility: typeof data.theme.visibility === 'boolean' ? data.theme.visibility : false,
          layout: data.theme.layout || config_theme.layout,
          normal_access: normalAccess, // Usar el valor explícitamente convertido
          environment_id: data.environment
        }
        
        proxy.config = {...config_theme};
        setPortalConfig({...config_theme});
      }
    }

    setEditingProxy(
      proxy || {
        id: undefined,
        basePath: '',
        name: '',
        description: '',
        products: [],
        projects: [],
        normal_access: false,
        environment: { name:'', id:0 },
        has_sandbox: false,
        config: {
          backgroundColor: '#f8f9fa',
          primaryColor: '#007bff',
          secondaryColor: '#6c757d',
          textColor: '#212529',
          logo: '',
          visibility: false,
          layout: 1,
          normal_access: false,

        }
      }
    );
    
    setDrawerOpen(true);
  };





  
  const [showDelete, setShowDelete] = useState(false);
  const [selDeveloper, setSelDeveloper] = useState({id:'' })
  const [selTeam, setselTeam] = useState({id:0, type:'', name:''});

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  }

  const handleDelete = async (developer) => {
    setShowDelete(true);
    setSelDeveloper(developer);
    setselTeam(developer);

  }




  const handleDeleteProxy = async (proxy) => {
   
      handleShowDelete();
      //console.log(proxy);
      //setProxies(proxies.filter((proxy) => proxy.id !== id));
      await ObexRequestHandler.post(`/publishing/delete_dev_portal?id=${proxy.id}`)
    
    getProxies();
    //const history = useHistory();
    history.push('/dev_portals')
    
  };


  const getCerts = async () => {
    const certs = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`)

    setCertsT(certs.data);
    history.push('/dev_portals')
  };


  const getProxies = async () => {
    setLoading(true);
    try {
      const proxies_configs = await ObexRequestHandler.get(
        `/gateway/get_dev_portal_by_org?org_id=${SessionService.ORGANIZATION}`, 
        {}, 
        true
      );

      console.log('Datos crudos del API:', proxies_configs.data);

      const proxies = proxies_configs.data.map((prox) => {
        console.log('Configuración individual del portal:', prox);
        return {
          basePath: prox.route,
          id: prox.id,
          name: prox.name,
          description: prox.description,
          products: prox.associated_products,
          projects: prox.associated_projects,
          normal_access: prox.normal_access,
          environment: {id:prox.environment_id, name: prox.alias},
          config: prox.config || {} // Verificar si config existe
        };
      });

      console.log('Proxies procesados:', proxies);

      const filtered = proxies.filter((proxy) =>
        proxy.basePath.toLowerCase().includes(filtro.toLowerCase())
      );
      
      setAllProxies(proxies);
      setProxies(proxies);
      setFilteredProxies(filtered);
    } catch (error) {
      console.error('Error al cargar los portales:', error);
    } finally {
      setLoading(false);
    }
  };



  const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFiltro(newValue);
    filterProjects(newValue);
  };

  const filterProjects = (searchTerm: string) => {
    if (!allproxies) return; // Protección contra undefined

    const filter_projects = allproxies.filter(element =>
      element.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setProxies(searchTerm === '' ? allproxies : filter_projects);
    setFilteredProxies(searchTerm === '' ? allproxies : filter_projects);
  };



  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/dev_portals'){
      history.push('/dev_portals')
      SessionService.updating = SessionService.updating -3;
      getProxies(),
      getCerts()
    window.scrollTo(0, 0);
    history.push('/dev_portals')
    }
  
  }, false);

  useEffect(() => {
    
try{
    SessionService.loginData.limits.forEach(ll =>{
      if (ll.org_id==SessionService.ORGANIZATION){
        setLimitDevPortal(ll.subscription_type.dev_portals || 1)
      }
    })

  } catch {}
    const loadInitialData = async () => {
      setLoading(true);
      try {
        // Inicializar el filtro
        setFiltro('');
        
        // Cargar los datos
        await Promise.all([
          getProxies(),
          getCerts()
        ]);
        setInitialized(true);
      } catch (error) {
        console.error('Error loading initial data:', error);
        setInitialized(true);
      } finally {
        setLoading(false);
        setInitialized(true);
      }
      setInitialized(true);
    };

    loadInitialData();

    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    getConsumption();

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
    
  }, []); // Solo se ejecuta al montar el componente

  return (
    <DashLayout sider={DashRouters} active="proxies" sublinks={[]}>
      <Row id="topHeader">
        <Col md="10">
          <div className="cld_wrapperTitle">
            <img src={devIcon} alt="icon dashboard" />
            <span className="cld_title w-100 margin-y-24px">
            <span style={{ color: '#949498', fontSize: '14px' }}>Dev Portal </span>/ Developer Portals
            </span>
          </div>
        </Col>
        <Col md="2" className="align-content-center">
          <Button
          //setshowUpgrade
            //disabled={proxies.length >= limitDevPortals}
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right  resBtn"
            onClick={() => {
              if (proxies.length >= limitDevPortals) {setshowUpgrade(true)}
                else handleOpenDrawer()
            }}>
            {proxies.length >= limitDevPortals ? 'Upgrade Plan' : 'Add Developer Portal'}
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <Row id='table' className='mt-5'>
        <Col>
          {loading ? (
            <Row style={{ textAlign: 'center' }}>
              <Col>
                <Spinner animation="border" style={{ marginLeft: '8px' }} />
              </Col>
            </Row>
          ) : (
            <div className='cld_borderWrapper'>
              <span className='cld_sectionTitle'>Developer Portals</span>

              <div className="searchContainer">
                <Form.Control 
                  id='buscador' 
                  type="text" 
                  value={filtro || ''} // Asegurarse de que nunca sea undefined
                  placeholder="Search by Developer portal name"
                  onChange={changeFilter} 
                />
                <Button
                  className="customButton-transparent px-4"
                  onClick={() => getProxies()}>
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </Button>
              </div>

              {((filteredProxies.length === 0 || proxies.length === 0) && initialized) ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                  <Nodata />
                </div>
              ) : (
              <Table className="cld_tableWrapper">
                <tbody>
                  <tr className='tableRow'>
                    <th style={{ width: '20%' }} className='textBold'>Base Path</th>
                    <th style={{ width: '30%' }} className='textBold'>Name</th>
                    <th style={{ width: '15%' }} className='textBold'>Description</th>
                    <th style={{ width: '15%' }} className='textBold'>Environment</th>
                    <th style={{ width: '15%' }} className='textBold'>Has Sandbox</th>
                    <th style={{ width: '20px', textAlign: 'end' }} className='textBold'>Actions</th>
                  </tr>


                  {(filteredProxies.length > 0 ? filteredProxies : proxies).map((proxy) => (
                    <tr className="tableRow" key={proxy.id}>
                      <td className='td_centered'>
                        <div style={{display:'flex'}}>
                        <div>{proxy.basePath}</div>
                        <div style={{width:'100%', position:'absolute', marginLeft:'75%'}}>
                        <a 
                              href={`${config.url === 'https://avs-primary-pre.101obex.mooo.com' ? 'https://staging.avapid.com' : (config.url === 'http://localhost'? 'http://localhost:8080':'https://avapid.com')}/devportal/${proxy.basePath.replace('/_', '')}`}
                              target="_blank" 
                              rel="noopener noreferrer" 
                              title={`${config.url === 'https://avs-primary-pre.101obex.mooo.com' ? 'https://staging.avapid.com' : (config.url === 'http://localhost'? 'http://localhost:8080':'https://avapid.com')}/devportal/${proxy.basePath.replace('/_', '')}`}
                              style={{ textDecoration: 'none', color: 'inherit' }} // Opcional: evita estilos de enlace
                          >
                          <FontAwesomeIcon icon={faExternalLinkSquareAlt}/>
                          </a>
                        </div>
                        </div>
                        </td>
                      <td className='td_centered'>{proxy.name}</td>
                      <td className='td_centered'>{proxy.description}</td>
                      {/*<td>{proxy.selectedGA.join(', ')}</td>*/}
                      {/*<td>
                        {proxy.certs && proxy.certs.length > 0
                          ? proxy.certs
                          : 'No certificates'}
                      </td>
                      <td>{proxy.balance ? 'Enabled' : 'Disabled'}</td>
                      <td>{proxy.algorithm || 'N/A'}</td>*/}
                      
                      {/*<td className='td_centered'>{proxy.products.map(pro => (pro.name == null ? '' :pro.name +' ')) } </td>
                      <td className='td_centered'>{proxy.projects.map(pro => (pro.project_description == null ? '': pro.project_description+' '))} </td>*/}
                      <td className='td_centered'>{proxy.environment.name } </td>
                      <td className='td_centered'>{proxy.has_sandbox} </td>
                      <td className="td_centered text-right">
                        <div className="actionsWrapper">
                          <Dropdown className="my-auto" style={{ width:'100%', marginRight: '0px' }}>
                            <Dropdown.Toggle className="">
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item key={1} onClick={() => handleOpenDrawer(proxy)}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item key={2} onClick={() => handleDelete(proxy)}>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
{/*}
                  {proxies.length < 3 &&
                    <div style={{ height: '70px' }}></div>
                  }

*/}

                </tbody>
              </Table>
              )}
            </div>
          )}
        </Col>
      </Row>
      <Drawer
      index="developer_portal"
        isOpen={drawerOpen}
        toggleSidebar={() => {
          setDrawerOpen(false);
          setEditingProxy(null);
        }}
        name={!(editingProxy?.basePath == '') ? 'Edit Developer Portal' : 'Add Developer Portal'}
      >
        <></>

        <DevPortalForm
          opened={drawerOpen}
          isEditing={!(editingProxy?.basePath == '')}
          id={editingProxy?.id}
          basePath={editingProxy?.basePath || ''}
          name={editingProxy?.name || ''}
          description={editingProxy?.description || ''}
          products={editingProxy?.products || []}
          projects={editingProxy?.projects || []}
          environment={editingProxy?.environment || { name:'', id:0 }}
          has_sandbox={editingProxy?.has_sandbox || false}
          normal_access={editingProxy?.normal_access || false}
          config={portalConfig || {}}
          onSubmit={handleSaveProxy}
        />
      </Drawer>

      <ConfirmDeletetModal 
          handleSubmit={()=>handleDeleteProxy(selDeveloper)} 
          showModal={showDelete}
          onCloseModal={handleShowDelete}
          loading={false}
          error={''}
          success={''}
          name_to_check={'DELETE'}
          title_to_show={`Confirm delete Dev Portal ${selTeam.name}`}
        />

        <UpgradePlanModal 
          showModal={ showUpgrade } 
          text={'In order to create a developer portal you need to upgrade your plan. \n You have consumed the maximun slots available.'} 
          onCloseModal={whenClosing} 
          onCancelModal={whenClosing} 
          currentSuscription={currentSuscription}
          onSuccess={whenClosing}
          />


    </DashLayout>
  );
};

export default DevPortalManager;
