import React from "react";
import { Image, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { DynamicButton } from "./DynamicButton";
import Cookies from 'universal-cookie';
import { getContrastColor } from './DynamicButton';

interface PortalNavbarProps {
  portal: {
    id?: string | number;
    portal_id?: string | number;
    name?: string;
    theme: {
      backgroundColor: string;
      textColor: string;
      primaryColor: string;
    };
  };
  Logo: string;
  userName: string;
  isDarkMode: boolean;
  User?: {
    type: string;
    user_id: number;
  };
  portalIdentifier: string;
  setDrawerOpen: (isOpen: boolean) => void;
  toggleDarkMode: () => void;
}

const PortalNavbar: React.FC<PortalNavbarProps> = ({
  portal,
  Logo,
  userName,
  isDarkMode,
  User,
  portalIdentifier,
  setDrawerOpen,
  toggleDarkMode
}) => {
  const cookies = new Cookies();
  const history = useHistory();
  
  const portalId = String(portal.id || portal.portal_id);
  
  // Leer las cookies
  const portalData = cookies.get('portal');
  const userData = cookies.get('user');
  
  // Mostrar en consola
  console.log('Cookie portal:', portalData);
  console.log('Cookie user:', userData);
  
  // Si necesitas acceder a propiedades específicas:
  if (portalData) {
    console.log('Nombre del portal:', portalData.name);
    console.log('ID del portal:', portalData.portal_id);
  }
  
  if (userData) {
    console.log('Usuario:', userData.username);
    console.log('Tipo de usuario:', userData.type);
  }

  const portalName = portal?.name || 'Portal';
  const backgroundColor = portal?.theme?.primaryColor || '#000000';
  const avatarUrl = portal?.name 
    ? `https://ui-avatars.com/api/?name=${encodeURIComponent(portalName)}&background=${backgroundColor.replace('#', '')}&color=fff&size=128`
    : Logo;

  return (
    <header
      className="d-flex justify-content-between align-items-center p-3"
      style={{ backgroundColor: isDarkMode ? '#333' : portal.theme.backgroundColor }}
    >
      <div className="d-flex align-items-center">
        <a onClick={() => history.push(`/devportal/${portalIdentifier}/portal`)}>
          <Image 
            src={avatarUrl}
            alt="Logo" 
            style={{ height: "50px", marginRight: "10px" }} 
          />
        </a>
        <h4 className="m-0" style={{ color: isDarkMode ? '#fff' : portal.theme.textColor }}>
          {portalName}
        </h4>
      </div>

      <div className="d-flex align-items-center">
        {User.type === 'app_builder' && (
          <>
            <a
              onClick={() => history.push(`/devportal/${portalIdentifier}/portal`)}
              className="mx-3"
              style={{ 
                color: portal.theme.primaryColor, 
                fontSize: "16px",
                textDecoration: window.location.pathname.endsWith('/portal') ? 'underline' : 'none',
                fontWeight: window.location.pathname.endsWith('/portal') ? 'bold' : 'normal'
              }}
            >
              API Products
            </a>
            <a
              onClick={() => history.push(`/devportal/${portalIdentifier}/catalog`)}
              className="mx-3"
              style={{ 
                color: portal.theme.primaryColor, 
                fontSize: "16px",
                textDecoration: window.location.pathname.endsWith('/catalog') ? 'underline' : 'none',
                fontWeight: window.location.pathname.endsWith('/catalog') ? 'bold' : 'normal'
              }}
            >
              My apps
            </a>
            <DynamicButton
              className="mr-3"
              text="Create App"
              theme={portal.theme}
              onClick={() => setDrawerOpen(true)}
            />
          </>
        )}

        <Dropdown>
          <style>
            {`
          
              .custom-dropdown-toggle {
                background-color: transparent !important;
                border: none !important;
                box-shadow: none !important;
              }
              .custom-dropdown-toggle:hover,
              .custom-dropdown-toggle:focus,
              .custom-dropdown-toggle:active,
              .show > .custom-dropdown-toggle {
                background-color: transparent !important;
                border: none !important;
                box-shadow: none !important;
              }
            `}
          </style>
          <Dropdown.Toggle 
            id="dropdown-basic" 
            className="custom-dropdown-toggle"
            style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '8px'
            }}
          >
            <div className="d-flex flex-column align-items-end me-2">
              <small style={{ color: isDarkMode ? '#ccc' : portal.theme.textColor, fontSize: '12px' }}>
                {User.type === 'app_builder' ? 'Apps Builder' : User.type}
              </small>
              <span style={{ color: isDarkMode ? '#fff' : portal.theme.textColor }}>
                {userName}
              </span>
            </div>
            <Image
              src={''}
              alt=""
              style={{ height: '40px', borderRadius: '50%' }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item 
              onClick={() => history.push(`/devportal/${portalIdentifier}/login`)}
              className="custom-dropdown-item"
              style={{
                '--primary-color': portal.theme.primaryColor,
                '--contrast-color': getContrastColor(portal.theme.primaryColor)
              } as React.CSSProperties}
            >
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
};

export default PortalNavbar;
