import React, { useEffect, useRef, useState } from "react";
import InputMask from 'react-input-mask';
import { Form, Button, Table, Dropdown, DropdownButton, Row, Col, Alert } from "react-bootstrap";
import GenericSelectableList from '../DashboardComponents/CustomSelectSearch'
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";
import { Result } from "antd";


type EnvironmentFormProps = {
  id?: string;
  ambient?: {id: number, name:string};
  port_id?: any;
  port?: string;
  visibility?: boolean;
  whiteListEnabled?: boolean;
  whiteList?: string[];
  apiProducts?: any[];
  opened: any
  products: any
  alias: any
  editing: any
  ambiente: any
  onClose: (env: {
    id?: string;
    ambient: {id: number, name:string};
    port: string;
    visibility: boolean;
    whiteListEnabled: boolean;
    whiteList: string[];
    apiProducts: any[];
  }) => void;
  ambiente_id: any
  onSubmit: (env: {
    id?: string;
    ambient: {id: number, name:string};
    port: string;
    visibility: boolean;
    whiteListEnabled: boolean;
    whiteList: string[];
    apiProducts: any[];
  }) => void;

};


type AmbientType = {
  id: number;
  name: string;
};

const AMBIENTS = [
  { id: 1, name: 'Live' },
  { id: 2, name: 'Live clone' },
 
];

const EnvironmentForm: React.FC<EnvironmentFormProps> = ({
  id,
  port_id = 0, 
  port = "",
  visibility = false,
  whiteListEnabled = false,
  whiteList = [],
  apiProducts = [],
  opened,
  alias,
  editing,
  products,
  ambiente,
  ambiente_id,
  onClose,
  onSubmit,
}) => {
  const [selectedAmbient, setSelectedAmbient] = useState<{id: number, name: string}>({ id: 1, name: 'Live' });
  const [selectedPort, setSelectedPort] = useState({ id:0, name: port.toString() });
  const [localVisibility, setLocalVisibility] = useState(visibility);
  const [localWhiteListEnabled, setLocalWhiteListEnabled] = useState(whiteListEnabled);
  const [localWhiteList, setLocalWhiteList] = useState<string[]>(whiteList);
  const [error, setErr] = useState('');

  const [amb, selecAmb] = useState({ id:0, name:'' })
  const [localAlias, setAlias] = useState('');
  const maskedInputRef = useRef(null);

  const [PRODUCTS, setPRODUCTs] = useState([]);


  const [listaRecuperados3, setlistaRecuperados3] = useState([])

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showDbMessage, setShowDbMessage] = useState(false);
  const [sameWhitelist, setSameWhitelist] = useState(true);
  const [sameProductList, setSameProducts] = useState(true);

  const getPRODUCTS = () =>{
    setPRODUCTs(apiProducts);
    console.log("lk")
    console.log(apiProducts)
  }

  const getProjects = async () => {
    try {
      const response = await ObexRequestHandler.get(`/projects?organization_id=${SessionService.ORGANIZATION}`);
      setProjects(response.data || []);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setProjects([]);
    }
  };

  const validateSubmitProducts = (array) => {
      
    // console.log('[AMBIENTS] Array validate-----', array, products)

    const array2Sorted = array.slice().sort();
    const result = products.length === array.length && products.slice().sort().every(function(value, index) {
      // console.log('[AMBIENTS] Array validate: ', value.id, array2Sorted[index].id)
      return value.id.toString() === array2Sorted[index].id.toString();
  }
    )
    // console.log('[AMBIENTS] Array validate result: ', result)

    setSameProducts(result);
  };



  const validateSubmitWhitelist = (array) => {
      
    const array2Sorted = array.slice().sort();
    const result = whiteList.length === array.length && whiteList.slice().sort().every(function(value, index) {
      // console.log('[AMBIENTS] Whitelist validate: ', value, array2Sorted[index])

      return value === array2Sorted[index];
  }    )
  // console.log('[AMBIENTS] Whitelist validate result: ', result)

    setSameWhitelist(result);
  };

  useEffect(()=>{
    setErr('');

    if (maskedInputRef.current) {
      try{
      const inputElement = maskedInputRef.current.inputElement; // Access the underlying input

      // Apply styles directly (less recommended, but works)
      // inputElement.style.backgroundColor = 'transparent';
      // inputElement.style.border = 'none';
      inputElement.style.backgroundColor = 'transparent';
      inputElement.style.border = 'none';
        // OR, better approach: add a class and style it in CSS
      inputElement.classList.add('no-background-border');
      } catch {
        console.log("ERROROROROR!!!")
      }
    }

    if (true) {
      if (opened==true && editing) {
        setAlias('');
        setLocalVisibility(visibility);
        setLocalWhiteListEnabled(whiteListEnabled);
        setLocalWhiteList(whiteList);
        setAlias(alias)
        setlistaRecuperados3(products)
        setSelectedPort({ id: port_id, name: port.toString() })
        
        // Corregir la inicialización del ambiente
        const ambientData = AMBIENTS.find(amb => amb.id === ambiente_id) || AMBIENTS[0];
        selecAmb(ambientData);
        setSelectedAmbient(ambientData);

      } else {
        setLocalVisibility(true);
        setLocalWhiteListEnabled(false);
        setLocalWhiteList([]);
        
        // Establecer Live como valor por defecto para nuevo ambiente
        const defaultAmbient = AMBIENTS[0]; // Live
        setSelectedAmbient(defaultAmbient);
        selecAmb(defaultAmbient);
        
        setAlias('')
        setlistaRecuperados3([])
        setSelectedPort({ id:0, name:'' });
      }
    }
    console.log('[AMBIENTS] ----------------------')

    console.log('[AMBIENTS] Disable ', !((!localAlias &&  localAlias !='' && localAlias != alias) || selectedPort.id != port_id || visibility != localVisibility ||
    ambiente_id != selectedAmbient.id|| localWhiteListEnabled != whiteListEnabled 
  || !sameProductList || !sameWhitelist
  ))
    console.log('[AMBIENTS] localAlias , alias  ',(localAlias &&  localAlias !='' && localAlias != alias) , !localAlias, localAlias !='',localAlias != alias,'-------------------')
    console.log('[AMBIENTS]  selectedPort.id , port_id ',   selectedPort.id != port_id )
    console.log('[AMBIENTS] listaRecuperados3 ',listaRecuperados3)
    console.log('[AMBIENTS]  visibility , localVisibility  ',  visibility != localVisibility )
    console.log('[AMBIENTS] ambiente_id , selectedAmbient.id ', ambiente_id != selectedAmbient.id)
    console.log('[AMBIENTS] localWhiteListEnabled ,whiteListEnabled  ',localWhiteListEnabled != whiteListEnabled )
    console.log('[AMBIENTS] localWhiteList.length', localWhiteList)
    console.log('[AMBIENTS] sameProductList ', !sameProductList)
    console.log('[AMBIENTS] sameWhitelist ',!sameWhitelist)
    console.log('[AMBIENTS] ----------------------')

  }, [opened])


  useEffect(() => {
    getProjects();
    getGatewayAPIProducts();
  }, []);

  useEffect(() => {
    setShowDbMessage(selectedAmbient.id > 1);
  }, [selectedAmbient]);

  const portOptions = [
    { id: '1', name: '44' },
    { id: '2', name: '80' },
    { id: '3', name: '81' },
    { id: '4', name: '82' },
    { id: '5', name: '83' },
    { id: '5', name: '84' },
    { id: '5', name: '85' },
  ];

  const handleAddToWhiteList = () => {
    setErr('');
    setLocalWhiteList([...localWhiteList, '']);
    validateSubmitWhitelist([...localWhiteList, ''])
  };

const handleUpdateWhiteListItem = (index: number, value: string) => {
  setErr('');
  const updatedWhiteList = [...localWhiteList];
  
  //  Validación con Regex (la misma que tenías, pero sin el || true)
  const regex = /^(\d{1,3}|\d{1,3}\.|\d{1,3}\.\d{1,3}|\d{1,3}\.\d{1,3}\.|\d{1,3}\.\d{1,3}\.\d{1,3}|\d{1,3}\.\d{1,3}\.\d{1,3}\.|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/;
  if (regex.test(value)) {
    updatedWhiteList[index] = value;
    setLocalWhiteList(updatedWhiteList);
  } else if (value === ""){ // Manejo de cadena vacía (opcional, pero útil)
    updatedWhiteList[index] = value;
    setLocalWhiteList(updatedWhiteList);
  }
  validateSubmitWhitelist(updatedWhiteList)

};

  const handleRemoveFromWhiteList = (index: number) => {
    setErr('');
    setLocalWhiteList(localWhiteList.filter((_, i) => i !== index));
    validateSubmitWhitelist(localWhiteList.filter((_, i) => i !== index))
  };



  const getGatewayAPIProducts = async () => {

    setErr('');
    const apiproduct_configs = await ObexRequestHandler.get(`/gateway/get_gateway_products?organization=${SessionService.ORGANIZATION}`)
    const productsResponse = await ObexRequestHandler.get(`/publishing/get_products_by_org_live?org_id=${SessionService.ORGANIZATION}`, {}, true);
    const installedProductResponse = await ObexRequestHandler.get(`/publishing/get_products_by_org_installed?org_id=${SessionService.ORGANIZATION}`, {}, true);
    
    
    const apiproduct = apiproduct_configs.data.map((prox) => ({ 
      id: prox.id, 
      name: prox.avap_api_product_name,
      type: prox.api_type_name
    }));

    const apiproduct2 = productsResponse.data.map((prox) => ({ 
      id: prox.id, 
      name: prox.name,
      type: 'NORMAL'
    }));

    const apiproduct3 = installedProductResponse.data.map((prox) => ({ 
      id: prox.id, 
      name: prox.name,
      type: 'NORMAL'
    }));

let jj = [...apiproduct, ...apiproduct2, ...apiproduct3];
    setPRODUCTs(jj);



  };

  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!localAlias || !selectedPort || listaRecuperados3.length==0 || 
        (localWhiteListEnabled && localWhiteList.length==0)) {
      setErr('Please fill all data')
      return;
    }

    try {
      const basePayload = {
        id: id,
        ambient_id: selectedAmbient.id,
        port: Number(selectedPort.name),
        visibility: localVisibility,
        white_list_enabled: localWhiteListEnabled,
        alias: localAlias,
        organization: SessionService.ORGANIZATION,
        whitelist: localWhiteList,
        api_products: listaRecuperados3.map(product => ({ id: product.id, type: product.type })),
        mode_id: selectedAmbient.id,
        clone: selectedAmbient.id > 1,
        project_id: selectedProject
      };

      // Debug del valor clone
      console.log('Debug clone value:', {
        selectedAmbientId: selectedAmbient.id,
        cloneValue: selectedAmbient.id > 1,
        entirePayload: basePayload
      });

      // Debug de la cantidad de requests
      console.log('Debug batch requests:', {
        totalProjects: projects.length,
        totalApiProducts: listaRecuperados3.length,
        projects: projects,
        apiProducts: listaRecuperados3
      });

      // Hacer un batch de solicitudes, una por cada proyecto
      const requests = projects.map((project, index) => {
        const projectPayload = {
          ...basePayload,
          project_id: project.id
        };
        
        console.log(`Preparing request ${index + 1}/${projects.length} for project:`, {
          projectId: project.id,
          projectName: project.name,
          payload: projectPayload
        });
        
        
      });

      let uu = await  ObexRequestHandler.post('/gateway/set_gateway_ambients', basePayload);

      console.log('Starting batch requests execution...');
     // const results = await Promise.all(requests);
     // console.log('Batch requests completed:', {
     //   totalRequestsCompleted: results.length,
     //   results: results
     // });

      onSubmit({
        id,
        ambient: { id:0, name:'' },
        port: '',
        visibility: false,
        whiteListEnabled: false,
        whiteList: [],
        apiProducts: []
      });

      if (!id) onClose({
        id,
        ambient: { id:0, name:'' },
        port: '',
        visibility: false,
        whiteListEnabled: false,
        whiteList: [],
        apiProducts: []
      });

    } catch (error) {
      console.error('Error submitting form:', error);
      setErr('Error saving environments');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>

        <Form.Group controlId="basePath" className="mb-3">
        <Form.Label>Alias</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter alias"
          value={localAlias}
          onChange={(e) => {
            setErr('')
            setAlias(e.target.value)

          }}
        />
      </Form.Group>


      <Form.Group controlId="ambient" className="mb-3">
        <Form.Label>Database</Form.Label>
        <GenericSelectableList
          items={AMBIENTS}
          searchKeys={['name']}
          onAdd={(selected) => {
            setErr('');
            setSelectedAmbient(selected);
          }}
          renderLabel={(item) => item.name}
          placeholder="Select Database"
          multiOption={false}
          itemSelected={selectedAmbient}
        />
      </Form.Group>

      <Form.Group controlId="port" className="mb-3">
        <Form.Label>Port</Form.Label>
        <GenericSelectableList
          items={portOptions} 
          searchKeys={['name']} 
          onAdd={(selected) => {
            console.log(selected)
            setErr('')
            setSelectedPort(selected)
          }}
          renderLabel={(item) => item.name} 
          placeholder="Select Port"
          itemSelected={selectedPort}
          multiOption={false} // Solo una selección
        />
      </Form.Group>

      <Form.Group controlId="visibility" className="mb-3">
        <Form.Label>Visibility</Form.Label>
        <Form.Check
          type="switch"
          label={localVisibility ? "Visible" : "Private Network/Intranet or Private."}
          checked={localVisibility}
          onChange={(e) => {
            setErr('')
            setLocalVisibility(e.target.checked)
          }}
        />
      </Form.Group>

      <Form.Group controlId="whiteList" className="mb-3">
        <Form.Check
          type="checkbox"
          label="Enable White List"
          checked={localWhiteListEnabled}
          onChange={(e) => {
            setErr('')
            setLocalWhiteListEnabled(e.target.checked)
          }}
        />
      </Form.Group>

      {localWhiteListEnabled && (
        <div>
          <Table className="tableForm" hover>
            <thead>
              <tr>
                <th>White List Item</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {localWhiteList.map((item, index) => (
                <tr key={index}>
                  <td>


                  <InputMask
                    //mask="999.999.999.999" //The IP mask
                    placeholder="000.000.000.000"
                    value={item}
                    ref={maskedInputRef}
                    formatChars={{ 9: '[0-9]' }}
                    className="ipMaskedInput"
                    onChange={(e) => handleUpdateWhiteListItem(index, e.target.value)}
                    style={{
                      
                    }}
                  >
                    </InputMask>

              {/*}      <Form.Control
                      type="text"
                      placeholder="Enter value"
                      value={item}
                      onChange={(e) => handleUpdateWhiteListItem(index, e.target.value)}
                    /> */}



                  </td>
                  <td>
                    <Button
                      //variant="danger"
                      style={{backgroundColor:'transparent', borderColor:'transparent'}}
                      size="sm"
                      onClick={() => handleRemoveFromWhiteList(index)}
                    >
                      x
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button variant="link" onClick={handleAddToWhiteList}>
            Add to White List
          </Button>
        </div>
      )}

      <Form.Group controlId="ambient" className="mb-3">
        <Form.Label>API Product Wrappers List</Form.Label>
        <GenericSelectableList
          items={PRODUCTS}
          searchKeys={['name']}
          //onAdd={(selected) => setSelectedAmbient(selected.name)}
          renderLabel={(item) => item.type!='NORMAL' ? item.name + ' warpper' : item.name}
          placeholder="Select API Product Wrapper"
          multiOption={true} // Solo una selección
          // onAdd={(selected) => validateSubmitProducts(selected)}
          // onRemove={(selected) => validateSubmitProducts(selected)}
          itemSelected={''}
          selectedItems={listaRecuperados3}
          setSelectedItems=/*{setlistaRecuperados3}*/{(selected) => {
            setlistaRecuperados3(selected);
            validateSubmitProducts(selected);
          }
        }




        />
      </Form.Group>

      {selectedAmbient.id > 1 && (
        <Alert variant="info">
          A new database is going to be created
        </Alert>
      )}

      <Row>
        <Col md='6'>
                {/*  Validaciones diferentes para activar el boton según si es de creación o de edición*/}

          {!editing && <Button 
            disabled={(!localAlias || !selectedPort || listaRecuperados3.length==0 || 
                      Number(selectedPort.name) == 0 || (localWhiteListEnabled && localWhiteList.length==0))}
            variant="primary" 
            className="mt-3 w-100" 
            type="submit">
            Save
          </Button>}
          {editing && <Button 
            disabled={!((localAlias &&  localAlias !='' && localAlias != alias) || selectedPort.id != port_id || visibility != localVisibility ||
                      ambiente_id != selectedAmbient.id|| localWhiteListEnabled != whiteListEnabled 
                    || !sameProductList || !sameWhitelist
                    
                    )}
            variant="primary" 
            className="mt-3 w-100" 
            type="submit">
            Save
          </Button>}
        </Col>
      </Row>

{ error.length>0 &&
<Alert variant="danger">
  {error}
</Alert>
}

    </Form>
  );
};

export default EnvironmentForm;
