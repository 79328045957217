export const metaConfig = [
	{
		path: '/framework',
		title: 'AVAP Framework: an API Lifecycle Management Platform',
		description: 'Discover AVAP Framework a complete API lifecycle management. Design, develop, publish, and scale APIs seamlessly. Virtual and Intelligent.',
		url: 'https://avapframework.com',
	},
	{
		path: '/avap',
		title: 'AVAP: High-Level Programming Language for Virtual APIs',
		description: 'Discover AVAP, advanced virtual and intelligent API programming language. Simplify API development with an efficient, and innovative approach.',
		url: 'https://avapframework.com/avap/',
	},
	{
		path: '/vsc-extensions',
		title: 'AVAP API Extensions for Microsoft Visual Code',
		description: 'Discover AVAP language extensions to build, version, and publish APIs seamlessly in Microsoft Visual Code. Simplify your API workflow with powerful tools.',
		url: 'https://avapframework.com/vsc-extensions/',
	},
	{
		path: '/devstudio',
		title: 'AVAP Dev Studio: Complete IDE for API Development',
		description: 'Discover AVAP Dev Studio, the IDE for full project management, API development, code maintenance, and API publication. Streamline your development process.',
		url: 'https://avapframework.com/devstudio/',
	},
	{
		path: '/version-control',
		title: 'AVAP API Version Control: Efficient API Versioning',
		description: 'Discover AVAP API Version Control for seamless API versioning, tracking, multi-version management, and ensuring backward compatibility. Simplify your API lifecycle.',
		url: 'https://avapframework.com/version-control/',
	},
	{
		path: '/api-publisher',
		title: 'AVAP API Publisher: Efficient API Publishing & Management',
		description: 'Discover AVAP API Publisher for streamlined API publishing with robust management, version control, and seamless platform integration. Simplify your workflow.',
		url: 'https://avapframework.com/api-publisher/',
	},
	{
		path: '/avs',
		title: 'AVAP Virtual Server: Lightweight API Lifecycle Management',
		description: 'Discover AVAP Virtual Server for efficient API lifecycle management, universal compatibility, and optimized traffic. Boost your API performance today.',
		url: 'https://avapframework.com/avs/',
	},
	{
		path: '/interpreter',
		title: 'AVAP Interpreter & Language Server: Fast API Development',
		description: 'AVAP Interpreter & Language Server for scalable Virtual API development, real-time interpretation, and AI-powered code enhancement. Boost your workflow.',
		url: 'https://avapframework.com/interpreter/',
	},
	{
		path: '/gateway',
		title: 'AVAP Gateway Advanced: Intelligent API Traffic Routing',
		description: 'Discover AVAP Gateway Advanced for secure dynamic traffic routing, optimized performance, and scalable security. Enhance your API infrastructure.',
		url: 'https://avapframework.com/gateway/',
	},
	{
		path: '/avap-marketplace',
		title: 'AVAP Marketplace: Discover & Monetize API Products',
		description: 'Discover AVAP Marketplace, a collaborative platform for exploring, integrating, and monetizing API products. Unlock new opportunities for your business.',
		url: 'https://avapframework.com/avap-marketplace/',
	},
	{
		path: '/avap-connectors',
		title: 'AVAP Connectors: Simplify Access to External Systems',
		description: 'Discover AVAP Connectors, pre-configured components designed to simplify access to external systems, platforms, and databases. Streamline integration.',
		url: 'https://avapframework.com/avap-connectors/',
	},
	{
		path: '/platon',
		title: 'PLATON, Programming Language Architecture for True Open Virtualization',
		description: 'Discover PLATON, an open-source project offering the kernel and specifications for a virtual programming language. Revolutionize your development approach.',
		url: 'https://avapframework.com/platon/',
	},
	{
		path: '/collaborative',
		title: 'AVAP Collaborative Network: P2P API Development',
		description: 'Discover AVAP Collaborative Network, a peer-to-peer platform for scalable API development, deployment, and optimized processing across servers. Enhance your API solutions.',
		url: 'https://avapframework.com/collaborative/',
	},
	{
		path: '/cloud',
		title: 'AVAP Cloud: Powerful API Lifecycle Management Platform',
		description: 'Introducing AVAP Cloud, a powerful platform for managing the entire API lifecycle. From design to deployment, powered by AVAP tech, AI, and virtuality at its core.',
		url: 'https://avapframework.com/cloud/',
	},
	{
		path: '/brunix',
		title: 'AVAP BRUNIX: Revolutionizing AI-Driven Development',
		description: 'Discover AVAP BRUNIX, an AI-powered platform that enhances coding with real-time collaboration, contextual memory, and predictive interactions for efficient development.',
		url: 'https://avapframework.com/brunix/',
	},
	{
		path: '/roadmap',
		title: 'Product Roadmap: Explore AVAP Framework’s Next Steps',
		description: 'Discover AVAP Framework Product Roadmap. Effortlessly visualize and explore the upcoming developments in our complete ecosystem.',
		url: 'https://avapframework.com/roadmap/',
	},
	{
		path: '/about',
		title: 'AVAP About Us: Our Mission, Values, and Innovation',
		description: 'Discover our mission, core values, and our journey in API innovation. Learn how we’re shaping the future of API technology.',
		url: 'https://avapframework.com/about/',
	},
	{
		path: '/professional_en',
		title: 'AVAP Cloud: Powerful API Lifecycle Management Platform',
		description: 'Introducing AVAP Cloud, a powerful platform for managing the entire API lifecycle. From design to deployment, powered by AVAP tech, AI, and virtuality at its core.',
		url: 'https://avapframework.com/professional_en/',
	},
	{
		path: '/business_en',
		title: 'AVAP Cloud: Powerful API Lifecycle Management Platform',
		description: 'Introducing AVAP Cloud, a powerful platform for managing the entire API lifecycle. From design to deployment, powered by AVAP tech, AI, and virtuality at its core.',
		url: 'https://avapframework.com/business_en/',
	},
];
