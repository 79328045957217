import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Table, Button, Spinner, Accordion, Card, Form, Image, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddProjectModalSel from '../Modals/addProjectModalSelect';
import AddProjectModal from '../Modals/addProjectModal';
import { faChevronDown, faChevronUp, faPencilAlt, faPlusCircle, faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { amountToEur } from '../../services/AmountService';
import ConsumptionProjectChart from './GraphComponents/ConsumptionProjectChart';
import UpgradePlanModal from '../Modals/UpgradePlanModal';
import Drawer from './Drawer'

import projectsIcon from '../../assets/img/ui_icons/cld_apiprojects-s.svg'

import editicon from '../../../src/assets/img/icon_edit.png';
import Logo from '../../assets/img/ilustracion_noInfo2.png'
import SessionService from '../../services/SessionsService';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';
import EditProjectModal from '../Modals/editProjectModal';
import ConfirmDeleteProjectModal from '../Modals/ConfirmDeleteProjectModal';
import Nodata from './components/Nodata';


type ProjectsProps = {

};




const ProjectsContent: FunctionComponent<ProjectsProps> = (props: ProjectsProps) => {
  const menuActive = 'projects';

  const [slotsInfo, setSlots] = useState({
    allowProjectsBySubscription: 0,
    maxAllowProjecs: 0,
    maxExtraProjects: 0,
    extraPurchasedProjects: 0,
    posibleSlotsToBuy: 0,
    activeProjects: 0,
    totalSlots: 0,
    availableSlots: 0
  });
  const { } = props
  const [loadingTable, setLoadingTable] = useState(false);
  const [possibleSlotsToBuy, setSlotsToBuy] = useState(0);
  const [projectInfo, setProjectInfo] = useState({ name: '', description: '' })
  const [availableSlots, setavailableSlots] = useState(0);
  const [projects, setProjects] = useState([]);
  const [addingProjects, setAddingProjects] = useState(false);
  const [comprar, serComprar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [recovered_tokens, set_recovered_tokens] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const [initialized, setInitialized] = useState(false);


  const AMBIENT_LABELS = {
    Active: { name: 'Active', color: '#28a745' },        // Verde
    Creating: { name: 'Creating', color: '#CE9C06' }, // Amarillo
    //3: { name: 'Live', color: '#28a745' }, // Amarillo

  };

  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;

    return (
      <span
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth: '100px',
          display: 'flex',
          width: '65px',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };




  const handleAddProjects = () => {
    setAddingProjects(true);
    setIsDrawerOpen(true); // Abrir el Drawer si hay slots disponibles
  }
  const [availableAdd, setAvailableAdd] = useState(true);
  const [projectSelected, setProjectSelected] = useState('')
  const [allLoaded, setAllLoaded] = useState(true);
  const [currentSuscripcion, setCurrentSusbscription] = useState(0);
  const [sublinks, setSublinks] = useState([

  ]);
  const [activeKey, setActiveKey] = useState('0');
  const [cardsLoading, setCardsLoading] = useState(true);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [isDrawerEditOpen, setIsDrawerEditOpen] = useState(false);

  const [filtro, setFiltro] = useState('');

  // Función para abrir/cerrar el Drawer directamente
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  }

  const getProjects = async () => {
    try {
      setCardsLoading(true);

      setLoadingTable(true);

      // Obtener todos los proyectos desde la API
      const fetchedProjects = (await ObexRequestHandler.get(`/projects?organization_id=${SessionService.ORGANIZATION}`, {}, true)).data || [];

      // Almacenar todos los proyectos
      setAllProjects(fetchedProjects);
      set_recovered_tokens(fetchedProjects);
      // Inicialmente, mostrar todos los proyectos

      setVariable(false);
      fetchedProjects.forEach(element => {
        if (element.isCreating == true) {
          setVariable(true);
        }
      });

      setProjects(fetchedProjects);
      setFirstTime(false)

      // Configurar otros estados según tus necesidades
      const slots = (await ObexRequestHandler.get('/consumption/slots', {}, true)).data || {};
      const licencia = (await ObexRequestHandler.get('/current_subscription', {}, true)).data || {};

      setavailableSlots(slots.availableSlots);
      setCurrentSusbscription(licencia.subscription_id);
      setSlots(slots);
      setSlotsToBuy(slots.maxExtraProjects - slots.extraPurchasedProjects);


      setCardsLoading(false);
      setInitialized(true);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      setAllProjects([]);
      setProjects([]);
      setCardsLoading(false);
      setInitialized(true);
    } finally {
      setLoadingTable(false);
      setInitialized(true);
    }
    history.push('/projects')
  }

  const handleSearch = () => {
    console.log(allProjects);
    console.log(`Filtro ${filtro}}`)
    if (filtro.trim() === '') {
      // Si el filtro está vacío, mostrar todos los proyectos
      setProjects(allProjects);
    } else {
      const filtroLower = filtro.toLowerCase();
      const filtered = allProjects.filter(p => p.description.toLowerCase().includes(filtroLower));
      setProjects(filtered);
    }

  }


  const whenClosing = () => {
    serComprar(false);
    setAddingProjects(false);
    getProjects();
  }

  const whenCancel = () => {
    setAddingProjects(false);

  }

  const handleDelete = async (project) => {

    if (true) {

      const result = await ObexRequestHandler.delete(`/projects/${project.name}`);
      const { success, message, data } = result;
      console.log(result);
      console.log(project);

      getProjects();
    }

  };

  const projectsLoaded = (projectSelecteds) => {

    setSublinks([]);
    // console.log('*-**');
    // console.log(projectSelecteds);
    if (projectSelecteds != '') {
      setSublinks([

      ]);
    }


  }



  const deleteProject = async (project) => {
    try {
      //setSuccess('');
      //setDeleting(true);
      const result = await ObexRequestHandler.delete(`/projects/${project}`);
      const { success, message, data } = result;
      if (!success) {
        //setError(message)
      } else {
        //setSuccess('true');
        /*   setTimeout(() => {
             history.push('/projects');
           }, 2000); */

      }
    } catch (error) {
      //setError('Project cannot be deleted.');
    }
    //setDeleting(false);
    //setSuccess('success');
    //SessionService.renewSession();
  }

  const history = useHistory();
  const routerLink = (url) => {
    const pushData = projects
      ? { state: { projects } }
      : {};
    history.push(url, pushData);
  }

  const loadingSpinner = <Row><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;
  const noProjectsText = <Row>
    <Col className="mx-auto text-center">
      <span className="d-block">
        You have not yet registered any project in your account.
      </span>
      <span className="d-block">
        Press add to register and configure your projects.
      </span>
    </Col>
  </Row>;

  const toggleAccordion = (key: string) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const generateCards = projects.map((p, index) => {
    // debugger

    return (

      <div style={{ marginTop: '1.5em' }} key={index} onSelect={() => setActiveKey(String(index))} className="mb-4">
        <Card>
          <Card.Header>

            <div className='d-flex justify-content-between'>
              <div className='text-bold' style={{ fontSize: '1.1rem' }} >
                {p.description}
              </div>

              <div style={{ display: 'flex' }} onClick={() => {

                toggleAccordion(String(index))
                console.log(index)

              }}>
                <a className="edit-link" onClick={() => routerLink(`/projects/${p.name}`)}><Image src={editicon} style={{ marginBottom: '' }} width="20px" alt="Logo" /></a>


              </div>
            </div>

          </Card.Header>

          <Row>
            <Card.Body className='pt-0'>
              <div className='d-flex flex-column' style={{ paddingLeft: '4rem', paddingRight: '4rem' }}>
                <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                  <div className='text-bold'>Project ID:</div>
                  <div>{p.name}</div>
                </div>

                <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                  <div className='text-bold'>Total Request (month):</div>
                  <div>{p.tx.current}</div>
                </div>

                <div className='d-flex flex-row justify-content-between py-3'>
                  <div className=' text-bold'>Creation date:</div>
                  <div>{p.creation_date.toString().split(' ')[0]}</div>
                </div>



              </div>
            </Card.Body>
          </Row>

        </Card>

        {/* <Button className="customButton-blue" onClick={toggleDrawer}>
        Open Drawer Directly
      </Button> */}

      </div>

    )
  })

  const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(e.target.value);
    filterProjects(e.target.value);
  };


  const filterProjects = async (filtros) => {

    const filter_projects = recovered_tokens.filter(element =>
      element.description.toLowerCase().includes(filtros.toLowerCase())
    );
    setProjects(filtros === '' ? recovered_tokens : filter_projects);

  }




  const [variable, setVariable] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      //console.log("Comprobando variable:", variable);
      getProjects();
      // Aquí puedes hacer lo que necesites con la variable
    }, 10000); // 10 segundos

    return () => clearInterval(interval); // Limpieza del intervalo al desmontar el componente
  }, [variable]); // Se ejecutará cuando la variable cambie


  document.addEventListener('updateView', (e) => {
    if (SessionService.updating > 0 && location.pathname == '/projects') {
      setFirstTime(true)
      history.push('/projects')
      SessionService.updating = SessionService.updating - 4;
      getProjects();
      window.scrollTo(0, 0);
      history.push('/projects')
    }

  }, false);



  useEffect(() => {
    window.scrollTo(0, 0);
    getProjects();
    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
  }, []);




  return (
    <>
      <BrowserView>

        <DashLayout sider={DashRouters} active={menuActive} sublinks={sublinks}>

          <div>


            <Row id='topHeader'>
              <Col md="10">
                <div className="cld_wrapperTitle">
                  <img src={projectsIcon} alt="icon dashboard" />
                  <span className="cld_title w-100 margin-y-24px">

                    {/*} <span style={{ color: '#949498', fontSize: '14px' }}>API Projects </span>/ */}API Projects
                  </span>
                </div>
              </Col>
              <Col md="2" className="align-content-center">
                <Button
                  style={{ width: '225px' }}
                  className="customButton-black btn px-4 float-right  resBtn"
                  onClick={()=>{
                    if (availableSlots>0) {handleAddProjects();serComprar(false)} else
                    if (availableSlots==0 && possibleSlotsToBuy>0) {setAddingProjects(true);serComprar(true)} else {
                      setAddingProjects(true);serComprar(true)
                    }
                    }
                  }>
                  {availableSlots>0 ? 'Add New' : (possibleSlotsToBuy>0 ? 'Add Slots' : 'Upgrade Plan')}
                </Button>
              </Col>
              <Col> <div className="customBorder"></div></Col>
            </Row>


            {/*}

            <Row id='topHeader' className='mb-5'>
              <Col md="10"  >
                <div className='d-flex flex-column'>
                  <div className="cld_wrapperTitle">
                    <img src={projectsIcon} alt="icon dashboard" />
                    <span className="cld_title w-100 margin-y-24px">
                      API Projects
                    </span>
                  </div>


                </div>
              </Col>
              <Col md="2" className='align-content-center'>
                <Button disabled={allLoaded} className="customButton-blue w-100  px-4 float-right" onClick={handleAddProjects}>Add New </Button>
              </Col>
              <Col>
                <div className="customBorder"></div>
              </Col>
            </Row>

*/}

            {(projects.length == 0 && cardsLoading == false && allProjects.length === 0 && initialized) &&
              <div className='nodata-wrapper'>
                <Nodata />
              </div>
            }
            <Row style={{ fontWeight: 'bold' }}>
              <Col md={12}>
                {cardsLoading && firstTime ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (projects.length > 0 || allProjects.length > 0) && (
                  // Intercalado de cartas y gráficos en wrappers únicos

                  <Row id="table" className="mt-5">
                    <Col>

                      <div className='cld_CardWrapper'>
                        <span className='cld_sectionTitle'>API Projects</span>
                        {/*} <Table responsive> */}



                        <div className="searchContainer" style={{ width: '485px', maxWidth: '485px', marginRight: '10px', display: 'flex' }}>
                          <Form.Control id='buscador' required type="text" value={filtro} placeholder="Search by project name"
                            onChange={changeFilter} />
                          <Button
                            className="customButton-transparent2 px-4"
                            onClick={handleSearch}>
                            <FontAwesomeIcon icon={faSearch} size="1x" />
                          </Button>
                        </div>

                        <Table style={{ marginTop: '1em' }} className="cld_tableWrapper">

                          <tbody>
                            <tr className="tableRow" style={{ color: '#595959' }}>
                              <th style={{ width: '20%' }} className='textBold'>Name</th>
                              <th style={{ width: '15%', textAlign: 'center' }} className='textBold'>Status</th>
                              <th style={{ width: '15%', textAlign: 'center' }} className='textBold'>Creation</th>
                              <th style={{ width: '12%', textAlign: 'center' }} className='textBold'>TX Used</th>
                              <th style={{ width: '12%', textAlign: 'center' }} className='textBold'>TX Available</th>
                              <th style={{ width: '12%', textAlign: 'center' }} className='textBold'>Limit TX</th>
                              <th style={{ width: '15%', textAlign: 'center' }} className='textBold'>API Products</th>
                              <th style={{ width: '20px', textAlign: 'end' }} className='textBold'>Actions</th>

                            </tr>
                            {projects.length === 0 ? (
                              <tr>
                                <td colSpan={8}>
                                  <div className='nodata-wrapper'>
                                    <Nodata />
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              projects.map(payment => (
                                <tr key={payment.id} className="tableRow">
                                  <td className='td_centered' style={{ fontWeight: 'normal' }}>{payment.description}</td>
                                  <td className='td_centered' style={{ fontWeight: 'normal' }}>{payment.isCreating ? getAmbientLabel('Creating') : getAmbientLabel('Active')}</td>
                                  <td className='td_centered' style={{ fontWeight: 'normal', textAlign: 'center' }} >{payment.creation_date.split(' ')[0]}</td>
                                  <td className='td_centered' style={{ fontWeight: 'normal', textAlign: 'center' }} >{payment.tx.current}</td>
                                  <td className='td_centered' style={{ fontWeight: 'normal', textAlign: 'center' }} >{payment.tx.rest}</td>
                                  <td className='td_centered' style={{ fontWeight: 'normal', textAlign: 'center' }} >{payment.tx.limit}</td>
                                  <td className='td_centered' style={{ fontWeight: 'normal', textAlign: 'center' }} >{payment.api_products_count}</td>
                                  <td className='td_centered' style={{ width: '8%' }}>

                                    {payment.isCreating && false ? <>
                                      <div style={{ textAlign: 'end', marginRight: '10px' }}>
                                        <Spinner animation="border" />
                                      </div>
                                    </> :
                                      <div className="actionsWrapper">
                                        <Dropdown className="my-auto" style={{ width: '100%', marginRight: '0px' }}>
                                          <Dropdown.Toggle className="">
                                            <FontAwesomeIcon icon={faEllipsisV} />
                                          </Dropdown.Toggle>
                                          {!payment.isCreating ?
                                            <Dropdown.Menu>

                                              <Dropdown.Item key={1} onClick={() =>/*history.push(`/projects/${payment.name}`)*/ { console.log(payment); setProjectInfo(payment); setIsDrawerEditOpen(true) }}>Edit</Dropdown.Item>
                                              {(payment.has_marketplace == 0 && payment.has_devportal == 0) && <Dropdown.Item key={2} onClick={() =>/*history.push(`/projects/${payment.name}`)*/ { setProjectInfo(payment); setShowModal(true); }}>Delete</Dropdown.Item>}



                                            </Dropdown.Menu> :
                                            <Dropdown.Menu>

                                              <Dropdown.Item key={1} onClick={null}>Creating Project</Dropdown.Item>




                                            </Dropdown.Menu>

                                          }
                                        </Dropdown>
                                      </div>
                                    }

                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </Table>
                      </div>

                    </Col>
                  </Row>
                  /*
                  
                  generateCards.map((card, index) => (
                    <div key={index} className="wrapperApiprojects" style={{ marginBottom: '20px' }}>
                      
                      <div >{card}</div>

                    
                      {projects[index] && (
                        <ConsumptionProjectChart
                          title={projects[index].name}
                          projects={projects.filter((rr) => rr.isCreating)}
                        />
                      )}
                    </div>
                  ))
*/



                )}
              </Col>
            </Row>

              
            <UpgradePlanModal
              showModal={addingProjects && availableSlots == 0 && possibleSlotsToBuy == 0 && comprar}
              text={'In order to add a new project you need to upgrade your plan.'}
              onCloseModal={whenClosing}
              onCancelModal={whenCancel}
              currentSuscription={currentSuscripcion}
              onSuccess={whenClosing}
            />


            {(addingProjects && availableSlots > 0 && !comprar)
              ?


              <Drawer index='projects' isOpen={isDrawerOpen} toggleSidebar={() => setIsDrawerOpen(false)} name="API Project" subtitle='Add New '>
                <AddProjectModal opened={isDrawerOpen} onCloseAction={() => {
                  setIsDrawerOpen(false);
                  getProjects()
                }}
                />

              </Drawer>


              : comprar && <AddProjectModalSel opened={isDrawerOpen} possibleProjectsToBuy={possibleSlotsToBuy} shown={addingProjects && possibleSlotsToBuy > 0} onCloseAction={whenClosing} />

            }


            <Drawer index='projects' isOpen={isDrawerEditOpen} toggleSidebar={() => {
              setIsDrawerEditOpen(false)
              history.push('/projects');
            }} name="API Project" subtitle='Edit '>
              <EditProjectModal
                projectInfo={projectInfo}
                opened={isDrawerEditOpen}
                onCloseAction={() => {
                  setIsDrawerEditOpen(false);
                  getProjects()
                }
                }
              />

            </Drawer>


            <ConfirmDeleteProjectModal
              handleSubmit={() => {
                setShowModal(!showModal)
                handleDelete(projectInfo);
                console.log('XX')
              }}
              showModal={showModal}
              onCloseModal={() => setShowModal(!showModal)}
              loading={/*deleting*/false}
              error={/*error*/''}
              success={/*success*/''}
              name_to_check={'DELETE'}
              title_to_show={projectInfo.description}
            />


          </div>

        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

// TODO possibleProjectsToBuy rescartar la info
export default ProjectsContent;