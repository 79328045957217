import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Modal, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import SupportBanner from '../../DashboardComponents/supportBanner';
import UpgradePlanModal from '../../Modals/UpgradePlanModal';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import SessionService from '../../../services/SessionsService';


import arrowMenu_W from '../../../assets/img/ui_icons/menuArrow_W.svg';
import arrowMenu_B from '../../../assets/img/ui_icons/menuArrow_B.svg';


//iconos Negros
import Dashboard_B from '../../../assets/img/ui_icons/cld_dashboard-b.svg';
import Organizations_B from '../../../assets/img/ui_icons/cld_org-b.svg';
import Teams_B from '../../../assets/img/ui_icons/cld_teams-b.svg';
import Developers_B from '../../../assets/img/ui_icons/cld-IAM-b.svg';
import Projects_B from '../../../assets/img/ui_icons/cld_apiprojects-b.svg';
import Publisher_B from '../../../assets/img/ui_icons/cld_publisher-b.svg';
import Sub_B from '../../../assets/img/ui_icons/cld_sub-b.svg';
import Upgrade_B from '../../../assets/img/ui_icons/cld_upgrade-b.svg';
import Billing_B from '../../../assets/img/ui_icons/cld_billing-b.svg';
import Tutorial_B from '../../../assets/img/ui_icons/cld_tutorial-b.svg';
import SignOut_B from '../../../assets/img/ui_icons/cld_singout-b.svg';
import cManager_B from '../../../assets/img/ui_icons/credentials-b.svg';
import rManager_B from '../../../assets/img/ui_icons/repo-b.svg';
import catalog_B from '../../../assets/img/ui_icons/catalog-b.svg';
import gateway_B from '../../../assets/img/ui_icons/gateway-b.svg';
import devPortal_B from '../../../assets/img/ui_icons/portal-b.svg';
import money_B from '../../../assets/img/ui_icons/coin-b.svg';
import analytics_B from '../../../assets/img/ui_icons/graph-b.svg';


//iconos Blancos

import IAM_W from '../../../assets/img/ui_icons/cld_dashboard-b.svg';
import IAM_B from '../../../assets/img/ui_icons/cld_org-b.svg';

import Dashboard_W from '../../../assets/img/ui_icons/cld_dashboard-w.svg';
import Organizations_W from '../../../assets/img/ui_icons/cld_org-w.svg';
import Teams_W from '../../../assets/img/ui_icons/cld_teams-w.svg';
import Developers_W from '../../../assets/img/ui_icons/cld-IAM-w.svg';
import Projects_W from '../../../assets/img/ui_icons/cld_apiprojects-w.svg';
import Publisher_W from '../../../assets/img/ui_icons/cld_publisher-w.svg';
import Sub_W from '../../../assets/img/ui_icons/cld_sub-w.svg';
import Upgrade_W from '../../../assets/img/ui_icons/cld_upgrade-w.svg';
import Billing_W from '../../../assets/img/ui_icons/cld_billing-w.svg';
import Tutorial_W from '../../../assets/img/ui_icons/cld_tutorial-w.svg';
import SignOut_W from '../../../assets/img/ui_icons/cld_singout-w.svg';
import cManager_W from '../../../assets/img/ui_icons/credentials-w.svg';
import rManager_W from '../../../assets/img/ui_icons/repo-w.svg';
import catalog_W from '../../../assets/img/ui_icons/catalog-w.svg';
import gateway_W from '../../../assets/img/ui_icons/gateway-w.svg';
import devPortal_W from '../../../assets/img/ui_icons/portal-w.svg';

import ia_W from '../../../assets/img/ui_icons/cld_ai-w.svg';
import ia_B from '../../../assets/img/ui_icons/cld_ai-b.svg';

import graph_W from '../../../assets/img/ui_icons/cld_aiCharts-w.svg';
import graph_B from '../../../assets/img/ui_icons/cld_aiCharts-b.svg';

import money_W from '../../../assets/img/ui_icons/coin-w.svg';
import analytics_W from '../../../assets/img/ui_icons/graph-w.svg';


//cloudNote : sidebar Cloud

type SideProps = {
  sider: any,
  active?: string,
  sublinks?: Array<any>
  isDarkMode: boolean
};

const DashboardSideBar: FunctionComponent<SideProps> = (props: SideProps) => {
  const { sider, active, sublinks, isDarkMode } = props;
  const history = useHistory();

  const { menus } = sider;
  const urlPathName = window.location.pathname;
  const [navMenu, setNav] = useState([]);
  const [activeSection, setActiveSection] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(SessionService.ISCOLLAPSED);
  const [showModal, setShowModal2] = useState(false);
  const [showModa3, setShowModal3] = useState(false);

  // Modal close handlers
  const handleClose2 = () => setShowModal2(false);
  const handleClose3 = () => setShowModal3(false);

  // Splash states and close handlers
  const [showSplash, setShowSplash] = useState(true);
  const handleClose = () => setShowSplash(false);

  const [showSplash2, setShowSplash2] = useState(false);
  const handleClose2a = () => setShowSplash2(false);
  const handleClose2b = () => { setShowSplash2(false); setShowSplash3(true); };
  const handleShow = () => setShowSplash2(!showSplash2);

  const [showSplash3, setShowSplash3] = useState(false);
  const handleClose3b = () => setShowSplash3(false);
  const handleShow2 = () => setShowSplash3(!showSplash3);

  const [showSplash4, setShowSplash4] = useState(false);
  const handleClose4 = () => setShowSplash4(false);
  const handleShow3 = () => setShowSplash4(!showSplash4);

  const [showSplash5, setShowSplash5] = useState(false);
  const handleClose5 = () => setShowSplash5(false);
  const handleShow4 = () => setShowSplash5(!showSplash5);

  const [showSplash6, setShowSplash6] = useState(false);
  const handleClose6 = () => setShowSplash6(false);
  const handleShow5 = () => setShowSplash6(!showSplash6);

  const [showSplash7, setShowSplash7] = useState(false);
  const handleClose7 = () => setShowSplash7(false);
  const handleShow6 = () => setShowSplash7(!showSplash7);

  const [showSplash8, setShowSplash8] = useState(false);
  const handleClose8 = () => setShowSplash8(false);
  const handleShow7 = () => setShowSplash8(!showSplash8);

  const [showSplash9, setShowSplash9] = useState(false);
  const handleClose9 = () => setShowSplash9(false);
  const handleShow8 = () => setShowSplash9(!showSplash9);

  const [showSplash10, setShowSplash10] = useState(false);
  const handleClose10 = () => setShowSplash10(false);
  const handleShow9 = () => setShowSplash10(!showSplash10);

  const [showSplash11, setShowSplash11] = useState(false);
  const handleClose11 = () => setShowSplash11(false);
  const handleShow10 = () => setShowSplash11(!showSplash11);

  const [showSplash12, setShowSplash12] = useState(false);
  const handleClose12 = () => setShowSplash12(false);
  const handleShow11 = () => setShowSplash12(!showSplash12);

  const [showSplash13, setShowSplash13] = useState(false);
  const handleClose13 = () => setShowSplash13(false);
  const handleShow12 = () => setShowSplash13(!showSplash13);

  const [showSplash14, setShowSplash14] = useState(false);
  const handleClose14 = () => setShowSplash14(false);
  const handleShow13 = () => setShowSplash14(!showSplash14);

  const [showSplash15, setShowSplash15] = useState(false);
  const handleClose15 = () => setShowSplash15(false);
  const handleShow14 = () => setShowSplash15(!showSplash15);

  const [showSplash16, setShowSplash16] = useState(false);
  const handleClose16 = () => setShowSplash16(false);
  const handleShow15 = () => setShowSplash16(!showSplash16);

  const [currentSubscription, SetCurrentsuscripcion] = useState(0);
  const valuesSideBarClient = [
    "Dashboard",
    "Projects",
    "Requests",
    "FX Exchange",
    "Balance",
    "Income",
    "Marketplace",
    "Developer Teams",
    "Organizations",
    "Developers",
    "Subscription",
    "Settings",
    "Tokens"
  ];

  // Diccionario base para los iconos
  const baseIcons = {
    dashboard: { light: Dashboard_B, dark: Dashboard_W },
    devs: { light: Developers_B, dark: Developers_W },
    organizations: { light: Organizations_B, dark: Organizations_W },
    credential: { light: cManager_B, dark: cManager_W },
    repo: { light: rManager_B, dark: rManager_W },
    teams: { light: Teams_B, dark: Teams_W },
    projects: { light: Projects_B, dark: Projects_W },
    publisher: { light: Publisher_B, dark: Publisher_W },
    catalog: { light: catalog_B, dark: catalog_W },
    gateway: { light: gateway_B, dark: gateway_W },
    portal: { light: devPortal_B, dark: devPortal_W },
    brunix: { light: ia_B, dark: ia_W },
    aicharts: { light: graph_B, dark: graph_W },
    monetization: { light: money_B, dark: money_W },
    analytics: { light: analytics_B, dark: analytics_W },
    sub: { light: Sub_B, dark: Sub_W },
    billing: { light: Billing_B, dark: Billing_W },
    tutorial: { light: Tutorial_B, dark: Tutorial_W },
    signout: { light: SignOut_B, dark: SignOut_W },
  };

  const arrowIcon = isDarkMode ? arrowMenu_W : arrowMenu_B;

  // Genera lightIcons y darkIcons dinámicamente según el JSON
  const generateIcons = () => {
    const lightIcons = {};
    const darkIcons = {};

    if (Array.isArray(sider.menus)) {
      sider.menus.forEach(menu => {
        const iconKey = menu.icon?.toLowerCase(); // Usa opcional chaining para evitar errores si `menu.icon` es undefined
        if (iconKey && baseIcons[iconKey]) {
          lightIcons[menu.title] = baseIcons[iconKey].light;
          darkIcons[menu.title] = baseIcons[iconKey].dark;
        }
      });
    } else {
      console.error("sider.menus is not an array or is undefined");
    }
  
    return { lightIcons, darkIcons };
  };

  const { lightIcons, darkIcons } = generateIcons();
  const icons = isDarkMode ? darkIcons : lightIcons;

  // Función que colapsa la barra en función del ancho de la ventana
  const handleResize = () => {
    setIsCollapsed(window.innerWidth <= 980);
    SessionService.ISCOLLAPSED = (window.innerWidth <= 980);
  };

  useEffect(() => {
    // Agrega el evento de redimensionamiento al cargar el componente
    window.addEventListener('resize', handleResize);
    handleResize(); // Verifica el tamaño inicial

    return () => {
      // Limpia el evento al desmontar el componente
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [isDashboardBlue, setIsDashboardBlue] = useState(false);

  const groupSider = () => {
    const groupTitles = sider.menus.map(item => item.title);
    const equal = JSON.stringify(valuesSideBarClient) === JSON.stringify(groupTitles);
    if (equal) setIsDashboardBlue(true);
  };

  const loadNav = () => {
    const navs = menus
      .filter((nav) => !['Requests', 'FX Exchange', 'Balance', 'Income', 'Marketplace', 'Settings'].includes(nav.title))
      .map((nav) => {
        const { id, links, basePath, icon, title } = nav;

        // Combinar sublinks dinámicos y estáticos
        let combinedLinks = [...links];
        if (active === id && sublinks) {
          combinedLinks = [...links, ...sublinks];
        }

        // Determinar si la sección o alguno de sus enlaces es activo
        const isSectionActive =
          urlPathName === basePath || 
          links.some(link => urlPathName === (link.basePath || `${basePath}/${link.path}`));

        // Si la sección está activa, actualizar activeSection
        if (isSectionActive && activeSection !== id) {
          setActiveSection(id);
        }

        return (
          <div id={`entradamenu${basePath.replace('/', '')}`}
            style={{ 
              position: basePath=='/signout' ? 'fixed':'relative', 
              bottom: `${basePath == '/signout' ? '20px':''}`,
              width: `${basePath == '/signout' ? `${isCollapsed ? '60px':'228px'}` : ''}`
            }}
            key={'section_' + nav.title}
            className={`sb-section-wrapper ${isSectionActive ? 'active' : ''}`}
            onClick={(e) => {
              e.stopPropagation(); // Prevenir la propagación del evento
              
              // Solo colapsar si no es una sección con submenús activos
              if (!combinedLinks.length) {
                setIsCollapsed(false);
              }

              const g = document.getElementById('entradamenusignout') as HTMLElement;
              if (g!=null) g.style.width = '228px';

              if (basePath === '/signout') {
                setShowModal2(true);
              } else if (basePath === '/upgrade_plan_splash') {
                setShowModal3(true);
              } else if (basePath === '/tutorial') {
                SessionService.TUTORIAL_SHOWED = true;
                SessionService.START_TUTORIAL = true;
                
                if (window.location.pathname !== '/cloud_dashboard') {
                  history.push('/cloud_dashboard');
                } else {
                  const event = new Event('startTutorial');
                  window.dispatchEvent(event);
                }
              } else {
                history.push(basePath);
              }
            }}
          >
            <div className="sb-link">
              <span
                className="icon"
                id={`icon-${title}`}
                style={{ backgroundPositionY:`${isSectionActive ? '16px': ''}`, backgroundImage: `url(${icons[title]})` }}
              />
              <span 
                style={{ fontSize:'16px', fontWeight:400, color:`${isSectionActive ? '#147bce': ''}` }} 
                className="sb-link-title"
              >
                {title}
              </span>
            </div>

            {/* Mostrar sublinks si hay enlaces y la sección está activa o tiene un submenu activo */}
            {(combinedLinks.length > 0 && (isSectionActive || activeSection === id)) && (
              <div 
                className="subsection"
                onClick={(e) => e.stopPropagation()} // Prevenir que los clicks en sublinks afecten al padre
              >
                {combinedLinks.map((link) => {
                  const { title, path, basePath: subBasePath } = link;
                  const url = subBasePath || `${basePath}/${path}`;
                  const isActive = urlPathName === url;

                  return (
                    <a
                      key={'link_' + (path || title)}
                      className={`sb-link ${isActive ? 'active' : ''}`}
                      style={{paddingLeft:'32px'}}
                      onClick={(e)=>{
                        e.stopPropagation();
                        history.push(url);
                      }}
                    >
                      <span>{title}</span>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        );
      });
    setNav(navs);
  };

  const getConsumption = async (disableCache = false) => {
    const result = await ObexRequestHandler.get('/consumption', {}, !disableCache);
    if (result.success) {
      SetCurrentsuscripcion(result.data.subscriptionInfo.subscription_id);
    }
  };

  useEffect(() => {
    getConsumption();
    groupSider();
    loadNav();
  }, []);

  // Actualizar el loadNav cuando cambia la ruta o el estado activo
  useEffect(() => {
    loadNav();
  }, [urlPathName, activeSection, sublinks, isDarkMode]);

  return (
    <>


      <div
        id="sidebarCloud-wrapper"
        className={`pt-4 d-flex flex-column ${isCollapsed ? 'sidebar-collapsed' : ''}`}
      >
         {!isCollapsed &&
        <button onClick={() => {
          SessionService.ISCOLLAPSED = !isCollapsed;
          setIsCollapsed(!isCollapsed)
          const g = document.getElementById('entradamenusignout') as HTMLElement;
          if (g!=null) g.style.width = isCollapsed ? '228px' : '48px'

        }} className="collapse-btn">

          <img
            id='arrow-icon'
            src={arrowIcon}
            alt="Arrow icon"
            className={`arrow-icon ${isCollapsed ? 'arrow-collapsed' : 'arrow-expanded'}`}
          />
        </button> }
        {navMenu}
        {false && <SupportBanner />}
      </div>

      <Modal style={{ paddingTop: '7rem' }} show={showModal} onHide={handleClose2} container={document.getElementById('dashboardWrapper')}>
        <Modal.Body className='cld_text'>Do you want to sign out?</Modal.Body>
        <Modal.Footer>
          <button className="customButton-blue" onClick={() => history.push('/cloud_login')}>
            Yes
          </button>
          <button className="customButton-blue" onClick={handleClose2}>
            No
          </button>
        </Modal.Footer>
      </Modal>

      <UpgradePlanModal
        showModal={showModa3}
        text=""
        onCloseModal={handleClose3}
        onCancelModal={handleClose3}
        currentSuscription={currentSubscription}
        onSuccess={() => {
          handleClose3();
          window.location.replace('/cloud_dashboard');
        }}
      />
    </>
  );
};

export default DashboardSideBar;
