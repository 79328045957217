import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Card, Button, Form, Table, Spinner, Image, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSearch, faBars, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import AddTeamModal from '../Modals/addTeamModal';
import { ReactSortable } from 'react-sortablejs';
import SessionService from '../../services/SessionsService';
import AddTeamsSlots from '../Modals/addTeamsSlots';
import Drawer from './Drawer';
import CustomDeveloperSelect from './CustomDeveloperSelect '

import teamsIcon from '../../assets/img/ui_icons/cld_teams-s.svg'
import editicon from '../../assets/img/ui_icons/cld_edit_table.svg';
import deleteIcon from '../../assets/img/ui_icons/cld_delete.svg';
import Logo from '../../assets/img/ilustracion_noInfo2.png';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';
import GenericSelectableList from './CustomSelectSearch';
import { useHistory } from 'react-router-dom';
import ConfirmDeletetModal from '../Modals/ConfirmDeleteModal';
import Nodata from './components/Nodata';

type IncomeProps = {};

const OrganizationTeams: FunctionComponent<IncomeProps> = ({ }) => {
  const [LeftDevTeams, setLeftDevTeams] = useState(0);
  const [projects, setProjects] = useState([]);
  const [developers, setDevelopers] = useState([]);
  const [newdevelopers, setNewDevelopers] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadDevs, setLoadDevs] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [filtro, setFilter] = useState('');
  const [addingOrg, setAddingOrg] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false); // New state for edit drawer
  const [activeTeam, setActiveTeam] = useState('');
  const [ListSel, addListSel] = useState([])
  const [ListSelP, addListSelP] = useState([])

    const [listaRecuperados, setlistaRecuperados] = useState([])
    const [listaRecuperadosR, setlistaRecuperadosR] = useState([])

    const [permissions, setPermissions] = useState([]);

    const [selectedDevelopers2, setSelectedDevelopers2] = useState<any[]>([]);

  const [selectedTeamId, setSelectedTeamId] = useState(null);

  const [organizations, setOrganizations] = useState([]); // Lista de organizaciones
  const [visibility, setVisibility] = useState('Global'); // Estado de visibilidad ('Global' o lista personalizada)
  const [selectedOrganizations, setSelectedOrganizations] = useState([]); // IDs de las organizaciones seleccionadas
  const [localSelectedGA, setSelectedGA] = useState([])
  const [localSelectedGAR, setSelectedGAR] = useState([]);
  const [localOgSelectedGAR, setOGSelectedGAR] = useState([]);
  const [recoveredProjects, setrecoveredProjects] = useState([]);

  const [roles, setRoles] = useState([]);
  const [sameRoles, setSameRoles] = useState(true);
  const [sameDevs, setSameDevs] = useState(true);
  const [sameName, setSameName] = useState(true);
  const [sameDec, setSameDec] = useState(true);

  const history = useHistory();
  const handleAddPermit = (permit) => {
    // Añade un permiso seleccionado
    if (!localSelectedGA.some((p) => p === permit.permit_id)) {
      setSelectedGA((prev) => [...prev, permit.id]);
    }
  };

  const handleRemovePermit = (permit) => {
    // Elimina un permiso seleccionado
    console.log(localSelectedGA)
    console.log(`Delete Permit ${permit.permit_id}`)
    console.log(permit)
    setSelectedGA((prev) => prev.filter((p) => p !== permit.permit_id));
  };

  const validateSubmitDevs = (array) => {
      

    const array2Sorted = array.slice().sort((a, b) => a.dev_id - b.dev_id);
    // console.log('[TEAMS] Dev Array validate-----', ListSel, array2Sorted );
    const result = ListSel.length === array.length && ListSel.slice().sort((a, b) => a.dev_id - b.dev_id).every(function(value, index) {
      // console.log('[TEAMS] Dev Array validate: ', value, array2Sorted[index])
      return value.dev_id === array2Sorted[index].dev_id;
  }
    )
    // console.log('[TEAMS] Dev Array validate result: ', result)

    setSameDevs(result);
  };

  const validateSubmitRoles = (array) => {
      

    const array2Sorted = array.slice().sort((a, b) => a - b);
    // console.log('[TEAMS] Array validate-----', array, localOgSelectedGAR, array2Sorted,localOgSelectedGAR.slice().sort((a, b) => a - b) );
    const result = localOgSelectedGAR.length === array.length && localOgSelectedGAR.slice().sort((a, b) => a - b).every(function(value, index) {
      // console.log('[TEAMS] Array validate: ', value, array2Sorted[index])
      return value === array2Sorted[index];
  }
    )
    // console.log('[TEAMS] Array validate result: ', result)

    setSameRoles(result);
  };



  const getOrganizations = async () => {
    try {
      const response = (await ObexRequestHandler.get('/organizations', {}, true)) || {};
      console.log('Raw response from API:', response); // Log completo de la respuesta

      if (response.success && response.data?.result && Array.isArray(response.data.result)) {
        setOrganizations(response.data.result); // Ajustar para almacenar las organizaciones
        console.log('Organizations fetched:', response.data.result);
      } else {
        console.error('Unexpected data structure from API:', response);
        setOrganizations([]); // Fallback a un array vacío si la estructura no coincide
      }
    } catch (error) {
      console.error('Error fetching organizations:', error);
      setOrganizations([]);
    }
  };


  const AMBIENT_LABELS = {
    Local: { name: 'Local', color: '#28a745' },        // Verde
    'Not Specified': { name: 'Not Specified', color: '#CE9C06' }, // Amarillo
    //3: { name: 'Live', color: '#28a745' }, // Amarillo
  
  };

  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;

    return (
      <span
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth: '100px',
          display: 'flex',
          width: '65px',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };

  const [cardholder, setCardholder] = useState('');

  const changeCardholder = (e) => {
    const newCardholder = e.target.value;
    setCardholder(newCardholder);
    setSameName(false);
  };

  const [cardnumber, setCardnumber] = useState('');
  
  const changeCardnumber = (e) => {
    const newCardnumber = e.target.value;
    setCardnumber(newCardnumber);
    setSameDec(false)
  };


  // Pre-carga la lista de developers al cargar el componente
  useEffect(() => {
    if (isEditDrawerOpen && selectedTeamId) {

      getDevelopers(selectedTeamId, activeTeam);

    } else if (isEditDrawerOpen) {

      fetchDevelopers()
    }
  }, [isEditDrawerOpen, selectedTeamId]);


  const toggleDrawer = async () => {
    // setSelectedDevelopers2([]);
    setSelectedGA([]);
    // setSelectedGAR([]);
    addListSel([]);
    addListSelP([]);
    setlistaRecuperados([]);


    //   console.log('----- () -----')
    //   console.log('[TEAMS]', selectedDevelopers2, localSelectedGAR)

    //   if (isDrawerOpen === true) { 

    //     getProjects('');
    //     fetchDevelopers();
    //     getOrganizations();

    //     console.log("--X--")

    // } 

    setIsDrawerOpen(!isDrawerOpen);
  };
  const closeDrawer = async () => {
    setSelectedDevelopers2([]);
    setSelectedGA([]);
    setSelectedGAR([]);
    addListSel([]);
    addListSelP([]);
    setlistaRecuperados([]);



    console.log('----- () -----')


    if (isDrawerOpen === true) {

      getProjects('');
      fetchDevelopers();
      getOrganizations();

      console.log("--X--")

    }

    setIsDrawerOpen(!isDrawerOpen);
  };
  const toggleEditDrawer = async () => {

    if (!isEditDrawerOpen) {
      // Cargar organizaciones antes de abrir el Drawer

      getProjects('');
      fetchDevelopers();
      getOrganizations();

      // setSelectedGAR([]);
      setSelectedGA([])
      // setSelectedDevelopers2([]);

    }
    // console.log('[TEAMS] Edit Drawe state', isEditDrawerOpen)

    history.push('/teams')
    setIsEditDrawerOpen(!isEditDrawerOpen);
    history.push('/teams')

  };


  const handleOrg = () => {
    setAddingOrg(!addingOrg);
    getProjects('');
    //document.getElementById('buscador')?.focus();
  };

  const changeFilter = (e) => {
    const newFilter = e.target.value;
    setFilter(newFilter);
    filterProjects(newFilter)
  };

  const filterProjects = async (filtro) => {

    console.log(recoveredProjects);

    const filter_projects = recoveredProjects.filter(element =>
      element.name.toLowerCase().includes(filtro.toLowerCase())
    );
    setProjects(filtro === '' ? recoveredProjects : filter_projects);

  }

  const getTeamDevelopers = async (team_id) => {

    try {
      const r = await ObexRequestHandler.get(`/devs_team?organization=${SessionService.ORGANIZATION}&team_id=${team_id}`);

      const { data } = r;
      const elements = [];
      data.forEach(element => {
        elements.push(element)
      });
      addListSel(elements)
      setSelectedDevelopers2(elements)
    } catch {

    }

  }

  const getTeamRoles = async (team_id) => {

    try {
      const r = await ObexRequestHandler.get(`/iam/get_team_roles?team_id=${team_id}`);
      const { data } = r;
      const elements = [];
      const selElements = [];
      data.forEach(element => {
        elements.push({ id: element.role_id, name: element.role_name });
        selElements.push(element.role_id);
      });
      addListSelP(elements);
      setSelectedGAR(selElements);
      setOGSelectedGAR(selElements);
    } catch {

    }

  }

  const getDevelopers = async (team_id, team_name) => {
    console.log('getDevelopers called with:', { team_id, team_name });
    try {
      setLoadDevs(true);
      setActiveTeam(team_name);
      history.push('/teams')
      const response = await ObexRequestHandler.get(
        `/team_devs?organization=${SessionService.ORGANIZATION}&team=${team_id}`,
        {},
        true
      );
      console.log('Response from /team_devs:', response);
      const developersresult = response.data || [];
      setDevelopers(developersresult);
      setNewDevelopers(developersresult);
      setSelectedTeamId(team_id)
      // setSelectedGAR([]);
      setSelectedGA([]);
      // setSelectedDevelopers2([]);
      setIsEditDrawerOpen(true); // Solo abre el Drawer cuando los datos están cargados
      history.push('/teams')
    } catch (error) {
      console.error('Error fetching developers:', error);
    } finally {
      setLoadDevs(false);
    }
  };

  const getAllDevelopers = async () => {
    //console.log("getDevelopers called with:", { team_id, team_name });
    try {
      setLoadDevs(true);
      //setActiveTeam(team_name);

      const response = await ObexRequestHandler.get(
        '/owner_organizations_devs',
        {},
        true
      );
      console.log('Response from /team_devs:', response);
      const developersresult = response.data || [];
      setDevelopers(developersresult);
      setNewDevelopers(developersresult);
      // setSelectedGAR([]);
      setSelectedGA([]);
      // setSelectedDevelopers2([]);
      setIsEditDrawerOpen(true); // Solo abre el Drawer cuando los datos están cargados
    } catch (error) {
      console.error('Error fetching developers:', error);
    } finally {
      setLoadDevs(false);
    }
  };

  const fetchDevelopers = async () => {
    try {
      setLoading(true); // Indica que los datos se están cargando
      const response = await ObexRequestHandler.get(
        `/all_developers?organization=${SessionService.ORGANIZATION}`,
        {},
        true
      );

      console.log('API Response Devs:', response); // Log completo de la respuesta

      const allDevelopers = response.data || [];
      setNewDevelopers(allDevelopers); // Almacena todos los desarrolladores en `newdevelopers`
      console.log('Parsed Developers:', allDevelopers);

    } catch (error) {
      console.error('Error fetching all developers:', error);
      setNewDevelopers([]); // Maneja el error con una lista vacía
    } finally {
      setLoading(false); // Finaliza la carga
    }
  };



  const [showDelete, setShowDelete] = useState(false);
  const [selDeveloper, setSelDeveloper] = useState({ id: 0 })
  const [selTeam, setselTeam] = useState({ name: '' });

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  }

  const handleDelete = async (developer) => {
    setShowDelete(true);
    setSelDeveloper(developer);
    setselTeam(developer);

  }





  const deleteTeam = async (teamId) => {
    try {

      handleShowDelete();
      const team = projects.find((p) => p.id === teamId);
      if (team && Number(team.project_count.toString()) != 0) {
        alert(`This team is associated with ${Number(team.project_count.toString())} projects, and cant be deleted`);
        handleShowDelete()
        return;
      }

      await ObexRequestHandler.get(
        `/delete_team?organization=${SessionService.ORGANIZATION}&team=${teamId}`,
        {},
        true
      );


      getProjects(filtro); // Actualiza la tabla después de eliminar
      history.push('/teams')
    } catch (error) {
      console.error('Error deleting team:', error);
      alert('Failed to delete the team. Please try again later.');
    }
  };



  const handleSwitch = async (e, category, id) => {
    const index = newdevelopers.findIndex((category) => category.id === id);
    const updatedDevelopers = [...newdevelopers];
    updatedDevelopers[index].checked = !updatedDevelopers[index].checked;
    setNewDevelopers(updatedDevelopers);

    if (!updatedDevelopers[index].checked) {
      await ObexRequestHandler.get(`/remove_developer_team?organization=${updatedDevelopers[index].org_id}&dev_team=${activeTeam}&email=${updatedDevelopers[index].email}&dev_id=${updatedDevelopers[index].dev_id}`, {}, true);
    } else {
      await ObexRequestHandler.get(`/add_developer_team?organization=${updatedDevelopers[index].org_id}&dev_team=${activeTeam}&email=${updatedDevelopers[index].email}`, {}, true);
    }
  };


  const categoriesList = (
    <Form>
      <ReactSortable tag="div" list={newdevelopers} setList={setNewDevelopers}>
        {newdevelopers.map((c) => (
          c.acepted ? (
            <Row key={c.id} className="my-1">
              <Col>
                <Card className="product-detail-card obex-card api-product-card">
                  <Card.Body className="py-3 px-4">
                    <Row>
                      <Col className="pl-2">
                        <FontAwesomeIcon icon={faBars} className="button-sort mr-2" size="1x" />
                        <span className="title-product">{c.email || 'No Email'}</span>
                      </Col>
                      <Col md="2" className="text-right">
                        <Form.Check
                          checked={c.checked || false}
                          type="switch"
                          id={`${c.id}`}
                          label=""
                          onChange={(e) => handleSwitch(e, c, c.id)}
                          className="obex-switch"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null
        ))}
      </ReactSortable>
    </Form>
  );

  const handleToggle = (id) => {
    const updatedDevelopers = newdevelopers.map((dev) =>
      dev.id === id ? { ...dev, checked: !dev.checked } : dev
    );
    setNewDevelopers(updatedDevelopers);
  };

  const saveDeveloperChanges = async () => {
    try {
      // Hardcodear el estado de visibilidad
      const visibilityState = visibility === 'Global' ? 'Global' : selectedOrganizations;
      const users_ids = []
      // Guardar los desarrolladores
      for (const dev of selectedDevelopers2) {
        if (dev.dev_id) users_ids.push(dev.dev_id)
      }

      // Actualizar la tabla o estado global con la visibilidad
      console.log('Visibility saved:', visibilityState);


      const payload = {
        organization: SessionService.ORGANIZATION,
        dev_team_id: selectedTeamId,
        users: users_ids,
        permits: localSelectedGA,
        roles: localSelectedGAR,
        name: cardholder,
        description: cardnumber
      }

      console.log('·~-<(PAYLOAD)>-~·')
      console.log(payload);
      console.log('·~~~~~~--~~~~~~~·')

      const r = await ObexRequestHandler.post('/update_developer_teams', payload);
      console.log(r)
      const s = await ObexRequestHandler.post('/iam/update_team_roles', payload);
      console.log(s)
      const t = await ObexRequestHandler.post('/iam/update_team_permits', payload);
      console.log(t)


      setIsEditDrawerOpen(false); // Cierra el Drawer
      getProjects(filtro); // Actualiza la tabla después de guardar
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };



  const getProjects = async (filtro) => {
    try {
      setProjects([]);
      setLoading(true);
      const projects = (await ObexRequestHandler.get(`/developer_teams?organization=${SessionService.ORGANIZATION}`, {}, false)).data || [];

      // Simula visibilidad hardcodeada
      const updatedProjects = projects.result.map((project) => ({
        ...project,
        visibility: 'Global', // O ['org1', 'org2'] si es personalizado
      }));

      setrecoveredProjects(updatedProjects);
      setLeftDevTeams(projects.max - projects.used);
      setProjects(filtro ? updatedProjects.filter((element) => element.name.toLowerCase().includes(filtro.toLowerCase())) : updatedProjects);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
    } finally {
      setLoading(false);
      setInitialized(true);
      //document.getElementById('buscador')?.focus();
    }
    setInitialized(true);
  };




  const handleSelect = (developer: any) => {
    // Evita duplicados y evita las adiciones iniciales desde el listado porque ya se carga el contenido en esta vista. La carga desde el listado es incorrecta para la veridicacion de Update
    //Los que vienen del onAdd del listado inicial, no tiene name, así se descartan aquí para no sobreescribir el contenido ya cargado.
    if (developer['email'] != undefined && !selectedDevelopers2.some((d) => d.dev_id === developer.dev_id)) {
      const updatedSelection = [...selectedDevelopers2, developer];

      validateSubmitDevs(updatedSelection)

      setSelectedDevelopers2(updatedSelection); // Actualiza la lista local
    }

  };

  const handleRemove = (developer: any) => {
    const updatedSelection = selectedDevelopers2.filter((d) => d.dev_id !== developer.dev_id);
    validateSubmitDevs(updatedSelection);
    setSelectedDevelopers2(updatedSelection);

  };



  const getTeamPermits = async (team_id) => {
    try {

      // setLoading(true);
      setlistaRecuperados([]);

      const permits = (
        await ObexRequestHandler.get(`/iam/get_team_permits?team_id=${team_id}`, {}, true)).data || [];
      const elements = [];
      permits.forEach(element => {
        elements.push({ id: element.permit_id, permit_code: element.permit_code, permit_description: element.permit_description });

      });

      setlistaRecuperados(elements);

      const selPermits = [];

      permits.forEach(element => {
        selPermits.push(element.permit_id);
      });
      setSelectedGA(selPermits);


    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }

    // setLoading(false);

  }




  const getPermits = async (filtro) => {
    try {

      //setloadDevs(true);
      // setLoading(true);
      setPermissions([]);

      const permits = (
        await ObexRequestHandler.get('/iam/get_permits', {}, true)).data || [];

      const filter_permits = permits.filter(element =>
        element.permit_code.toLowerCase().includes(filtro.toLowerCase())
      );
      setPermissions(filtro === '' ? permits : filter_permits);

      //setProjects(projects);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }

    // setLoading(false);

  }

  const getRoles = async () => {
    try {
      // setLoading(true);
      //setloadDevs(true);

      setRoles([]);
      const temp_roles = [];

      const roles = (
        await ObexRequestHandler.get(`/iam/get_roles?org_id=${SessionService.ORGANIZATION}`, {}, true)).data || [];


      roles.forEach(element => {
        temp_roles.push({ id: element.role_id, name: element.role_name })
      });

      setRoles(temp_roles);

      //setProjects(projects);
      history.push('/teams')
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }
    // setLoading(false);
  }



  document.addEventListener('updateView', (e) => {
    if (SessionService.updating > 0 && location.pathname == '/teams') {
      history.push('/teams')
      SessionService.updating = SessionService.updating - 3;
      getProjects('');
      fetchDevelopers();
      getOrganizations();
      getPermits('');
      getRoles();
      window.scrollTo(0, 0);
      history.push('/teams')
    }

  }, false);





  useEffect(() => {
    window.scrollTo(0, 0);
    getProjects('');
    fetchDevelopers();
    getOrganizations();
    getPermits('');
    getRoles();
    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
  }, []);


  return (
    <>
      <BrowserView>
        <DashLayout sider={DashRouters} active={'teams'} sublinks={[]}>
          <Row id='topHeader'>
            <Col md='8'>
              <div className='d-flex flex-column'>
                <div className="cld_wrapperTitle">
                  <img src={teamsIcon} alt="icon dashboard" />
                  <span className="cld_title w-100 margin-y-24px">
                    <span style={{ color: '#949498', fontSize: '14px' }}>IAM </span>/ Teams
                  </span>
                </div>

                {/* <span className="cld_legend w-100">Create, organize, and manage teams within your organization.</span> */}
              </div>
            </Col>
            <Col md="4" className='align-content-center'>
              {LeftDevTeams < 1 ? (
                <Button style={{ width: '225px' }} className="customButton-black btn px-4 float-right resBtn" onClick={handleOrg}>Create Team</Button>
              ) : (
                <Button style={{ width: '225px' }} className="customButton-black btn px-4 float-right resBtn" onClick={toggleDrawer}>Create Team</Button>
              )}
            </Col>
            <Col>
              <div className="customBorder"></div>
            </Col>
          </Row>

          {/* <Row id='form' className='mt-5'>
            <Col>
              <Form.Row>
                <Col md="9">
                  <Form.Control id='buscador' required type="text" value={filtro} placeholder="Search by Team name" onChange={changeFilter} />
                </Col>
                <Col md={3}>
                  <Button className="customButton-blue w-100 px-4" onClick={() => getProjects(filtro)}>
                    <FontAwesomeIcon icon={faSearch} className="mr-1" /> Search
                  </Button>
                </Col>
              </Form.Row>
            </Col>
          </Row> */}

          <Row id='table' className='mt-5'>
            <Col>
              {addingOrg && <AddTeamsSlots shown={addingOrg} onCloseAction={handleOrg} onSuccess={handleOrg} MaxDBSize={1} ActualDBSize={0} />}
              {loading ? (
                <Row style={{ textAlign: 'center' }}>
                  <Col>
                    <Spinner animation="border" style={{ marginLeft: '8px' }} />
                  </Col>
                </Row>
              ) : (
                <div className='cld_borderWrapper'>
                  <span className='cld_sectionTitle'>Teams</span>

                  <div className="searchContainer">
                    <Form.Control id='buscador' required type="text" value={filtro} placeholder="Search by Team name" onChange={changeFilter} />
                    <Button className="customButton-transparent px-4" onClick={() => getProjects(filtro)}>
                      <FontAwesomeIcon icon={faSearch} size="1x" />
                    </Button>
                  </div>

                  {(projects.length === 0 && initialized) ? (
                    <div className='nodata-wrapper'>
                      <Nodata />
                    </div>
                  ) : (
                    <Table className='cld_tableWrapper' >
                      <tbody>
                        <tr className='headerRow'>
                          <th style={{ width: '20%' }} className='textBold'>Name</th>
                          <th style={{ width: '25%' }} className='textBold'>Description</th>
                          <th style={{ width: '15%', textAlign: 'center' }} className='textBold'># Of members</th>

                          <th style={{ width: '20%', textAlign: 'center' }} className="textBold">Visibility</th>
                          <th style={{ width: '20%', textAlign: 'center' }} className='textBold'># Of projects</th>
                          <th style={{ width: '20px', textAlign: 'end' }} className='textBold'>Actions</th>
                        </tr>
                        {projects.map(project => (
                          <tr className='td_centered tableRow' key={project.id}>
                            <td className='td_centered project'>{project.name}</td>
                            <td className='td_centered desc'>{project.descripcion}</td>
                            <td className="td_centered devsItem" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '20px', backgroundColor: 'var(--color-background-from)', textAlign: 'center' }} ><div className="">{project.developers}</div></td>




                            <td style={{ textAlign: 'center' }} className="td_centered visibility">
                              {project.visibility === 'Global'
                                ? 'Local'
                                : organizations
                                  .filter((org) => project.visibility?.includes(org.id))
                                  .map((org) => org.name)
                                  .join(', ') || 'Not specified'}
                            </td>


                            <td className=" devsItem" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '20px', backgroundColor: 'var(--color-background-from)', textAlign: 'center' }} ><div className="">{project.project_count}</div></td>


                            <td className="text-right td_centered" style={{ textAlign: 'end' }}>
                              <div className="actionsWrapper">
                                {/* Botón de edición */}


                                <Dropdown className='my-auto' style={{ width: '100%', marginRight: '0px' }}>
                                  <Dropdown.Toggle className="" >
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item key={1} onClick={() => {
                                      history.push('/teams')


                                      getDevelopers(project.id, project.name);

                                    getTeamDevelopers(project.id);
                                    getTeamPermits(project.id);
                                    getTeamRoles(project.id);
                                    setCardholder(project.name);
                                    setCardnumber(project.descripcion)
                                    setSameDevs(true);
                                    setSameRoles(true);
                                    setSameName(true);
                                    setSameDec(true);
                                          
                                    history.push('/teams')
                                    
                                  }
                                  }>Edit</Dropdown.Item>
                                  {((!project.developers || project.developers === 0 )) && <Dropdown.Item key={2} onClick={() => handleDelete(project)}>Delete</Dropdown.Item>}
                                </Dropdown.Menu>
                              </Dropdown>



                                {/*
                              <a className="edit-link" onClick={() => getDevelopers(project.id, project.name)}>
                                <span className="editIcon" style={{ backgroundImage: `url(${editicon})` }}></span>
                                Edit
                              </a>

                              
                              {project.developers === 0 && (
                                <a
                                  className="edit-link ml-2"
                                  onClick={() => deleteTeam(project.id)}
                                >
                                  <span className="editIcon" style={{ backgroundImage: `url(${deleteIcon})` }}></span>
                                  Delete
                                </a>
                              )} */}
                              </div>
                            </td>
                          </tr>
                        ))}
                        {/*}
                  {projects.length < 3 &&
                    <div style={{ height:'65px' }}></div>
                  }
*/}

                      </tbody>
                    </Table>
                  )}
                </div>
              )}
            </Col>
          </Row>

          <Drawer index='teams' isOpen={isDrawerOpen} toggleSidebar={toggleDrawer} name="Create Team" subtitle=''>
            <AddTeamModal handleClose={closeDrawer} organization={SessionService.ORGANIZATION} developers={newdevelopers} isOpened={isDrawerOpen} />
          </Drawer>

          <Drawer
            index='teams'
            isOpen={isEditDrawerOpen}

            toggleSidebar={toggleEditDrawer}
            name="Edit Team"
            subtitle=""
          >

                      <Form.Row className="mb-3">
                        <Form.Label style={{fontSize:'16px'}} column sm="12" className="obex-form-label">
                          Organization Name
                        </Form.Label>
                        <Form.Control
                          id="entrada"
                          required
                          type="text"
                          placeholder="Name"
                          value={cardholder /*|| props.organization_name*/}
                          onChange={changeCardholder}
                        />
                      </Form.Row>
                      <Form.Row className="mb-3">
                        <Form.Label column sm="12" className="obex-form-label">
                          Organization Description
                        </Form.Label>
                        <Col md="12">
                          <Form.Control
                            required
                            type="text"
                            maxLength={40}
                            value={cardnumber /*|| props.organization_description*/}
                            placeholder="Description"
                            onChange={changeCardnumber}
                          />
                        </Col>
                      </Form.Row>


            {loadDevs ? (
              <Row style={{ textAlign: 'center' }}>
                <Col>
                  <Spinner animation="border" />
                </Col>
              </Row>
            ) : (
              <Form>
                {/* Sección de desarrolladores */}
                <Form.Label>
                  Users
                </Form.Label>
                <CustomDeveloperSelect
                  selDevelopers={ListSel}
                  developers={newdevelopers} // Pasa los developers disponibles
                  onAdd={(developer) => { handleSelect(developer); console.log('[TEAMS] Added developer:', developer) }} // Callback al añadir
                  onRemove={(developer) => { handleRemove(developer); console.log('[TEAMS] Removed developer:', developer) }} // Callback al eliminar
                  opened={isEditDrawerOpen}
                />

                {/*}
                
                <div className="visibility-section mt-4">
                  <h5 style={{fontSize:'16px'}} className="mb-3">Visibility</h5>
                 
                  <Form.Check
                    type="switch"
                    id="visibility-global"
                    label="Global"
                    checked={visibility === "Global"}
                    onChange={(e) => {
                      const isGlobal = e.target.checked;
                      if (isGlobal) {
                        setVisibility("Global");
                        setSelectedOrganizations([]); // Limpia organizaciones seleccionadas
                      } else {
                        setVisibility("Custom"); // Cambia a visibilidad personalizada
                      }
                    }}
                    className="mb-3"
                  />

                  
                  {visibility === "Global" && (
                    <p style={{fontSize:'12px'}} className="text-muted">Global visibility is enabled for all organizations.</p>
                  )}

                  
                  {visibility !== "Global" && (
                    <div className="organization-toggles">
                      {organizations.length > 0 ? (
                        organizations.map((org) => (
                          <Form.Check
                            key={org.id}
                            type="switch"
                            id={`visibility-${org.id}`}
                            label={org.name || 'Unnamed Organization'}
                            checked={selectedOrganizations.includes(org.id)}
                            onChange={(e) => {
                              const updatedSelection = e.target.checked
                                ? [...selectedOrganizations, org.id]
                                : selectedOrganizations.filter((id) => id !== org.id);

                              setSelectedOrganizations(updatedSelection);
                              setVisibility("Custom"); // Asegúrate de mantener visibilidad personalizada
                            }}
                          />
                        ))
                      ) : (
                        <p className="text-muted">No organizations available</p>
                      )}
                    </div>
                  )}
                </div>


*/}

                {/*}
              <Form.Label style={{ marginTop:'1em' }}>Permit's List</Form.Label>
              <GenericSelectableList
                items={permissions} 
                searchKeys={['permit_code', 'description']} // Búsqueda por código o descripción
                onAdd={(permit) => setSelectedGA((prev) => [...prev, permit.id])} 
                onRemove={(permit) =>
                  setSelectedGA((prev) => prev.filter((r) => r !== permit.id))}
                renderLabel={(permit) => `${permit.permit_code} (${permit.permit_description})`}
                placeholder="Select permissions..."
                multiOption={true} // Permitir selección múltiple
                selectedItems={listaRecuperados}
                setSelectedItems={setlistaRecuperados}
                itemSelected={''}
              />
    */}


                <Form.Group controlId="selectGA" className="mt-3 mb-3">
                  <Form.Label>Roles</Form.Label>
                  <GenericSelectableList
                    items={roles}
                    searchKeys={['name']}
                    onAdd={(role) => {
                      validateSubmitRoles([...localSelectedGAR, role.id]);
                      setSelectedGAR((prev) => [...prev, role.id])
                    }}
                    onRemove={(role) => {
                      validateSubmitRoles(localSelectedGAR.filter((r) => r !== role.id));
                      setSelectedGAR((prev) => prev.filter((r) => r !== role.id))
                    }
                    }
                    renderLabel={(role) => role.name}
                    placeholder="Search roles..."
                    multiOption={true} // Selección múltiple
                    selectedItems={ListSelP}
                    setSelectedItems={addListSelP}
                    itemSelected={''}
                  />
                </Form.Group>

                {/* Botón para guardar */}
                <Row>
                  <Col md='6'>
                    <Button onClick={saveDeveloperChanges} variant='primary'
                      disabled={!(!sameDevs || !sameRoles || !sameName || !sameDec) /*localSelectedGAR.length==0 && selectedDevelopers2.length==0*/}
                      className="w-100 mt-4">
                      Update
                      {loading && <Spinner animation="border" size="sm" />}
                    </Button>
                  </Col>
                </Row>

              </Form>
            )}
          </Drawer>


          <ConfirmDeletetModal
            handleSubmit={() => deleteTeam(selDeveloper.id)}
            showModal={showDelete}
            onCloseModal={handleShowDelete}
            loading={false}
            error={''}
            success={''}
            name_to_check={'DELETE'}
            title_to_show={`Confirm delete team ${selTeam.name}`}
          />


        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  );
};

export default OrganizationTeams;
