import React from "react";
import Introduction from "../../newViews/Docs/0_Introduction";
import Billing_System from "../../newViews/Docs/1_Billing_System";
import Add_Ons from "../../newViews/Docs/2_Add_Ons";
import Currencies_And_Fx_Exchange from "../../newViews/Docs/3_Currencies_And_Fx_Exchange";
import Communities from "../../newViews/Docs/4_Communities";
import Marketplace from "../../newViews/Docs/5_Marketplace";
import How_To_Work_With_Point_Redemption from "../../newViews/Docs/6_How_To_Work_With_Point_Redemption";
import Plan_Wallets from "../../newViews/Docs/7_Plan_Wallets";
import Encrypted_Nodes_Or_Projects from "../../newViews/Docs/8_Encrypted_Nodes_Or_Projects";
import Accesos_EN from "../../newViews/Docs/1_tools/0_Accesos_EN/Accesos_EN";
import Intro_1 from "../../newViews/Docs/intro1";
import Intro_2 from "../../newViews/Docs/intro2";
import Intro_3 from "../../newViews/Docs/intro3";
import Intro_4 from "../../newViews/Docs/intro4";
import Intro_5 from "../../newViews/Docs/intro5";
import Intro_6 from "../../newViews/Docs/intro6";
import Intro_7 from "../../newViews/Docs/intro7";
import Intro_8 from "../../newViews/Docs/intro8";
import Intro_9 from "../../newViews/Docs/intro9";
import Intro_10 from "../../newViews/Docs/intro10";
import Intro_11 from "../../newViews/Docs/intro11";
import Intro_12 from "../../newViews/Docs/intro12";
import Intro_13 from "../../newViews/Docs/intro13";
import Intro_14 from "../../newViews/Docs/intro14";

import Chapter1_1 from "../../newViews/Docs/chapter1_1";
import Chapter1_2 from "../../newViews/Docs/chapter1_2";
import Chapter1_3 from "../../newViews/Docs/chapter1_3";
import Chapter1_4 from "../../newViews/Docs/chapter1_4";
import Chapter1_5 from "../../newViews/Docs/chapter1_5";
import Chapter1_6 from "../../newViews/Docs/chapter1_6";
import Chapter1_7 from "../../newViews/Docs/chapter1_7";
import Chapter1_8 from "../../newViews/Docs/chapter1_8";

import Chapter2_1 from "../../newViews/Docs/chapter2_1";
import Chapter2_2 from "../../newViews/Docs/chapter2_2";
import Chapter2_3 from "../../newViews/Docs/chapter2_3";
import Chapter2_4 from "../../newViews/Docs/chapter2_4";
import Chapter2_5 from "../../newViews/Docs/chapter2_5";
import Chapter2_6 from "../../newViews/Docs/chapter2_6";
import Chapter3_1 from "../../newViews/Docs/chapter3_1";
import Chapter3_2 from "../../newViews/Docs/chapter3_2";
import Chapter3_3 from "../../newViews/Docs/chapter3_3";
import Chapter3_4 from "../../newViews/Docs/chapter3_4";
import Chapter3_5 from "../../newViews/Docs/chapter3_5";
import Chapter3_6 from "../../newViews/Docs/chapter3_6";
import Chapter4_1 from "../../newViews/Docs/chapter4_1";
import Chapter4_2 from "../../newViews/Docs/chapter4_2";
import Chapter4_3 from "../../newViews/Docs/chapter4_3";
import Chapter4_4 from "../../newViews/Docs/chapter4_4";
import Chapter4_5 from "../../newViews/Docs/chapter4_5";
import Chapter4_6 from "../../newViews/Docs/chapter4_6";
import Chapter5_1 from "../../newViews/Docs/chapter5_1";
import Chapter5_2 from "../../newViews/Docs/chapter5_2";
import Chapter5_3 from "../../newViews/Docs/chapter5_3";
import Chapter5_4 from "../../newViews/Docs/chapter5_4";
import Chapter5_5 from "../../newViews/Docs/chapter5_5";
import Chapter5_6 from "../../newViews/Docs/chapter5_6";
import Chapter5_7 from "../../newViews/Docs/chapter5_7";
import Chapter5_8 from "../../newViews/Docs/chapter5_8";
import Chapter5_9 from "../../newViews/Docs/chapter5_9";
import Chapter5_10 from "../../newViews/Docs/chapter5_10";
import Chapter5_11 from "../../newViews/Docs/chapter5_11";
import Chapter5_12 from "../../newViews/Docs/chapter5_12";
import Chapter6_1 from "../../newViews/Docs/chapter6_1";
import Chapter6_2 from "../../newViews/Docs/chapter6_2";
import Chapter6_3 from "../../newViews/Docs/chapter6_3";
import Chapter6_4 from "../../newViews/Docs/chapter6_4";
import Chapter6_5 from "../../newViews/Docs/chapter6_5";
import Chapter6_6 from "../../newViews/Docs/chapter6_6";
import Chapter6_7 from "../../newViews/Docs/chapter6_7";
import Chapter6_8 from "../../newViews/Docs/chapter6_8";
import Chapter6_9 from "../../newViews/Docs/chapter6_9";
import Chapter6_10 from "../../newViews/Docs/chapter6_10";

import Chapter7_1 from "../../newViews/Docs/chapter7_1";
import Chapter7_2 from "../../newViews/Docs/chapter7_2";
import Chapter7_3 from "../../newViews/Docs/chapter7_3";
import Chapter7_4 from "../../newViews/Docs/chapter7_4";
import Chapter7_5 from "../../newViews/Docs/chapter7_5";
import Chapter7_6 from "../../newViews/Docs/chapter7_6";

import Chapter8_1 from "../../newViews/Docs/chapter8_1";
import Chapter8_2 from "../../newViews/Docs/chapter8_2";
import Chapter8_3 from "../../newViews/Docs/chapter8_3";
import Chapter8_4 from "../../newViews/Docs/chapter8_4";
import Chapter8_5 from "../../newViews/Docs/chapter8_5";
import Chapter8_6 from "../../newViews/Docs/chapter8_6";

import Chapter9_1 from "../../newViews/Docs/chapter9_1";
import Chapter9_2 from "../../newViews/Docs/chapter9_2";
import Chapter9_3 from "../../newViews/Docs/chapter9_3";
import Chapter9_4 from "../../newViews/Docs/chapter9_4";
import Chapter9_5 from "../../newViews/Docs/chapter9_5";
import Chapter9_6 from "../../newViews/Docs/chapter9_6";
import Chapter9_7 from "../../newViews/Docs/chapter9_7";
import Chapter9_8 from "../../newViews/Docs/chapter9_8";
import Chapter9_9 from "../../newViews/Docs/chapter9_9";
import Chapter9_10 from "../../newViews/Docs/chapter9_10";
import Chapter9_11 from "../../newViews/Docs/chapter9_11";
import Chapter9_12 from "../../newViews/Docs/chapter9_12";
import Chapter9_13 from "../../newViews/Docs/chapter9_13";
import Chapter9_14 from "../../newViews/Docs/chapter9_14";
import Chapter9_15 from "../../newViews/Docs/chapter9_15";
import Chapter9_16 from "../../newViews/Docs/chapter9_16";
import Chapter9_17 from "../../newViews/Docs/chapter9_17";
import Chapter9_18 from "../../newViews/Docs/chapter9_18";
import Chapter9_19 from "../../newViews/Docs/chapter9_19";
import Chapter9_20 from "../../newViews/Docs/chapter9_20";
import Chapter9_21 from "../../newViews/Docs/chapter9_21";
import Chapter9_22 from "../../newViews/Docs/chapter9_22";
import Chapter9_23 from "../../newViews/Docs/chapter9_23";
import Chapter9_24 from "../../newViews/Docs/chapter9_24";
import Chapter9_25 from "../../newViews/Docs/chapter9_25";
import Chapter9_26 from "../../newViews/Docs/chapter9_26";
import Chapter9_27 from "../../newViews/Docs/chapter9_27";
import Chapter9_28 from "../../newViews/Docs/chapter9_28";
import Chapter9_29 from "../../newViews/Docs/chapter9_29";
import Chapter9_30 from "../../newViews/Docs/chapter9_30";
import Chapter9_31 from "../../newViews/Docs/chapter9_31";
import Chapter9_32 from "../../newViews/Docs/chapter9_32";
import Chapter9_33 from "../../newViews/Docs/chapter9_33";
import Chapter9_34 from "../../newViews/Docs/chapter9_34";
import Chapter9_34_1 from "../../newViews/Docs/chapter9_34_1";
import Chapter9_35 from "../../newViews/Docs/chapter9_35";
import Chapter9_36 from "../../newViews/Docs/chapter9_36";
import Chapter9_37 from "../../newViews/Docs/chapter9_37";
import Chapter9_38 from "../../newViews/Docs/chapter9_38";
import Chapter9_39 from "../../newViews/Docs/chapter9_39";
import Chapter9_40 from "../../newViews/Docs/chapter9_40";
import Chapter9_41 from "../../newViews/Docs/chapter9_41";
import Chapter9_42 from "../../newViews/Docs/chapter9_42";
import Chapter9_43 from "../../newViews/Docs/chapter9_43";
import Chapter9_44 from "../../newViews/Docs/chapter9_44";
import Chapter9_45 from "../../newViews/Docs/chapter9_45";
import Chapter9_46 from "../../newViews/Docs/chapter9_46";
import Chapter9_47 from "../../newViews/Docs/chapter9_47";
import Chapter9_48 from "../../newViews/Docs/chapter9_48";
import Chapter9_49 from "../../newViews/Docs/chapter9_49";
import Chapter9_50 from "../../newViews/Docs/chapter9_50";
import Chapter9_51 from "../../newViews/Docs/chapter9_51";

import Chapter10_1 from "../../newViews/Docs/chapter10_1";
import Chapter10_2 from "../../newViews/Docs/chapter10_2";
import Chapter10_3 from "../../newViews/Docs/chapter10_3";
import Chapter10_4 from "../../newViews/Docs/chapter10_4";
import Chapter10_5 from "../../newViews/Docs/chapter10_5";

import Chapter11_1 from "../../newViews/Docs/chapter11_1";
import Chapter11_2 from "../../newViews/Docs/chapter11_2";
import Chapter11_3 from "../../newViews/Docs/chapter11_3";
import Chapter11_4 from "../../newViews/Docs/chapter11_4";
import Chapter11_5 from "../../newViews/Docs/chapter11_5";

import Chapter12_1 from "../../newViews/Docs/chapter12_1";
import Chapter12_2 from "../../newViews/Docs/chapter12_2";
import Chapter12_3 from "../../newViews/Docs/chapter12_3";
import Chapter12_4 from "../../newViews/Docs/chapter12_4";
import Chapter12_5 from "../../newViews/Docs/chapter12_5";
import Chapter12_6 from "../../newViews/Docs/chapter12_6";

import Chapter13_1 from "../../newViews/Docs/chapter13_1";
import Chapter13_2 from "../../newViews/Docs/chapter13_2";
import Chapter13_3 from "../../newViews/Docs/chapter13_3";
import Chapter13_4 from "../../newViews/Docs/chapter13_4";
import Chapter13_5 from "../../newViews/Docs/chapter13_5";
import Chapter13_6 from "../../newViews/Docs/chapter13_6";
import Chapter13_7 from "../../newViews/Docs/chapter13_7";
import Chapter13_8 from "../../newViews/Docs/chapter13_8";
import Chapter13_9 from "../../newViews/Docs/chapter13_9";

import Chapter14_1 from "../../newViews/Docs/chapter14_1";
import Chapter14_2 from "../../newViews/Docs/chapter14_2";
import Chapter14_3 from "../../newViews/Docs/chapter14_3";
import Chapter14_4 from "../../newViews/Docs/chapter14_4";
import Chapter14_5 from "../../newViews/Docs/chapter14_5";

import Appendices_1 from "../../newViews/Docs/appendices_1";
import Appendices_2 from "../../newViews/Docs/appendices_2";
import Appendices_3 from "../../newViews/Docs/appendices_3";
import Appendices_4 from "../../newViews/Docs/appendices_4";
import Appendices_5 from "../../newViews/Docs/appendices_5";
import Appendices_6 from "../../newViews/Docs/appendices_6";
import Appendices_7 from "../../newViews/Docs/appendices_7";
import Appendices_8 from "../../newViews/Docs/appendices_8";
import Appendices_9 from "../../newViews/Docs/appendices_9";
import Appendices_10 from "../../newViews/Docs/appendices_10";
import Appendices_11 from "../../newViews/Docs/appendices_11";
import Appendices_12 from "../../newViews/Docs/appendices_12";
import Appendices_13 from "../../newViews/Docs/appendices_13";
import Appendices_14 from "../../newViews/Docs/appendices_14";
import Appendices_15 from "../../newViews/Docs/appendices_15";
import Appendices_16 from "../../newViews/Docs/appendices_16";
import Appendices_17 from "../../newViews/Docs/appendices_17";
import Appendices_18 from "../../newViews/Docs/appendices_18";
import Appendices_19 from "../../newViews/Docs/appendices_19";
import Appendices_20 from "../../newViews/Docs/appendices_20";
import Appendices_21 from "../../newViews/Docs/appendices_21";
import Appendices_22 from "../../newViews/Docs/appendices_22";
import Appendices_23 from "../../newViews/Docs/appendices_23";
import Appendices_24 from "../../newViews/Docs/appendices_24";





/*
import Chapter7_1 from "../../newViews/Docs/chapter7_1";
import Chapter7_2 from "../../newViews/Docs/chapter7_2";
import Chapter7_3 from "../../newViews/Docs/chapter7_3";
import Chapter7_4 from "../../newViews/Docs/chapter7_4";
import Chapter7_5 from "../../newViews/Docs/chapter7_5";
import Chapter7_6 from "../../newViews/Docs/chapter7_6";
import Chapter7_7 from "../../newViews/Docs/chapter7_7";
import Chapter7_8 from "../../newViews/Docs/chapter7_8";
import Chapter7_9 from "../../newViews/Docs/chapter7_9";
import Chapter7_10 from "../../newViews/Docs/chapter7_10";

import Chapter8_1 from "../../newViews/Docs/chapter8_1";
import Chapter8_2 from "../../newViews/Docs/chapter8_2";
import Chapter8_3 from "../../newViews/Docs/chapter8_3";
import Chapter8_4 from "../../newViews/Docs/chapter8_4";
import Chapter8_5 from "../../newViews/Docs/chapter8_5";
import Chapter8_6 from "../../newViews/Docs/chapter8_6";

import Chapter10_1 from "../../newViews/Docs/chapter10_1";
import Chapter10_2 from "../../newViews/Docs/chapter10_2";
import Chapter10_3 from "../../newViews/Docs/chapter10_3";
import Chapter10_4 from "../../newViews/Docs/chapter10_4";
import Chapter10_5 from "../../newViews/Docs/chapter10_5";

import Chapter11_1 from "../../newViews/Docs/chapter11_1";
import Chapter11_2 from "../../newViews/Docs/chapter11_2";
import Chapter11_3 from "../../newViews/Docs/chapter11_3";
import Chapter11_4 from "../../newViews/Docs/chapter11_4";
import Chapter11_5 from "../../newViews/Docs/chapter11_5";

import Chapter12_1 from "../../newViews/Docs/chapter12_1";
import Chapter12_2 from "../../newViews/Docs/chapter12_2";
import Chapter12_3 from "../../newViews/Docs/chapter12_3";
import Chapter12_4 from "../../newViews/Docs/chapter12_4";
import Chapter12_5 from "../../newViews/Docs/chapter12_5";
import Chapter12_6 from "../../newViews/Docs/chapter12_6";


import Chapter13_1 from "../../newViews/Docs/chapter13_1";
import Chapter13_2 from "../../newViews/Docs/chapter13_2";
import Chapter13_3 from "../../newViews/Docs/chapter13_3";
import Chapter13_4 from "../../newViews/Docs/chapter13_4";
import Chapter13_5 from "../../newViews/Docs/chapter13_5";
import Chapter13_6 from "../../newViews/Docs/chapter13_6";
import Chapter13_7 from "../../newViews/Docs/chapter13_7";
import Chapter13_8 from "../../newViews/Docs/chapter13_8";
import Chapter13_9 from "../../newViews/Docs/chapter13_9";

import Chapter14_1 from "../../newViews/Docs/chapter14_1";
import Chapter14_2 from "../../newViews/Docs/chapter14_2";
import Chapter14_3 from "../../newViews/Docs/chapter14_3";
import Chapter14_4 from "../../newViews/Docs/chapter14_4";
import Chapter14_5 from "../../newViews/Docs/chapter14_5";
*/

const routerDocs =
{
    "showFirst":{
        "id": "Introduction",
        "links": [
            {
                "path": "Introduction",
                "title": "Introduction",
                "view":<Intro_1/>
            }
        ],
        "title": "Introduction"
    },
    "menus": [
        {
            "id": "facturation system",
            "links": [

                {
                    "path": "Facturation_System_EN12",
                    "title": "Discovering AVAP",
                    "view":<Intro_2/>
                },
                {
                    "path": "Facturation_System_EN13",
                    "title": "What Makes AVAP Special?",
                    "view":<Intro_3/>
                },
                {
                    "path": "Facturation_System_EN11",
                    "title": "What Will You Find in This Book?",
                    "view":<Intro_4/>
                },
                {
                    "path": "Facturation_System_EN10",
                    "title": "Are You Ready to Get Started?",
                    "view":<Intro_5/>
                },
                {
                    "path": "Facturation_System_EN9",
                    "title": "The Virtuality Attribute in AVAP",
                    "view":<Intro_6/>
                },
                {
                    "path": "Facturation_System_EN8",
                    "title": "Virtuality Principle in AVAP",
                    "view":<Intro_7/>
                },
                {
                    "path": "Facturation_System_EN7",
                    "title": "Language Specifications Decoupled from the Interpreter",
                    "view":<Intro_8/>
                },
                {
                    "path": "Facturation_System_EN6",
                    "title": "Dynamic Code Construction in Real-Time",
                    "view":<Intro_9/>
                },
                {
                    "path": "Facturation_System_EN5",
                    "title": "Development of Dynamic Virtual APIs",
                    "view":<Intro_10/>
                },                {
                    "path": "Facturation_System_EN4",
                    "title": "Benefits of the Virtuality Attribute",
                    "view":<Intro_11/>
                },
                {
                    "path": "Facturation_System_EN3",
                    "title": "Interaction with Artificial Intelligence",
                    "view":<Intro_12/>
                },
                {
                    "path": "Facturation_System_EN2",
                    "title": "Access to Databases",
                    "view":<Intro_13/>
                },
                {
                    "path": "Facturation_System_EN1",
                    "title": "Conclusions",
                    "view":<Intro_14/>
                },
            ],
            "title": "Introduction"
        },
        {
            "id": "add ons",
            "links": [
                {
                    "path": "add onsDiscover",
                    "title": "Dynamic Programming Language",
                    "view":<Chapter1_1/>
                },
                {
                    "path": "add onsWhat",
                    "title": "Features of AVAP™ as a Dynamic Language",
                    "view":<Chapter1_2/>
                },
                {
                    "path": "add onBook",
                    "title": "Dynamic Typing",
                    "view":<Chapter1_3/>
                },
                {
                    "path": "add onStarted",
                    "title": "Automatic Memory Management",
                    "view":<Chapter1_4/>
                },
                {
                    "path": "add onAttributes",
                    "title": "Runtime Interpreter: Dynamic Code Construction",
                    "view":<Chapter1_5/>
                },
                {
                    "path": "add onPriciple",
                    "title": "Flexibility in Programming",
                    "view":<Chapter1_6/>
                },
                {
                    "path": "add onAttributes 1",
                    "title": "Advantages of AVAP™ as a Dynamic Language",
                    "view":<Chapter1_7/>
                },
                {
                    "path": "add onPriciple 1",
                    "title": "Summary",
                    "view":<Chapter1_8/>
                },
            ],
            "title": "Dynamic Programming Language"
        },
        {
            "id": "currencies and fx exchange",
            "links": [
                {
                    "path": "currencies and fx exchange 1",
                    "title": "Notation in AVAP",
                    "view":<Chapter2_1/>
                },
                {
                    "path": "currencies and fx exchange 2",
                    "title": "General Conventions",
                    "view":<Chapter2_2/>
                },
                {
                    "path": "currencies and fx exchange 3",
                    "title": "Specific Notation Rules",
                    "view":<Chapter2_3/>
                },
                {
                    "path": "currencies and fx exchange 4",
                    "title": "Explanation",
                    "view":<Chapter2_4/>
                },
                {
                    "path": "currencies and fx exchange 5",
                    "title": "Practical Example",
                    "view":<Chapter2_5/>
                },
                {
                    "path": "currencies and fx exchange 6",
                    "title": "Conclusions",
                    "view":<Chapter2_6/>
                },
            ],
            "title": "Notation"
        },
        {
            "id": "chapter 3",
            "links": [
                {
                    "path": "chapter 3 1",
                    "title": "Lexical Analysis",
                    "view":<Chapter3_1/>
                },
                {
                    "path": "chapter 3 2",
                    "title": "Lexical Components in AVAP",
                    "view":<Chapter3_2/>
                },
                {
                    "path": "chapter 3 3",
                    "title": "Lexical Analysis Process",
                    "view":<Chapter3_3/>
                },
                {
                    "path": "chapter 3 4",
                    "title": "Keywords",
                    "view":<Chapter3_4/>
                },
                {
                    "path": "chapter 3 5",
                    "title": "Practical Example",
                    "view":<Chapter3_5/>
                },
                {
                    "path": "chapter 3 6",
                    "title": "Conclusions",
                    "view":<Chapter3_6/>
                },

            ],
            "title": "Lexics"
        },
        {
            "id": "chapter 4",
            "links": [
                {
                    "path": "chapter 4 1",
                    "title": "Data Model",
                    "view":<Chapter4_1/>
                },
                {
                    "path": "chapter 4 2",
                    "title": "Data Types",
                    "view":<Chapter4_2/>
                },
                {
                    "path": "chapter 4 3",
                    "title": "Data Structures",
                    "view":<Chapter4_3/>
                },
                {
                    "path": "chapter 4 4",
                    "title": "Operations and Methods",
                    "view":<Chapter4_4/>
                },
                {
                    "path": "chapter 4 5",
                    "title": "Practical Example",
                    "view":<Chapter4_5/>
                },
                {
                    "path": "chapter 4 6",
                    "title": "Conclusions",
                    "view":<Chapter4_6/>
                },

            ],
            "title": "Data model"
        },
        {
            "id": "chapter 5",
            "links": [
                {
                    "path": "chapter 5 1",
                    "title": "Data Types",
                    "view":<Chapter5_1/>
                },
                {
                    "path": "chapter 5 2",
                    "title": "Basic Data Types",
                    "view":<Chapter5_2/>
                },
                {
                    "path": "chapter 5 3",
                    "title": "Integers (int)",
                    "view":<Chapter5_3/>
                },
                {
                    "path": "chapter 5 4",
                    "title": "Floating-Point Numbers (float)",
                    "view":<Chapter5_4/>
                },
                {
                    "path": "chapter 5 5",
                    "title": "Strings (str)",
                    "view":<Chapter5_5/>
                },
                {
                    "path": "chapter 5 6",
                    "title": "Booleans (bool)",
                    "view":<Chapter5_6/>
                },
                {
                    "path": "chapter 5 7",
                    "title": "Conversion Between Data Types",
                    "view":<Chapter5_7/>
                },
                {
                    "path": "chapter 5 8",
                    "title": "Conversion to Integer",
                    "view":<Chapter5_8/>
                },
                {
                    "path": "chapter 5 9",
                    "title": "Conversion to Floating-Point",
                    "view":<Chapter5_9/>
                },
                {
                    "path": "chapter 5 10",
                    "title": "Conversion to String",
                    "view":<Chapter5_10/>
                },
                {
                    "path": "chapter 5 11",
                    "title": "Operations with Data Types",
                    "view":<Chapter5_11/>
                },
                {
                    "path": "chapter 5 12",
                    "title": "Summary",
                    "view":<Chapter5_12/>
                },

            ],
            "title": "Data types"
        },
        {
            "id": "chapter 6",
            "links": [
                {
                    "path": "chapter 6 1",
                    "title": "Working with Variables",
                    "view":<Chapter6_1/>
                },
                {
                    "path": "chapter 6 2",
                    "title": "Importance of Variables",
                    "view":<Chapter6_2/>
                },

                {
                    "path": "chapter 6 3",
                    "title": "Types of Variables in AVAP",
                    "view":<Chapter6_3/>
                },
                {
                    "path": "chapter 6 4",
                    "title": "Local Variables",
                    "view":<Chapter6_4/>
                },
                {
                    "path": "chapter 6 5",
                    "title": "Global Variables",
                    "view":<Chapter6_5/>
                },
                {
                    "path": "chapter 6 6",
                    "title": "Declaration of Variables in AVAP",
                    "view":<Chapter6_6/>
                },
                {
                    "path": "chapter 6 7",
                    "title": "addVar() Function",
                    "view":<Chapter6_7/>
                },
                {
                    "path": "chapter 6 8",
                    "title": "Direct Declaration",
                    "view":<Chapter6_8/>
                },
                {
                    "path": "chapter 6 9",
                    "title": "Direct Initialization",
                    "view":<Chapter6_9/>
                },
                {
                    "path": "chapter 6 10",
                    "title": "Summary",
                    "view":<Chapter6_10/>
                },
            ],
            "title": "Working with Variables"
        },
        {
            "id": "Chapter 7",
            "links": [
                {
                    "path": "chapter 7 1",
                    "title": "How to Work with Comments",
                    "view":<Chapter7_1/>
                },
                {
                    "path": "chapter 7 2",
                    "title": "Line Comments",
                    "view":<Chapter7_2/>
                },

                {
                    "path": "chapter 7 3",
                    "title": "Block Comments",
                    "view":<Chapter7_3/>
                },
                {
                    "path": "chapter 7 4",
                    "title": "Documentation Comments",
                    "view":<Chapter7_4/>
                },
                {
                    "path": "chapter 7 5",
                    "title": "Best Practices",
                    "view":<Chapter7_5/>
                },
                {
                    "path": "chapter 7 6",
                    "title": "Summary",
                    "view":<Chapter7_6/>
                },
                
            ],
            "title": "How to Work with Comments"
        },

        {
            "id": "Chapter 8",
            "links": [
                {
                    "path": "chapter 8 1",
                    "title": "Expressions in AVAP",
                    "view":<Chapter8_1/>
                },
                {
                    "path": "chapter 8 2",
                    "title": "Types of Expressions",
                    "view":<Chapter8_2/>
                },

                {
                    "path": "chapter 8 3",
                    "title": "Operators",
                    "view":<Chapter8_3/>
                },
                {
                    "path": "chapter 8 4",
                    "title": "Working with Lists",
                    "view":<Chapter8_4/>
                },
                {
                    "path": "chapter 8 5",
                    "title": "Practical Example",
                    "view":<Chapter8_5/>
                },
                {
                    "path": "chapter 8 6",
                    "title": "Conclusions",
                    "view":<Chapter8_6/>
                },
                
            ],
            "title": "Expressions in AVAP"
        },

        {
            "id": "Chapter 9",
            "links": [
                {
                    "path": "chapter 9 1",
                    "title": "Execution Model in AVAP",
                    "view": <Chapter9_1/>
                },
                {
                    "path": "chapter 9 2",
                    "title": "Names and Bindings",
                    "view": <Chapter9_2/>
                },
                {
                    "path": "chapter 9 3",
                    "title": "Name Resolution",
                    "view": <Chapter9_3/>
                },
                {
                    "path": "chapter 9 4",
                    "title": "Importing Files",
                    "view": <Chapter9_4/>
                },
                {
                    "path": "chapter 9 5",
                    "title": "Exceptions",
                    "view": <Chapter9_5/>
                },
                {
                    "path": "chapter 9 6",
                    "title": "The Import System in AVAP",
                    "view": <Chapter9_6/>
                },
                {
                    "path": "chapter 9 7",
                    "title": "Import Rules",
                    "view": <Chapter9_7/>
                },
                {
                    "path": "chapter 9 8",
                    "title": "Limitations and Considerations",
                    "view": <Chapter9_8/>
                },
                {
                    "path": "chapter 9 9",
                    "title": "Advanced Example",
                    "view": <Chapter9_9/>
                },
                {
                    "path": "chapter 9 10",
                    "title": "Expressions in AVAP",
                    "view": <Chapter9_10/>
                },
                {
                    "path": "chapter 9 11",
                    "title": "Arithmetic Conversions",
                    "view": <Chapter9_11/>
                },
                {
                    "path": "chapter 9 12",
                    "title": "Atoms",
                    "view": <Chapter9_12/>
                },
                {
                    "path": "chapter 9 13",
                    "title": "Identifiers (Names)",
                    "view": <Chapter9_13/>
                },
                {
                    "path": "chapter 9 14",
                    "title": "Literals",
                    "view": <Chapter9_14/>
                },
                {
                    "path": "chapter 9 15",
                    "title": "Parenthesized Forms",
                    "view": <Chapter9_15/>
                },
                {
                    "path": "chapter 9 16",
                    "title": "Comprehensions for Lists, Sets and Dictionaries",
                    "view": <Chapter9_16/>
                },
                {
                    "path": "chapter 9 17",
                    "title": "List Displays",
                    "view": <Chapter9_17/>
                },
                {
                    "path": "chapter 9 18",
                    "title": "Set Displays",
                    "view": <Chapter9_18/>
                },
                {
                    "path": "chapter 9 19",
                    "title": "Dictionary Displays",
                    "view": <Chapter9_19/>
                },
                {
                    "path": "chapter 9 20",
                    "title": "Slices",
                    "view": <Chapter9_20/>
                },
                {
                    "path": "chapter 9 21",
                    "title": "Calls",
                    "view": <Chapter9_21/>
                },
                {
                    "path": "chapter 9 22",
                    "title": "Implementation Details in AVAP",
                    "view": <Chapter9_22/>
                },
                {
                    "path": "chapter 9 23",
                    "title": "Usage Example",
                    "view": <Chapter9_23/>
                },
                {
                    "path": "chapter 9 24",
                    "title": "Binary Arithmetic Operations",
                    "view": <Chapter9_24/>
                },
                {
                    "path": "chapter 9 25",
                    "title": "Shift Operations",
                    "view": <Chapter9_25/>
                },
                {
                    "path": "chapter 9 26",
                    "title": "Binary Bitwise Operations",
                    "view": <Chapter9_26/>
                },
                {
                    "path": "chapter 9 27",
                    "title": "Comparisons",
                    "view": <Chapter9_27/>
                },
                {
                    "path": "chapter 9 28",
                    "title": "Value Comparisons",
                    "view": <Chapter9_28/>
                },
                {
                    "path": "chapter 9 29",
                    "title": "Simple Statements",
                    "view": <Chapter9_29/>
                },
                {
                    "path": "chapter 9 30",
                    "title": "Expression Statements",
                    "view": <Chapter9_30/>
                },
                {
                    "path": "chapter 9 31",
                    "title": "Assignment Statements",
                    "view": <Chapter9_31/>
                },
                {
                    "path": "chapter 9 32",
                    "title": "Return",
                    "view": <Chapter9_32/>
                },
                {
                    "path": "chapter 9 33",
                    "title": "Raise",
                    "view": <Chapter9_33/>
                },
                {
                    "path": "chapter 9 34",
                    "title": "The break Statement",
                    "view": <Chapter9_34/>
                },
                {
                    "path": "chapter 9 34 1",
                    "title": "break",
                    "view": <Chapter9_34_1/>
                },
                {
                    "path": "chapter 9 35",
                    "title": "The continue Statement",
                    "view": <Chapter9_35/>
                },
                {
                    "path": "chapter 9 36",
                    "title": "The import Statement",
                    "view": <Chapter9_36/>
                },
                {
                    "path": "chapter 9 37",
                    "title": "Compound Statements",
                    "view": <Chapter9_37/>
                },
                {
                    "path": "chapter 9 38",
                    "title": "Control Flow Structures in AVAP",
                    "view": <Chapter9_38/>
                },
                {
                    "path": "chapter 9 39",
                    "title": "The if Statement",
                    "view": <Chapter9_39/>
                },
                {
                    "path": "chapter 9 40",
                    "title": "The try Statement",
                    "view": <Chapter9_40/>
                },
                {
                    "path": "chapter 9 41",
                    "title": "Patterns in AVAP",
                    "view": <Chapter9_41/>
                },
                {
                    "path": "chapter 9 42",
                    "title": "OR Patterns",
                    "view": <Chapter9_42/>
                },
                {
                    "path": "chapter 9 43",
                    "title": "AS Patterns",
                    "view": <Chapter9_43/>
                },
                {
                    "path": "chapter 9 44",
                    "title": "Literal Patterns",
                    "view": <Chapter9_44/>
                },
                {
                    "path": "chapter 9 45",
                    "title": "Capture Patterns",
                    "view": <Chapter9_45/>
                },
                {
                    "path": "chapter 9 46",
                    "title": "Wildcard Patterns",
                    "view": <Chapter9_46/>
                },
                {
                    "path": "chapter 9 47",
                    "title": "Value Patterns",
                    "view": <Chapter9_47/>
                },
                {
                    "path": "chapter 9 48",
                    "title": "Group Patterns",
                    "view": <Chapter9_48/>
                },
                {
                    "path": "chapter 9 49",
                    "title": "Sequence Patterns",
                    "view": <Chapter9_49/>
                },
                {
                    "path": "chapter 9 50",
                    "title": "Mapping Patterns",
                    "view": <Chapter9_50/>
                },
                {
                    "path": "chapter 9 51",
                    "title": "Class Patterns",
                    "view": <Chapter9_51/>
                }
            ],
            "title": "Execution Model in AVAP"
        },


        {
            "id": "chapter 10",
            "links": [
                {
                    "path": "chapter 10 1",
                    "title": "IF-THEN-ELSE Statement",
                    "view":<Chapter10_1/>
                },
                {
                    "path": "chapter 10 2",
                    "title": "Syntax of the IF-THEN-ELSE Statement",
                    "view":<Chapter10_2/>
                },
                {
                    "path": "chapter 10 3",
                    "title": "Functioning of the IF-THEN-ELSE Statement",
                    "view":<Chapter10_3/>
                },  
                {
                    "path": "chapter 10 4",
                    "title": "Result",
                    "view":<Chapter10_4/>
                },
                {
                    "path": "chapter 10 5",
                    "title": "Conclusions",
                    "view":<Chapter10_5/>
                },          
            ],
            "title": "Conditional Statements"
        },

        {
            "id": "chapter 11",
            "links": [
                {
                    "path": "chapter 11 1",
                    "title": "startLoop() Statement",
                    "view":<Chapter11_1/>
                },
                {
                    "path": "chapter 11 2",
                    "title": "Syntax of the Loop Statement",
                    "view":<Chapter11_2/>
                },
                {
                    "path": "chapter 11 3",
                    "title": "Functioning of the Loop Statement",
                    "view":<Chapter11_3/>
                },  
                {
                    "path": "chapter 11 4",
                    "title": "Example of Use",
                    "view":<Chapter11_4/>
                },
                {
                    "path": "chapter 11 5",
                    "title": "Result and Conclusions",
                    "view":<Chapter11_5/>
                },          
            ],
            "title": "Loop Statement"
        },


        {
            "id": "Chapter 12",
            "links": [
                {
                    "path": "chapter 12 1",
                    "title": "addParam() Function",
                    "view":<Chapter12_1/>
                },
                {
                    "path": "chapter 8 2",
                    "title": "Usage of addParam",
                    "view":<Chapter12_2/>
                },

                {
                    "path": "chapter 8 3",
                    "title": "Example Usage",
                    "view":<Chapter12_3/>
                },
                {
                    "path": "chapter 8 4",
                    "title": "Internal Operation",
                    "view":<Chapter12_4/>
                },
                {
                    "path": "chapter 8 5",
                    "title": "Important Considerations",
                    "view":<Chapter12_5/>
                },
                {
                    "path": "chapter 8 6",
                    "title": "Conclusions",
                    "view":<Chapter12_6/>
                },
                
            ],
            "title": "API Inbound interface"
        },
        

        
        
        {
            "id": "chapter 13",
            "links": [
                {
                    "path": "chapter 13 1",
                    "title": "Function Libraries",
                    "view":<Chapter13_1/>
                },
                {
                    "path": "chapter 13 2",
                    "title": "Purpose of Includes",
                    "view":<Chapter13_2/>
                },
                {
                    "path": "chapter 13 3",
                    "title": "Advantages of Using Includes",
                    "view":<Chapter13_3/>
                },  
                {
                    "path": "chapter 13 4",
                    "title": "Syntax of Includes",
                    "view":<Chapter13_4/>
                },
                {
                    "path": "chapter 13 5",
                    "title": "Operation",
                    "view":<Chapter13_5/>
                },  
                {
                    "path": "chapter 13 6",
                    "title": "Common Uses",
                    "view":<Chapter13_6/>
                },  
                {
                    "path": "chapter 13 7",
                    "title": "Considerations",
                    "view":<Chapter13_7/>
                },  
                {
                    "path": "chapter 13 8",
                    "title": "Practical Example",
                    "view":<Chapter13_8/>
                },  
                {
                    "path": "chapter 13 9",
                    "title": "Function Libraries Function Products",
                    "view":<Chapter13_9/>
                },          
            ],
            "title": "Working with libraries"
        },
        {
            "id": "chapter 14",
            "links": [
                {
                    "path": "chapter 14 1",
                    "title": "Function Declaration",
                    "view":<Chapter14_1/>
                },
                {
                    "path": "chapter 14 2",
                    "title": "Function Construction",
                    "view":<Chapter14_2/>
                },
                {
                    "path": "chapter 14 3",
                    "title": "Technical Features",
                    "view":<Chapter14_3/>
                },  
                {
                    "path": "chapter 14 4",
                    "title": "Practical Example",
                    "view":<Chapter14_4/>
                },
                {
                    "path": "chapter 14 5",
                    "title": "Conclusions",
                    "view":<Chapter14_5/>
                },          
            ],
            "title": "Function Declaration"
        },
        {
            "id": "APPENDICES",
            "links": [
                {
                    "path": "appendices 1",
                    "title": "Function Glossary",
                    "view":<Appendices_1/>
                },
                {
                    "path": "appendices 2",
                    "title": "randomString()",
                    "view": <Appendices_2/>
                },
                {
                    "path": "appendices 3",
                    "title": "stampToDatetime()",
                    "view": <Appendices_3/>
                },
                {
                    "path": "appendices 4",
                    "title": "getTimeStamp()",
                    "view": <Appendices_4/>
                },
                {
                    "path": "appendices 5",
                    "title": "getRegex()",
                    "view": <Appendices_5/>
                },
                {
                    "path": "appendices 6",
                    "title": "getDateTime()",
                    "view": <Appendices_6/>
                },
                {
                    "path": "appendices 7",
                    "title": "encodeMD5()",
                    "view": <Appendices_7/>
                },
                {
                    "path": "appendices 8",
                    "title": "encodeSHA256()",
                    "view": <Appendices_8/>
                },
                {
                    "path": "appendices 9",
                    "title": "getQueryParamList()",
                    "view": <Appendices_9/>
                },
                {
                    "path": "appendices 10",
                    "title": "getListLen()",
                    "view": <Appendices_10/>
                },
                {
                    "path": "appendices 11",
                    "title": "itemFromList()",
                    "view": <Appendices_11/>
                },
                {
                    "path": "appendices 12",
                    "title": "variableFromJSON()",
                    "view": <Appendices_12/>
                },
                {
                    "path": "appendices 13",
                    "title": "AddVariableToJSON()",
                    "view": <Appendices_13/>
                },
                {
                    "path": "appendices 14",
                    "title": "variableToList()",
                    "view": <Appendices_14/>
                },
                {
                    "path": "appendices 15",
                    "title": "addParam()",
                    "view": <Appendices_15/>
                },
                {
                    "path": "appendices 16",
                    "title": "addResult()",
                    "view": <Appendices_16/>
                },
                {
                    "path": "appendices 17",
                    "title": "RequestPost()",
                    "view": <Appendices_17/>
                },
                {
                    "path": "appendices 18",
                    "title": "ormCreateTable()",
                    "view": <Appendices_18/>
                },
                {
                    "path": "appendices 19",
                    "title": "ormCheckTable()",
                    "view": <Appendices_19/>
                },
                {
                    "path": "appendices 20",
                    "title": "ormAccessUpdate()",
                    "view": <Appendices_20/>
                },
                {
                    "path": "appendices 21",
                    "title": "ormAccessSelect()",
                    "view": <Appendices_21/>
                },
                {
                    "path": "appendices 22",
                    "title": "ormAccessInsert()",
                    "view": <Appendices_22/>
                },
                {
                    "path": "appendices 23",
                    "title": "ormAI()",
                    "view": <Appendices_23/>
                },
                {
                    "path": "appendices 24",
                    "title": "functionAI()",
                    "view": <Appendices_24/>
                }
                          
            ],
            "title": "Appendix"
        },
        

       
    ],
    "basePath": "docs/",
    "titleNav": "TITULO QUE TU QUIERAS"
};

export default routerDocs;