import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, Form, Spinner, Dropdown, Alert } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEllipsisV, faSearch } from "@fortawesome/free-solid-svg-icons";
import devIcon from '../../assets/img/ui_icons/cld_publisher-s.svg';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";

import Drawer from "./Drawer";
import GenericSelectableList from "./CustomSelectSearch";
import FileUpload from "./components/uploadFile";
import { useHistory } from "react-router-dom";
import Nodata from "./components/Nodata";

const ApiProductPublisher = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [apiProducts, setApiProducts] = useState([]);
  const [loadingApiProducts, setLoadingApiProducts] = useState(false);
  const [creatingAP, setCreatingAP] = useState(false);

  const [selectedProject, setSelectedProject] = useState({ id: 0, name: '', description: '' });

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [selectedApiForEdit, setSelectedApiForEdit] = useState(null);
  const [selectedApi, setSelectedApi] = useState("");
  const [Portals, setPortals] = useState([]);
  const [FilteredPortals, setFilteredPortals] = useState([]);
  const [error, setErr] = useState('');
  const [error2, setError] = useState('');
  const [isAddCertDrawerOpen, setIsAddCertDrawerOpen] = useState(false)
  const [localSelectedDP, setSelectedDP] = useState([]);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [up, setUp] = useState(0)

  const [selectedAPIProduct, setselectedAPIProduct] = useState(0);
  const [AddProduct, setAddProduct] = useState(false);
    const [jsonContent, setJsonContent] = useState(null);
    const [cardholder, setCardholder] = useState('');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const history = useHistory();

    const handleClose = () =>{
      // fetchApiProducts();
      setCreatingAP(false);
    }

     const [cardNumber, setCardNumber] = useState('');
      const [cardholder2, setCardholder2] = useState('');

      const [success, setSuccess] = useState('');
      const [submitDisabled, setSubmitDisabled] = useState(true);


    const [validName, setValidName] = useState(false);
    const [validTarget, setValidTarget] = useState(false);
    const [errorNombre, setErrorNombre] = useState(false);
    const [errorTarget, setErrorTarget] = useState(false);
  
    const validateName = (name) => {
      const nameFormat = /^[a-zA-Z]{1,18}$/;
      if (name.match(nameFormat)) {
        setValidName(true);
        setErrorNombre(false);
        setSubmitDisabled(!validTarget);
      } else {
        setValidName(false);
        setSubmitDisabled(true);
        setErrorNombre(true);
      }
    };
  
    const validateTarget = (target) => {
      const targetFormat = /^[a-zA-Z\s]+$/;
      if (target.match(targetFormat)) {
        setValidTarget(true);
        setErrorTarget(false);
        setSubmitDisabled(!validName);
      } else {
        setValidTarget(false);
        setErrorTarget(true);
        setSubmitDisabled(true);
      }
    };
  
    const changeCardNumber = (e) => {
      const newCardNumber = e.target.value;
      setCardNumber(newCardNumber);
      validateTarget(newCardNumber);
    };
  
    const changeCardholder = (e) => {
      const newCardholder = e.target.value;
      setCardholder2(newCardholder);
      validateName(newCardholder);
    };
  
    const handleSubmit2 = async (event) => {
      event.preventDefault();
      setLoading(true);
      setError('');
      const result = await ObexRequestHandler.post(
        `/publishing/create_api_product?obex_project_id=${selectedProject.id}&api_product=${cardholder2}&description=${cardNumber}`,
        {},
        false
      );
      const { success, data, message } = result;
  
      if (success) {
        setLoading(false);
        fetchApiProducts();
        setSuccess(data);
        setTimeout(() => {
          setSuccess('');
          setSubmitDisabled(true);
          handleClose();
        }, 1000);
      } else {
        setError(message);
        setLoading(false);
      }
    };



    const handlePublishProduct = async (api) =>{
      history.push('/api_products')
      const payload = {
        gateway_id: api.gateway_id, 
        new_status: !api.is_published, 
        api_id: api.api_id, 
        api_product_id: api.api_product_id
      }
  
      await ObexRequestHandler.post('/update_portal_api_product_status', payload);
  
      fetchApiProducts();
      //update_portal_apis_status
      history.push('/api_products')
    }


    const handleUnPublishProduct = async (api) =>{
      history.push('/api_products')
      const payload = {
        gateway_id: api.gateway_id, 
        new_status: !api.is_published, 
        api_id: api.api_id, 
        api_product_id: api.api_product_id
      }
  
      await ObexRequestHandler.post('/unpublish_portal_api_product', payload);
  
      fetchApiProducts();
      history.push('/api_products')
      //update_portal_apis_status
    }




    const handleChange1 = async (e) => {
      const { name, value } = e.target;
      availableProjects.forEach((pp)=>{
        if (pp.id == e.target.value){
          console.log(pp);
          setSelectedProject({ id:pp.id, name: pp.name, description: pp.description })
        }
      })
      
      if (name === 'project') {
      //  setLoadingApiProducts(true);

      } else {

      }
    };




    const fetchApiData = async () => {
      try {
        setLoading(true);
  
        const projectsResponse = await ObexRequestHandler.get('/projects', {}, true);


        const yes_projects = [];
        projectsResponse.data.forEach(element => {
          if (element.organization_id == SessionService.ORGANIZATION) yes_projects.push(element);
        });

        setAvailableProjects(yes_projects || []);
        if (projectsResponse.data.length>0) setSelectedProject({id:projectsResponse.data[0].id, name:projectsResponse.data[0].name, description:projectsResponse.data[0].description})
        console.log('Projects Response:', projectsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

  const getDevPortals = async () => {

    setPortals([]);
  
      const portals_configs = 
        await ObexRequestHandler.get(`/gateway/get_dev_portal_by_org?org_id=${SessionService.ORGANIZATION}`, {}, true)

      const portals = portals_configs.data.map((por) => ({
        id: por.id,
        name: por.name,
        description: por.description,
        route: por.route
      }));
      setPortals(portals);
      console.log("PORTALS")
      console.log(portals);
      history.push('/api_products')
    };


    const handleFileChange = (file/*e: React.ChangeEvent<HTMLInputElement>*/) => {
      //const file = e.target.files ? e.target.files[0] : null;
  
      if (file && file.type === 'application/json') {
        const reader = new FileReader();
  
        reader.onload = () => {
          // Comprobamos si el resultado es una cadena
          const fileContent = reader.result;
          if (typeof fileContent === 'string') {
            try {
              const json = JSON.parse(fileContent);
              setJsonContent(json); // Asigna el contenido JSON a la variable
            } catch (error) {
              alert('El archivo no es un JSON válido');
            }
          } else {
            alert('El archivo no es un formato adecuado');
          }
        };
  
        reader.readAsText(file); // Lee el archivo como texto
      } else {
        alert('Por favor, seleccione un archivo JSON');
      }
    };


      const [selectedFile, setSelectedFile] = useState(null);
      const handleFileSelect = (file) => {
        console.log(file)
        setSelectedFile(file); 
        handleFileChange(file);
    };

  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [SelectedPProductId, setSelectedPProductId] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Formulario enviado con el proyecto seleccionado:', selectedProjectId);
    console.log('Formulario enviado con el api product seleccionado:', SelectedPProductId);
    console.log('Contenido del archivo JSON:', jsonContent);

    //let url_service = `${cloud}/gateway/routing/add_gateway_link?obex_project_id=${SelectedProject}`

    //custom file
    const formData = new FormData();
    formData.append('file', selectedFile); // logica para subir el archivo


    let prod_sel = SelectedPProductId;

    if (AddProduct) {

      const id_prod = await ObexRequestHandler.post(`/publishing/create_api_product?obex_project_id=${selectedProjectId}&api_product=${cardholder}`);

      
      console.log(id_prod);
      prod_sel = id_prod.data.id[0].id
    }

    const payload = {
      api_product_id: prod_sel,
      my_api: jsonContent
    }

    
    await ObexRequestHandler.post(`/gateway/routing/add_gateway_link?obex_project_id=${selectedProjectId}`, payload)

    fetchApiProducts();
    getDevPortals();
    history.push('/api_products')
    closeDrawer();

  };


    const openDrawer = (role) => {
      //setSelectedPProductId('');
      setIsDrawerOpen(true);
      
      //fetchAPIProducts(selectedProjectId);
    };
  
    const closeDrawer = () => {
      setIsDrawerOpen(false);
      //fetchApisByProject();
      history.push('/api_products')
    };



    const handleAddDP = (dp: string) => {
      setErr('')
      if (!localSelectedDP.includes(dp)) {
        setSelectedDP([...localSelectedDP, dp]);
        console.log([...localSelectedDP, dp]);
      }
    };
  
    const handleRemoveDP = (dp: string) => {
      setErr('')
      setSelectedDP(localSelectedDP.filter((item) => item !== dp));
    };


  const handlePublish = async (api_product) => {

    /*
    
              let data = JSON.stringify({
                "api_product_id": api_product_id,
                "api_product_version": version_value,
                "developer_token": AccesToken
                });
                
                let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${cloud}/api_product_apis_by_dev`,
                headers: { 
                  'Content-Type': 'application/json', 
                },
                data : data
                };
                
                axios.request(config)
    
    
    */




    console.log(api_product.latest_version);

    let version_value = 0.1;
    try {
      if (api_product.latest_version) version_value = Number(api_product.latest_version) + 0.1;
    } catch { }
    const data = {
      api_product_id: api_product.api_product_id,
      api_product_version: version_value,
    };

    console.log(data);
    console.log(api_product);



    const publish_data = await ObexRequestHandler.post('/api_product_apis_by_dev', data);

    console.log(publish_data);
    fetchApiProducts();

  }



  const handleDevPortal = async (api_product) => {


    const data = {
      api_product_id: api_product.api_product_id,
      new_status: !api_product.active

    }

    const publish_data = await ObexRequestHandler.post('/update_portal_prod_status', data);

    console.log(publish_data);
    fetchApiProducts();

  }


  // Function to fetch API products from the backend
  const fetchApiProducts = async () => {
    setLoading(true);
    try {
      const data = await ObexRequestHandler.get(`/publishing/get_products_by_org?org_id=${SessionService.ORGANIZATION}`)

      console.log(data)
      const resmod = [];

      data.data.forEach(dat => {
        if (dat.is_published_active || true) resmod.push(dat);
      })
      setInitialized(true);
      setApiProducts(resmod);
    } catch (error) {
      setInitialized(true);
      console.error('Error fetching API products:', error);
    } finally {
      setLoading(false);
      setInitialized(true);
    }
    setInitialized(true);
  };


  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/api_products'){
      history.push('/api_products')
      SessionService.updating = SessionService.updating -3;
      fetchApiData();
      fetchApiProducts();
      getDevPortals();

    window.scrollTo(0, 0);
    history.push('/api_products')
    }
  
  }, false);


  const AMBIENT_LABELS = {
    Yes: { name: 'Yes', color: '#28a745' },        // Verde
    No: { name: 'No', color: 'red' }, // Amarillo
    //3: { name: 'Live', color: '#28a745' }, // Amarillo
  
  };
  
  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;
  
    return (
      <span 
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth:'100px',
          display:'flex',
          width:'65px',
          justifyContent:'center',
          marginLeft:'auto',
          marginRight:'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };



  // Fetch API products on component mount (useEffect hook)
  useEffect(() => {
    fetchApiProducts();
    fetchApiData();
    getDevPortals();

    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
  }, []); // Empty dependency array ensures fetching only once on mount

  // Search functionality (unchanged)
  const filteredApis = apiProducts.filter(api =>
    api.api_product_name.toLowerCase().includes(searchTerm.toLowerCase())



  );

  const apiOptions = [
    { id: 1, name: "API Product 1" },
    { id: 2, name: "API Product 2" },
    { id: 3, name: "API Product 3" },
    { id: 4, name: "API Product 4" }
  ];



  const openEditDrawer = (api) => {
    setSelectedApiForEdit(api);
    setIsEditDrawerOpen(true);
  };

  const closeEditDrawer = () => {
    setSelectedApiForEdit(null);
    setIsEditDrawerOpen(false);
  };

  const handleApiChange = (e) => {
    setSelectedApi(e.target.value);
  };


  return (
    <DashLayout sider={DashRouters} active={'api-catalog'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8' >
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={devIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px"><span style={{ color: '#949498', fontSize: '14px' }}>API Publisher </span>/ API Products </span> 
            </div>
          </div>
        </Col>
        <Col md='4' className='align-content-center'>
          {/* Aquí se puede colocar algún botón de acción si fuera necesario */}

          <Button style={{ width: '225px' }} className="customButton-black btn px-4 float-right  resBtn" onClick={
                    
                    () => {

                      setCreatingAP(true);
                      /*
                      setAddProduct(false);
                      setCardholder('');
                      setJsonContent(null);
                      setSelectedFile(null)
                      openDrawer(null)*/
                      setCardholder2('')
                      setCardNumber('')
                      setSelectedProject({ id:availableProjects[0].id, name:availableProjects[0].name, description:availableProjects[0].description })
                    }
        
        
                  }>
                    Create API Product
                  </Button>


        </Col>




        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div className="cld_borderWrapper">
          <span className='cld_sectionTitle'>Published API Products</span>

          <div className="searchContainer mb-4">
            <Form.Control
              id='buscador'
              required
              type="text"
              value={searchTerm}
              placeholder="Search by API Product name"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button className="customButton-transparent px-4">
              <FontAwesomeIcon icon={faSearch} size="1x" />
            </Button>
          </div>

          <Table className="cld_tableWrapper" style={{height:'auto'}}>
            <tbody>
              <tr className="headerRow">
                <th style={{ width: '25%' }} className="textBold">API Product</th>
                <th style={{ width: '25%' }} className="textBold">Description</th>
                <th style={{ width: '20%' }} className="textBold">APIs</th>
                {/*<th style={{ width: '9%' }} className="textBold">APIs count</th>*/}
                <th style={{ width: '15%' }} className="textBold">API Project</th>
                <th style={{ width: '15%', textAlign:'center' }} className="textBold">Published</th>
                
                {/*}
                <th style={{ width: '10%' }} className="textBold">Market Version</th>
                {/*<th style={{ width: '9%' }} className="textBold">Dev Portal</th>*/}
                {/*<th style={{ width: '15%' }} className="textBold">Dev Portals</th>
                */}
          
                <th style={{width: '20px', textAlign: 'end' }} className="textBold">Actions</th>
              </tr>
              {(filteredApis.length === 0 && initialized)? (
                <tr>
                  <td colSpan={5}>
                    <div className="nodata-wrapper">
                      <Nodata />
                    </div>
                  </td>
                </tr>
              ) : (filteredApis.map((api) => (
                <tr className="tableRow" key={api.id}>
                  <td className="td_centered">{api.api_product_name}</td>
                  <td className="td_centered">{api.api_product_description}</td>
                  <td className="td_centered">{api.published_endpoints!=null ? (api.published_endpoints.map((name)=>name+' ')):''}</td>
                  {/*<td className="td_centered">{Number(api.published_entries_count) + Number(api.gateway_entries_count	)}</td>*/}
                  <td className="td_centered">{api.project_name+ ' '}</td>
                  <td className="td_centered">{api.published_entries_count>0 ? getAmbientLabel('Yes'): getAmbientLabel('No')}</td>
                {/*}
                  <td className="td_centered">{api.latest_version || 'unpublished'}</td>
                  {/*<td className="td_centered">{api.active ? 'Yes' : 'No'}</td>*/}
                  {/*<td className="td_centered">{api.dev_portal_list.map(dp=>dp.name+ ' ')}</td>
                  */}
                  <td>
                    <div className="actionsWrapper">
                      <Dropdown className='my-auto  text-right' style={{ textAlign: 'end', width: '100%', marginRight: '0px' }}>
                        <Dropdown.Toggle className="">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/*}
                          { !api.installed &&
                          <Dropdown.Item
                            key={1}
                            onClick={() => handlePublish(api)}
                          >
                            Pub in Marketplace
                          </Dropdown.Item>
                          }

                          {!api.active ?

                            <Dropdown.Item
                              key={1}
                              onClick={() => handleDevPortal(api)}
                            >
                              To Dev Portals
                            </Dropdown.Item>
                            :
                            <Dropdown.Item
                              key={1}
                              onClick={() => handleDevPortal(api)}
                            >
                              Retire from Dev Portal
                            </Dropdown.Item>



                          }

                          <Dropdown.Item
                            key={2}
                            onClick={() => {
                              setIsAddCertDrawerOpen(true); 
                              setSelectedDP(api.dev_portal_list)
                              setselectedAPIProduct(api.api_product_id)
                            }}
                          >
                            Edit
                          </Dropdown.Item>
*/}

<Dropdown.Item
                            key={2}
                            onClick={() => {
                              //setIsAddCertDrawerOpen(true); 
                              
                              setAddProduct(false);
                              setCardholder('');
                              setJsonContent(null);
                              setSelectedFile(null)
                              openDrawer(null)
                              setUp(up+1)
                              setSelectedDP(api.dev_portal_list)
                              setselectedAPIProduct(api.api_product_id)
                              setSelectedPProductId(api.api_product_id)
                              setSelectedProjectId(api.project_id)
                            }}
                          >
                           Import OAS 2.0 Specs
                          </Dropdown.Item>
{ Number(api.apis_live)>0 &&
                          (api.published_entries_count == 0 ?
                          <Dropdown.Item
                            key={3}
                            onClick={(e) => {
                            console.log(e)
                            handlePublishProduct(api);
                            }}
                          >
                           Publish to Catalog
                           
                          </Dropdown.Item> : 
                          <Dropdown.Item
                          key={3}
                          onClick={(e) => {
                          console.log(e)
                          handleUnPublishProduct(api);
                          }}
                        >
                         Unpublish from Catalog
                         
                        </Dropdown.Item>
                          )
                        }
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              )))}
{/*}
                  {filteredApis.length < 3 &&
                    <div style={{ height:'95px' }}></div>
                  }
*/}

            </tbody>
          </Table>
        </div>
      )}

 {/*}     <Drawer
        toggleSidebar={closeEditDrawer}
        isOpen={isEditDrawerOpen}
        name="Edit API"
        subtitle=""
      >
        <div>
          <h5>Edit API: {selectedApiForEdit?.api_product_name}</h5>
          <p>This is a simulated content for editing the API.</p>
          <Form.Group controlId="apiSelect">
            <Form.Label>Select API</Form.Label>
            <Form.Control
              as="select"
              value={selectedApi}
              onChange={handleApiChange}
            >
              <option value="">-- Select an API --</option>
              {apiOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

*/}

      {/* Drawer for Adding To Dev Portals */}
      <Drawer
        isOpen={isAddCertDrawerOpen}
        toggleSidebar={() => setIsAddCertDrawerOpen(!isAddCertDrawerOpen)}
        name="API Poduct in Dev Portals"
        index=""
      >
        <div>
          {/* <h3>Add a New Certificate</h3> */}
          <Form
            onSubmit={async (e) => {
              e.preventDefault();

              const payload = {
                api_product: selectedAPIProduct,
                dev_portal_list: localSelectedDP
              }

              if (false 
              ) {

                setErr('Please fill all data')

              } else {
                setLoading(true);
              await ObexRequestHandler.post('/publishing/update_api_product_devportals', payload)
              setIsAddCertDrawerOpen(false);
                setLoading(false);

                fetchApiProducts();
                getDevPortals();
              }

            }}
          >






        <Form.Group controlId="devPortal">
          <Form.Label>Available Dev Portals</Form.Label>


            <Form.Group controlId="SelectSP" className="mb-3">

              <GenericSelectableList
                items={Portals} // Convierte las cadenas en objetos con `id` y `name`
                searchKeys={['name']} // Busca por el nombre
                onAdd={handleAddDP}
                onRemove={handleRemoveDP}
                renderLabel={(item) => item.name ? `${item.name}` : `> ${item}`} // Renderiza directamente el nombre
                placeholder="Select Dev Portal."
                multiOption={true} // Permitir selección múltiple
                selectedItems={localSelectedDP}
                setSelectedItems={setSelectedDP}
                itemSelected={''}
              />
            </Form.Group>
        </Form.Group>






            <Row>
              <Col md='6'>
                <Button 
                variant="primary" 
                disabled={localSelectedDP.length==0}
                className="w-100" type="submit" >
                  Submit
                  {loading && <Spinner animation="border" size="sm" />}
                </Button>
              </Col>
            </Row>
        { error.length>0 &&
          <Alert variant='danger'>
          {error}
</Alert>}

          </Form>
        </div>
      </Drawer>



      {/*//////// */}

      <Drawer        index='publisher'
        toggleSidebar={closeDrawer}
        isOpen={isDrawerOpen}
        name={'Add OpenAPI Specs'}
        subtitle=''
      >


        <Form onSubmit={handleSubmit}>
          {/*}
          <Form.Group controlId="project">
            <Form.Label>Available Projects</Form.Label>
            <Form.Control
              as="select"
              name="project"
              value={selectedProjectId}
              onChange={handleChange}
            >
              <option value="">Select a project</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.description}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
*/}
{/*}
          {selectedProjectId && (
            <Form.Group controlId="apiProduct">
              <Form.Label>Available API Products</Form.Label>
              {loadingApiProducts ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : apiProducts.length === 0 ? (
                <Alert variant="warning">No API Products found for this project.</Alert>
              ) : (
                <Form.Control
                  as="select"
                  name="apiProduct"
                  value={SelectedPProductId}
                  onChange={handleChangeProduct}
                >
                  <option value="">Select an API Product</option>
                  {apiProducts.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>

                  ))}
                  <option>
                    Add API Product
                  </option>
                </Form.Control>
              )}
            </Form.Group>
          )}
          */}

          {(selectedProjectId && SelectedPProductId || true) &&
            <><Row>
              <Form.Group controlId="file" style={{ paddingLeft: '15px', paddingRight: '15px', width: '100%' }}>
                <Form.Label>OpenAPI Spec File</Form.Label>
                <FileUpload
                    onFileSelect={handleFileSelect}
                    allowedTypes={['application/json']}
                    label="Select a JSON file"
                    reset={up}
                />
  



              </Form.Group>
             



            </Row>
              {(jsonContent || true) &&
                <Row>
                  <Col md='6'>
                    <Button 
                      disabled = {!jsonContent || !selectedAPIProduct}
                      
                    type="submit" variant="primary" className="w-100">
                      Submit
                    </Button>
                  </Col>
                </Row>
              }
            </>
          }
        </Form>

      </Drawer>


      {/* //// */}



      {/* //// */}
<Drawer 
name={'Create API Product'}
toggleSidebar={handleClose}
isOpen={creatingAP}  index={""}>
<Row>
        <Col md="12" className="mx-auto">
          <Form className="obex-form payment-method-form" onSubmit={handleSubmit2}>
            <Form.Group className="mb-3">
              <Form.Label className="obex-form-label">Name</Form.Label>
              <Form.Control
                //isValid={!errorNombre && cardholder.length > 0}
                //isInvalid={errorNombre}
                id="entrada-organizacion"
                required
                type="text"
                placeholder="Name"
                value={cardholder2}
                onChange={changeCardholder}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="obex-form-label">Description</Form.Label>
              <Form.Control
                //isInvalid={errorTarget}
                //isValid={!errorTarget && cardNumber.length > 0}
                required
                type="text"
                maxLength={19}
                placeholder="Description"
                value={cardNumber}
                onChange={changeCardNumber}
              />
            </Form.Group>


            {availableProjects.length === 0 ? (
            <Alert variant="danger">No API Projects available. Please create one first.</Alert>
          ) : (
            <Form.Group controlId="project">
              <Form.Label>Available Projects</Form.Label>
              <Form.Control
                as="select"
                name="project"
                value={selectedProject.id}
                onChange={handleChange1}
              >
                <option value="">Select a project</option>
                {availableProjects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}


            <Row>
              <Col md='6'>
                <Button disabled={cardNumber == '' || cardholder2 == ''} type="submit" variant='primary' className="w-100">
                  Create {success && <FontAwesomeIcon icon={faCheck} />}
                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                </Button>
              </Col>
            </Row>

          </Form>
          {error && <Alert variant="danger">{error}</Alert>}
        </Col>
      </Row>


</Drawer>


{/*}


          <Button variant="primary" onClick={closeEditDrawer}>
            Close
          </Button>
        </div>
      </Drawer>*/}
    </DashLayout>
  );
};

export default ApiProductPublisher;
