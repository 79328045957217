import React from 'react';

import nodata from '../../../assets/img/ui_icons/noData.svg';

const Nodata = () => {
  return (
    <div className="nodata-container">
      <img src={nodata} alt="No data" className='nodata-image' />
      <h3 className='nodata-title'>You don’t have data to show</h3>
      <p className='nodata-subtitle'>When you have data to show, you’ll find here.</p>
    </div>
  );
};

export default Nodata;

