import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, Form, Spinner, Dropdown, DropdownButton } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import Drawer from "./Drawer";

import editicon from '../../assets/img/ui_icons/cld_edit_table.svg';
import deleteIcon from '../../assets/img/ui_icons/cld_delete.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSearch } from "@fortawesome/free-solid-svg-icons";

import devIcon from '../../assets/img/ui_icons/cld_org-s.svg'
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";

import GenericSelectableList from '../DashboardComponents/CustomSelectSearch'
import SessionService from "../../services/SessionsService";
import { useHistory } from "react-router-dom";
import ConfirmDeletetModal from "../Modals/ConfirmDeleteModal";
import Nodata from "./components/Nodata";

const RolesManagement = () => {
  const [roles, setRoles] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [search, setSearch] = useState('');
  const [searchV, setSearchV] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [localSelectedGA, setSelectedGA] = useState([]);
  const [listaRecuperados, setlistaRecuperados] = useState([]);
  const [changesMade, setChangesMade] = useState(false);

  const [blocked, setBlocked] = useState(false);

  const handleAddGA = (ga: string) => {
    if (!localSelectedGA.includes(ga)) {
      setSelectedGA([...localSelectedGA, ga]);
      console.log("+++")
      console.log(ga);
      console.log(localSelectedGA);
      //if (cardholder && cardNumber && localSelectedGA.length>0) setSubmitDisabled(false);
    }
  };

  const history = useHistory();


  const AMBIENT_LABELS = {
    System: { name: 'System', color: '#28a745' },        // Verde
    Custom: { name: 'Custom', color: '#CE9C06' }, // Amarillo
    //3: { name: 'Live', color: '#28a745' }, // Amarillo
  
  };
  
  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;
  
    return (
      <span 
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth:'100px',
          display:'flex',
          width:'65px',
          justifyContent:'center',
          marginLeft:'auto',
          marginRight:'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };

  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/roles'){
      history.push('/roles')
      SessionService.updating = SessionService.updating -3;
      
      getPermits('');
      getRoles('');
      history.push('/roles')

    window.scrollTo(0, 0);
    history.push('/roles')
    }
  
  }, false);


  const handleRemoveGA = (ga: string) => {
    setSelectedGA(localSelectedGA.filter((item) => item !== ga));
  };

  const [permissions, setPermissions] = useState([]);

  const getRolePermits = async (role_id) => {
    try {

      //setloadDevs(true);
      // setLoading(true);
      setlistaRecuperados([]);

      const permits = (
        await ObexRequestHandler.get(`/iam/get_permits_role?rol_id=${role_id}`, {}, true)).data || [];

      setlistaRecuperados(permits);

      //setProjects(projects);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }
    history.push('/roles')
    // setLoading(false);

  }



  const getPermits = async (filtro) => {
    try {

      //setloadDevs(true);
      setLoading(true);
      setPermissions([]);

      const permits = (
        await ObexRequestHandler.get('/iam/get_permits', {}, true)).data || [];

      const filter_permits = permits.filter(element =>
        element.permit_code.toLowerCase().includes(filtro.toLowerCase())
      );
      setPermissions(filtro === '' ? permits : filter_permits);

      //setProjects(projects);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }
    history.push('/roles')
    setLoading(false);

  }

  const getRoles = async (filtro) => {
    try {
      setLoading(true);
      //setloadDevs(true);

      setRoles([]);

      const roles = (
        await ObexRequestHandler.get(`/iam/get_roles?org_id=${SessionService.ORGANIZATION}`, {}, true)).data || [];

      const filter_roles = roles.filter(element =>
        element.role_name.toLowerCase().includes(filtro.toLowerCase())
      );
      setRoles(filtro === '' ? roles : filter_roles);

      //setProjects(projects);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }
    setLoading(false);
  }

  useEffect(() => {
    setChangesMade(false)
    getPermits('');
    getRoles('');
    history.push('/roles')

    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
  }, []);

  const handleSearchT = (e) => setSearch(e);
  const handleSearch = (e) => setSearch(e.target.value);
  const handleSearchV = (e) => {
    setSearchV(e.target.value);
    //filterProjects(e.target.value);
    handleSearchT(e.target.value)
  }


/*
  const filterProjects = async (filtro) => {

    console.log(recoveredProjects);

    const filter_projects = recoveredProjects.filter(element =>
      element.name.toLowerCase().includes(filtro.toLowerCase())
    );
    setProjects(filtro === '' ? recoveredProjects : filter_projects);
  
  }
*/
  

  const filteredRoles = roles.filter(
    (role) =>
      (filterType === 'all' || role.type === filterType) &&
      role.role_name.toLowerCase().includes(search.toLowerCase())
  );

  const openDrawer = (role) => {
    setChangesMade(false)
    history.push('/roles')
    setlistaRecuperados([]);
    setBlocked(false);
    if (role) {

      if (role.role_type === 'Predefined') setBlocked(true);
      getRolePermits(role.role_id);
      setCurrentRole(role);
      setIsEditing(true);
      history.push('/roles')
    } else {
      history.push('/roles')
      setCurrentRole({
        role_name: '',
        role_description: '',
        permissions: { read: false, write: false, delete: false },
        type: 'Custom',
      });
      setIsEditing(false);
      history.push('/roles')
    }
    history.push('/roles')
    setIsDrawerOpen(true);
    history.push('/roles')
  };

  const closeDrawer = () => {
    console.log('[ROL] Closedrawe')
    setCurrentRole(null);
    setIsEditing(false);
    setIsDrawerOpen(false);
    setChangesMade(false);
    getPermits('');
    getRoles('');

    history.push('/roles')


  };
  const justCloseDrawer = () => {
    console.log('[ROL] Just Closedrawe')
    setCurrentRole(null);
    setIsEditing(false);
    setIsDrawerOpen(false);
    setChangesMade(false);

    // getPermits('');
    // getRoles('');

    history.push('/roles')


  };

  const togglePermission = (permission) => {
    setCurrentRole((prevRole) => ({
      ...prevRole,
      permissions: {
        ...prevRole.permissions,
        [permission]: !prevRole.permissions[permission],
      },
    }));
  };

  const handleAddPermit = (permit) => {
    // Añade un permiso seleccionado
    if (!localSelectedGA.some((p) => p.id === permit.id)) {
      setSelectedGA((prev) => [...prev, permit]);
      setChangesMade(true);

    }
  };

  const handleRemovePermit = (permit) => {
    // Elimina un permiso seleccionado
    setSelectedGA((prev) => prev.filter((p) => p.id !== permit.id));
    setChangesMade(true);

  };





  const [showDelete, setShowDelete] = useState(false);
  const [selDeveloper, setSelDeveloper] = useState({ })
  const [selTeam, setselTeam] = useState({role_name:''});

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  }

  const handleDelete = async (developer) => {
    setShowDelete(true);
    setSelDeveloper(developer);
    setselTeam(developer);

  }



  const handleDeleteRole = async (role) => {
    console.log(role);
handleShowDelete()
    const payload = {
      role_id: role.role_id
    }

    await ObexRequestHandler.post('/iam/delete_role', payload);

    getPermits('');
    getRoles('');
  }

  const handleSubmit = async () => {
    if (isEditing) {
      setRoles((prevRoles) =>
        prevRoles.map((role) =>
          role.id === currentRole.id ? currentRole : role
        )
      );

      
      console.log(currentRole);
      console.log(roles);
      console.log(localSelectedGA)
      console.log(listaRecuperados)

      const payload3 = {
        permits: listaRecuperados,
        role_name: currentRole.role_name,
        role_description: currentRole.role_description,
        org_id: SessionService.ORGANIZATION
      }
      setLoading(true);
      const result2 = await ObexRequestHandler.post('/iam/update_role', payload3)
      console.log(result2);
      setLoading(false);
      closeDrawer();

    } else {
      setRoles((prevRoles) => [
        ...prevRoles,
        { ...currentRole, id: prevRoles.length + 1 },
      ]);

       
          /* CREACION DEL ROL */
        console.log(currentRole);
        console.log(roles);
        console.log(localSelectedGA)

        const payload3 = {
          permits: localSelectedGA,
          role_name: currentRole.role_name,
          role_description: currentRole.role_description,
          org_id: SessionService.ORGANIZATION
        }
        setLoading(true);
        const result2 = await ObexRequestHandler.post('/iam/create_role', payload3)
        console.log(result2);
        setLoading(false);

        closeDrawer();

    }



    
  };

  return (
    <DashLayout sider={DashRouters} active={'roles'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8' >
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={devIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px"><span style={{ color:'#949498', fontSize:'14px' }}>IAM </span>/ Roles </span>
            </div>

          </div>
        </Col>
        <Col md='4' className='align-content-center'>
          <Button style={{ width: '225px' }} className="customButton-black btn px-4 float-right  resBtn" onClick={() => openDrawer(null)}>
            Create a Role
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div className="cld_borderWrapper">



          <span className='cld_sectionTitle'>Roles</span>


          <div className="searchContainer">
                    <Form.Control id='buscador' required type="text" value={searchV} placeholder="Search by Role name"
                      onChange={handleSearchV} />
                    <Button className="customButton-transparent px-4"
                      onClick={()=>handleSearchT(searchV)}><FontAwesomeIcon icon={faSearch} size="1x" />
                    </Button>
                  </div>

{/*}
          <Row className="searchContainer mb-3" >
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder="Search roles..."
                value={search}
                onChange={handleSearch}
              /> /*}
            </Col>
            {/*}
            <Col md={4}>
              <Form.Control
                as="select"
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
              >
                <option value="all">All</option>
                <option value="Predefined">Predefined</option>
                <option value="Custom">Custom</option>
              </Form.Control>
            </Col>*/}
        {/*}  </Row> */}

          <Table className="cld_tableWrapper" responsive>
            <tbody>
              <tr className="headerRow">
                <th style={{ width: '20%' }} className="textBold">Role Name</th>
                <th style={{ width: '25%' }} className="textBold">Description</th>
                <th style={{ width: '13%', textAlign: 'center' }} className="textBold">Type</th>
                <th style={{ width: '15%', textAlign: 'center' }} className="textBold"># Permissions</th>
                <th style={{ width: '13%', textAlign: 'center' }} className="textBold"># Users</th>
                <th style={{ width: '13%', textAlign: 'center' }} className="textBold"># Teams</th>
                <th style={{ width:  '20px', textAlign: 'end' }} className="textBold">Actions</th>
              </tr>

              {filteredRoles.length === 0 ? (
                <tr>
                  <td colSpan={7}>
                    <div className="nodata-wrapper">
                      <Nodata />
                    </div>
                  </td>
                </tr>
              ) : (filteredRoles.map((role) => (
                <tr className="tableRow " key={role.id}>
                  <td className="td_centered">{role.role_name}</td>
                  <td className="td_centered">{role.role_description}</td>
                  <td style={{textAlign:'center'}} className="td_centered">{role.role_type == 'custom' ? 'Custom':'System'}</td>
                  <td className="td_centered">
                    <div className="devsItem" style={{ marginLeft:'auto', marginRight:'auto', marginTop:'20px', backgroundColor: 'var(--color-background-from)', textAlign:'center' }}>
                      {role.permit_count}
                    </div>
                  </td>
                  <td className="td_centered">
                    <div className="devsItem" style={{ marginLeft:'auto', marginRight:'auto', marginTop:'20px', backgroundColor: 'var(--color-background-from)', textAlign:'center' }}>
                      {role.user_count}
                    </div>
                  </td>
                  <td className="td_centered">
                    <div className="devsItem" style={{ marginLeft:'auto', marginRight:'auto', marginTop:'20px', backgroundColor: 'var(--color-background-from)', textAlign:'center' }}>
                      {role.team_count}
                    </div>
                  </td>
                  <td style={{ textAlign: 'end' }}>
                    <div className="actionsWrapper" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Dropdown className='my-auto'>
                        <Dropdown.Toggle className="" style={{ padding: '5px 10px', display: 'flex', justifyContent: 'center' }}>
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item key={1} onClick={() => openDrawer(role)}>{role.role_type !== 'Predefined' ? 'Edit' : 'View'}</Dropdown.Item>
                          { (role.role_type !== 'Predefined' && role.user_count==0 && role.team_count==0) && <Dropdown.Item key={2} onClick={() => {handleDelete(role)}}>Delete</Dropdown.Item> }
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              )))}
            </tbody>
          </Table>
        </div>
      )}

      <Drawer
             index='roles'
        isOpen={isDrawerOpen}
        toggleSidebar={justCloseDrawer}
        name={isEditing ? `Edit Role: ${currentRole?.role_name}` : 'Create Role'}
        subtitle='' /*{isEditing ? "Edit Role" : "Create Role1"}*/
      >
        <Form>
          <Form.Group>
            <Form.Label>Role Name</Form.Label>
            <Form.Control
            disabled= {blocked}
            required
              type="text"
              value={currentRole?.role_name || ''}
              onChange={(e) => {
                setCurrentRole((prevRole) => ({
                  ...prevRole,
                  role_name: e.target.value,
                }));
                setChangesMade(true);
              }
              }
              placeholder="Enter role name"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
            required
            disabled= {blocked}
              type="text"
              value={currentRole?.role_description || ""}
              onChange={(e) =>{
                setCurrentRole((prevRole) => ({
                  ...prevRole,
                  role_description: e.target.value,
                }));
                setChangesMade(true);
              
              }
              }
              placeholder="Enter description"
            />
          </Form.Group>
          <Form.Group>
            {/*<Form.Label>Permissions</Form.Label>*/}

            <Form.Group controlId="selectGA" className="mb-3">
              <Form.Label>Select Permits</Form.Label>
              <GenericSelectableList
                items={permissions} 
                searchKeys={['permit_code', 'description']} // Búsqueda por código o descripción
                onAdd={handleAddPermit} 
                onRemove={handleRemovePermit}
                renderLabel={(permit) => `${permit.permit_code} (${permit.description})`}
                placeholder="Select permissions..."
                multiOption={true} // Permitir selección múltiple
                selectedItems={listaRecuperados}
                setSelectedItems={setlistaRecuperados}
                itemSelected={''}
              />
            </Form.Group>



            {/*}
            <div>
              {["read", "write", "delete"].map((permission) => (
                <div key={permission} className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`toggle-${permission}`}
                    checked={currentRole?.permissions?.[permission] || false}
                    onClick={() => togglePermission(permission)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`toggle-${permission}`}
                  >
                    {permission.charAt(0).toUpperCase() + permission.slice(1)}
                  </label>
                </div>
              ))}
            </div>

*/}

          </Form.Group>

          
          <Row>
            <Col md='6'>
            {!isEditing ? (currentRole != null ?
                (currentRole.role_type != 'Predefined' &&
              <Button
              disabled={listaRecuperados.length==0 || !currentRole?.role_name}
                className="mt-3 w-100"
                variant="primary"
                onClick={handleSubmit}
              >
                
                {isEditing ? 'Update' : 'Save'}
                {loading && <Spinner animation="border" size="sm" />}
              </Button>
                )
                :
                <Button
                disabled={listaRecuperados.length==0 || !currentRole?.role_name}
                className="mt-3 w-100"
                variant="primary"
                onClick={handleSubmit}
              >
                
                {isEditing ? 'Update' : 'Save'}
      {loading && <Spinner animation="border" size="sm" />}
                  </Button>
            ):(
              currentRole != null ?
              (currentRole.role_type != 'Predefined' &&
            <Button
            disabled={!changesMade}
              className="mt-3 w-100"
              variant="primary"
              onClick={handleSubmit}
            >
              
              {isEditing ? 'Update' : 'Save'}
              {loading && <Spinner animation="border" size="sm" />}
            </Button>
              )
              :
              <Button
              disabled={!changesMade}
              className="mt-3 w-100"
              variant="primary"
              onClick={handleSubmit}
            >
              
              {isEditing ? 'Update' : 'Save'}
{loading && <Spinner animation="border" size="sm" />}
            </Button>

            )
            }

            </Col>
          </Row>
        </Form>
      </Drawer>


      <ConfirmDeletetModal 
          handleSubmit={()=>handleDeleteRole(selDeveloper)} 
          showModal={showDelete}
          onCloseModal={handleShowDelete}
          loading={false}
          error={''}
          success={''}
          name_to_check={'DELETE'}
          title_to_show={`Confirm delete role ${selTeam.role_name}`}
        />

        
    </DashLayout>
  );
};

export default RolesManagement;
