import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button, Form, InputGroup, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
/* import logo2 from '../../assets/img/logos/AVAP Cloud_Professional_Logo_white.svg';
import logo from '../../assets/img/logos/AVAP_Cloud_Business_Logo_white.svg'; */

import logo2 from '../../assets/img/logos/AVAP_Cloud_negativo.svg';
import logo from '../../assets/img/logos/AVAP_Cloud_negativo.svg';

import foto_professional from '../../assets/img/newAvapCloud/Foto_Cloud_latina.png';
import foto_cloud from '../../assets/img/newAvapCloud/Foto_Cloud_business.png';

import info from '../../assets/img/newDashboard/informacion.png';
import infoDark from '../../assets/img/newDashboard/informacionDark.png';

import avap_framework_mobile from '../../assets/img/mobile/logo_AVAPCloud_Professional.svg';
import succes_payment from '../../assets/img/mobile/ilustracion_PagoExito.svg';

import './Avapcloud.css';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage, Field, Formik } from 'formik';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { useHistory } from 'react-router-dom';
import PaymentConfirmation from '../../components/Modals/paymentConfirmation';
import AddCreditCard from '../../components/Modals/addCreditCard';
import SessionService from '../../services/SessionsService';
import SessionStorageHandler from '../../handlers/SessionStorageHandler';
import { firstMonthPrice } from '../../services/AmountService';
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json';

import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/Disclaimer';
import { Checkbox } from 'antd';
import { check } from 'prettier';


import icn_layer from '../../assets/img/landingPage/cld-ldn-layered.svg';
import icn_universal from '../../assets/img/landingPage/cld-ldn-universal.svg';
import icn_database from '../../assets/img/landingPage/cld-ldn-database.svg';
import background_cloud from '../../assets/img/landingPage/fondoApps.png';





const Avapcloud = () => {

  const analyticsTags = [
    'G-KKCW4SZLW4',

  ];


  const analytics = Analytics({
    app: 'Avap Cloud',
    plugins: [
      googleTagManager({
        containerId: 'GTM-W6MGQTXW'
      })
    ]
  })


  //ReactGA.initialize('UA-170480071-2');


  let { token } = useParams();
  const [e_mail, setE_Mail] = useState('');
  const [Pass_Word, setPass_Word] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [selectedOption, setSelectedOption] = useState('NO_AVAP_ID');
  const [reviewText, setReviewText] = useState('Register your information to obtain your membership');
  const [error, setError] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [proratedPrice, setProratedPrice] = useState(0);
  const [plansName, setPlansName] = useState('Cloud Bussines');
  const [signin, setSignin] = useState(false)
  const [formTouched, setFormTouched] = useState(false);
  const [formTouched2, setFormTouched2] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [card_id_stripe, setCardID] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cards_, setCards] = useState([]);
  const [newadded_card, setAddedCard] = useState('');
  const [susCription, setSusCription] = useState(2);
  const [annualPayment, setAnnualPayment] = useState(false);
  const [TotalPriceMonthly, settotalPriceMonthly] = useState(0)
  const [TotalPriceYear, setTotalPriceYear] = useState(0)
  const [ExtraProjectsPlan, setextraProjectsPlan] = useState(0);
  const [ProjectDesc, setprojectDesc] = useState('');
  const [ProjectPriceMonthly, setprojectPriceMonthly] = useState(0);
  const [ProjectPriceYear, setprojectPriceYear] = useState(0);
  const [showResume, setShowResume] = useState(false);
  const [formObject, setFormObject] = useState({})
  const [cardholder, setCardholder] = useState('');
  const [cvv, setCVV] = useState('');
  const [contracting, setcontracting] = useState(false);

  const [cardNumber, setCardNumber] = useState(0);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const yearInputRef = useRef(null);
  const cvvInputRef = useRef(null);

  const [yamarcado, setYamarcado] = useState(false);


  const [resumen, setResumen] = useState(false);
  const [resulta, setResulta] = useState(false);


  const [errorMail, seTerrorMail] = useState(false);
  const [errorContrasena, seTerrorContrasena] = useState(false);
  const [errorRepite, seTerrorRepite] = useState(false);
  const [errorNombre, seTerrorNombre] = useState(false);
  const [errorFecha, seTerrorFecha] = useState(false);
  const [errorTarjeta, seTerrorTarjeta] = useState(false);
  const [errorCVV, seTerrorCVV] = useState(false);
  const [errorCheck, seTerrorCheck] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const [tipo_contratacion, setTipoContratacion] = useState(0);

  const handleChangeCheck = (e) => {
    seTerrorCheck(!e.target.checked);
    setIsChecked(e.target.checked);
  };

  const handleCheckout = () => {
    let error = false;

    if (!(mail.length > 0)) { seTerrorMail(true); error = true; }
    if (!(repeatPassword.length > 0)) { seTerrorRepite(true); error = true; }
    if (!(password.length > 0)) { seTerrorContrasena(true); error = true; }
    if (!(mail.length > 0)) { seTerrorTarjeta(true); error = true; }
    if (!error && !errorMail && !errorCheck && !errorMail && !errorRepite) {
      //setResumen(true);



      handlePayMobile();





    }
  }

  const handleChangeEmail = (e) => {
    setE_Mail(e.target.value);
    console.log(e_mail);
  }

  const handleChangePassword = (e) => {
    setPass_Word(e.target.value);
    console.log(Pass_Word);
  }

  const handlePayMobile = async () => {
    const payload = {
      suscriptionId: susCription,
      email: mail,
      password: password,
      cardNumber: cardNumber,
      month: month,
      year: year,
      cvv: cvv,
      annual: false
    }
    setcontracting(true);
    try {
      await post_signup_avapcloud_mobile(payload);
    } catch {
      setcontracting(false);
    }
    setcontracting(false);
  }

  const changeMonth = (e) => {
    const newMonth = e.target.value;
    const regex = /^(0?[1-9]|1[0-2])$/;
    if (!regex.test(newMonth)) {
      seTerrorFecha(true);

    } else {
      seTerrorFecha(false);

    }
    setMonth(newMonth);
    if (newMonth.toString().length == e.target.maxLength) {
      yearInputRef.current.focus();
    }



  }

  const changeYear = (e) => {
    const newYear = e.target.value;

    const regex = /^\d{4}$/;
    if (!regex.test(newYear)) {
      seTerrorFecha(true);

    } else {
      seTerrorFecha(false);

    }
    setYear(newYear);
    if (newYear.toString().length == e.target.maxLength) {
      cvvInputRef.current.focus();
    }

  }

  const changeCardholder = (e) => {
    const newCardholder = e.target.value;
    setCardholder(newCardholder);
  }

  const changeCVV = (e) => {
    const newCVV = e.target.value;
    setCVV(newCVV);
  }

  const changeCardNumber = (e) => {


    let newCardNumber = e.target.value.split(' ').join(''); // remove hyphens
    const regex = /^(\d{4}[-\s]?){3}\d{4}$/;
    if (!regex.test(newCardNumber)) {
      seTerrorTarjeta(true);

    } else {
      seTerrorTarjeta(false);

    }
    setCardNumber(newCardNumber);
    if (newCardNumber.length > 0) {
      newCardNumber = newCardNumber.match(new RegExp('.{1,4}', 'g')).join(' ');
      e.target.value = newCardNumber;
    }
  }

  const changeCardholder2 = (e) => {



    const newCardholder = e.target.value;
    const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:\s[A-Za-zÀ-ÖØ-öø-ÿ]+)*$/;
    if (!regex.test(newCardholder)) {
      seTerrorNombre(true);
    } else {

      seTerrorNombre(false);
    }
    setCardholder(newCardholder);
  }


  const changeMail = (e) => {

    const newMail = e.target.value;
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(newMail)) {
      seTerrorMail(true);
    } else {


      seTerrorMail(false);
    }
    setMail(newMail);
  };


  const ChangeRePass = (e) => {
    const newPass = e.target.value;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d])[a-zA-Z\d\W_]{8,}$/
    if (!regex.test(newPass) || newPass != password) {
      seTerrorRepite(true);
    } else {
      seTerrorRepite(false);
      console.log(newPass);
    }
    changerepPass(newPass);
  };


  const [showPassword2, setShowPassword2] = useState(false);

  const handleShowPassword2 = () => { setShowPassword2(!showPassword2) };



  const [monthlyPrice, setMonthlyPrice] = useState(0)


  const [success, setSuccess] = useState('');
  const [parte, setParte] = useState('AVAP_ID')
  const history = useHistory();


  const [mail, setMail] = useState('');
  //const [password, setPassword] = useState('');
  const [repeatPassword, changerepPass] = useState('');
  const [signining, setSignining] = useState(false);




  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }


  const handleSwitch = () => {
    const yy = !annualPayment;
    setAnnualPayment(!annualPayment);
    const p = yy ? (firstMonthPrice(monthlyPrice) + (monthlyPrice * (9))) : firstMonthPrice(monthlyPrice);
    setProratedPrice(p)
    setTotalPrice(yy ? (monthlyPrice * 10).toFixed(2) : (monthlyPrice).toFixed(2))
  }


  const onCloseAction = () => {

    setShow(false);
  }

  const OnSuccesAction = () => {

    setShow(false)
    if (!error) {
      history.push(`${tipo_contratacion == 1 ? '/business-success_en' : '/professional_en-success'}`);
    }
    setShow(false);
  }

  const makePayment = async () => {
    try {
      startPayment();
      setLoading(true);

      const payload = { card_stripe_id: card_id_stripe, annual: annualPayment };
      const result = await ObexRequestHandler.post('/signup_avapcloud', payload);

      const { success, message, data } = result;
      if (!success) {
        setShowResume(false);
        setResumen(false);
        throw { message };
      }
      setSuccess(data);
      OnSuccesAction();

    } catch (error) {
      setShowResume(false);
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA PROJECTS SLOTS ', error);
    }
    setSignin(false);
    setLoading(false);
  }
  const handleClick = (option) => {
    setSelectedOption(option);
    setError('');
    if (option === 'AVAP_ID') {
      setReviewText('Register your information to obtain your membership');
    } else if (option === 'NO_AVAP_ID') {
      setReviewText('Register your information to obtain your membership');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const changePass = (e) => {

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d])[a-zA-Z\d\W_]{8,}$/
    if (!regex.test(e.target.value)) {
      seTerrorContrasena(true);
    } else {
      seTerrorContrasena(false);

    }
    setPassword(e.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formatCardNumber = (value) => {
    const cleaned = value.replace(/\D+/g, '');
    const match = cleaned.match(/.{1,4}/g);
    return match ? match.join(' ') : '';
  };

  const handleKeyDown = (event) => {
    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'Tab') {
      event.preventDefault();
    }
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email required')
      .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Incorrect email'),
    password: Yup.string()
      .required('Password required')
      .matches(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,200}$/, 'The password must be 8 characters long and include at least one letter and one number'),
    terms: Yup.boolean()
      .oneOf([true], 'Accept the Terms and Conditions')
      .required('Accept the Terms and Conditions'),
  });

  const SignupSchema2 = Yup.object().shape({
    email: Yup.string()
      .required('Email required')
      .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Incorrect email'),
    password: Yup.string()
      .required('Password required')
      .matches(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,200}$/, 'The password must be 8 characters long and include at least one letter and one number'),
    password2: Yup.string()
      .required('Password required')
      //.matches(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,200}$/, 'La contraseña debe tener 8 caracteres e incluir al menos una letra y un número'),
      .oneOf([Yup.ref('password'), null], 'The passwords must match.'),
    /*
      cardNumber: Yup.string()
      .required('Se requiere una número de tarjeta')
    // .matches(/^\d{13,18}$/, 'Must have more than 13 numbers or less than 18 numbers')
    // .min(13, 'Card number invalid')
    // .max(18, 'Card number invalid')
    ,
    month: Yup.number()
      .required('mes requerido')
      .min(1, 'mes inválido')
      .max(12, 'mes inválido'),
      
    year: Yup.date()
    .required('año requerido')
    .min(
      new Date().getFullYear(),
      'año inválido'
    ),
    cvv: Yup.string()
      .required('cvv requerido')
      .min(3, 'cvv inválido'), */
    terms: Yup.boolean()
      .oneOf([true], 'Accept the Terms and Conditions')
      .required('Accept the Terms and Conditions'),

  });

  const post_login_avapCloud = async (valueForm) => {
    setLoading(true);

    try {
      const result = await SessionService.login(valueForm.email, valueForm.password, false);

      if (!result.error) {

        setError(result.error);
        getCards();

        setShow(true);

      } else {
        if (result.error === 'sesion_existente' || result.status) {
          SessionStorageHandler.setUserSessionData(result.id_sesion, result.id_usuario);
          setShow(true);

        } else {
          if (result.error === 'cuenta_bloqueada') {
            setError('Your account has been locked.');
          }
          throw 'Incorrect username or password';
        }

      }

      // const result = (await ObexRequestHandler.post('/unique_signin', {
      //   email: valueForm.email,
      //   password: valueForm.password

      // }));
      // console.log(result);
      // if (result.success == false) {
      //   setError(result.message);
      //   setTimeout((): void => setError(''), 5000);
      // } else {
      //   console.log('Login success');
      //   setShow(true);
      // }
    } catch (error) {
      console.error('ERROR POST', error);
      setError(error.message);
      setTimeout((): void => setError(''), 5000);

    }
  };
  // const post_login_avapCloud = async (valueForm) => {
  //   try {
  //     const result = (await ObexRequestHandler.post('/signup_avapcloud', {
  //       email: valueForm.email,
  //       password: valueForm.password

  //     }));
  //     if (result.success == false) {
  //       setError(result.message.replace('Cannot signup.: ', ''))
  //       setTimeout((): void => setError(''), 5000);
  //     } else {
  //       console.log('Login success');
  //     }
  //   } catch (error) {
  //     console.error('ERROR POST', error);
  //     setError(error);
  //     setTimeout((): void => setError(''), 5000);

  //   }
  // };

  const post_signup_avapcloud_mobile = async (valueForm) => {
    try {

      setSignin(true);
      const result = (await ObexRequestHandler.post('/signup_avapcloud', {
        suscriptionId: susCription,
        email: valueForm.email,
        password: valueForm.password,
        cardNumber: valueForm.cardNumber,
        cardMonthExp: valueForm.month,
        cardYearExp: valueForm.year,
        cardCvv: valueForm.cvv,
        annual: annualPayment
      }));
      setSignin(false);
      setLoading(false);
      if (result.success == false) {
        setShowResume(false);
        setResumen(false);
        setError(result.message.replace('Cannot signup.: ', ''));
        setTimeout((): void => setError(''), 5000);

      } else {
        setResumen(false); setResulta(true)
        history.push(`${tipo_contratacion == 1 ? '/business-success_en' : '/professional-success_en'}`);
      }
      setSignin(false);
    } catch (error) {
      setShowResume(false);
      setResumen(false);
      console.error('ERROR POST', error);
      setError(error.message);
      setTimeout((): void => setError(''), 5000);

      setSignin(false)
    }
    setSignin(false);
  };

  const post_signup_avapcloud = async (valueForm) => {
    try {
      setResumen(false);
      setSignin(true);
      const result = (await ObexRequestHandler.post('/signup_avapcloud', {
        suscriptionId: tipo_contratacion === 0 ? 1 : 2,
        email: valueForm.email,
        password: valueForm.password,
        cardNumber: valueForm.cardNumber,
        cardMonthExp: valueForm.month,
        cardYearExp: valueForm.year,
        cardCvv: valueForm.cvv,
        annual: annualPayment
      }));
      setSignin(false);
      setLoading(false);
      if (result.success == false) {
        setResumen(false);
        setShowResume(false);
        setError(result.message.replace('Cannot signup.: ', ''));
        setTimeout((): void => setError(''), 5000);

      } else {
        console.log('Login success');
        history.push(`${tipo_contratacion == 0 ? '/professional-success_en' : '/business-success_en'}`);

      }
      setSignin(false);
    } catch (error) {
      console.error('ERROR POST', error);
      setError(error.message);
      setResumen(false);
      setShowResume(false);
      setTimeout((): void => setError(''), 5000);

      setSignin(false)
    }
    setSignin(false);
  };
  const getPricingPlan = async (id) => {
    try {
      const pricing = (
        await ObexRequestHandler.get(`/pricing_plan?id=${id}`, {}, true)
      ).data || {};
      //console.log(pricing);
      setTotalPrice(annualPayment ? pricing.subscription_monthly_price * 10 : pricing.subscription_monthly_price);
      setMonthlyPrice(pricing.subscription_monthly_price);
      //console.log(pricing.subscription_monthly_price);

      const p = annualPayment ? (firstMonthPrice(pricing.amount) + (pricing.amount * (9))) : firstMonthPrice(pricing.amount);
      setProratedPrice(p);
      setPlansName(pricing.subscription_name)
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
    }
  }

  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          if (cards_.some(e => e.id == c.id)) {
            //console.log('exists');
          } else {
            setAddedCard(c.id);
          }
        }
      }
      setCards(pmCards);

    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }





  useEffect(() => {

    if (yamarcado == false) {
      getCards();

      if (config.url === 'https://acl.avapcloud.mooo.com') analytics.page()
      //console.log(window.location.pathname.toString());
      if (window.location.pathname.toString() === '/professional_en') token = 'professional';
      if (window.location.pathname.toString() === '/business') token = 'business';

      if (token === 'professional') {
        setSusCription(1)
        getPricingPlan(1);

      }
      else {

        getPricingPlan(2);
        setSusCription(2);
      }

      analyticsTags.forEach(tag => {
        ReactGA.initialize(tag);
      });




      if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
        ReactGA.initialize('G-KKCW4SZLW4');
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Cloud Sign In Page' });
      }

      setYamarcado(true);

    }

  });

  const handleModal = () => {
    getCards();
    setShowModal(!showModal);
    console.log('HANDLE MODAL')
    getCards();
  };


  const handleCard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    if (value == 'addCard') {
      handleModal();
    } else {
      setCardID(value);

    }
    console.log(value);
    e.preventDefault();
    e.stopPropagation();

  }

  return (

    <>{<BrowserView>

      <div className="pr-wrapper" style={{ backgroundImage: `url(${background_cloud})` }}>

        <div className="pr-left">
          <div className="pr-left-container">
            <div className='pr-logo-container'>
              <img
                id='logo'
                src={ tipo_contratacion == 0 ? logo2 : logo}
                alt="Logo" />
            </div>

            <div className="pr-card">

              <div className="pr-info">
                <div className="pr-info-title">
                  Start your 30-day FREE trial today
                </div>
                <div className="pr-info-description">
                  Get started, register and access your membership
                </div>
              </div>

              {/* Botones de selección */}
              <div className="pr-card-buttons">
                <button
                  className={selectedOption === 'NO_AVAP_ID' ? 'btn-pricing-card selected' : ' btn-pricing-card'}
                  onClick={() => handleClick('NO_AVAP_ID')}
                >No AVAP ID

                </button>
                <button
                  className={selectedOption === 'AVAP_ID' ? 'btn-pricing-card selected' : ' btn-pricing-card'}
                  onClick={() => handleClick('AVAP_ID')}
                  disabled={signin}
                >I have AVAP ID

                </button>
              </div>

              {selectedOption === 'AVAP_ID' && (

                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                    terms: false
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    // handleContinueClick2(values);


                    /*
                    post_login_avapCloud(values);
                    console.log(values);*/



                    // setShowResume(true);

                    setFormObject(values);

                    setSubmitting(false);

                    post_signup_avapcloud(values);


                    setSubmitting(false); // Esto es para volver a habilitar el botón después de enviar
                  }}
                >
                  {({ isSubmitting, errors, touched, handleSubmit, handleBlur }) => (
                    <div>
<InputGroup className="mb-3">
  <Form.Control
    as="select"
    className="inputs"
    value={tipo_contratacion === 0 ? "professional" : "business"}
    onChange={(e) => setTipoContratacion(e.target.value === "professional" ? 0 : 1)}
    style={{ color: "#6c757d", height:'54px' }}
  >
    <option value="professional">AVAP Cloud Professional</option>
    <option value="business">AVAP Cloud Business</option>
  </Form.Control>
</InputGroup>
                      <Field
                        id='email_entrada'
                        placeholder="Email address"
                        className="inputs"
                        name="email"
                        required
                        disabled={signin}
                        onBlur={(e) => {
                          handleBlur(e);
                          setFormTouched(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                        }}
                        style={{ width: '100%', }} />
                      {false && errors.email && touched.email && (
                        <div className="mt-2 msgError">{errors.email}</div>
                      )}
                      <InputGroup className="mt-3">
                        <Field
                          id='password_entrada'
                          required

                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          disabled={signin}
                          placeholder="Password"
                          className="inputs borderRadiusLeftLogin form-control"
                          style={{ paddingLeft: '10px', height: '54px' }}
                          onBlur={(e) => {
                            handleBlur(e);
                            setFormTouched(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                          }} />
                        <InputGroup.Append>
                          <Button
                            onClick={handleShowPassword}
                            variant="outline-secondary"
                            style={{
                              borderTopRightRadius: 5,
                              borderBottomRightRadius: 5,
                              borderTop: '1px solid #ced4da',
                              borderRight: '1px solid #ced4da',
                              borderBottom: '1px solid #ced4da',
                              borderLeft: '1px solid #ffff',
                              height: '100%',
                            }}
                          >
                            {showPassword ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                style={{ color: '#AFAFAF' }} />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{ color: '#AFAFAF' }} />
                            )}
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                      {false && errors.password && touched.password && (
                        <div className="mt-2 msgError">
                          {errors.password}
                        </div>
                      )}
                      <div className='pr-form-errors'>
                        {(errors.email != undefined && touched.email)
                          && (
                            <Alert style={{ transform: 'translateY(130px)', marginTop: '80px', zIndex: 999 }} variant='danger mt-3'>
                              {errors.email}
                            </Alert>
                          )}

                        {(errors.password != undefined && touched.password)
                          && (
                            <Alert style={{ transform: 'translateY(130px)', zIndex: 999 }} variant='danger mt-3'>
                              {errors.password}
                            </Alert>
                          )}


                        {/*}
    <div style={{ marginTop:'15px', height:'54px', width:'100%', borderColor:'#ced4da', borderWidth:'1px', borderStyle:'solid', borderRadius:'5px', display:'flex' }}>
    <div className='divI3' style={{ width:'94%', marginTop:'auto', marginBottom:'auto', paddingLeft:'15px' }}>Pago Anual</div>
    
    <Form className="obex-form" style={{ width:'5%', marginTop:'auto', marginBottom:'auto', marginLeft:'0%' }}>
  <Form.Row >

      <Form.Check
        type="switch"
        id="custom-switch"
        label=""
        onChange={handleSwitch}
        className="obex-switch"
      />

  </Form.Row>
</Form>

    </div>
    
*/}
                      </div>
                      <div style={{ display: 'flex', marginTop: '10px' }} className="checkbox-containerAVAP">
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>

                            <Field disabled={signin} type="checkbox" name="terms" id="" onBlur={(e) => {
                              handleBlur(e);
                              setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                            }} />


                            <div className='pr-terms-description'>
                              I accept the&nbsp;<a href='http://legal.101obex.com/licenses/cloud' target='_blank' rel='noreferer' >Terms and Conditions</a>&nbsp; and the&nbsp;  <a href='http://legal.101obex.com/licenses/privacy' target='_blank' rel='noreferer'> Privacy Policy.</a>
                            </div>
                          </div>
                          {/* <div className="fontText1">
          * No se realizará ningún cargo a la tarjeta hasta concluir el periodo de prueba.
        </div> */}
                        </div>
                      </div>

                      <div style={{height:'70px'}}>

                      </div>
                      {false && errors.terms && touched.terms && (
                        <div className="mt-2 msgError">{errors.terms}</div>
                      )}

                      {/* <Button type="submit" className=" custom-button" style={{ display: "block", width: "40%" }} onClick={() => handleSubmit()} disabled={isSubmitting}>
      Continue
    </Button> */}

                      {error != '' &&
                        <Alert style={{ zIndex: 999, position: 'absolute', width: '34%' }} variant='danger mt-3'>
                          {error}
                        </Alert>}

                      <button type="button" className="glowBtn"
                        onClick={async () => {

                          if (selectedOption === 'AVAP_ID' && false) {
                            //as HTMLInputElement
                            const ent_email = document.getElementById('email_entrada') as HTMLInputElement;
                            const ent_password = document.getElementById('password_entrada') as HTMLInputElement;
                            await SessionService.login(ent_email.value, ent_password.value, false)
                            const result = (await ObexRequestHandler.post('/unique_signin', {
                              email: ent_email.value,
                              password: ent_password.value

                            }));
                            console.log(result);


                            if (result.success == false) {
                              setError(result.message);
                              setTimeout((): void => setError(''), 5000);
                            } else {
                              console.log('Login success');
                              setError(''); setSuccess(''); handleSubmit();
                            }



                          } else {
                            setError(''); setSuccess(''); handleSubmit();
                          }


                        }}
                        disabled={signin || isSubmitting || Object.keys(errors).length > 0 || !formTouched}
                      >
                        <span className='btnInner' style={{ padding: '10px 40px' }}> Continue</span>
                      </button>

                    </div>
                  )}

                </Formik>

              )}



              {selectedOption === 'NO_AVAP_ID' && (
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                    password2: '',
                    terms: false
                  }}
                  validationSchema={SignupSchema2}
                  onSubmit={(values, { setSubmitting }) => {

                    //   if (values.month.length < 2) values.month = '0' + values.month;
                    //post_signup_avapcloud(values);
                    //setShowResume(true);
                    setResumen(false);
                    setFormObject(values);
                    post_signup_avapcloud(values);
                    setSubmitting(false); // Esto es para volver a habilitar el botón después de enviar
                  }}
                >
                  {({ values, isSubmitting, errors, touched, handleSubmit, handleBlur, setFieldValue }) => (
                    <div>

<InputGroup className="mb-3">
  <Form.Control
    as="select"
    className="inputs"
    value={tipo_contratacion === 0 ? "professional" : "business"}
    onChange={(e) => setTipoContratacion(e.target.value === "professional" ? 0 : 1)}
    style={{ color: "#6c757d", height:'54px' }}
  >
    <option value="professional">AVAP Cloud Professional</option>
    <option value="business">AVAP Cloud Business</option>
  </Form.Control>
</InputGroup>

                      <Field
                        placeholder="Email address"
                        className="inputs"
                        name="email"
                        required
                        disabled={signin}
                        style={{ width: '100%' }}
                        onBlur={(e) => {
                          handleBlur(e);
                          setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                        }} />

                      {false && errors.email && touched.email && (
                        <div className="mt-2 msgError">{errors.email}</div>
                      )}
                      <InputGroup className="mt-3">
                        <Field
                          required
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Password"
                          name="password"
                          disabled={signin}
                          className="inputs borderRadiusLeftLogin  form-control"
                          style={{ paddingLeft: '10px', height: '54px' }}
                          onBlur={(e) => {
                            handleBlur(e);
                            setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                          }} />
                        <InputGroup.Append>
                          <Button
                            onClick={handleShowPassword}
                            variant="outline-secondary"
                            style={{
                              borderTopRightRadius: 5,
                              borderBottomRightRadius: 5,
                              borderTop: "1px solid #ced4da",
                              borderRight: "1px solid #ced4da",
                              borderBottom: "1px solid #ced4da",
                              borderLeft: "1px solid #ffff",
                              height: "100%",
                            }}
                          >
                            {showPassword ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                style={{ color: "#AFAFAF" }} />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{ color: "#AFAFAF" }} />
                            )}
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>

                      {false && errors.password && touched.password && (
                        <div className="mt-2 msgError">{errors.password}</div>
                      )}


                      <InputGroup className="mt-3">
                        <Field
                          required
                          type={showPassword2 ? 'text' : 'password'}
                          placeholder="Repeat the password"
                          name="password2"
                          disabled={signin}
                          className="inputs borderRadiusLeftLogin  form-control"
                          style={{ paddingLeft: '10px', height: '54px' }}
                          onBlur={(e) => {
                            handleBlur(e);
                            setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                          }} />
                        <InputGroup.Append>
                          <Button
                            onClick={handleShowPassword2}
                            variant="outline-secondary"
                            style={{
                              borderTopRightRadius: 5,
                              borderBottomRightRadius: 5,
                              borderTop: "1px solid #ced4da",
                              borderRight: "1px solid #ced4da",
                              borderBottom: "1px solid #ced4da",
                              borderLeft: "1px solid #ffff",
                              height: "100%",
                            }}
                          >
                            {showPassword2 ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                style={{ color: "#AFAFAF" }} />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{ color: "#AFAFAF" }} />
                            )}
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>

                      {false && errors.password2 && touched.password2 && (
                        <div className="mt-2 msgError">{errors.password2}</div>
                      )}


                      {/*}
    <Field
      name="cardNumber"
      render={({ field }) => (
        <input
          {...field}
          placeholder="Número de tarjeta"
          disabled={signin}
          className="inputs mt-3"
          inputMode="numeric"
          minLength="13"
          maxLength="19"
          required
          style={{ width: "100%" }}
          value={values.cardNumber}
          onChange={(e) => {
            const formattedValue = formatCardNumber(e.target.value);
            setFieldValue('cardNumber', formattedValue);
          } }
          onBlur={(e) => {
            handleBlur(e);
            setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
          } } />
      )} />


    {false && errors.cardNumber && touched.cardNumber && (
      <div className="mt-2 msgError">{errors.cardNumber}</div>
    )}
*/}

                      {/*}
    <div style={{ display: "flex", width: "60%" }} className="mt-3">
      <div style={{ width: "40", display: "flex" }}>
        <Field disabled={signin}
          className="inputsCVV"
          type="text"
          placeholder="Mes"
          name="month"
          inputMode="numeric"
          pattern="[0-9]*"
          onKeyDown={handleKeyDown}
          maxLength="2"
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, width: "50%", borderRight: "none", marginBottom: "0" }}
          onBlur={(e) => {
            handleBlur(e);
            setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
          } } />
        <div id="divided" className="d-flex align-items-center " style={{ marginLeft: "-2px" }}>
          <div className="line border" style={{ height: "70%" }}></div>
        </div>

        <Field disabled={signin} className="inputsCVV" type="text" placeholder="Año" name="year" inputMode="numeric" pattern="[0-9]*" onKeyDown={handleKeyDown} minLength="4" maxLength="4" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: "50%", borderLeft: "none", marginBottom: "0" }} onBlur={(e) => {
          handleBlur(e);
          setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
        } } />

      </div>

      <div style={{ width: "100%", marginLeft: "15px" }}>
        <Field disabled={signin} className="inputsCVV" placeholder="CVV" name='cvv' minLength="3" maxLength="4" inputMode="numeric"
          pattern="[0-9]*" onKeyDown={handleKeyDown} style={{ width: "60%", marginBottom: "0" }} onBlur={(e) => {
            handleBlur(e);
            setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
          } } />
      </div>
    </div>

    {false && errors.month && touched.month && (
      <div className="mt-2 msgError">{errors.month}</div>
    )}


    {false && errors.year && touched.year && (
      <div className="mt-2 msgError">{errors.year}</div>
    )}

    {false && errors.cvv && touched.cvv && (
      <div className="mt-2 msgError">{errors.cvv}</div>
    )}
*/}

                      {/*}
    <div style={{ marginTop:'15px', height:'54px', width:'100%', borderColor:'#ced4da', borderWidth:'1px', borderStyle:'solid', borderRadius:'5px', display:'flex' }}>
                      <div className='divI3' style={{ width:'94%', marginTop:'auto', marginBottom:'auto', paddingLeft:'15px' }}>Pago Anual</div>
                      
                      <Form className="obex-form" style={{ width:'5%', marginTop:'auto', marginBottom:'auto', marginLeft:'0%' }}>
                    <Form.Row >
             
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label=""
                          onChange={handleSwitch}
                          className="obex-switch"
                        />
       
                    </Form.Row>
                  </Form>
    
                      </div>
    
    */}
                      <div style={{ display: 'flex', marginTop: '10px' }} className="checkbox-containerAVAP">
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>

                            <Field disabled={signin} type="checkbox" name="terms" id="" onBlur={(e) => {
                              handleBlur(e);
                              setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                            }} />


                            <div className="pr-terms-description">

                              I accept the&nbsp;<a href='http://legal.101obex.com/licenses/cloud' target='_blank'>Terms and Conditions</a> &nbsp;and the  &nbsp;<a href='http://legal.101obex.com/licenses/privacy' target='_blank'>Privacy Policy.</a>
                            </div>
                          </div>
                          {/* <div className="fontText1">
          * No se realizará ningún cargo a la tarjeta hasta concluir el periodo de prueba.
        </div> */}
                        </div>
                      </div>

                      {false && errors.terms && touched.terms && (
                        <div className="mt-2 msgError">{errors.terms}</div>
                      )}
                      <div className='pr-form-errors'>
                        {(errors.email != undefined && touched.email)
                          && (
                            <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
                              {errors.email}
                            </Alert>
                          )}

                        {(errors.password != undefined && touched.password)
                          && (
                            <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
                              {errors.password}
                            </Alert>
                          )}
                        {(errors.password2 != undefined && touched.password2)
                          && (
                            <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
                              {errors.password2}
                            </Alert>
                          )}

                        {/*}
      {(errors.cardNumber != undefined && touched.cardNumber)
        && (
          <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
            {errors.cardNumber}
          </Alert>
        )}

      {(errors.month != undefined && touched.month)
        && (
          <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
            {errors.month}
          </Alert>
        )}
      {(errors.year != undefined && touched.year)
        && (
          <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
            {errors.year}
          </Alert>
        )}
      {(errors.cvv != undefined && touched.cvv)
        && (
          <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
            {errors.cvv}
          </Alert>
        )}

        */}
                        {(errors.terms != undefined && touched.terms)
                          && (
                            <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
                              {errors.terms}
                            </Alert>
                          )}
                      </div>



                      <button type="button"
                        className="glowBtn"
                        onClick={() => { if (!signin) { setError(''); setSuccess(''); handleSubmit(); } }}
                        disabled={signin || isSubmitting || Object.keys(errors).length > 0 || !formTouched2}
                      >


                        <span className='btnInner' style={{ padding: '10px 40px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '10px' }}> Continue {signin && <Spinner animation="border" size="sm" style={{ color: '#fff' }} />}</span>
                      </button>
                      {error != '' &&
                        <Alert style={{ zIndex: 999, marginTop: '-100px' }} variant='danger mt-3'>
                          {error}
                        </Alert>}

                    </div>
                  )}

                </Formik>
              )}


            </div>
          </div>
        </div>
        <div className="pr-right">


          <div className='pr-right-container'>
            <div className="pr-banner">
              <p className='pr-banner-title'>30-Day FREE TRIAL</p>
              <p className='pr-banner-description'>Unlock your business’s full potential with AVAP Cloud</p>
            </div>
            <div className="pr-title">
              Powerful API Lifecycle Management Platform
            </div>
            <div className="pr-description">
              Manage the entire API lifecycle in a SaaS model from design to testing and deployment
            </div>
            <div className="pr-leyend">
              Powered by AVAP technology, virtuality and artificial intelligence will be in the core of every API and API product you develop.
            </div>
            <div className="pr-pills">
              <div className="pr-pill">
                <img src={icn_layer} alt="Three layered Infrastructure" />
                <div className="pr-pill-title">
                  Three layered Infrastructure
                </div>

              </div>
              <div className="pr-pill">
                <img src={icn_universal} alt="Universal Platform" />
                <div className="pr-pill-title">


                  Universal Platform
                </div>
              </div>
              <div className="pr-pill">
                <img src={icn_database} alt="Database Control" />
                <div className="pr-pill-title">
                  Database Control

                </div>
              </div>
            </div>
          </div>









          <div id='pr-oldInfo' style={{ display: 'none' }}>
            {showResume ?
              <div className="formI">
                <h2 className="hI" style={{ marginTop: '0px' }}>Confirm your subscription</h2>

                <div className="divI1">
                  <div className="divI2 boxShadowCardBottom">{plansName === 'Professional' || window.location.pathname == '/professional_en' ? 'AVAP Cloud Professional' : plansName}</div>
                  <div className="divI3">
                    <div>Monthly membership</div>
                    <div>${parseInt(totalPrice).toFixed(2)} {(annualPayment ? 'USD/año' : 'USD/mes')}</div>
                  </div>
                </div>
                {/*}
            <div className="divI1">
              <div className="divI2 boxShadowCardBottom">Add Ons</div>
              <div className="divI3">
                <div>One more project</div>
                <div>24€/month</div>
              </div>
            </div>
            {*/}
                <div className="divI1">
                  <div className="divI4 boxShadowCardBottom">
                    <div style={{ color: '#147BCE', fontFamily: "Regular Intro Bold Alt" }}>
                      Total Price
                    </div>
                    <div style={{ fontFamily: "Regular Intro Bold Alt", color: "black" }}>
                      ${parseInt(totalPrice).toFixed(2)} USD
                    </div>
                  </div>
                  <div className="divI3">
                    At the end of the 30-day trial period, on the first day of each month, the full fee plus additional charges will be billed. The first month of service will be charged at a prorated rate. ${parseInt(proratedPrice.toString()).toFixed(2)}/month.
                  </div>
                </div>
                <div style={{ display: 'flex' }}>

                  <div style={{ width: '50%' }}>*Taxes may apply</div> <div style={{ width: '50%', textAlign: 'end' }}>
                    <button onClick={() => {

                      if (selectedOption == 'AVAP_ID' && false) setShow(true); else post_signup_avapcloud(formObject);

                    }} style={{ alignSelf: 'flex-end' }}
                      className='custom-button-blue textBold'>Subscribe{loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}</button></div>
                </div>
                {/*}
              <Form className="obex-form">
                    <Form.Row>
                      <Col md="6">
                        <Form.Check
              
                          type="switch"
                          id="custom-switch"
                          label="Annual Payment"
                          onChange={handleSwitch}
                          className="obex-switch"
                        />
                        </Col>
                        <Col md="6" className="text-right">
                          <span>*Taxes may apply</span>
                        </Col>
                    </Form.Row>
                  </Form>
    */}

              </div>

              : <>
                <div id="principal" style={{ paddingBottom: '40px' }}>

                  <div style={{
                    padding: '10px',
                    marginTop: '20px',
                    marginLeft: '80px',
                    marginRight: '0px',

                    backgroundColor: '#d9d9d9',
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    display: 'flex'
                  }}>

                    {window.location.pathname === '/professional_en' ?
                      <div style={{ width: '80%' }}>
                        <div className='landingFont2 textBold' style={{ color: 'black' }}>
                          Manage you API projects
                        </div>

                        <div className='landingFont2 textBold' style={{ color: 'black' }}>
                          like a pro with AVAP Cloud
                        </div>
                      </div>
                      :
                      <div style={{ width: '80%' }}>
                        <div className='landingFont2 textBold' style={{ color: 'black' }}>
                          test
                        </div>

                        <div className='landingFont2 textBold' style={{ color: 'black' }}>
                          potential with AVAP Cloud
                        </div>
                      </div>

                    }
                    <div style={{ lineHeight: '25px', width: '20%', borderLeftStyle: 'solid', borderLeftWidth: '1px', borderLeftColor: 'white', paddingLeft: '20px' }}>

                      <div className='textBold landingFont2'>30-Day</div>
                      {/* <div className='textBold'>Day</div> */}
                      <div className='textBold landingFont2' style={{ color: '#4a76b8' }}>FREE <br /> TRIAL</div>

                    </div>

                  </div>

                  <div style={{
                    padding: '10px',
                    marginTop: '20px',
                    marginLeft: '80px',
                    marginRight: '80px',
                    backgroundColor: 'transparent',
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    display: 'flex'
                  }}>

                    {token == 'professional' || window.location.pathname.toString() === '/professional_en' ?
                      <div className='textBold landingFont3b' style={{ color: 'black', fontSize: '' }}>Boost your career with our comprehensive API lifecycle development and management platform.</div>
                      :
                      <div className='textBold landingFont3b' style={{ color: 'black', fontSize: '' }}>Accelerate your projects with our platform for API lifecycle development and management.</div>
                    }
                  </div>


                  <div style={{ width: '100%', display: 'flex' }}>

                    <div style={{ width: '50%', justifyContent: 'center', textAlign: 'center', marginTop: '50px' }}>
                      <img
                        src={token == 'professional' || window.location.pathname == '/professional_en' ? foto_professional : foto_cloud}
                        style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '42px', width: '70%', maxWidth: '100%' }}
                        alt="Logo" />
                    </div>

                    {token == 'professional' || window.location.pathname == '/professional_en' &&
                      <div style={{ width: '50%', textAlign: 'start' }}>

                        <div className='landingFont3b textBold' style={{ color: '#0473bf', marginTop: '22px', paddingRight: '80px', marginBottom: '20px', fontSize: '1vw' }}>AVAP Cloud provides you with the tools:</div>
                        <div style={{ paddingLeft: '10px', paddingRight: '80px', fontSize: '1vw' }}>
                          <li>Advanced infrastructure and technology</li>
                          <li>Professional-grade development</li>
                          <li>Access to tools for designing, deploying, securing, and monitoring your APIs</li>
                          <li>Professional growth</li>
                        </div>

                        <div className='landingFont3b textBold' style={{ color: '#0473bf', marginTop: '42px', paddingRight: '80px', fontSize: '1vw', marginBottom: '20px' }}>AVAP Cloud Advantages: </div>
                        <div style={{ paddingLeft: '10px', paddingRight: '80px', fontSize: '1vw' }}>
                          <li>Efficient design of virtual APIs</li>
                          <li>Robust security</li>
                          <li>Real-time monitoring</li>
                          <li>Flexible scalability</li>
                          <li>Adaptable to market needs</li>
                          <li>API virtualization</li>
                        </div>



                      </div>}
                    {(token != 'professional' && window.location.pathname !== '/professional_en') &&
                      <div style={{ width: '50%', textAlign: 'start' }}>

                        <div className='landingFont3b textBold' style={{ color: '#0473bf', marginTop: '22px', paddingRight: '80px', marginBottom: '20px', fontSize: '1vw' }}>AVAP Cloud: Your Tech Partner</div>
                        <div style={{ paddingLeft: '10px', paddingRight: '60px', fontSize: '1vw' }}>
                          <li>Advanced infrastructure and technology</li>
                          <li>Access to corporate-level technology and infrastructure</li>
                          <li>Project focus</li>
                          <li>Constant monitoring</li>

                          <li>Tailored scalability</li>
                          <li>Increased productivity</li>
                          <li>Reduced Go To Market times</li>
                          <li>Security</li>
                          <li>Platform for designing, deploying, securing, and monitoring your APIs</li>
                          {/*<li>Crecimiento profesional.</li>*/}
                        </div>



                      </div>}


                  </div>
                  <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>

                    {token == 'professional' || window.location.pathname == '/professional_en' &&
                      <button style={{
                        justifyContent: 'center',
                        textAlign: 'center',
                        height: '40px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        backgroundColor: '#0473bf',
                        color: 'white',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        borderColor: 'transparent',
                        fontSize: '1vw'
                      }} className='textBold'>Corporate resources at freelance prices!</button>}

                    {(token != 'professional' && window.location.pathname !== '/professional_en') &&
                      <button style={{
                        justifyContent: 'center',
                        textAlign: 'center',
                        height: '20px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        backgroundColor: 'transparent',
                        color: 'transparent',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        borderColor: 'transparent',
                        fontSize: '40px'
                      }} className='textBold'>¡Recurs</button>}


                    <div style={{ width: '20px', minWidth: '20px' }}></div>

                    <div className='landingFont1 textBold' style={{ color: '#0473bf', width: '150px', textAlign: 'center', marginLeft: '10px', marginRight: '10px', textAlignLast: 'end' }}>${annualPayment ? monthlyPrice * 10 : monthlyPrice}</div>
                    <div style={{ width: '40px', maxWidth: '40px', lineHeight: '18px', paddingTop: '20px' }}>USD {annualPayment ? 'anual' : 'month'}</div>


                  </div>

                  {(token != 'professional' && window.location.pathname !== '/professional_en') &&
                    <div style={{ height: '1px', marginLeft: '50%', width: '50%', backgroundColor: '#d9d9d9' }}></div>}


                </div>
              </>}
          </div>




        </div>

      </div>



      <div id='modals' className="">
        <div className="">
          <div className={`${selectedOption === 'AVAP_ID' ? '' : ''}`}>
            <div className="">
              <div style={{ minHeight: selectedOption === 'AVAP_ID' ? '0px' : '0px' }}></div>
              {show &&

                <Modal dialogClassName="addons-dialog w-75 obex-dialog"
                  show={show}
                  onHide={onCloseAction}
                  animation={false}>
                  <Modal.Header closeButton className="border-0 pb-0">
                    <Modal.Title className="text-center w-100">
                      <Row>
                        <Col className="addons-title">
                          <span>Subscribe to AVAP Cloud.{token === 'professional' || window.location.pathname == '/professional_en' ? 'Professional' : 'Business'}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="10" className="mx-auto text-center modal-subtitle">
                          <span className="text-center d-block">Please confirm your purchase and proceed to pay for your new subscription.</span>
                        </Col>
                      </Row>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <PaymentConfirmation
                      actionSubmit={makePayment}
                      handleGoBack={() => {
                        // console.log("BACK");
                        setShow(false);
                      }}
                      handleAnnualPayment={null}
                      nameServiceSelected={token === 'professional' || window.location.pathname == '/professional_en' ? 'AVAP Cloud Professional' : 'AVAP Cloud Business'}
                      amountMonth={parseInt(totalPrice)}
                      amountYear={(parseInt(totalPrice) * 10) / 12}
                      processingPayment={loading}
                      success={success}
                      error={error}
                      handleCard={handleCard}
                      isBlue={true}
                      cards={cards_}
                      prorated={true}
                      addedCard={newadded_card}
                      spanish={true} />
                  </Modal.Body>
                </Modal>}
              {showModal && <AddCreditCard show={showModal} handleClose={handleModal} spanish={true} />}
              {/* <div style={{ marginTop: "20px" }}>
      <button type="button" className="buttonAVAP" onClick={handleOpenModal}>
        Continuar
      </button>
    </div> */}
            </div>
          </div>
        </div>



        <Modal show={isModalOpen} onHide={handleCloseModal} centered dialogClassName="custom-modal">
          <Modal.Header closeButton style={{ borderBottom: "0px" }}>
            <div style={{ display: "ruby" }}>
              <Modal.Title style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>
                Select Your Add-ons
              </Modal.Title>
              <div style={{ textAlign: "center", color: "black" }}>
                Select your add-ons and continue to checkout. Not sure? you can add in-add at a later time.
              </div>
            </div>
          </Modal.Header>
          <Modal.Body style={{ borderBottom: "0px" }}>
            <div className="">
              <div className="text-center" style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                <div className="modaldiv1">
                  <div style={{ placeContent: "center" }}>Select Plan</div>
                  <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt", placeContent: "center" }}>Start Up</div>
                </div>
                <div className="modaldiv1">
                  <div style={{ placeContent: "center" }}>Estimated monthly cost:</div>
                  <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt", placeContent: "center" }}>29.00€*</div>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }} className="modaldiv2">
                <div style={{ width: "40%" }}>
                  <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>Add one project</div>
                  <div>3 days included with your plan. Gain further insight with additional history and API access.</div>
                </div>
                <div style={{ width: "20%", placeContent: "center" }}>
                  <button className="buttonAVAP" style={{ width: "100%" }}>continue</button>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }} className="modaldiv2">
                <div style={{ width: "40%" }}>
                  <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>Add one project</div>
                  <div>3 days included with your plan. Gain further insight with additional history and API access.</div>
                </div>
                <div style={{ width: "40%", display: "flex" }}>
                  <div style={{ width: "50%", placeContent: "center" }}>
                    <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>5.00€/mo*</div>
                  </div>
                  <div style={{ width: "50%", placeContent: "center" }}>
                    <button className="buttonAVAP" style={{ width: "100%" }}>continue</button>
                  </div>
                </div>
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "0px" }}>
            <div style={{ textAlign: "center", width: "100%" }}>
              <button className="buttonAVAP" onClick={handleCloseModal} style={{ marginBottom: "20px", width: "50%" }}>Continue to Checkout</button>
              <div>*Taxes and overages may apply</div>
            </div>
          </Modal.Footer>

        </Modal>


      </div>
    </BrowserView>
    }

      <MobileView>

        <div id="pr-wrapper-mobile" className="pr-wrapper" style={{ backgroundImage: `url(${background_cloud})` }}>


          <div className="pr-left">
            <div className="pr-left-container">
              <div className='pr-logo-container'>
                <img
                  id='logo'
                  src={token == 'professional' || window.location.pathname == '/professional_en' ? logo2 : logo}
                  alt="Logo" />
              </div>

              <div className="pr-card">

                <div className="pr-info">
                  <div className="pr-info-title">
                    Start your 30-day FREE trial today
                  </div>
                  <div className="pr-info-description">
                    Get started, register and access your membership
                  </div>
                </div>

                {/* Botones de selección */}
                <div className="pr-card-buttons">
                  <button
                    className={selectedOption === 'NO_AVAP_ID' ? 'btn-pricing-card selected' : ' btn-pricing-card'}
                    onClick={() => handleClick('NO_AVAP_ID')}
                  >No AVAP ID

                  </button>
                  <button
                    className={selectedOption === 'AVAP_ID' ? 'btn-pricing-card selected' : ' btn-pricing-card'}
                    onClick={() => handleClick('AVAP_ID')}
                    disabled={signin}
                  >I have AVAP ID

                  </button>
                </div>

                {selectedOption === 'AVAP_ID' && (

                  <Formik
                    initialValues={{
                      email: '',
                      password: '',
                      terms: false
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      // handleContinueClick2(values);


                      /*
                      post_login_avapCloud(values);
                      console.log(values);*/



                      // setShowResume(true);

                      setFormObject(values);

                      setSubmitting(false);

                      post_signup_avapcloud(values);


                      setSubmitting(false); // Esto es para volver a habilitar el botón después de enviar
                    }}
                  >
                    {({ isSubmitting, errors, touched, handleSubmit, handleBlur }) => (
                      <div>
                        <Field
                          id='email_entrada'
                          placeholder="Email address"
                          className="inputs"
                          name="email"
                          required
                          disabled={signin}
                          onBlur={(e) => {
                            handleBlur(e);
                            setFormTouched(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                          }}
                          style={{ width: '100%', }} />
                        {false && errors.email && touched.email && (
                          <div className="mt-2 msgError">{errors.email}</div>
                        )}
                        <InputGroup className="mt-3">
                          <Field
                            id='password_entrada'
                            required

                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            disabled={signin}
                            placeholder="Password"
                            className="inputs borderRadiusLeftLogin form-control"
                            style={{ paddingLeft: '10px', height: '54px' }}
                            onBlur={(e) => {
                              handleBlur(e);
                              setFormTouched(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                            }} />
                          <InputGroup.Append>
                            <Button
                              onClick={handleShowPassword}
                              variant="outline-secondary"
                              style={{
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderTop: '1px solid #ced4da',
                                borderRight: '1px solid #ced4da',
                                borderBottom: '1px solid #ced4da',
                                borderLeft: '1px solid #ffff',
                                height: '100%',
                              }}
                            >
                              {showPassword ? (
                                <FontAwesomeIcon
                                  icon={faEyeSlash}
                                  style={{ color: '#AFAFAF' }} />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faEye}
                                  style={{ color: '#AFAFAF' }} />
                              )}
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        {false && errors.password && touched.password && (
                          <div className="mt-2 msgError">
                            {errors.password}
                          </div>
                        )}
                        <div className='pr-form-errors'>
                          {(errors.email != undefined && touched.email)
                            && (
                              <Alert style={{ transform: 'translateY(130px)', marginTop: '80px', zIndex: 999 }} variant='danger mt-3'>
                                {errors.email}
                              </Alert>
                            )}

                          {(errors.password != undefined && touched.password)
                            && (
                              <Alert style={{ transform: 'translateY(130px)', zIndex: 999 }} variant='danger mt-3'>
                                {errors.password}
                              </Alert>
                            )}

                        </div>
                        <div style={{ display: 'flex', marginTop: '10px' }} className="checkbox-containerAVAP">
                          <div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                              <Field disabled={signin} type="checkbox" name="terms" id="" onBlur={(e) => {
                                handleBlur(e);
                                setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                              }} />


                              <div className='pr-terms-description'>
                                I accept the&nbsp;<a href='http://legal.101obex.com/licenses/cloud' target='_blank' rel='noreferer' >Terms and Conditions</a>&nbsp; and the&nbsp;  <a href='http://legal.101obex.com/licenses/privacy' target='_blank' rel='noreferer'> Privacy Policy.</a>
                              </div>
                            </div>
                            {/* <div className="fontText1">
          * No se realizará ningún cargo a la tarjeta hasta concluir el periodo de prueba.
        </div> */}
                          </div>
                        </div>


                        {false && errors.terms && touched.terms && (
                          <div className="mt-2 msgError">{errors.terms}</div>
                        )}

                        {/* <Button type="submit" className=" custom-button" style={{ display: "block", width: "40%" }} onClick={() => handleSubmit()} disabled={isSubmitting}>
      Continue
    </Button> */}

                        {error != '' &&
                          <Alert style={{ zIndex: 999, position: 'absolute', width: '34%' }} variant='danger mt-3'>
                            {error}
                          </Alert>}

                        <button type="button" className="glowBtn"
                          onClick={async () => {

                            if (selectedOption === 'AVAP_ID' && false) {
                              //as HTMLInputElement
                              const ent_email = document.getElementById('email_entrada') as HTMLInputElement;
                              const ent_password = document.getElementById('password_entrada') as HTMLInputElement;
                              await SessionService.login(ent_email.value, ent_password.value, false)
                              const result = (await ObexRequestHandler.post('/unique_signin', {
                                email: ent_email.value,
                                password: ent_password.value

                              }));
                              console.log(result);


                              if (result.success == false) {
                                setError(result.message);
                                setTimeout((): void => setError(''), 5000);
                              } else {
                                console.log('Login success');
                                setError(''); setSuccess(''); handleSubmit();
                              }



                            } else {
                              setError(''); setSuccess(''); handleSubmit();
                            }


                          }}
                          disabled={signin || isSubmitting || Object.keys(errors).length > 0 || !formTouched}
                        >
                          <span className='btnInner' style={{ padding: '10px 40px' }}> Continue</span>
                        </button>

                      </div>
                    )}

                  </Formik>

                )}



                {selectedOption === 'NO_AVAP_ID' && (
                  <Formik
                    initialValues={{
                      email: '',
                      password: '',
                      password2: '',
                      terms: false
                    }}
                    validationSchema={SignupSchema2}
                    onSubmit={(values, { setSubmitting }) => {

                      //   if (values.month.length < 2) values.month = '0' + values.month;
                      //post_signup_avapcloud(values);
                      //setShowResume(true);
                      setResumen(false);
                      setFormObject(values);
                      post_signup_avapcloud(values);
                      setSubmitting(false); // Esto es para volver a habilitar el botón después de enviar
                    }}
                  >
                    {({ values, isSubmitting, errors, touched, handleSubmit, handleBlur, setFieldValue }) => (
                      <div>
                        <Field
                          placeholder="Email address"
                          className="inputs"
                          name="email"
                          required
                          disabled={signin}
                          style={{ width: '100%' }}
                          onBlur={(e) => {
                            handleBlur(e);
                            setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                          }} />

                        {false && errors.email && touched.email && (
                          <div className="mt-2 msgError">{errors.email}</div>
                        )}
                        <InputGroup className="mt-3">
                          <Field
                            required
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            name="password"
                            disabled={signin}
                            className="inputs borderRadiusLeftLogin  form-control"
                            style={{ paddingLeft: '10px', height: '54px' }}
                            onBlur={(e) => {
                              handleBlur(e);
                              setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                            }} />
                          <InputGroup.Append>
                            <Button
                              onClick={handleShowPassword}
                              variant="outline-secondary"
                              style={{
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderTop: "1px solid #ced4da",
                                borderRight: "1px solid #ced4da",
                                borderBottom: "1px solid #ced4da",
                                borderLeft: "1px solid #ffff",
                                height: "100%",
                              }}
                            >
                              {showPassword ? (
                                <FontAwesomeIcon
                                  icon={faEyeSlash}
                                  style={{ color: "#AFAFAF" }} />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faEye}
                                  style={{ color: "#AFAFAF" }} />
                              )}
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>

                        {false && errors.password && touched.password && (
                          <div className="mt-2 msgError">{errors.password}</div>
                        )}


                        <InputGroup className="mt-3">
                          <Field
                            required
                            type={showPassword2 ? 'text' : 'password'}
                            placeholder="Repeat the password"
                            name="password2"
                            disabled={signin}
                            className="inputs borderRadiusLeftLogin  form-control"
                            style={{ paddingLeft: '10px', height: '54px' }}
                            onBlur={(e) => {
                              handleBlur(e);
                              setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                            }} />
                          <InputGroup.Append>
                            <Button
                              onClick={handleShowPassword2}
                              variant="outline-secondary"
                              style={{
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderTop: "1px solid #ced4da",
                                borderRight: "1px solid #ced4da",
                                borderBottom: "1px solid #ced4da",
                                borderLeft: "1px solid #ffff",
                                height: "100%",
                              }}
                            >
                              {showPassword2 ? (
                                <FontAwesomeIcon
                                  icon={faEyeSlash}
                                  style={{ color: "#AFAFAF" }} />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faEye}
                                  style={{ color: "#AFAFAF" }} />
                              )}
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>

                        {false && errors.password2 && touched.password2 && (
                          <div className="mt-2 msgError">{errors.password2}</div>
                        )}



                        <div style={{ display: 'flex', marginTop: '10px' }} className="checkbox-containerAVAP">
                          <div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                              <Field disabled={signin} type="checkbox" name="terms" id="" onBlur={(e) => {
                                handleBlur(e);
                                setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                              }} />


                              <div className="pr-terms-description">

                                I accept the&nbsp;<a href='http://legal.101obex.com/licenses/cloud' target='_blank'>Terms and Conditions</a>&nbsp;and the  &nbsp;<a href='http://legal.101obex.com/licenses/privacy' target='_blank'>Privacy Policy.</a>
                              </div>
                            </div>

                          </div>
                        </div>

                        {false && errors.terms && touched.terms && (
                          <div className="mt-2 msgError">{errors.terms}</div>
                        )}
                        <div className='pr-form-errors'>
                          {(errors.email != undefined && touched.email)
                            && (
                              <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
                                {errors.email}
                              </Alert>
                            )}

                          {(errors.password != undefined && touched.password)
                            && (
                              <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
                                {errors.password}
                              </Alert>
                            )}
                          {(errors.password2 != undefined && touched.password2)
                            && (
                              <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
                                {errors.password2}
                              </Alert>
                            )}

                          {/*}
      {(errors.cardNumber != undefined && touched.cardNumber)
        && (
          <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
            {errors.cardNumber}
          </Alert>
        )}

      {(errors.month != undefined && touched.month)
        && (
          <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
            {errors.month}
          </Alert>
        )}
      {(errors.year != undefined && touched.year)
        && (
          <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
            {errors.year}
          </Alert>
        )}
      {(errors.cvv != undefined && touched.cvv)
        && (
          <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
            {errors.cvv}
          </Alert>
        )}

        */}
                          {(errors.terms != undefined && touched.terms)
                            && (
                              <Alert style={{ zIndex: 999 }} variant='danger mt-3'>
                                {errors.terms}
                              </Alert>
                            )}
                        </div>



                        <button type="button"
                          className="glowBtn"
                          onClick={() => { if (!signin) { setError(''); setSuccess(''); handleSubmit(); } }}
                          disabled={signin || isSubmitting || Object.keys(errors).length > 0 || !formTouched2}
                        >


                          <span className='btnInner' style={{ padding: '10px 40px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '10px' }}> Continue {signin && <Spinner animation="border" size="sm" style={{ color: '#fff' }} />}</span>
                        </button>
                        {error != '' &&
                          <Alert style={{ zIndex: 999, marginTop: '-100px' }} variant='danger mt-3'>
                            {error}
                          </Alert>}

                      </div>
                    )}

                  </Formik>
                )}


              </div>
            </div>
          </div>
          <div className="pr-right">


            <div className='pr-right-container'>
              <div className="pr-banner">
                <p className='pr-banner-title'>30-Day FREE TRIAL</p>
                <p className='pr-banner-description'>Unlock your business’s full potential with AVAP Cloud</p>
              </div>
              <div className="pr-title">
                Powerful API Lifecycle Management Platform
              </div>
              <div className="pr-description">
                Manage the entire API lifecycle in a SaaS model from design to testing and deployment
              </div>
              <div className="pr-leyend">
                Powered by AVAP technology, virtuality and artificial intelligence will be in the core of every API and API product you develop.
              </div>
              <div className="pr-pills">
                <div className="pr-pill">
                  <img src={icn_layer} alt="Three layered Infrastructure" />
                  <div className="pr-pill-title">
                    Three layered Infrastructure
                  </div>

                </div>
                <div className="pr-pill">
                  <img src={icn_universal} alt="Universal Platform" />
                  <div className="pr-pill-title">


                    Universal Platform
                  </div>
                </div>
                <div className="pr-pill">
                  <img src={icn_database} alt="Database Control" />
                  <div className="pr-pill-title">
                    Database Control

                  </div>
                </div>
              </div>
            </div>









            <div id='pr-oldInfo' style={{ display: 'none' }}>
              {showResume ?
                <div className="formI">
                  <h2 className="hI" style={{ marginTop: '0px' }}>Confirm your subscription</h2>

                  <div className="divI1">
                    <div className="divI2 boxShadowCardBottom">{plansName === 'Professional' || window.location.pathname == '/professional_en' ? 'AVAP Cloud Professional' : plansName}</div>
                    <div className="divI3">
                      <div>Monthly membership</div>
                      <div>${parseInt(totalPrice).toFixed(2)} {(annualPayment ? 'USD/año' : 'USD/mes')}</div>
                    </div>
                  </div>
                  {/*}
            <div className="divI1">
              <div className="divI2 boxShadowCardBottom">Add Ons</div>
              <div className="divI3">
                <div>One more project</div>
                <div>24€/month</div>
              </div>
            </div>
            {*/}
                  <div className="divI1">
                    <div className="divI4 boxShadowCardBottom">
                      <div style={{ color: '#147BCE', fontFamily: "Regular Intro Bold Alt" }}>
                        Total Price
                      </div>
                      <div style={{ fontFamily: "Regular Intro Bold Alt", color: "black" }}>
                        ${parseInt(totalPrice).toFixed(2)} USD
                      </div>
                    </div>
                    <div className="divI3">
                      At the end of the 30-day trial period, on the first day of each month, the full fee plus additional charges will be billed. The first month of service will be charged at a prorated rate. ${parseInt(proratedPrice.toString()).toFixed(2)}/month.
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>

                    <div style={{ width: '50%' }}>*Taxes may apply</div> <div style={{ width: '50%', textAlign: 'end' }}>
                      <button onClick={() => {

                        if (selectedOption == 'AVAP_ID' && false) setShow(true); else post_signup_avapcloud(formObject);

                      }} style={{ alignSelf: 'flex-end' }}
                        className='custom-button-blue textBold'>Subscribe{loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}</button></div>
                  </div>
                  {/*}
              <Form className="obex-form">
                    <Form.Row>
                      <Col md="6">
                        <Form.Check
              
                          type="switch"
                          id="custom-switch"
                          label="Annual Payment"
                          onChange={handleSwitch}
                          className="obex-switch"
                        />
                        </Col>
                        <Col md="6" className="text-right">
                          <span>*Taxes may apply</span>
                        </Col>
                    </Form.Row>
                  </Form>
    */}

                </div>

                : <>
                  <div id="principal" style={{ paddingBottom: '40px' }}>

                    <div style={{
                      padding: '10px',
                      marginTop: '20px',
                      marginLeft: '80px',
                      marginRight: '0px',

                      backgroundColor: '#d9d9d9',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      display: 'flex'
                    }}>

                      {window.location.pathname === '/professional_en' ?
                        <div style={{ width: '80%' }}>
                          <div className='landingFont2 textBold' style={{ color: 'black' }}>
                            Manage you API projects
                          </div>

                          <div className='landingFont2 textBold' style={{ color: 'black' }}>
                            like a pro with AVAP Cloud
                          </div>
                        </div>
                        :
                        <div style={{ width: '80%' }}>
                          <div className='landingFont2 textBold' style={{ color: 'black' }}>
                            test
                          </div>

                          <div className='landingFont2 textBold' style={{ color: 'black' }}>
                            potential with AVAP Cloud
                          </div>
                        </div>

                      }
                      <div style={{ lineHeight: '25px', width: '20%', borderLeftStyle: 'solid', borderLeftWidth: '1px', borderLeftColor: 'white', paddingLeft: '20px' }}>

                        <div className='textBold landingFont2'>30-Day</div>
                        {/* <div className='textBold'>Day</div> */}
                        <div className='textBold landingFont2' style={{ color: '#4a76b8' }}>FREE <br /> TRIAL</div>

                      </div>

                    </div>

                    <div style={{
                      padding: '10px',
                      marginTop: '20px',
                      marginLeft: '80px',
                      marginRight: '80px',
                      backgroundColor: 'transparent',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      display: 'flex'
                    }}>

                      {token == 'professional' || window.location.pathname.toString() === '/professional_en' ?
                        <div className='textBold landingFont3b' style={{ color: 'black', fontSize: '' }}>Boost your career with our comprehensive API lifecycle development and management platform.</div>
                        :
                        <div className='textBold landingFont3b' style={{ color: 'black', fontSize: '' }}>Accelerate your projects with our platform for API lifecycle development and management.</div>
                      }
                    </div>


                    <div style={{ width: '100%', display: 'flex' }}>

                      <div style={{ width: '50%', justifyContent: 'center', textAlign: 'center', marginTop: '50px' }}>
                        <img
                          src={token == 'professional' || window.location.pathname == '/professional_en' ? foto_professional : foto_cloud}
                          style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '42px', width: '70%', maxWidth: '100%' }}
                          alt="Logo" />
                      </div>

                      {token == 'professional' || window.location.pathname == '/professional_en' &&
                        <div style={{ width: '50%', textAlign: 'start' }}>

                          <div className='landingFont3b textBold' style={{ color: '#0473bf', marginTop: '22px', paddingRight: '80px', marginBottom: '20px', fontSize: '1vw' }}>AVAP Cloud provides you with the tools:</div>
                          <div style={{ paddingLeft: '10px', paddingRight: '80px', fontSize: '1vw' }}>
                            <li>Advanced infrastructure and technology</li>
                            <li>Professional-grade development</li>
                            <li>Access to tools for designing, deploying, securing, and monitoring your APIs</li>
                            <li>Professional growth</li>
                          </div>

                          <div className='landingFont3b textBold' style={{ color: '#0473bf', marginTop: '42px', paddingRight: '80px', fontSize: '1vw', marginBottom: '20px' }}>AVAP Cloud Advantages: </div>
                          <div style={{ paddingLeft: '10px', paddingRight: '80px', fontSize: '1vw' }}>
                            <li>Efficient design of virtual APIs</li>
                            <li>Robust security</li>
                            <li>Real-time monitoring</li>
                            <li>Flexible scalability</li>
                            <li>Adaptable to market needs</li>
                            <li>API virtualization</li>
                          </div>



                        </div>}
                      {(token != 'professional' && window.location.pathname !== '/professional_en') &&
                        <div style={{ width: '50%', textAlign: 'start' }}>

                          <div className='landingFont3b textBold' style={{ color: '#0473bf', marginTop: '22px', paddingRight: '80px', marginBottom: '20px', fontSize: '1vw' }}>AVAP Cloud: Your Tech Partner</div>
                          <div style={{ paddingLeft: '10px', paddingRight: '60px', fontSize: '1vw' }}>
                            <li>Advanced infrastructure and technology</li>
                            <li>Access to corporate-level technology and infrastructure</li>
                            <li>Project focus</li>
                            <li>Constant monitoring</li>

                            <li>Tailored scalability</li>
                            <li>Increased productivity</li>
                            <li>Reduced Go To Market times</li>
                            <li>Security</li>
                            <li>Platform for designing, deploying, securing, and monitoring your APIs</li>
                            {/*<li>Crecimiento profesional.</li>*/}
                          </div>



                        </div>}


                    </div>
                    <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>

                      {token == 'professional' || window.location.pathname == '/professional_en' &&
                        <button style={{
                          justifyContent: 'center',
                          textAlign: 'center',
                          height: '40px',
                          paddingLeft: '15px',
                          paddingRight: '15px',
                          backgroundColor: '#0473bf',
                          color: 'white',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          borderRadius: '10px',
                          boxShadow: 'none',
                          borderColor: 'transparent',
                          fontSize: '1vw'
                        }} className='textBold'>Corporate resources at freelance prices!</button>}

                      {(token != 'professional' && window.location.pathname !== '/professional_en') &&
                        <button style={{
                          justifyContent: 'center',
                          textAlign: 'center',
                          height: '20px',
                          paddingLeft: '15px',
                          paddingRight: '15px',
                          backgroundColor: 'transparent',
                          color: 'transparent',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          borderRadius: '10px',
                          boxShadow: 'none',
                          borderColor: 'transparent',
                          fontSize: '40px'
                        }} className='textBold'>¡Recurs</button>}


                      <div style={{ width: '20px', minWidth: '20px' }}></div>

                      <div className='landingFont1 textBold' style={{ color: '#0473bf', width: '150px', textAlign: 'center', marginLeft: '10px', marginRight: '10px', textAlignLast: 'end' }}>${annualPayment ? monthlyPrice * 10 : monthlyPrice}</div>
                      <div style={{ width: '40px', maxWidth: '40px', lineHeight: '18px', paddingTop: '20px' }}>USD {annualPayment ? 'anual' : 'month'}</div>


                    </div>

                    {(token != 'professional' && window.location.pathname !== '/professional_en') &&
                      <div style={{ height: '1px', marginLeft: '50%', width: '50%', backgroundColor: '#d9d9d9' }}></div>}


                  </div>
                </>}
            </div>




          </div>

        </div>



        <div id='modals' className="">
          <div className="">
            <div className={`${selectedOption === 'AVAP_ID' ? '' : ''}`}>
              <div className="">
                <div style={{ minHeight: selectedOption === 'AVAP_ID' ? '0px' : '0px' }}></div>
                {show &&

                  <Modal dialogClassName="addons-dialog w-75 obex-dialog"
                    show={show}
                    onHide={onCloseAction}
                    animation={false}>
                    <Modal.Header closeButton className="border-0 pb-0">
                      <Modal.Title className="text-center w-100">
                        <Row>
                          <Col className="addons-title">
                            <span>Subscribe to AVAP Cloud.{token === 'professional' || window.location.pathname == '/professional_en' ? 'Professional' : 'Business'}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="10" className="mx-auto text-center modal-subtitle">
                            <span className="text-center d-block">Please confirm your purchase and proceed to pay for your new subscription.</span>
                          </Col>
                        </Row>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <PaymentConfirmation
                        actionSubmit={makePayment}
                        handleGoBack={() => {
                          // console.log("BACK");
                          setShow(false);
                        }}
                        handleAnnualPayment={null}
                        nameServiceSelected={token === 'professional' || window.location.pathname == '/professional_en' ? 'AVAP Cloud Professional' : 'AVAP Cloud Business'}
                        amountMonth={parseInt(totalPrice)}
                        amountYear={(parseInt(totalPrice) * 10) / 12}
                        processingPayment={loading}
                        success={success}
                        error={error}
                        handleCard={handleCard}
                        isBlue={true}
                        cards={cards_}
                        prorated={true}
                        addedCard={newadded_card}
                        spanish={true} />
                    </Modal.Body>
                  </Modal>}
                {showModal && <AddCreditCard show={showModal} handleClose={handleModal} spanish={true} />}
                {/* <div style={{ marginTop: "20px" }}>
      <button type="button" className="buttonAVAP" onClick={handleOpenModal}>
        Continuar
      </button>
    </div> */}
              </div>
            </div>
          </div>



          <Modal show={isModalOpen} onHide={handleCloseModal} centered dialogClassName="custom-modal">
            <Modal.Header closeButton style={{ borderBottom: "0px" }}>
              <div style={{ display: "ruby" }}>
                <Modal.Title style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>
                  Select Your Add-ons
                </Modal.Title>
                <div style={{ textAlign: "center", color: "black" }}>
                  Select your add-ons and continue to checkout. Not sure? you can add in-add at a later time.
                </div>
              </div>
            </Modal.Header>
            <Modal.Body style={{ borderBottom: "0px" }}>
              <div className="">
                <div className="text-center" style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                  <div className="modaldiv1">
                    <div style={{ placeContent: "center" }}>Select Plan</div>
                    <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt", placeContent: "center" }}>Start Up</div>
                  </div>
                  <div className="modaldiv1">
                    <div style={{ placeContent: "center" }}>Estimated monthly cost:</div>
                    <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt", placeContent: "center" }}>29.00€*</div>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }} className="modaldiv2">
                  <div style={{ width: "40%" }}>
                    <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>Add one project</div>
                    <div>3 days included with your plan. Gain further insight with additional history and API access.</div>
                  </div>
                  <div style={{ width: "20%", placeContent: "center" }}>
                    <button className="buttonAVAP" style={{ width: "100%" }}>continue</button>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }} className="modaldiv2">
                  <div style={{ width: "40%" }}>
                    <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>Add one project</div>
                    <div>3 days included with your plan. Gain further insight with additional history and API access.</div>
                  </div>
                  <div style={{ width: "40%", display: "flex" }}>
                    <div style={{ width: "50%", placeContent: "center" }}>
                      <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>5.00€/mo*</div>
                    </div>
                    <div style={{ width: "50%", placeContent: "center" }}>
                      <button className="buttonAVAP" style={{ width: "100%" }}>continue</button>
                    </div>
                  </div>
                </div>

              </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "0px" }}>
              <div style={{ textAlign: "center", width: "100%" }}>
                <button className="buttonAVAP" onClick={handleCloseModal} style={{ marginBottom: "20px", width: "50%" }}>Continue to Checkout</button>
                <div>*Taxes and overages may apply</div>
              </div>
            </Modal.Footer>

          </Modal>


        </div>




      </MobileView></>
  );
};

export default Avapcloud;
