import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Table, Button, Form } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import devIcon from '../../assets/img/ui_icons/cld_org-s.svg';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Drawer from "./Drawer";
import GenericSelectableList from '../DashboardComponents/CustomSelectSearch';
import { useHistory } from "react-router-dom";

const TABLES = [
  'avapapiproducts',
  'obex_infrastructure_designer_gateways_configuration'
];


interface ApiProduct {
  id?: string;
  api_product_id: string;
  api_product_name: string;
  name?: string;
  active: boolean;
  endpoint_route: string;
  documentation: string;
  obex_project_id: number;
  group_id: number;
  latest_version?: string;
}

interface ApiResponse<T> {
  success: boolean;
  data: T[];
}

interface Wrapper {
  id?: string;
  name: string;
  avap_api_product_name: string;
  active: boolean;
  endpoint_route: string;
  group_id: number;
  api_type_name?: string;
  version?: string;
}

interface GroupData {
  id: number;
  name: string;
  descripcion: string;
  apiProducts: ApiProduct[];
  wrappers: Wrapper[];
  type_table?: string;
}

const GateWayApiproducts = () => {
  const [loading, setLoading] = useState(false);
  const [groupsData, setGroupsData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupDescription, setNewGroupDescription] = useState('');
  const [selectedGroupElements, setSelectedGroupElements] = useState<GroupData>({
    id: 0,
    name: '',
    descripcion: '',
    apiProducts: [],
    wrappers: []
  });
  const [expandedGroupId, setExpandedGroupId] = useState(null);
  const [wrappers, setWrappers] = useState([]);
  const [selectedWrapper, setSelectedWrapper] = useState('');
  const [apiProducts, setApiProducts] = useState([]);
  const [selectedApiProduct, setSelectedApiProduct] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const history = useHistory();

  const [selectedWrappers, setSelectedWrappers] = useState<Wrapper[]>([]);
  const [selectedApiProducts, setSelectedApiProducts] = useState<ApiProduct[]>([]);
  const [groupsWithData, setGroupsWithData] = useState<GroupData[]>([]);


  const AMBIENT_LABELS = {
    Active: { name: 'Active', color: '#28a745' },        // Verde
    Inactive: { name: 'Inactive', color: 'red' }, // Amarillo
    //3: { name: 'Live', color: '#28a745' }, // Amarillo
  
  };
  
  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;
  
    return (
      <span 
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth:'100px',
          display:'flex',
          width:'65px',
          justifyContent:'center',
          marginLeft:'auto',
          marginRight:'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };


  const fetchGroups = async () => {
    setLoading(true);
    try {
      const route = `/groups_org?org_id=${SessionService.ORGANIZATION}`;
      console.log('🚀 Requesting route:', route);
      
      const response = await ObexRequestHandler.get(
        route, 
        {}
      );
      console.log('📦 Response data:', response.data);
      setGroupsData(response.data);
    } catch (error) {
      console.error('❌ Error al obtener grupos:', error);
    }
    setLoading(false);
  };

  const fetchGroupData = async (): Promise<GroupData[]> => {
    setLoading(true);
    try {
      // 1. Obtener todos los grupos
      const groupsResponse = await ObexRequestHandler.get(
        `/groups_org?org_id=${SessionService.ORGANIZATION}`,
        {}
      );
      console.log('🏁 Grupos iniciales:', groupsResponse.data);
      
      // 2. Por cada grupo, obtener sus elementos
      const groupsWithElements: GroupData[] = await Promise.all(
        groupsResponse.data.map(async (group): Promise<GroupData> => {
          console.log('📍 Procesando grupo:', {
            id: group.id,
            name: group.name,
            descripcion: group.descripcion
          });

          // Fetch API Products
          const apiProductsResponse = await ObexRequestHandler.get(
            `/group_list?group_id=${group.id}&table=avapapiproducts`,
            {}
          );
          console.log('📦 API Products raw data para grupo ' + group.id + ':', apiProductsResponse.data);
          
          // Fetch Wrappers
          const wrappersResponse = await ObexRequestHandler.get(
            `/group_list?group_id=${group.id}&table=obex_infrastructure_designer_gateways_configuration`,
            {}
          );
          console.log('🔧 Wrappers raw data para grupo ' + group.id + ':', wrappersResponse.data);

          // Mapear API Products - Corregido para acceder directamente al array de datos
          const apiProducts = Array.isArray(apiProductsResponse.data) ? apiProductsResponse.data.map(item => {
            const mappedProduct = {
              id: item.id?.toString(),
              api_product_id: item.id?.toString(),
              api_product_name: item.name || 'Unnamed Product',
              active: item.active || false,
              endpoint_route: item.endpoint_route || '',
              documentation: item.documentation || '',
              obex_project_id: item.obex_project_id,
              group_id: item.group_id
            };
            console.log('📝 API Product mapeado:', mappedProduct);
            return mappedProduct;
          }) : [];

          // Mapear Wrappers - Corregido para mostrar el port directamente
          const wrappers = Array.isArray(wrappersResponse.data) ? wrappersResponse.data.map(item => {
            const mappedWrapper = {
              id: item.id?.toString(),
              name: item.name || item.alias || 'Unnamed Wrapper',
              avap_api_product_name: item.name || item.alias || 'Unnamed Wrapper',
              active: item.active || false,
              endpoint_route: item.port || '',
              group_id: item.group_id
            };
            console.log('🔨 Wrapper mapeado:', mappedWrapper);
            return mappedWrapper;
          }) : [];

          // Construir objeto de grupo con sus elementos
          const groupWithData: GroupData = {
            id: group.id,
            name: group.name,
            descripcion: group.descripcion,
            type_table: group.type_table,
            apiProducts,
            wrappers
          };
          history.push('/api_products_resources')
          console.log('✨ Grupo completo construido:', groupWithData);
          return groupWithData;
        })
      );

      console.log('🎉 Array final de grupos con elementos:', groupsWithElements);
      setGroupsWithData(groupsWithElements);
      return groupsWithElements;
    } catch (error) {
      console.error('❌ Error fetching group data:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const toggleGroupExpansion = async (groupId) => {
    if (expandedGroupId === groupId) {
      setExpandedGroupId(null);
      setSelectedGroupElements(prev => {
        const newState = { ...prev };
        delete newState[groupId];
        return newState;
      });
    } else {
      setExpandedGroupId(groupId);
      const elements = await fetchGroupData();
      console.log('🔍 Elementos obtenidos para el grupo:', {
        groupId,
        elements
      });
      setSelectedGroupElements(prev => ({
        ...prev,
        [groupId]: elements.find(g => g.id === groupId)
      }));
    }
  };

  const fetchWrappers = async () => {
    try {
      const response = await ObexRequestHandler.get(
        `/gateway/get_gateway_products?organization=${SessionService.ORGANIZATION}`,
        {}
      );
      console.log('🚀 Wrappers response:', response);
      
      // Asegurarnos de que siempre tengamos un array, incluso si la respuesta es null
      const wrappersData = response.data || [];
      console.log('📦 Wrappers data:', wrappersData);
      
      // Mapear los datos para asegurar la estructura correcta
      const formattedWrappers = wrappersData.map(wrapper => ({
        id: wrapper.id,
        avap_api_product_name: wrapper.avap_api_product_name || wrapper.name,
        active: wrapper.active || false,
        endpoint_route: wrapper.endpoint_route || '',
        api_type_name: wrapper.api_type_name,
        version: wrapper.version
      }));

      setWrappers(formattedWrappers);
    } catch (error) {
      console.error('❌ Error fetching wrappers:', error);
      setWrappers([]);
    }
  };

  const fetchApiProducts = async () => {
    try {
      const response = await ObexRequestHandler.get(
        `/publishing/get_products_by_org?org_id=${SessionService.ORGANIZATION}`,
        {}
      );
      console.log('🚀 API Products response:', response);
      
      // Asegurarnos de que siempre tengamos un array, incluso si la respuesta es null
      const productsData = response.data || [];
      console.log('📦 API Products data:', productsData);
      
      // Mapear los datos para asegurar la estructura correcta
      const formattedProducts = productsData.map(product => ({
        id: product.id,
        api_product_id: product.api_product_id,
        api_product_name: product.api_product_name || product.name,
        active: product.active || false,
        endpoint_route: product.endpoint_route || '',
        documentation: product.documentation || '',
        latest_version: product.latest_version || '1.0'
      }));

      setApiProducts(formattedProducts);
    } catch (error) {
      console.error('❌ Error fetching API products:', error);
      setApiProducts([]);
    }
  };






  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/api_products_resources'){
      history.push('/api_products_resources')
      SessionService.updating = SessionService.updating -3;


      const loadAllGroupElements = async () => {
        setLoading(true);
        try {
          const response = await ObexRequestHandler.get(
            `/groups_org?org_id=${SessionService.ORGANIZATION}`,
            {}
          );
          const groups = response.data || [];
          setGroupsData(groups);
          
          // Cargar elementos solo cuando se expande un grupo, no todos al inicio
          setSelectedGroupElements({
            id: 0,
            name: '',
            descripcion: '',
            apiProducts: [],
            wrappers: []
          });
          
        } catch (error) {
          console.error('Error loading groups:', error);
        }
        setLoading(false);
      };
  
      loadAllGroupElements();
      

      const loadData = async () => {
        setLoading(true);
        try {
          await Promise.all([
            fetchWrappers(),
            fetchApiProducts()
          ]);
        } catch (error) {
          console.error('Error loading initial data:', error);
        } finally {
          setLoading(false);
          history.push('/api_products_resources')
        }
        history.push('/api_products_resources')
      };
  
      loadData();
      history.push('/api_products_resources')

      fetchGroupData();

    window.scrollTo(0, 0);
    history.push('/api_products_resources')
    }
  
  }, false);




  useEffect(() => {
    const loadAllGroupElements = async () => {
      setLoading(true);
      try {
        const response = await ObexRequestHandler.get(
          `/groups_org?org_id=${SessionService.ORGANIZATION}`,
          {}
        );
        const groups = response.data || [];
        setGroupsData(groups);
        
        // Cargar elementos solo cuando se expande un grupo, no todos al inicio
        setSelectedGroupElements({
          id: 0,
          name: '',
          descripcion: '',
          apiProducts: [],
          wrappers: []
        });
        
      } catch (error) {
        console.error('Error loading groups:', error);
      }
      setLoading(false);
    };

    loadAllGroupElements();

    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
  }, []);

  const openDrawer = async (group: GroupData | null = null) => {
    console.log('Opening drawer with group:', group);
    setIsEditing(!!group);
    setIsDrawerOpen(true);
    await fetchWrappers();
    await fetchApiProducts();
    
    if (group) {
      setCurrentGroup(group);
      setNewGroupName(group.name);
      setNewGroupDescription(group.descripcion);
      
      try {
        setLoading(true);
        const groupElements = await fetchGroupData();
        const groupData = groupElements.find(g => g.id === group.id);
        
        if (groupData) {
          setSelectedWrappers(groupData.wrappers);
          setSelectedApiProducts(groupData.apiProducts);
        }
        
      } catch (error) {
        console.error('Error loading group elements:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setCurrentGroup(null);
      setNewGroupName('');
      setNewGroupDescription('');
      setSelectedWrappers([]);
      setSelectedApiProducts([]);
    }
  };

  const closeDrawer = () => {
    // Limpiar 
    setIsDrawerOpen(false);
    setNewGroupName('');
    setNewGroupDescription('');
    setCurrentGroup(null);
    setIsEditing(false);
    // Limpiar los estados de elementos seleccionados
    setSelectedWrappers([]);
    setSelectedApiProducts([]);
  };

  const handleAddWrapper = (wrapper) => {

    setSelectedWrappers(prev => {
      const exists = prev.some(w => w.id === wrapper.id);
      if (exists) return prev;
      return [...prev, wrapper];
    });
  };

  const handleAddApiProduct = (product) => {

    setSelectedApiProducts(prev => {
      const exists = prev.some(p => p.id === product.id);
      if (exists) return prev;
      return [...prev, product];
    });
  };

  const handleRemoveWrapper = async (wrapper) => {
    try {
      await ObexRequestHandler.delete(
        `/group_list_elem?table=obex_infrastructure_designer_gateways_configuration&elem_id=${wrapper.id}&group_id=${currentGroup.id}`,
        {}
      );
      setSelectedWrappers(prev => prev.filter(w => w.id !== wrapper.id));
    } catch (error) {
      console.error('❌ Error removing wrapper:', error);
    }
  };

  const handleRemoveApiProduct = async (product) => {
    try {
      await ObexRequestHandler.delete(
        `/group_list_elem?table=avapapiproducts&elem_id=${product.api_product_id}&group_id=${currentGroup.id}`,
        {}
      );
      setSelectedApiProducts(prev => prev.filter(p => p.id !== product.id));
    } catch (error) {
      console.error('❌ Error removing API product:', error);
    }
  };

  // Combinar los elementos seleccionados para el submit
  const getAllSelectedItems = () => {
    const wrappersWithTable = selectedWrappers.map(w => {
      console.log('🔧 Wrapper item:', w);
      return {
        ...w,
        table: 'obex_infrastructure_designer_gateways_configuration'
      };
    });
    
    const productsWithTable = selectedApiProducts.map(p => {
      console.log('📦 API Product item:', p);
      return {
        ...p,
        table: 'avapapiproducts'
      };
    });
    
    const allItems = [...wrappersWithTable, ...productsWithTable];
    console.log('📋 Combined items:', allItems);
    return allItems;
  };

  const handleEditGroup = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const editPayload = {
        group_id: currentGroup.id,
        description: newGroupDescription,
        name: newGroupName
      };
      
      await ObexRequestHandler.post('/edit_group', editPayload);

      // Debug wrappers
      console.log('🔍 Selected Wrappers Raw:', selectedWrappers);
      const wrappersIds = selectedWrappers.map(w => {
        const id = parseInt(w.id);
        console.log(`Converting wrapper ID: ${w.id} to ${id}`);
        return id;
      }).filter(id => !isNaN(id));
      console.log('🔧 Processed Wrapper IDs:', wrappersIds);

      if (wrappersIds.length > 0) {
        const wrappersPayload = {
          table: 'obex_infrastructure_designer_gateways_configuration',
          elem_id: wrappersIds.length === 1 ? wrappersIds[0] : wrappersIds,
          group_id: parseInt(currentGroup.id)
        };
        console.log('📤 Wrappers Payload:', wrappersPayload);
        try {
          const response = await ObexRequestHandler.post('/add_to_group_list', wrappersPayload);
          console.log('✅ Wrappers Response:', response);
        } catch (error) {
          console.error('❌ Error adding wrappers:', error.response?.data);
        }
      }

      const apiProductsIds = selectedApiProducts.map(p => parseInt(p.api_product_id));

      if (apiProductsIds.length > 0) {
        const apiProductsPayload = {
          table: 'avapapiproducts',
          elem_id: apiProductsIds.length === 1 ? apiProductsIds[0] : apiProductsIds,
          group_id: parseInt(currentGroup.id)
        };
        console.log('📦 Adding API products payload:', apiProductsPayload);
        await ObexRequestHandler.post('/add_to_group_list', apiProductsPayload);
      }

      const updatedGroups = await fetchGroupData();
      setGroupsWithData(updatedGroups);
      closeDrawer();
      
    } catch (error) {
      console.error('❌ Error in handleEditGroup:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      // 1. Crear el grupo
      const createGroupPayload = {
        name: newGroupName,
        description: newGroupDescription,
        org_id: SessionService.ORGANIZATION
      };
      
      const groupResponse = await ObexRequestHandler.post('/create_group', createGroupPayload);
      console.log('✨ Grupo creado:', groupResponse);

      // 2. Obtener el ID del grupo de la respuesta
      const groupId = groupResponse?.data?.group_id || groupResponse?.data?.id;
      console.log('📌 ID del grupo creado:', groupId);

      if (!groupId) {
        console.error('No se pudo obtener el ID del grupo');
        return;
      }

      // 3. Añadir elementos al grupo
      const wrappersIds = selectedWrappers.map(w => parseInt(w.id));
      const apiProductsIds = selectedApiProducts.map(p => parseInt(p.api_product_id));

      if (wrappersIds.length > 0) {
        const wrappersPayload = {
          table: 'obex_infrastructure_designer_gateways_configuration',
          elem_id: wrappersIds.length === 1 ? wrappersIds[0] : wrappersIds,
          group_id: parseInt(groupId)
        };
        console.log('🔧 Adding wrappers payload:', wrappersPayload);
        await ObexRequestHandler.post('/add_to_group_list', wrappersPayload);
      }

      if (apiProductsIds.length > 0) {
        const apiProductsPayload = {
          table: 'avapapiproducts',
          elem_id: apiProductsIds.length === 1 ? apiProductsIds[0] : apiProductsIds,
          group_id: parseInt(groupId)
        };
        console.log('📦 Adding API products payload:', apiProductsPayload);
        await ObexRequestHandler.post('/add_to_group_list', apiProductsPayload);
      }

      const updatedGroups = await fetchGroupData();
      setGroupsWithData(updatedGroups);
      closeDrawer();
      
    } catch (error) {
      console.error('❌ Error creating group:', error);
      console.error('Response data:', error.response?.data);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      setLoading(true);
      const response = await ObexRequestHandler.delete(
        `/group_list?group_id=${groupId}&delete_all_lists_of_group=true`,
        {}
      );
      
      console.log('🗑️ Delete group response:', response);
      
      // Reconstruir el array de grupos con elementos
      const updatedGroups = await fetchGroupData();
      setGroupsWithData(updatedGroups);
      
    } catch (error) {
      console.error('❌ Error deleting group:', error);
    } finally {
      setLoading(false);
    }
  };

  // Asegurarnos de que los datos se cargan cuando el componente se monta
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          fetchWrappers(),
          fetchApiProducts()
        ]);
      } catch (error) {
        console.error('Error loading initial data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []); // Se ejecuta solo al montar el componente

  useEffect(() => {
    fetchGroupData();
  }, []);

  return (
    <DashLayout sider={DashRouters} active={'api-publisher'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8'>
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={devIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px">
                <span style={{ color: '#949498', fontSize: '14px' }}>Gateway </span>
                / API products resources
              </span>
            </div>
          </div>
        </Col>
        <Col md='4' className='align-content-center'>
          <Button 
            style={{ width: '225px' }} 
            className="customButton-black btn px-4 float-right resBtn" 
            onClick={() => openDrawer()}
          >
            Create Group
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div className="cld_borderWrapper">
          <Table className="cld_tableWrapper">
            <tbody>
              <tr className="headerRow">
                <th className="textBold">Group Information</th>
                <th className="textBold">Group Elements</th>
                <th style={{ textAlign: 'end' }} className="textBold">Actions</th>
              </tr>
              {groupsWithData.map((group, index) => (
                <tr className="tableRow" key={index}>
                  <td className="td_centered">
                    <strong>{group.name || 'Unnamed Group'} {group.id}</strong>
                    <br />
                    <span style={{ color: '#666' }}>{group.descripcion || 'No description'}</span>
                    <br />
                    {group.type_table}
                  </td>
                  <td style={{padding: '15px'}}>
                    
                    {/* API Products Table */}
                    {group.apiProducts.length > 0 && (
                      <>
                        <h6 className="text-white mb-2">API Products</h6>
                        <Table className="table-sm mb-3" style={{ margin: 0, color: 'white' }}>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th style={{textAlign:'center'}} >Status</th>
                              <th>Endpoint Route</th>
                            </tr>
                          </thead>
                          <tbody>
                            {group.apiProducts.map((element, elementIndex) => (
                              <tr key={`api-${elementIndex}`}>
                                <td>{element.api_product_name}</td>
                                <td>
                                  
                                    {element.active ? getAmbientLabel('Active') : getAmbientLabel('Inactive')}
                                  
                                </td>
                                <td>{element.endpoint_route}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    )}

                    {/* Wrappers Table */}
                    {group.wrappers.length > 0 && (
                      <>
                        <h6 className="text-white mb-2">Wrappers</h6>
                        <Table className="table-sm mb-3" style={{ margin: 0, color: 'white' }}>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th style={{textAlign:'center'}}>Status</th>
                              <th>Endpoint Route</th>
                            </tr>
                          </thead>
                          <tbody>
                            {group.wrappers.map((wrapper, wrapperIndex) => (
                              <tr key={`wrapper-${wrapperIndex}`}>
                                <td>{wrapper.avap_api_product_name}</td>
                                <td>
                                  
                                    {wrapper.active ? getAmbientLabel('Active') : getAmbientLabel('Inactive')}
                                 
                                </td>
                                <td>{wrapper.endpoint_route}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    )}

                    {(!group.apiProducts.length && !group.wrappers.length) && (
                      <div className="text-center text-muted">
                        No elements found
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="actionsWrapper">
                      <Dropdown className='my-auto' style={{ textAlign: 'end', width: '100%', marginRight: '0px' }}>
                        <Dropdown.Toggle className="organizations">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => openDrawer(group)}>Edit</Dropdown.Item>
                          <Dropdown.Item 
                            onClick={() => {
                              if (window.confirm('¿Estás seguro de que deseas eliminar este grupo?')) {
                                handleDeleteGroup(group.id);
                              }
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      <Drawer
        index="gateway_groups"
        toggleSidebar={closeDrawer}
        isOpen={isDrawerOpen}
        name={isEditing ? `Edit Group: ${currentGroup?.name}` : 'Create New Group'}
        subtitle=''
      >
        <Form 
          onSubmit={isEditing ? handleEditGroup : handleSubmit}
          className="h-100"
        >
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter group name"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter group description"
              value={newGroupDescription}
              onChange={(e) => setNewGroupDescription(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Wrappers</Form.Label>
            <GenericSelectableList
              items={wrappers}
              searchKeys={['avap_api_product_name', 'api_type_name']}
              onAdd={handleAddWrapper}
              onRemove={handleRemoveWrapper}
              renderLabel={(wrapper) => 
                `${wrapper.avap_api_product_name} - ${wrapper.api_type_name} ${wrapper.version}`
              }
              placeholder="Select wrappers..."
              multiOption={true}
              selectedItems={selectedWrappers}
              setSelectedItems={(items) => {
                // Asegurar que no hay duplicados al establecer los items
                const uniqueItems = items.filter((item, index, self) =>
                  index === self.findIndex(t => t.id === item.id)
                );
                setSelectedWrappers(uniqueItems);
              }}
              itemSelected={''}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>API Products</Form.Label>
            <GenericSelectableList
              items={apiProducts}
              searchKeys={['api_product_name']}
              onAdd={handleAddApiProduct}
              onRemove={handleRemoveApiProduct}
              renderLabel={(product) => 
                `${product.api_product_name} (v${product.latest_version})`
              }
              placeholder="Select API products..."
              multiOption={true}
              selectedItems={selectedApiProducts}
              setSelectedItems={(items) => {
                // Asegurar que no hay duplicados al establecer los items
                const uniqueItems = items.filter((item, index, self) =>
                  index === self.findIndex(t => t.id === item.id)
                );
                setSelectedApiProducts(uniqueItems);
              }}
              itemSelected={''}
            />
          </Form.Group>

          <Row>
            <Col md='6'>
              <Button 
                type="submit" 
                variant="primary" 
                className="w-100"
                disabled={loading || !newGroupName?.trim()}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  isEditing ? 'Update Group' : 'Create Group'
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </DashLayout>
  );
};

export default GateWayApiproducts;
