import React, { FunctionComponent, useState, useEffect } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Table, Container, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../../services/AmountService';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../mobileComponents/Disclaimer';

import billingIcon from '../../../assets/img/ui_icons/cld_billing-s.svg'
import SessionService from '../../../services/SessionsService';
import { useHistory } from 'react-router-dom';

type PaymentsProps = {

}


const PaymentsContent: FunctionComponent<PaymentsProps> = (props) => {

  const [payments, setPayments] = useState([]);
  const history = useHistory()
  const [stillLoaded, setStillLoaded] = useState(true);
  const [sublinks, setSublinks] = useState([
    {
      "path": "upgrade_subcription",
      "title": "Upgrade Subscription"
    },

    {
      "path": "change_payment_duration",
      "title": "Change Payment Duration"
    },

    {
      "path": "payments",
      "title": "Payments"
    }

  ]);

  const getPayments = async () => {
    try {
      const result = await ObexRequestHandler.get('/payments');
      const { success, data } = result;
      if (success) {
        setStillLoaded(false);
        setPayments(data);
      } else {
        setStillLoaded(false);
        console.log('Cannot get the account settings information');
      }
    } catch (error) {
      setStillLoaded(false);
      console.error('ERROR FETCHING ACCOUNT SETTINGS ', error);
    }
    history.push('/cloud_billing')
  }


  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/cloud_billing'){
      history.push('/cloud_billing')
      SessionService.updating = SessionService.updating -3;
      getPayments()
    window.scrollTo(0, 0);
    history.push('/cloud_billing')
    }
  
  }, false);


  useEffect(() => {
    window.scrollTo(0, 0);
    getPayments()

    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
  }, [])

  return (
    <>
      <BrowserView>
        <DashLayout active="plan_billing" sider={DashRouters} >


          <Row id='topHeader'>
            <Col >
              <div className='d-flex flex-column'>
              <div className="cld_wrapperTitle">
              <img src={billingIcon} alt="icon dashboard" />
                <span className="cld_title w-100 margin-y-24px">
                {/*<span style={{color:'#949498', fontSize:'14px'}}>Billing </span>/ */}Billing 
          
                </span>
                </div>
                <div className="customBorder"></div>
                {/* <span className="cld_legend w-100">
                  Record and detail of  the amounts charged to your account.
                </span> */}
              </div>
            </Col>

          </Row>


          <div>
            {stillLoaded ?

              <Container className="vh-100 d-flex flex-column vw-100">

                <Row className="h-100" >


                  <Col md="1" className="mx-auto d-flex align-items-center">
                    <Spinner animation="border" />
                  </Col>

                </Row>
              </Container>
              :

              <>
                <div style={{ marginBottom: '2rem' }}></div><><Row style={{ marginBottom: '0rem' }}>
                  {/*} <Col>
              <h2 className='textBold'>Subscription</h2>
            </Col> */}
                </Row>
                  <Row>

                    <Col>
                      <div  className='cld_CardWrapper'>
                      <span className='cld_sectionTitle'>Subscription Payments</span>
                        <Table responsive>

                          <tbody>
                            <tr style={{ color: '#595959' }}>
                              <th className='textBold' style={{ width: '10%' }}>Type</th>
                              <th style={{ textAlign: 'center', width: '20%' }} className='textBold'>Net Amount</th>
                              <th style={{ textAlign: 'center', width: '20%' }} className='textBold'>Total Amount</th>
                              <th style={{ textAlign: 'center', width: '20%' }} className='textBold'>Fee</th>
                              <th style={{ textAlign: 'start', width: '20%' }} className='textBold'>Description</th>
                              <th style={{ textAlign: 'start', width: '10%' }} className='textBold'>Date</th>

                            </tr>

                            {payments.sort((a, b) => b.payment_id - a.payment_id).map(payment => (
                              <tr key={payment.id} className="tableRow">
                                <td>Payout</td>
                                <td style={{ textAlign: 'center' }}>({parseToCoin(payment.amount)})</td>
                                <td style={{ textAlign: 'center' }}>{parseToCoin(payment.amount)}</td>
                                <td style={{ textAlign: 'center' }}> - </td>
                                <td style={{ textAlign: 'start' }}>{payment.description}</td>
                                <td style={{ textAlign: 'start' }}>{payment.date}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row></></>
            }
          </div>
        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default PaymentsContent;