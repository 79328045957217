import React, { useEffect, useState } from 'react';
import SessionService from '../../../services/SessionsService';
import Cookies from 'universal-cookie';
//import say from 'say' 


interface WebSocketComponentProps {
  prompt?: any; // Aquí especificas que 'sider' puede ser de cualquier tipo
}

const WebSocketComponent = ({ prompt, restart, setRestart }) => {
  const [messages, setMessages] = useState('');

/*
  useEffect(()=>{
    if (restart){
      setRestart(false);

      const socket = new WebSocket('wss://staging.avapcloud.com:3001'); // Conectar al puente WebSocket
    
      socket.onopen = () => {
        console.log('WebSocket connected');
  
        // Enviar un mensaje al servidor cuando se conecta
        const message = {
          token: `id_owner_user:${SessionService.loginData.user_id}`,
          prompt: prompt, // "Dada la informacion de mi cuenta, hay algun valor que presente alerta con respecto de los limites establecidos? hazme un resumen de los datos principales consumos con respecto de los limites de mi cuenta, por favor.",
          context: '101obex',
          api: 'chatcompletion',
          model: 'gpt-3.5-turbo',
          openai_api_token: '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'
        };
  
        if (SessionService.Analysis == undefined){
          SessionService.Analysis = [];
          socket.send(JSON.stringify(message));
        } else {
          SessionService.Analysis.forEach(element => {
            setMessages((prevMessages) => prevMessages + element);
          });
        }
      };
  
      socket.onmessage = (event) => {
        console.log('Mensaje recibido:', event.data);
  
        // Concatenar el nuevo mensaje sin agregar espacio adicional
        SessionService.Analysis.push(event.data.replace('END', ''))
        setMessages((prevMessages) => prevMessages + event.data.replace('END', ''));
      };
  
      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
  
      socket.onclose = () => {
        console.log('WebSocket disconnected');
      };
  
      return () => {
        socket.close();
      };

    }

  }, [restart])
  */


  useEffect(() => {
    window.onload = () => {
      console.log('La página se ha recargado.');
      if (SessionService.loginData === undefined) {

        console.log("XXXX----XXXX")
        SessionService.renewSession();

      }
      
      
    };
    
    return ()=>{
        window.onload = null;
    }

  }, []);


  useEffect(() => {

    if (restart){
      setRestart(false);
      setMessages('');
    }
    
    const socket = new WebSocket('wss://staging.avapcloud.com:3001'); // Conectar al puente WebSocket
    
    socket.onopen = () => {
      console.log('WebSocket connected');

                const cookies = new Cookies();
                const userInfo = cookies.get('101Obex');
      // Enviar un mensaje al servidor cuando se conecta
      const message = {
        token: `id_owner_user:${userInfo.user_id}`,
        prompt: prompt, // "Dada la informacion de mi cuenta, hay algun valor que presente alerta con respecto de los limites establecidos? hazme un resumen de los datos principales consumos con respecto de los limites de mi cuenta, por favor.",
        context: '101obex',
        api: 'chatcompletion',
        model: 'gpt-3.5-turbo',
        openai_api_token: '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'
      };

      if (SessionService.Analysis == undefined){
        SessionService.Analysis = [];
        socket.send(JSON.stringify(message));
      } else {
        SessionService.Analysis.forEach(element => {
          setMessages((prevMessages) => prevMessages + element);
        });
      }
    };

    socket.onmessage = (event) => {
      console.log('Mensaje recibido:', event.data);

      // Concatenar el nuevo mensaje sin agregar espacio adicional

      if (event.data.includes('END')){
       // say.speak(event.data)
      }
      SessionService.Analysis.push(event.data.replace('END', ''))
      setMessages((prevMessages) => prevMessages + event.data.replace('END', ''));
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
    };

    return () => {
      socket.close();
    };
  }, [restart]);

  return (
    <div>
   {/*}   <h2>Mensajes del Servidor:</h2> */}
      <div
        style={{
       //   border: "1px solid black",
          padding: '10px',
          minHeight: '100px',
          whiteSpace: 'pre-wrap', // Mantiene los saltos de línea y espacios, pero no se agrega más espacio
          overflowY: 'auto', // Permite desplazamiento vertical
          overflowX: 'hidden', // Desactiva el scroll horizontal
          maxHeight: '400px', // Puedes ajustar la altura según lo que necesites
        }}
      >
        {messages} 
      </div>
    </div>
  );
};

export default WebSocketComponent;
