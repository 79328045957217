import React, { Fragment } from 'react';
import GettingStarted_SoftwareDownload_Figure1_1 from '../../assets/img/developerPortal/GettingStarted_SoftwareDownload_Figure1.1.png';

const Software_Downloads = () => (
  <Fragment>
    <div className="body-md">
      <h1>Software Download</h1>
      
      <h2>AVAP Framework Software Download</h2>
      <p>
        On the AVAP ID portal, a user can download all software available on the AVAP Framework, regardless of whether it is associated with a free license or a commercial license.
      </p>
      <p>
        Log in to AVAP ID and navigate to the Downloads section, where you will find all available products and their versions across the different platforms associated with each one.
      </p>

      <br />
      <div style={{ textAlign: 'center' }}>
        <img
          src={GettingStarted_SoftwareDownload_Figure1_1}
          alt="Figure 1.1 Downloads Section in AVAP ID"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
        <small>Figure 1.1 Downloads Section in AVAP ID</small>
      </div>
      <br />

      <p>
        Select the desired product download platform and proceed with the download.
      </p>
      <p>
        All products require an active AVAP ID, and for commercial products linked to a membership or paid commercial license, an active membership or commercial license is required for proper use and functionality.
      </p>
      <p>
        For IDE extensions in Microsoft Visual Studio Code, it is recommended to install them directly from the Microsoft Visual Studio Code development IDE, although users can also download the extensions from the AVAP ID portal and then install them in Microsoft Visual Studio Code.
      </p>
      <p>
        You can find more information related to AVAP ID at AVAP Alexandria{' '}
        <a href="http://alexandria.avapframework.com/" target="_blank" rel="noopener noreferrer">
          alexandria.avapframework.com
        </a>{' '}
        and connect with the user community at AVAP Communities{' '}
        <a href="http://communities.avapframework.com/" target="_blank" rel="noopener noreferrer">
          communities.avapframework.com
        </a>
        .
      </p>
    </div>
  
  </Fragment>
);
export default Software_Downloads;
