import React, { FunctionComponent } from 'react'; 
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import Logo from '../../assets/img/newAvapCloud/AVAP Cloud_Business_Logo.png';
import Logo2 from '../../assets/img/newAvapCloud/AVAP_Cloud_Professional-logo.png';
import ImgSerpentina from '../../assets/img/ilustracion-serpentina.png';
import { useParams } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';
import succes_payment from '../../assets/img/mobile/ilustracion_PagoExito.svg';

function routeLogin() {
  const path = '/cloud_login';
  window.location.href = path;
}

const handleLogIn = async () => {
routeLogin();

}




const VerifyCloudSignUp:FunctionComponent<{}> = () => {

  const { token } = useParams();
  return(
    <>
      <BrowserView>
    <Container fluid>
      <Row style={{marginTop:'3rem'}} className="pt-3 verify-signup">
        <Col md="6" className="border rounded px-5 pt-3 pb-4 login-form mx-auto">
          <Row className="justify-content-center my-4">
            <Col xs="6">
              <Image src={token == 'professional' || window.location.pathname != '/business-success_en' ? Logo2 : Logo } fluid/>
            </Col>
          </Row>
          <Row className="">
            <Col className="my-2 text-center" >
              <h1 style={{ marginTop:'24px' }} className="mb-2">Membership Registration Complete</h1>
              <span className="verify-text-content">
              Congratulations! Your membership has been successfully created. An email has been sent 
              to your registered address to verify your account. Please check your inbox and click the link in the
               email to complete the activation process.</span>
            </Col>
          </Row>
          <Row className="my-5">
            <Col md="4" className="mx-auto">
              <Image src={ImgSerpentina} fluid/>
            </Col>
          </Row>
          <Row  className="" style={{textAlign:'center'}}>
            <Col md="12" className="mx-auto">
            <div className='landingFont3 textBold'>Remember to verify your account</div>
             {/*} <Button type="submit" onClick={handleLogIn} className="btn-block btn-green">Login to Cloud Dashboard</Button>  */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    </BrowserView>
      <MobileView>
       


<div style={{ backgroundColor:'#FFFFFF', minHeight:'100vh' }}>

  <div style={{ textAlign:'center', color:'#4A76B8', paddingTop:'100px', paddingLeft:'40px', paddingRight:'40px', fontSize:'1.2rem' }} className='landingFont3 textBold'>
  Congratulations!
</div>
<div style={{textAlign:'center'}}>
<img style={{ paddingTop:'30px', paddingLeft:'20px', paddingRight:'20px' }} src={succes_payment}></img>
</div>
        
<div style={{ width:'100%', backgroundColor:'transparent', marginTop:'20px' }}>
                  <div style={{textAlign:'center', fontSize:'1.1rem', color:'#333031', width:'80%', paddingTop:'10px', paddingBottom:'10px', marginLeft:'auto', marginRight:'auto'}} className='landingFont3b textBold'>
                  Membership Registration Complete
                  </div>
</div>
                  <div style={{ width:'100%', backgroundColor:'transparent', marginTop:'20px' }}>
                  <div style={{textAlign:'center', fontSize:'1.1rem', color:'#333031', width:'80%', paddingTop:'10px', paddingBottom:'10px', marginLeft:'auto', marginRight:'auto'}} className='landingFont3b'>
                An email has been sent 
              to your registered address to verify your account. Please check your inbox and click the link in the
               email to complete the activation process.
                  </div>

          </div>
          <div style={{ textAlign:'center', marginTop:'40px'}}>
<button style={{ textAlign:'center', height:'50px', marginTop:'40px' }} onClick={()=>{window.location.replace('/cloud_login')}} className='custom-button-blue-mobile' >Visit AVAP Cloud</button>
</div>

</div>







      </MobileView>
    </>
  )
}


export default VerifyCloudSignUp;