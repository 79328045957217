import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Form,
  Card,
  Table,
  Dropdown,
  Modal,
  Spinner,
  Alert,
} from "react-bootstrap";
import { FaTh, FaList } from "react-icons/fa";
import Drawer from "../avapCloud/components/Drawer";
import CreateAppForm from "./CreateAppForm";
import { useHistory, useParams } from 'react-router-dom';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import Cookies from 'universal-cookie';

import sun from '../../assets/img/ui_icons/sun.svg';
import moon from '../../assets/img/ui_icons/moon.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import PortalNavbar from './components/portal_navbar';
import { DynamicButton } from './components/DynamicButton';

const cookies = new Cookies();

interface ApiProduct {
  title: string;
  description: string;
  latestVersion: string;
  details: string;
  type: any;
}

interface Portal {
  portal_id: number;
  id: string;
  name: string;
  logo: string;
  theme: {
    primaryColor: string;
    secondaryColor: string;
    backgroundColor: string;
    textColor: string;
  };
  user: {
    name: string;
    photo: string;
  };
  apiProducts: ApiProduct[];
}

interface ButtonProps {
  text: string;
  theme: {
    primaryColor: string;
  };
  onClick?: () => void;
  className?: string;
}

interface PortalNavbarProps {
  portal: Portal;
  Logo: string;
  userName: string;
  isDarkMode: boolean;
  User: {
    type: string;
    user_id: number;
  };
  portalIdentifier: string;
  setDrawerOpen: (isOpen: boolean) => void;
  toggleDarkMode: () => void;
}

const ApiPortal: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const portalIdentifier = id.startsWith('/_') ? id.slice(2) : id;
  const [portal, setPortal] = useState<Portal | null>(() => {
    const portalData = cookies.get('portal');
    return portalData || null;
  });
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [viewMode, setViewMode] = useState<'card' | 'list'>('card');
  const [filteredProducts, setFilteredProducts] = useState<ApiProduct[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();
  const [userID, setUserId] = useState(0)
  const [userName, setUserName] = useState('unknown')
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });
  const [portal_id, set_portal_id] = useState(0);
  const [Logo, setLogo] = useState('https://ruta-a-tu-imagen/@profile.png');

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

  const portalStyles = React.useMemo(() => ({
    primaryColor: portal?.theme?.primaryColor || '#000',
    backgroundColor: portal?.theme?.backgroundColor || '#fff',
    textColor: portal?.theme?.textColor || '#000'
  }), [portal?.theme]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (password != repeatPassword) {
      setError('Password and Repeat Password mut be the same');
     
    } else {
    try {



      setLoading(true);
      setSuccess('');
      setError('');// Limpiamos los posibles errores

      console.log(User);
      //const result = await ObexRequestHandler.post('/request_reset_password', { email });
      const result = await ObexRequestHandler.get(`/publishing/change_password_portal_user?user_id=${User.user_id}&password=${password}`);
      //console.log(`${result}`)
      //let result = {success:true, data:'', message:''}
      const { success, data, message } = result;
      if (success){
        setSuccess(data);
        User.change = false;
        cookies.set('user', JSON.stringify(User), { 
          path: `/devportal/${id}`, 
          expires: new Date(Date.now() + (10 * 60 * 1000)),
          sameSite: 'strict'
        });
      } else {
        setError(message);
      }
    } catch (error) {
      console.error('ERROR REQUESTING RESET PASSWORD ', error);
      setError(error.message || error);
    }
    setLoading(false);
    setTimeout(function() { 
      setError('');
      setSuccess('');
      //                           props.handleClose();
      }.bind(this), 2000)
    //setTimeout(function() { 
    //  const path = '/login';
    //  window.location.href = path;
    //  }.bind(this), 4000)

    setShowModal(false);
    }
  };



  const [showModal, setShowModal] = useState(true);
  const closeChange = () => {
    setShowModal(false);
  }

  const handleClose = () => {
    DevPortalExists();
    //DevPortalProductsExists();
  }

  /*

  const DevPortalExists = async ()=>{

    try{
    const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_config/${id}`, {}, true);

    const { data } = res;
    if (data.id!=undefined) {
      setPortal(data)
    }


    } catch (error) {
      console.log(error)
    }
  }
*/
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');


const handlePassword = (e) => {
  setError('')
  const { value } = e.target;
  setPassword(value);
}

const handleRepeatPassword = (e) => {
  setError('')
  const { value } = e.target;
  setRepeatPassword(value);
}


const [User, setUser] = useState({type: '', user_id:0, change: false})
const setFavicon = (url: string) => {
  let link = document.querySelector("link[rel='icon']") as HTMLLinkElement;

  if (!link) {
    // Si no existe, lo creamos
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }

  // Cambiamos la URL del favicon
  link.href = url;
};


  const DevPortalExists = async () => {
    const portalData = cookies.get('portal');
    const user = cookies.get('user');
    
    if (!portalData) {
      history.replace(`/devportal/${id}/login`);
      return;
    }

    if (JSON.stringify(portalData) !== JSON.stringify(portal)) {
      setPortal(portalData);
      document.title = portalData.name;
      setFavicon(portalData.logo);
    }

    set_portal_id(portalData.portal_id);
    setUser(user);
    
    if (user) {
      setShowModal(user.change);
    }

    if (user?.username) {
      setUserName(user.username);
      setUserId(user.user_id);
    }

    // Obtener el logo
    const getLogo = async () => {
      try {
        const logo = await ObexRequestHandler.get(`/publishing/get_dev_portal_logo/${id}`);
        setLogo(logo.data.logo);
      } catch (error) {
        console.error('Error al obtener el logo:', error);
        // Mantener el logo por defecto en caso de error
      }
    };
    getLogo();

    // Obtener los productos
    if (portalData.portal_id) {
      DevPortalProductsExists(portalData.portal_id);
    }
  }



  const DevPortalAppsExists = async (portal_id) => {

    try {
      const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_apps/${portal_id}`, {}, true);

      const { data } = res;

      if (data.length > 0) {
        const api_products = data.map((prod) => ({
          title: prod.app_name,
          description: prod.app_description,
          latestVersion: 'v0.1',
          details: prod.app_description,
          type: prod.type
        }));
        
        setFilteredProducts(api_products);
        setAllProducts(api_products);
      }


    } catch (error) {
      console.log(error)
    }
  }


  const DevPortalProductsExists = async (portal_id) => {

    try {
      const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_products/${portal_id}`, {}, true);

      const { data } = res;

      if (data.length > 0) {
        const api_products = data.map((prod) => ({
          title: prod.avap_api_product_name,
          description: prod.dev_portal_published_description,
          latestVersion: 'v0.1',
          details: prod.dev_portal_published_description + prod.avap_api_product_documentation,
          type: prod.wrapper_id != null ? 'REST':'WRAPPER'
        }));
        
        setFilteredProducts(api_products);
        setAllProducts(api_products);
      }


    } catch (error) {
      console.log(error)
    }
  }

  const toggleDarkMode = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };


  useEffect(() => {
    DevPortalExists();
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  // Agregar un nuevo estado para todos los productos
  const [allProducts, setAllProducts] = useState<ApiProduct[]>([]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    
    if (term === '') {
      setFilteredProducts(allProducts);
    } else {
      const filtered = allProducts.filter((product) =>
        product.title.toLowerCase().includes(term) ||
        product.description.toLowerCase().includes(term)
      );
      setFilteredProducts(filtered);
    }
  };

  const handleAppSubmit = (app: { name: string; description: string; selectedAPIs: string[] }) => {
    console.log('App Created:', app);
    setDrawerOpen(false);
  };

  const toggleViewMode = () => {
    setViewMode((prevMode) => (prevMode === 'card' ? 'list' : 'card'));
  };

  if (!portal) return <div>Loading...</div>;

  return (
    <div id="devPortalWrapper">
      <PortalNavbar
        portal={portal}
        Logo={Logo}
        userName={userName}
        isDarkMode={isDarkMode}
        User={User}
        portalIdentifier={portalIdentifier}
        setDrawerOpen={setDrawerOpen}
        toggleDarkMode={toggleDarkMode}
      />

      {/* Barra central */}
      <div
        className="text-center p-4 dp-banner"
        style={{ backgroundColor: portalStyles.primaryColor, color: "#fff" }}
      >
        <h2>Welcome to our API Portal!</h2>
        <p>Start building and innovating with our APIs</p>
        <Form.Control
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-50 mx-auto"
        />
      </div>

      {/* Área de productos */}
      <Container className="my-4">
        <Row className="mb-3">
          <Col>
            <h4>API Products</h4>
          </Col>
          <Col className=" justify-content-end d-flex">
            <Button onClick={toggleViewMode}
              style={{
                backgroundColor: portalStyles.primaryColor,
                border: 'none'
              }}
            >
              {viewMode === "card" ? <FaList /> : <FaTh />}
            </Button>
          </Col>
        </Row>

        {/* Vista de productos */}
        {viewMode === "card" ? (
          <Row>
            {filteredProducts.map((product, index) => (
              <Col md={4} key={index} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>{product.title}</Card.Title>
                    <Card.Text>
                      <strong>Description:</strong> {product.description}
                    </Card.Text>
                    <Card.Text>
                      <strong>Latest Version:</strong> {product.latestVersion}
                    </Card.Text>
                    <Card.Text>
                      <strong>Details:</strong> {product.details}
                    </Card.Text>
                    <Card.Text>
                      <strong>Type:</strong> {product.type}
                    </Card.Text>
                  </Card.Body>

                  <DynamicButton
                    text="Documentation"
                    theme={{ primaryColor: portalStyles.primaryColor }}
                    className="d-table"
                    onClick={() => history.push(`/devportal/${id}/documentation`)} />
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Table className="cld_tableWrapper" hover>
            <thead>
              <tr className="tableRow">
                <th className=" ">Title</th>
                <th className=" ">Description</th>
                <th className=" ">Latest Version</th>
                <th className=" ">Details</th>
              </tr>
            </thead>
            <tbody className="tableRow">
              {filteredProducts.map((product, index) => (
                <tr key={index}>
                  <td>{product.title}</td>
                  <td>{product.description}</td>
                  <td>{product.latestVersion}</td>
                  <td>{product.details}</td>
                  <td><DynamicButton
                    text="Documentation"
                    theme={{ primaryColor: portalStyles.primaryColor }}
                    onClick={() => history.push(`/devportal/${id}/documentation`)} /></td>
                </tr>
              ))}

            </tbody>
          </Table>
        )}
      </Container>

      {/* Drawer */}
      <Drawer
        isOpen={drawerOpen}
        toggleSidebar={() => setDrawerOpen(false)} // Cierra el Drawer
        name="Create App"
      >


<Modal show={showModal} onHide={handleClose} id="themodal">
          <Modal.Body >
          <Form className="obex-form payment-method-form" onSubmit={handleSubmit}>
            <h2>Please, set a new password</h2>
              <Form.Row className="mb-4">
                <Form.Label column sm="12" className="obex-form-label">
                  Password
                </Form.Label>
                <Col md="12">
                  <Form.Control required type="password" placeholder="Password" onChange={handlePassword}/>
                </Col>
              </Form.Row>
              <Form.Row className="mb-4">
                <Form.Label column sm="12" className="obex-form-label">
                  Repeat Password
                </Form.Label>
                <Col md="12">
                  <Form.Control required type="password" placeholder="Repeat Password" onChange={handleRepeatPassword}/>
                </Col>
              </Form.Row>

              <Form.Row className="my-3">
                <Col md="4" className="mx-auto">
                  <Button type="submit" className="textBold custom-button-blue">
                    Continue
                    {success && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '8px' }}/>}
                    {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                  </Button>
                </Col>
              </Form.Row>
              {error.length>0 && <Alert variant="danger">{error}</Alert>}
            </Form>

          </Modal.Body>
          
</Modal>
          

        <CreateAppForm

          app_data={{
            id: 0,
            name: undefined,
            description: undefined,
            latestVersion: undefined,
            createdAt: undefined,
            apiProducts: [],
            apiProductsID: [],
            tokens: [],
            tokensId: []
          }}
          onSubmit={() => {
            setDrawerOpen(false);
            DevPortalExists();

          }}
          devportal_id={portal.portal_id.toString()} ></CreateAppForm>
      </Drawer>
    </div>
  );
};

export default ApiPortal;
