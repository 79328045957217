import React from 'react';

interface ButtonProps {
  text: string;
  theme: {
    primaryColor: string;
  };
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

// Función para calcular el brillo del color
export const getContrastColor = (hexColor: string): string => {
  // Validar que el color sea un valor hexadecimal válido
  if (!hexColor || !/^#[0-9A-Fa-f]{6}$/.test(hexColor)) {
    console.warn('Color inválido, usando texto negro por defecto');
    return '#000000';
  }

  try {
    // Convertir hex a RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    
    // Validar que los valores RGB sean números válidos
    if (isNaN(r) || isNaN(g) || isNaN(b)) {
      throw new Error('Valores RGB inválidos');
    }
    
    const toSRGB = (value: number): number => {
      value = value / 255;
      return value <= 0.03928
        ? value / 12.92
        : Math.pow((value + 0.055) / 1.055, 2.4);
    };
    
    const luminance = 0.2126 * toSRGB(r) + 0.7152 * toSRGB(g) + 0.0722 * toSRGB(b);
    
    return luminance > 0.5 ? '#000000' : '#ffffff';
  } catch (error) {
    console.warn('Error al calcular el color de contraste:', error);
    return '#000000'; // Color por defecto en caso de error
  }
};

// Función para oscurecer el color en hover
const getDarkerColor = (hexColor: string): string => {
  try {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    
    // Oscurecer cada componente en un 15%
    const darkerR = Math.max(0, r - (r * 0.15));
    const darkerG = Math.max(0, g - (g * 0.15));
    const darkerB = Math.max(0, b - (b * 0.15));
    
    return `#${Math.round(darkerR).toString(16).padStart(2, '0')}${Math.round(darkerG).toString(16).padStart(2, '0')}${Math.round(darkerB).toString(16).padStart(2, '0')}`;
  } catch (error) {
    console.warn('Error al calcular el color hover:', error);
    return hexColor; // Devolver el mismo color si hay error
  }
};

export const DynamicButton: React.FC<ButtonProps> = ({ text, theme, onClick, className, disabled }) => {
  const buttonStyle = {
    backgroundColor: theme?.primaryColor || '#ffffff',
    color: theme?.primaryColor ? getContrastColor(theme.primaryColor) : '#000000',
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: disabled ? "not-allowed" : "pointer",
    transition: "background-color 0.3s ease",
    opacity: disabled ? 0.6 : 1,
    '&:hover': {
      backgroundColor: !disabled && theme?.primaryColor ? getDarkerColor(theme.primaryColor) : '#f0f0f0',
    }
  } as React.CSSProperties;

  return (
    <button 
      style={buttonStyle} 
      onClick={onClick} 
      className={className}
      disabled={disabled}
      onMouseOver={(e) => {
        if (!disabled) {
          const target = e.currentTarget;
          target.style.backgroundColor = theme?.primaryColor ? getDarkerColor(theme.primaryColor) : '#f0f0f0';
        }
      }}
      onMouseOut={(e) => {
        if (!disabled) {
          const target = e.currentTarget;
          target.style.backgroundColor = theme?.primaryColor || '#ffffff';
        }
      }}
    >
      {text}
    </button>
  );
};