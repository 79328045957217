import React, { FunctionComponent, useState, useEffect } from 'react';
import { Row, Col, Button, Form, Table, Spinner, Dropdown, Alert } from 'react-bootstrap';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import Drawer from './Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import SessionService from '../../services/SessionsService';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import orgIcon from '../../assets/img/ui_icons/credentials.png'
import { useHistory } from 'react-router-dom';
import ConfirmDeletetModal from '../Modals/ConfirmDeleteModal';
import Nodata from './components/Nodata';

type Certificate = {
  id: string;
  name: string;
  issuedby: string;
  issueddate: string;
  expiresdate: string;
  expired: boolean,
  status: string; // 'Valid', 'Expiring Soon', 'Expired'
  type: string;
  certificate_data: any;
};

const Certs: FunctionComponent = () => {
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAddCertDrawerOpen, setIsAddCertDrawerOpen] = useState(false);
  const [securityTypes] = useState(['Self-Signed', 'SSL/TLS', 'Client Certificates']);
  const [securityType, setSecurityType] = useState('Self-Signed')
  const [expires, setExpires] = useState('');
  const [issuer, setIssuer] = useState('');
  const [certName, setCertName] = useState('');
  const [recovered_tokens, set_recovered_tokens] = useState([]);
  const [error, setErr] = useState('');
  const [initialized, setInitialized] = useState(false);

  const [certificate_data, setCertData] = useState('');
  const [certificate_public, setCertPublic] = useState('');
  const history = useHistory();
  const handleCertificate = (e) => {
    setErr('');
    const { name, value, type, checked } = e.target;
    setCertData(value);
  };

  const handlePublic = (e) => {
    setErr('');
    const { name, value, type, checked } = e.target;
    setCertPublic(value);
  };

  const handleCertNameChange = (e) => {
    setErr('');
    const { name, value, type, checked } = e.target;
    setCertName(value);
  };

  const handleIssuer = (e) => {
    setErr('');
    const { name, value, type, checked } = e.target;
    setIssuer(value);
  };


  const AMBIENT_LABELS = {
    Active: { name: 'Active', color: '#28a745' },        // Verde
    Inactive: { name: 'Inactive', color: 'red' }, // Amarillo
    Expired: { name: 'Expired', color: 'red' }, // Amarillo

  };

  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;

    return (
      <span
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth: '100px',
          display: 'flex',
          width: '65px',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };


  const handleExpires = (e) => {
    setErr('');
    const { name, value, type, checked } = e.target;
    setExpires(value);
  };

  const handleCertificateChange = (e) => {
    setErr('');
    const { name, value, type, checked } = e.target;
    setSecurityType(value);
  };
  // Datos simulados
  const mockCertificates: Certificate[] = [
    {
      id: '1',
      name: 'SSL Certificate A',
      issuedby: 'Let\'s Encrypt',
      issueddate: '2023-01-01',
      expiresdate: '2024-01-01',
      status: 'Valid',
      type: 'Self-Signed',
      expired: false,
      certificate_data: ''
    },
    {
      id: '2',
      name: 'TLS Certificate B',
      issuedby: 'DigiCert',
      issueddate: '2022-05-01',
      expiresdate: '2023-05-01',
      status: 'Expired',
      type: 'TLS',
      expired: false,
      certificate_data: ''
    },
    {
      id: '3',
      name: 'API Cert X',
      issuedby: 'AWS',
      issueddate: '2023-06-01',
      expiresdate: '2023-12-31',
      expired: false,
      status: 'Expiring Soon',
      type: 'Client',
      certificate_data: ''
    },
  ];

  // Simulación de carga de datos
  // const fetchCertificates = async (filter: string) => {
  //   setLoading(true);

  //   const mockTokensRequst2 = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`) 

  //   console.log(mockTokensRequst2.data);

  //     const filteredCerts = mockTokensRequst2.data.filter(
  //       (cert) =>
  //         cert.name.toLowerCase().includes(filter.toLowerCase()) ||
  //         cert.id.includes(filter)
  //     );
  //     setCertificates(filter === '' ? mockTokensRequst2.data : filteredCerts);
  //     setLoading(false);

  // };

  const fetchCertificates = async (filter: string): Promise<void> => {
    setLoading(true);

    try {
      const response = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`);
      const data = response.data;

      if (!Array.isArray(data)) {
        console.error('Unexpected data format:', data);
        setCertificates([]);
        return;
      }

      const filteredCerts = data.filter((cert: any) => {
        const certNameMatch = cert.name?.toLowerCase().includes(filter.toLowerCase());
        const certIdMatch = cert.id?.toString().includes(filter);
        return certNameMatch || certIdMatch;
      });
      set_recovered_tokens(data)
      setCertificates(filter.trim() === '' ? data : filteredCerts);
    } catch (error) {
      console.error('Error fetching certificates:', error);
      setCertificates([]);
      setInitialized(true);
    } finally {
      setLoading(false);
      setInitialized(true);
    }
    history.push('/certs')
    setInitialized(true);
  };


  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
    filterProjects(e.target.value);
  };


  const filterProjects = async (filtro) => {

    const filter_projects = recovered_tokens.filter(element =>
      element.name.toLowerCase().includes(filtro.toLowerCase())
    );
    setCertificates(filtro === '' ? recovered_tokens : filter_projects);

  }






  const [showDelete, setShowDelete] = useState(false);
  const [selDeveloper, setSelDeveloper] = useState({ id: '' })
  const [selTeam, setselTeam] = useState({ id: 0, type: '', name: '' });

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  }

  const handleDelete = async (developer) => {
    setShowDelete(true);
    setSelDeveloper(developer);
    setselTeam(developer);

  }






  const handleRevokeCertificate = async (certId: string) => {
    handleShowDelete();
    const updatedCertificates = certificates.map((cert) =>
      cert.id === certId ? { ...cert, status: 'Revoked' } : cert
    );
    setCertificates(updatedCertificates);


    await ObexRequestHandler.get(`/cert/del_certs?id=${certId}`);

    fetchCertificates('');
    history.push('/certs')

  };



  const handleDeactivate = async (developer) => {
    //setShowDelete(true);
    //setSelDeveloper(developer);
    //setselTeam(developer);

    const payload = {
      cert_id: developer.id
    }
    await ObexRequestHandler.post('/cert/deactivate_certs', payload)
    fetchCertificates(filter);
    history.push('/certs')

  }


  const handleActivate = async (developer) => {
    //setShowDelete(true);
    //setSelDeveloper(developer);
    //setselTeam(developer);

    const payload = {
      cert_id: developer.id
    }
    await ObexRequestHandler.post('/cert/activate_certs', payload)
    fetchCertificates(filter);
    history.push('/certs')

  }


  const handleDownloadCertificate = (certificate: Certificate) => {

    const bufferData = new Uint8Array(certificate.certificate_data.data);

    const blob = new Blob([bufferData], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `certificate_${certificate.id}.txt`;

    link.click();
  };

  const handleAddCertificate = (newCert: Certificate) => {
    setCertificates((prevCerts) => [...prevCerts, newCert]);
  };


  document.addEventListener('updateView', (e) => {
    if (SessionService.updating > 0 && location.pathname == '/certs') {
      history.push('/certs')
      SessionService.updating = SessionService.updating - 3;
      setErr('');
      fetchCertificates('');
      window.scrollTo(0, 0);
      history.push('/certs')
    }

  }, false);




  useEffect(() => {
    setErr('');
    fetchCertificates('');

    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };

  }, []);

  return (
    <DashLayout sider={DashRouters} active="certificates" sublinks={[]}>
      <Row id="topHeader">
        <Col md="10">
          <div className="d-flex flex-column">
            <div className="cld_wrapperTitle">
              <img style={{ width: '24px' }} src={orgIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px">
                <span style={{ color: '#949498', fontSize: '14px' }}>Credential Manager </span>/ Certs
              </span>
            </div>
          </div>
        </Col>
        <Col md="2" className="align-content-center">
          <Button
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right resBtn"
            onClick={() => {

              setSecurityType('Self-Signed')
              setExpires('');
              setIssuer('');
              setCertName('');
              setCertPublic('')
              setCertData('')

              setIsAddCertDrawerOpen(true)

            }}
          >
            Add Certificate
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>





      <Row id="table" className="mt-5">
        <Col>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <div className="cld_borderWrapper" style={{ height: 'auto', overflow: 'visible' }}>

              <span className='cld_sectionTitle'>Certs</span>

              <div className="searchContainer">
                <Form.Control
                  id="buscador"
                  required
                  type="text"
                  value={filter}
                  placeholder="Search by Certificate Name"
                  onChange={handleFilterChange}
                />
                <Button
                  className="customButton-transparent px-4"
                  onClick={() => fetchCertificates(filter)}
                >
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </Button>
              </div>


              <Table style={{ overflow: 'visible' }} className="cld_tableWrapper" >
                <thead>
                  <tr className='tableRow'>
                    <th style={{ width: '20%' }} className="textBold">Name</th>
                    <th style={{ width: '20%' }} className="textBold">Issued By</th>
                    <th style={{ width: '15%'}} className="textBold">Type</th>
                    <th style={{ width: '15%' }} className="textBold">Issued Date</th>
                    <th style={{ width: '10%' }} className="textBold">Expires</th>
                    <th style={{ width: '20%', textAlign: 'center' }} className="textBold">Status</th>

                    <th style={{ width: '20px', textAlign: 'end' }} className="textBold">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {(certificates.length === 0 && initialized) ? (
                    <tr>
                      <td colSpan={8}>
                        <div className="nodata-wrapper">
                          <Nodata />
                        </div>
                      </td>
                    </tr>
                  ) : (certificates.map((cert) => (
                    <tr className='tableRow' key={cert.id}>
                      <td className='td_centered'>{cert.name}</td>
                      <td className='td_centered'>{cert.issuedby}</td>
                      <td className='td_centered'>{cert.type}</td>
                      <td className='td_centered'>{cert.issueddate.split('T')[0]}</td>
                      <td className='td_centered'>{cert.expiresdate.split('T')[0]}</td>
                      <td style={{ textAlign: 'center' }} className='td_centered'>{cert.status === undefined ? (cert.expired ? getAmbientLabel('Expired') : getAmbientLabel('Active')) : (cert.status ? getAmbientLabel('Active') : getAmbientLabel('Inactive'))}</td>

                      <td>
                        {/*}
                        <Button
                          className="customButton-blue"
                          onClick={() => handleDownloadCertificate(cert)}
                          style={{ marginRight: '8px' }}
                        >
                          Download
                        </Button>
                        {cert.status !== 'Revoked' && (
                          <Button
                            className="customButton-red"
                            onClick={() => handleRevokeCertificate(cert.id)}
                          >
                            Revoke
                          </Button>
                        )}
                          */}

                        <div className="actionsWrapper">

                          <Dropdown className='my-auto' style={{ width: '100%', marginRight: '0px', textAlign: 'end' }}>
                            <Dropdown.Toggle className="" >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {cert.type == 'Self-Signed' && <Dropdown.Item key={1} onClick={() => { handleDownloadCertificate(cert) }}>Download</Dropdown.Item>}
                              {(cert.status !== 'Revoked') && <Dropdown.Item key={1} onClick={() => handleDelete(cert)}>Revoke</Dropdown.Item>}
                              {cert.status ? <Dropdown.Item className='noItallic' key={2} onClick={() => handleDeactivate(cert)}>Deactivate</Dropdown.Item> : <Dropdown.Item className='noItallic' key={2} onClick={() => handleActivate(cert)}>Activate</Dropdown.Item>}

                            </Dropdown.Menu>
                          </Dropdown>
                        </div>


                      </td>
                    </tr>
                  )))}
                  {/*}
                  {certificates.length < 3 &&
                    <div style={{ height:'65px' }}></div>
                  } */}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>

      {/* Drawer for Adding Certificate */}
      <Drawer
        index='certificates'
        isOpen={isAddCertDrawerOpen}
        toggleSidebar={() => setIsAddCertDrawerOpen(!isAddCertDrawerOpen)}
        name="Add Certificate"
      >
        <div>
          {/* <h3>Add a New Certificate</h3> */}
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              /*
              handleAddCertificate({
                id: `${certificates.length + 1}`,
                name: 'New Certificate',
                issuedBy: 'Custom CA',
                issuedDate: new Date().toISOString().split('T')[0],
                expiresDate: '2025-12-31',
                status: 'Valid',
                type:'Self-Signed'
              });*/

              const expiration = expires;
              const type = securityType;
              const org_id = SessionService.ORGANIZATION;
              const certificate_name = certName;
              const public_key = certificate_public;
              const payload = {
                issuer,
                expiration,
                type,
                org_id,
                certificate_name,
                certificate_data,
                public_key
              }
              console.log(payload)

              if (!expiration || !type || !certificate_name
                || (type == 'SSL/TLS' && !certificate_name)
                || (type == 'SSL/TLS' && !public_key)
                || (type == 'Client Certificates' && !certificate_name)
                || (type == 'Client Certificates' && !public_key)
              ) {

                setErr('Please fill all data')

              } else {
                setLoading(true);
                await ObexRequestHandler.post('/cert/add_certs', payload)
                setIsAddCertDrawerOpen(false);
                setLoading(false);

                fetchCertificates('');
              }

            }}
          >


            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control required type="text" placeholder="Enter certificate name"
                value={certName}
                onChange={handleCertNameChange} />

            </Form.Group>


            <Form.Group>
              <Form.Label>Certificate Type</Form.Label>
              <Form.Control
                as="select"
                name="securityType"
                required
                value={securityType}
                onChange={handleCertificateChange}
              >
                <option value="">Select a Certificate type</option>
                {securityTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Issued By</Form.Label>
              <Form.Control required type="text" placeholder="Enter issuer"
                value={issuer}
                onChange={handleIssuer}
              />
            </Form.Group>


            <Form.Group controlId="expirationDate" className="mb-3">
              <Form.Label>Expiration Date</Form.Label>
              <Form.Control
                required
                type="date"
                name="expirationDate"
                value={expires || ''}
                onChange={handleExpires}
              />
            </Form.Group>

            {(securityType == 'Client Certificates' || securityType == 'SSL/TLS') &&
              <><Form.Group>
                <Form.Label>Certificate</Form.Label>
                <Form.Control
                  as="textarea"
                  required
                  placeholder="Paste the certificate here"
                  rows={10} // Ajusta el número de filas para que el editor sea más grande
                  value={certificate_data || ''}
                  onChange={handleCertificate}
                />
              </Form.Group>
                <Form.Group>
                  <Form.Label>Public Key</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    placeholder="Paste the public key here"
                    rows={5} // Ajusta el número de filas para que el editor sea más grande
                    value={certificate_public || ''}
                    onChange={handlePublic}
                  />
                </Form.Group></>

            }
            <Row>
              <Col md='6'>
                <Button
                  disabled={
                    !certName || !securityType || !issuer || !expires ||
                    ((securityType == 'Client Certificates' || securityType == 'SSL/TLS') && !certificate_data) ||
                    ((securityType == 'Client Certificates' || securityType == 'SSL/TLS') && !certificate_public)
                  }
                  variant="primary" className="w-100" type="submit" >
                  Save
                  {loading && <Spinner animation="border" size="sm" />}
                </Button>
              </Col>
            </Row>
            {error.length > 0 &&
              <Alert variant='danger'>
                {error}
              </Alert>}

          </Form>
        </div>
      </Drawer>


      <ConfirmDeletetModal
        handleSubmit={() => handleRevokeCertificate(selDeveloper.id)}
        showModal={showDelete}
        onCloseModal={handleShowDelete}
        loading={false}
        error={''}
        success={''}
        name_to_check={'DELETE'}
        title_to_show={`Confirm delete team ${selTeam.name}`}
      />

    </DashLayout>
  );
};

export default Certs;
