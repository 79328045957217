import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col, Spinner, Dropdown, Form, Alert } from 'react-bootstrap';
import { faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import Drawer from "./Drawer";

import ObexRequestHandler from '../../handlers/ObexRequestsHandler';

import ProxIcon from '../../assets/img/ui_icons/cld_proxy.svg'
import editicon from "../../assets/img/ui_icons/cld_edit_table.svg";
import deleteIcon from "../../assets/img/ui_icons/cld_delete.svg";
import SessionService from "../../services/SessionsService";
import { useHistory } from "react-router-dom";
import ConfirmDeletetModal from "../Modals/ConfirmDeleteModal";
import Nodata from "./components/Nodata";

const ApiProductManagement = () => {

  const [loading, setLoading] = useState(true);
  const [apiProducts, setApiProducts] = useState([]);
  const [loadingApiProducts, setLoadingApiProducts] = useState(false);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [recovered_tokens, set_recovered_tokens] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [allowWrapped, setAllowWrapped] = useState(false);

  const [formData, setFormData] = useState({
    version: '',
    type: { type_id: 0, name: '' },
    security: false,
    securityType: { type_id: 0, name: '' },
    headers: [],
    signatureType: '',
    project: '',
    apiProduct: {id:null, name:''},
    editingId: null,
  });
  const [ogFormData, setOgFormData] = useState({
    version: '',
    type: { type_id: 0, name: '' },
    security: false,
    securityType: { type_id: 0, name: '' },
    headers: [],
    signatureType: '',
    project: '',
    apiProduct: {id:null, name:''},
    editingId: null,
  });
  const [currentHeader, setCurrentHeader] = useState('');
  // const [apiProducts] = useState([
  //   { id: "1", name: "Product 1", versions: ["v1", "v2", "v3"] },
  //   { id: "2", name: "Product 2", versions: ["v1", "v2"] },
  //   { id: "3", name: "Product 3", versions: ["v1", "v3"] },
  // ]);
  const [apiTypes, setAPITypes] = useState([{ type_id: 1, name: 'REST' }, { type_id: 2, name: 'SOAP' }, { type_id: 3, name: 'GraphQL' }, { type_id: 4, name: 'RPC' }]);
  const [securityTypes, setSecurityTypes] = useState([{ type_id: 0, name: 'Basic' }, { type_id: 1, name: 'Token' }, { type_id: 2, name: "API Key" }, { type_id: 3, name: "Firm" }]);
  const [signatureTypes] = useState(['Type A', 'Type B', 'Type C']);

  const history = useHistory();


  const AMBIENT_LABELS = {
    REST: { name: 'REST', color: '#28a745' },        // Verde
    SOAP: { name: 'SOAP', color: '#CE9C06' }, // Amarillo
    GraphQL: { name: 'GraphQL', color: 'orange' }, // Amarillo
    RPC: { name: 'RPC', color: '#blue' }
  };
  
  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;
  
    return (
      <span 
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth:'100px',
          display:'flex',
          width:'65px',
          justifyContent:'center',
          marginLeft:'auto',
          marginRight:'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };


  const getGatewayAPIProducts = async () => {
    try {
      const gatewayAPIProducts = await ObexRequestHandler.get(`/gateway/get_gateway_products?organization=${SessionService.ORGANIZATION}`);
      const { data } = gatewayAPIProducts;

      if (data) {
        const dataProcessed = data.map((dato) => ({
          id: dato.id,
          apiProduct: { id: dato.product_id, name: dato.avap_api_product_name },
          version: dato.version,
          type: { type_id: dato.type_id, name: dato.api_type_name },
          security: dato.security_type_name!=null,
          securityType: { type_id: dato.security_id, name: dato.security_type_name },
          headers: dato.product_headers,
          signatureType: 'No Signature',
        }));
        setAllTableData(dataProcessed); // Almacena todos los datos
        setFilteredTableData(dataProcessed); // Inicialmente, muestra todos los datos
        set_recovered_tokens(dataProcessed);
        setInitialized(true);
      } else {
        console.warn('No se recibieron datos de gatewayAPIProducts');
        setInitialized(true);
      }
      setInitialized(true);
    } catch (error) {
      setInitialized(true);
      console.error('Error al obtener gatewayAPIProducts:', error);
      setInitialized(true);
    }
    history.push('/apiproduct')
    setInitialized(true);
  };



  const getAPITypes = async () => {

    const apitypes = await ObexRequestHandler.get('/gateway/get_api_types');
    const { data } = apitypes;

    if (data) {
      setAPITypes(data);
    }

    else {

    }

  }


  const getSECURITYTypes = async () => {
    const securitytypes = await ObexRequestHandler.get('/gateway/get_security_types');
    const { data } = securitytypes;

    if (data) {
      setSecurityTypes(data);
    }

    else {

    }


  }

  const toggleDrawer = () => {
    
    setCurrentHeader('');
    if (isDrawerOpen) {
      // Restablece el formulario al cerrar
      
      setFormData({
        version: "",
        type: { type_id: 0, name: '' },
        security: false,
        securityType: { type_id: 0, name: '' },
        headers: [],
        signatureType: "",
        project: "",
        apiProduct: {id:null, name:""},
        editingId: null, // Limpia el ID al cerrar
      });
    }
    setIsDrawerOpen(!isDrawerOpen);
    history.push('/apiproduct')
  };

  const handleFormChange = (e) => {
    console.log(formData);
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const addHeader = () => {
    if (currentHeader.trim()) {
      console.log(currentHeader)
      console.log(formData)
      if (formData.headers == null) {
      setFormData((prevData) => ({
        ...prevData,
        headers: [currentHeader],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        headers: [...prevData.headers, currentHeader],
      }));
    }
      setCurrentHeader("");
    }
  };

  const removeHeader = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      headers: prevData.headers.filter((_, i) => i !== index),
    }));
  };

  const [filteredTableData, setFilteredTableData] = useState(tableData);
  const [filtro, setFiltro] = useState('');

  const handleSearch = () => {
    const searchTerm = filtro.toLowerCase().trim();

    const filtered = allTableData.filter(item =>
      item.apiProduct.name.toLowerCase().includes(searchTerm) ||
      item.version.toLowerCase().includes(searchTerm) ||
      item.type.name.toLowerCase().includes(searchTerm) ||
      (item.securityType && item.securityType.name.toLowerCase().includes(searchTerm)) ||
      item.signatureType.toLowerCase().includes(searchTerm)
    );

    setFilteredTableData(filtered);
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    

    const payload = {
      type: formData.type,
      securityType: formData.securityType,
      apiProduct: formData.apiProduct.id,
      organization: SessionService.ORGANIZATION,
      headers: formData.headers || [],
      version: formData.version,
      security: formData.security,
      editing_id: formData.editingId
    }
    console.log("hhhhhhh")
    console.log(payload)
    try {
      const resultado = await ObexRequestHandler.post('/gateway/set_gateway_products', payload);
      console.log('Resultado de la creación/edición:', resultado);
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
    }

    if (formData.editingId) {
      // Editar un elemento existente
      const updatedAllTableData = allTableData.map((item) =>
        item.id === formData.editingId ? { ...formData, id: formData.editingId } : item
      );
      setAllTableData(updatedAllTableData);
      //set_recovered_tokens(updatedAllTableData)
      setFilteredTableData(updatedAllTableData);
    } else {
      // Agregar un nuevo elemento
      const newEntry = { ...formData, id: Date.now() };
      const updatedAllTableData = [...allTableData, newEntry];
      setAllTableData(updatedAllTableData);
      //set_recovered_tokens(updatedAllTableData)
      setFilteredTableData(updatedAllTableData);
    }
    if (!formData.editingId){
    // Restablecer formulario
    setFormData({
      version: "",
      type: { type_id: 0, name: '' },
      security: false,
      securityType: { type_id: 0, name: '' },
      headers: [],
      signatureType: "",
      project: "",
      apiProduct: {id:null, name:''},
      editingId: null, // Limpia el ID después de la edición o creación
    });
    

    toggleDrawer();
  }
    fetchApiData();
    getAPITypes();
    getSECURITYTypes();
    getGatewayAPIProducts();
  };


  const [showDelete, setShowDelete] = useState(false);
  const [selDeveloper, setSelDeveloper] = useState({id:'' })
  const [selTeam, setselTeam] = useState({id:0, type:'', name:'', product_name: '', apiProduct:''});

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  }

  const handleDelete = async (developer) => {
    setShowDelete(true);
    setSelDeveloper(developer);
    setselTeam(developer);

  }



  const handleDeleteReal = async (id) => {
    handleShowDelete();
    const updatedTableData = tableData.filter((item) => item.id !== id);


    await ObexRequestHandler.get(`/gateway/delete_gateway_products?gateway_product_id=${id}`)

    setTableData(updatedTableData);
    setFilteredTableData(updatedTableData); // Actualiza los datos filtrados

    fetchApiData();
    getAPITypes();
    getSECURITYTypes();
    getGatewayAPIProducts();
    
  };


  const handleEdit = (item) => {
    console.log('--->>>--->>>')
    console.log(item)
    setFormData({
      ...item,
      editingId: item.id, // Guardar el ID para identificar que se está editando
    });
    setOgFormData({
      ...item,
      editingId: item.id, // Guardar el ID para identificar que se está editando
    });
    toggleDrawer();
  };

  const filterTable = () => {
    const filtered = tableData.filter(item =>
      item.apiProduct.name.toLowerCase().includes(filtro.toLowerCase()) ||
      item.version.toLowerCase().includes(filtro.toLowerCase()) ||
      item.type //toLowerCase().includes(filtro.toLowerCase())
    );
    setFilteredTableData(filtered);
  };
  const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(e.target.value);
    filterProjects(e.target.value);
  };


  const filterProjects = async (filtro) => {

    const filter_projects = recovered_tokens.filter(element =>
      element.apiProduct.name.toLowerCase().includes(filtro.toLowerCase())
    );
    setFilteredTableData(filtro === '' ? recovered_tokens : filter_projects);
  
  }


  const fetchApiData = async () => {
    try {
      setLoading(true);

      // Obtener API Products
      //const productsResponse = await ObexRequestHandler.get(`/api/products`, {}, true);

      const productsResponse2 = await ObexRequestHandler.get(`/publishing/get_products_by_org?org_id=${SessionService.ORGANIZATION}`, {}, true);
      
      const pro = []

      productsResponse2.data.forEach(element => {
        if (element.has_live_ambient === true) pro.push({id: element.api_product_id, name: element.api_product_name})
      });


      setApiProducts(pro || []);
      //set_recovered_tokens(pro || []);
      // Obtener proyectos disponibles
      const projectsResponse = await ObexRequestHandler.get(`/projects`, {}, true);
      setAvailableProjects(projectsResponse.data || []);
      console.log('Projects Response:', projectsResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleChange = async (e) => {
    const { name, value } = e.target;

    console.log(name, value);
    if (name === 'apiProduct') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: {id: value},
      }));
     } else
    if (name === 'project') {
      setFormData((prevData) => ({ ...prevData, project: value, apiProduct: { id: null, name:'' } }));
      setLoadingApiProducts(true);

      try {
        const response = await ObexRequestHandler.get(
          `/publishing/get_publish_validated_products_by_session?obex_project_id=${value}`,
          {},
          true
        );
        console.log('response');
        console.log(response);
        console.log('response');

        const publishedApis = response.data || {};
        const products = publishedApis.result || [];

        if (products.length === 0) {
          console.warn('No API Products found for the selected project.');
        }

        setApiProducts(products.map((product) => ({ id: product.api_product_id, name: product.api_product_name })));
      } catch (error) {
        console.error('Error fetching API Products:', error);
        setApiProducts([]);
      } finally {
        setLoadingApiProducts(false);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };



  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/apiproduct'){
      history.push('/apiproduct')
      SessionService.updating = SessionService.updating -3;
      fetchApiData();
      getAPITypes();
      getSECURITYTypes();
      getGatewayAPIProducts();
    window.scrollTo(0, 0);
    history.push('/apiproduct')
    }
  
  }, false);

  useEffect(() => {

    try{
    SessionService.loginData.limits.forEach(ll =>{
      if (ll.org_id==SessionService.ORGANIZATION){
        setAllowWrapped(ll.subscription_type.api_product_wrapped || false)
      }
    })
  } catch {}
    fetchApiData();
    getAPITypes();
    getSECURITYTypes();
    getGatewayAPIProducts();

    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
    
  }, []);


  return (
    <DashLayout sider={DashRouters} active={'api-products'} sublinks={[]}>
      {/* Header */}
      <Row id="topHeader">
        <Col md="8">
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={ProxIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px">
                <span style={{ color: '#949498', fontSize: '14px' }}>Gateway Manager </span>/ API Product Wrappers
              </span>
            </div>
          </div>
        </Col>
        <Col md="4" className="align-content-center">
          <Button
            disabled = {!allowWrapped}
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right  resBtn"
            onClick={()=>{
              setFormData({
                version: "",
                type: { type_id: 0, name: '' },
                security: false,
                securityType: { type_id: 0, name: '' },
                headers: [],
                signatureType: "",
                project: "",
                apiProduct: {id:null, name:""},
                editingId: null, // Limpia el ID al cerrar
              });
              toggleDrawer()}}>
            Add API Product Wrapper
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      {/* Table */}
      <Row id="table" className="mt-5">
        <Col>
{loading ? (
        <Spinner animation="border" />
      ) : (
          <div className="cld_borderWrapper">
            <span className='cld_sectionTitle'>Api Product Wrappers</span>

            <div className="searchContainer">
              <Form.Control id='buscador' required type="text" value={filtro} placeholder="Search by API Product"
                onChange={changeFilter} />
              <Button
                className="customButton-transparent px-4"
                onClick={handleSearch}>
                <FontAwesomeIcon icon={faSearch} size="1x" />
              </Button>

            </div>
            <div className="cld_tableWrapper">
              <Table className="cld_tableWrapper">
                <thead>
                  <tr className="headerRow">
                    <th style={{ width: '25%' }} >API Product</th>
                    <th style={{ textAlign:'center', width: '15%' }} >Version</th>
                    <th style={{ textAlign:'center', width: '20%' }} >Type</th>
                    <th style={{ width: '10%' }} >Security</th>
                    <th style={{ width: '15%' }} >Headers</th>
                    {/*<th style={{ width: '15%' }} >Signature Type</th>*/}
                    <th style={{ width: '20px', textAlign: 'end' }} className='textBold'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                {(filteredTableData.length === 0 && initialized)? (
                    <tr>
                      <td colSpan={6}>
                        <div className='nodata-wrapper'>
                          <Nodata />
                        </div>
                      </td>
                    </tr>
                  ) : (
                  filteredTableData.map((item) => (
                    <tr key={item.id} className="tableRow">
                      <td className="td_centered">{item.apiProduct.name}</td>
                      <td  style={{ textAlign:'center' }} className="td_centered">{item.version}</td>
                      <td style={{textAlign:'center'}} className="td_centered">{getAmbientLabel(item.type.name)}</td>
                      <td className="td_centered">{item.security ? item.securityType.name : 'None'}</td>
                      <td className="td_centered">{item.headers ? item.headers.map(item => (item.key!=null ? item.key: item)).join(', ') : ''}</td>
                      {/*<td className="td_centered">{item.signatureType}</td>*/}
                      <td className="text-right">

                        <div className="actionsWrapper">
                          <Dropdown className="my-auto" style={{ width:'100%', marginRight: '0px' }}>
                            <Dropdown.Toggle className="">
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item key={1} onClick={() => handleEdit(item)}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item key={1} onClick={() => handleDelete(item)}>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))
                )}



                </tbody>
              </Table>
            </div>
          </div>)}
        </Col>
      </Row>

      {/* Drawer */}
      <Drawer index="product_warppers" isOpen={isDrawerOpen} toggleSidebar={toggleDrawer} name={formData.editingId ? 'Edit API Product Wrapper': 'Add API Product'}>
        <Form onSubmit={handleFormSubmit}>
          {/*<h3 style={{color:'white'}}>API Product</h3>*/}

{/*}
         
          {availableProjects.length === 0 ? (
            <Alert variant="danger">No API Projects available. Please create one first.</Alert>
          ) : (
            <Form.Group controlId="project">
              <Form.Label>Available Projects</Form.Label>
              <Form.Control
                as="select"
                name="project"
                value={formData.project}
                onChange={handleChange}
              >
                <option value="">Select a project</option>
                {availableProjects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}

*/}
          {/* Select de API Products */}
          {(formData.project || true) && (
            <Form.Group controlId="apiProduct">
              <Form.Label>Available API Products</Form.Label>
              {loadingApiProducts ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : apiProducts.length === 0 ? (
                <Alert variant="warning">No API Products found for this project.</Alert>
              ) : (
                <Form.Control
                  as="select"
                  name="apiProduct"
                  value={formData.apiProduct.id!=null? formData.apiProduct.id:0}
                  onChange={handleChange}
                >
                  <option value="">Select an API Product</option>
                  {apiProducts.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </Form.Control>
              )}
            </Form.Group>
          )}

          {/* Versions Select */}
          {formData.apiProduct && (
            <Form.Group>
              <Form.Label>Version</Form.Label>
              <Form.Control
                as="select"
                name="version"
                value={formData.version}
                onChange={handleFormChange}
              >
                <option value="">Select a version</option>
                {/* Hardcoded versions */}
                <option value="v1">v1</option>
                <option value="v2">v2</option>
                <option value="v3">v3</option>
              </Form.Control>
            </Form.Group>
          )}

          {/* Type Select */}
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              name="type"
              required
              value={formData.type.name}
              onChange={handleFormChange}
            >
              <option value="">Select a type</option>
              {apiTypes.map((type) => (
                <option key={type.type_id} value={type.name}>
                  {type.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Security Checkbox */}
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Enable Security"
              name="security"
              checked={formData.security}
              onChange={handleFormChange}
            />

            {formData.security && (
              <Form.Group>
                <Form.Label>Security Type</Form.Label>
                <Form.Control
                  as="select"
                  name="securityType"
                  value={formData.securityType.name}
                  onChange={handleFormChange}
                >
                  <option value="">Select a security type</option>
                  {securityTypes.map((type) => (
                    <option key={type.type_id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

            )}


          </Form.Group>

          {/* Headers */}
          <Form.Group>
            <Form.Label>Headers</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                value={currentHeader}
                onChange={(e) => setCurrentHeader(e.target.value)}
                placeholder="Add header"
              />
              <Button variant="secondary" onClick={addHeader} className="ml-2">
                Add
              </Button>
            </div>
            {(formData.headers!=null ? formData.headers: []).map((header, index) => (
              <div key={index} className="d-flex justify-content-between align-items-center mt-2">
                <span>{header.key == null ? header: header.key}</span>
                <Button
                  //variant="danger"
                  style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
                  size="sm" onClick={() => removeHeader(index)}>
                  x
                </Button>
              </div>
            ))}
          </Form.Group>


          {/*}
          <Form.Group>
            <Form.Label>Signature Type</Form.Label>
            <Form.Control
              as="select"
              name="signatureType"
              value={formData.signatureType}
              onChange={handleFormChange}
            >
              <option value="">Select a signature type</option>
              {signatureTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
*/}

          <Row>
            <Col md='6'>
            {formData.editingId != null && <Button type="submit" 
              disabled={!(formData.apiProduct.id != ogFormData.apiProduct.id || formData.type.type_id != ogFormData.type.type_id
                || formData.version != ogFormData.version ||  formData.headers != ogFormData.headers
                ||  formData.security != ogFormData.security  ||  formData.securityType.type_id != ogFormData.securityType.type_id
              )}
              variant="primary" className="w-100">
                Submit
              </Button>}

            {formData.editingId == null && <Button type="submit" 
              disabled={!formData.apiProduct || !formData.type || !formData.version}
              variant="primary" className="w-100">
                Submit
              </Button>
              }
            </Col>

            {/*}
            <Col md='6'>
              <Button variant="outline-secondary" onClick={toggleDrawer} className="w-100">
                Cancel
              </Button>
            </Col>*/}
          </Row>



        </Form>
      </Drawer>


      <ConfirmDeletetModal 
          handleSubmit={()=>handleDeleteReal(selDeveloper.id)} 
          showModal={showDelete}
          onCloseModal={handleShowDelete}
          loading={false}
          error={''}
          success={''}
          name_to_check={'DELETE'}
          title_to_show={`Confirm delete API Product Wrapper ${selTeam.apiProduct}`}
        />

    </DashLayout>
  );
};

export default ApiProductManagement;
