import React, { useEffect, useState } from "react";
import { Form, Button, Table } from "react-bootstrap";
import { FaRedo, FaTrash } from "react-icons/fa";
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import { notification } from 'antd';
import { DynamicButton } from "./components/DynamicButton";

interface InvitedUser {
  id?: number;
  email: string;
  status: string;
  date: string;
  table?: string;
}

interface AddUsersFormProps {
  devPortalId: number;
  appId: string;
  portal: any;
}

interface InviteFormData {
  email: string;
  password: string;
  requirePasswordChange: boolean;
}

interface ButtonProps {
  text: string;
  theme: {
    primaryColor: string;
  };
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

const AddUsersForm: React.FC<AddUsersFormProps> = ({ devPortalId, appId, portal }) => {
  const [inviteList, setInviteList] = useState<InviteFormData[]>([{
    email: '',
    password: '',
    requirePasswordChange: false
  }]);
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);
  const [error, setError] = useState<string | null>(null);

  const fetchInvitedUsers = async () => {
    try {
      const response = await ObexRequestHandler.get(`/list_user_invited_hist?table=dev_portal_app_users&id=${appId}`);
      console.log('Response from invited users:', response.data);
      
      if (Array.isArray(response.data)) {
        const formattedUsers = response.data.map(user => ({
          email: user.email,
          status: user.status,
          date: user.date,
        }));
        setInvitedUsers(formattedUsers);
      } else {
        console.warn('Formato de respuesta inesperado:', response.data);
        setInvitedUsers([]);
      }
    } catch (error) {
      console.error('Error fetching invited users:', error);
      notification.error({
        message: 'Error',
        description: 'Error al obtener la lista de usuarios invitados'
      });
      setInvitedUsers([]);
    }
  };

  useEffect(() => {
    // Prueba específica con la ruta proporcionada
    ObexRequestHandler.get('/list_user_invited_hist?table=dev_portal_app_users&id=37')
      .then(response => {
        console.log('Prueba de ruta específica:', response.data);
      })
      .catch(error => {
        console.error('Error en prueba:', error);
      });

    if (devPortalId && appId) {
      fetchInvitedUsers();
    }
  }, [devPortalId, appId]);

  const isValidEmail = (email: string) => {
    // Expresión regular para validar el formato del correo electrónico
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);   
  };

  const handleAddEmail = () => {
    const trimmedEmail = inviteList[0].email.trim();
    
    if (!trimmedEmail) {
      setError('Email input shouldn\'t be empty');
      return;
    }

    if (!isValidEmail(trimmedEmail)) {
      setError('Please adda valid email');
      return;
    }

    if (invitedUsers.some(user => user.email === trimmedEmail)) {
      setError('This email is already on the list');
      return;
    }

    setInviteList([{
      email: trimmedEmail,
      password: '',
      requirePasswordChange: false
    }]);
    setError('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      await Promise.all(inviteList.map(invite => {
        const payload = {
          email: invite.email,
          table_dest: "dev_portal_app_users",
          dev_portal_id: devPortalId,
          username: invite.email,
          password: invite.password,
          active: true,
          change: invite.requirePasswordChange,
          app_id: appId
        };

        console.log('Sending invitation payload:', payload);
        return ObexRequestHandler.post('/invite_external_user', payload);
      }));

      notification.success({
        message: 'Success',
        description: `Successfully sent ${inviteList.length} invitation${inviteList.length > 1 ? 's' : ''}`
      });

      setInviteList([{
        email: '',
        password: '',
        requirePasswordChange: false
      }]);
      fetchInvitedUsers();
    } catch (error) {
      console.error('Error:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to send invitations. Please try again.'
      });
      setError('Error inviting users');
    }
  };

  const handleResendInvitation = async (email: string) => {
    try {
      // Implementar lógica de reenvío cuando esté disponible
      console.log('Reenviar invitación a:', email);
    } catch (error) {
      console.error('Error resending invitation:', error);
      setError('Error resending invitation');
    }
  };

  const handleDeleteInvitation = async (email: string) => {
    try {
      await ObexRequestHandler.get(`/deny_ext_invitation?email=${email}&table=dev_portal_app_users&id_rel=${appId}`);
      fetchInvitedUsers();
    } catch (error) {
      console.error('Error deleting invitation:', error);
      setError('Error deleting invitation');
    }
  };

  return (
    <div>
      <p>App ID: {appId}</p>
      {error && <div className="alert alert-danger">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={inviteList[0].email}
            onChange={(e) => setInviteList([{
              email: e.target.value,
              password: '',
              requirePasswordChange: false
            }])}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={inviteList[0].password}
            onChange={(e) => setInviteList([{
              email: inviteList[0].email,
              password: e.target.value,
              requirePasswordChange: false
            }])}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Require password change"
            checked={inviteList[0].requirePasswordChange}
            onChange={(e) => setInviteList([{
              email: inviteList[0].email,
              password: inviteList[0].password,
              requirePasswordChange: e.target.checked
            }])}
          />
        </Form.Group>

        <DynamicButton
          text="Invite User"
          theme={portal.theme}
          disabled={!inviteList[0].email || !inviteList[0].password}
          onClick={handleSubmit as unknown as () => void}
        />
      </Form>

      <div className="mt-4">
        <h5>Invited Users</h5>
        <div className="invited-users-list">
          {invitedUsers.map((user) => (
            <div key={user.email} className="invited-user-item">
              <div className="user-info">
                <div className="email">{user.email}</div>
                <div className="date">{new Date(user.date).toLocaleDateString()}</div>
              </div>
              <div className="status">
                <span className={`status-badge ${user.status.toLowerCase()}`}>
                  {user.status}
                </span>
              </div>
              <div className="actions">
                <Button
                  variant="link"
                  onClick={() => handleResendInvitation(user.email)}
                  title="Resend Invitation"
                  className="action-button"
                >
                  <FaRedo style={{ color: portal.theme.primaryColor }} />
                </Button>
                <Button
                  variant="link"
                  onClick={() => handleDeleteInvitation(user.email)}
                  title="Delete Invitation"
                  className="action-button"
                >
                  <FaTrash style={{ color: portal.theme.primaryColor }} />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddUsersForm;
