import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, Form, Spinner, Dropdown, Alert } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import Drawer from "./Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSearch } from "@fortawesome/free-solid-svg-icons";
import devIcon from '../../assets/img/ui_icons/devportal.png';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";

import UnitTestForm from "./Publisher_unitTest";
import DocumentationForm from "./Publisher_documentation";
import FileUpload from "./components/uploadFile";
import { useHistory } from "react-router-dom";
import ConfirmDeletetModal from "../Modals/ConfirmDeleteModal";
import Nodata from "./components/Nodata";

const DevPortalIndex = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [SelectedPProductId, setSelectedPProductId] = useState('');
  const [apis, setApis] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [apiProducts, setApiProducts] = useState([]);
  const [loadingApiProducts, setLoadingApiProducts] = useState(false);
  const [isDocumentationDrawerOpen, setIsDocumentationDrawerOpen] = useState(false);
  const [isUnittestDrawerOpen, setIsUnittestDrawerOpen] = useState(false);
  const [devPorSel, setDevPorSel] = useState(0);
  const [initialized, setInitialized] = useState(false);

  const [jsonContent, setJsonContent] = useState(null);
  const [selectedApiForUnittest, setSelectedApiForUnittest] = useState(null);

  const [localEmail, setEmail] = useState('');
  const [localPassword, setPassword] = useState('');
  const [ogName, setOgIndexName] = useState('');
  const [ogDescription, setOgDescription] = useState('');
  const [mustChange, setMustChange] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const history = useHistory();

  const [index, setIndex] = useState(undefined)

  ///custon upload file
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (file) => {
    setSelectedFile(file); 
};


const [showDelete, setShowDelete] = useState(false);
const [selDeveloper, setSelDeveloper] = useState({id:'' })
const [selTeam, setselTeam] = useState({id:0, type:'', name:''});

const handleShowDelete = () => {
  setShowDelete(!showDelete);
}

const handleDelete = async (developer) => {
  setShowDelete(true);
  setSelDeveloper(developer);
  setselTeam(developer);

}


const handleDeleteProxy = async (proxy) => {

  handleShowDelete()
    console.log(proxy);
    //setProxies(proxies.filter((proxy) => proxy.id !== id));
    const payload = {
      index_id : proxy.index_id
    }
    console.log(payload);
    await ObexRequestHandler.post('/publishing/del_dev_portal_index', payload);
  
  fetchProjects();
};


  const openDocumentationDrawer = () => {
    setIsDocumentationDrawerOpen(true);
  };

  const closeDocumentationDrawer = () => {
    setIsDocumentationDrawerOpen(false);
  };

  const openUnittestDrawer = () => {
    setIsUnittestDrawerOpen(true);
  };

  const closeUnittestDrawer = () => {
    setIsUnittestDrawerOpen(false);
  };

  const fetchProjects = async () => {
    history.push('/dev_portals_index');
    setLoading(true);
    setProjects([]);
    try {
      const response = await ObexRequestHandler.get(`/gateway/get_dev_portal_by_org?org_id=${SessionService.ORGANIZATION}`, {}, true)
      const projectsInfo = response.data || [];
      setProjects(projectsInfo);
      console.log(projectsInfo);
      setSelectedProjectId('');
      if (projectsInfo.length>0){
      setSelectedProjectId(projectsInfo[0].id)
      fetchAPIProducts(projectsInfo[0].id)
      } else {
        setApis([])
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
    
    setLoading(false);
    history.push('/dev_portals_index');
  };


  const fetchAPIProducts = async (projects_id) => {
    try {
      const response = await ObexRequestHandler.get(`/publishing/get_dev_portal_index/${projects_id}`, {}, true
      );
      const publishedApis = response.data || {};
      const products = publishedApis.Products || [];

      if (products.length === 0) {
        console.warn('No API Products found for the selected project.');
      }

      setApiProducts(products.map((product) => ({ id: product.id, name: product.name })));
      setInitialized(true);
    } catch (error) {
      console.error('Error fetching API Products:', error);
      setInitialized(true);
      setApiProducts([]);
    } finally {
      setLoadingApiProducts(false);
      setInitialized(true);
    }
    setInitialized(true);
  }


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (file && file.type === 'application/json') {
      const reader = new FileReader();

      reader.onload = () => {
        // Comprobamos si el resultado es una cadena
        const fileContent = reader.result;
        if (typeof fileContent === 'string') {
          try {
            const json = JSON.parse(fileContent);
            setJsonContent(json); // Asigna el contenido JSON a la variable
          } catch (error) {
            alert('El archivo no es un JSON válido');
          }
        } else {
          alert('El archivo no es un formato adecuado');
        }
      };

      reader.readAsText(file); // Lee el archivo como texto
    } else {
      alert('Por favor, seleccione un archivo JSON');
    }
  };



  const handleSubmitUser = async (e) => {
    e.preventDefault();

    const payload = index ? {
      dev_portal_id: selectedProjectId,
      name: localEmail,
      description: localPassword,
      id : index }
: {
                      dev_portal_id: selectedProjectId,
                      name: localEmail,
                      description: localPassword

                    }
    
    console.log("lklklklkll");
    console.log(payload);
    console.log("lklklklkll");
    await ObexRequestHandler.post('/publishing/add_dev_portal_index', payload)

    fetchApisByProject();

    closeDrawer();


  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Formulario enviado con el proyecto seleccionado:', selectedProjectId);
    console.log('Formulario enviado con el api product seleccionado:', SelectedPProductId);
    console.log('Contenido del archivo JSON:', jsonContent);

    //let url_service = `${cloud}/gateway/routing/add_gateway_link?obex_project_id=${SelectedProject}`

    //custom file
    const formData = new FormData();
    formData.append("file", selectedFile); // logica para subir el archivo

    const payload = {
      api_product_id: SelectedPProductId,
      my_api: jsonContent
      

    }

    await ObexRequestHandler.post(`/gateway/routing/add_gateway_link?obex_project_id=${selectedProjectId}`, payload)

    fetchApisByProject();

    closeDrawer();

  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    setSelectedProjectId(value);
    await fetchAPIProducts(value);
    console.log(name, value);
  }

  const handleChangeProduct = async (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    setSelectedPProductId(value);
    console.log(name, value);
  }

  const handlePublish = async (api) =>{
 
    const payload = {
      gateway_id: api.gateway_id, 
      new_status: !api.is_published, 
      api_id: api.api_id, 
      api_product_id: api.api_product_id
    }

    await ObexRequestHandler.post('/update_portal_apis_status', payload);

    fetchApisByProject();

    //update_portal_apis_status
  }

  const fetchApisByProject = async () => {
    if (!selectedProjectId) return;

    setLoading(true);
    try {
      const response = await ObexRequestHandler.get(`/publishing/get_dev_portal_index/${selectedProjectId}`, {}, true);
      console.log('Response from publishing/get_dev_portal_users', response);
      const data = response.data || [];

      const dataFiltered = []
      console.log(data);
      data.forEach(element => {
        if (element.api_id){
          if (element.has_documentation_entry	!== 'No') dataFiltered.push(element); else dataFiltered.push(element);
        } else dataFiltered.push(element);
      });

      setApis(dataFiltered);
    } catch (error) {
      console.error('Error fetching APIs by project:', error);
    }
    setLoading(false);
  };


  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/dev_portals_index'){
      history.push('/dev_portals_index')
      SessionService.updating = SessionService.updating -3;
      fetchProjects();
    window.scrollTo(0, 0);
    history.push('/dev_portals_index')
    }
  
  }, false);




  useEffect(() => {
    fetchProjects();
    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
  }, []);



  const openDrawer = (role) => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setEmail('');
    setPassword('');
    setIsEditing(false);
    history.push('/dev_portals_index')
  };

  const handleEditUnittest = (api) => {
    setSelectedApiForUnittest(api);
    openUnittestDrawer();
  };


  useEffect(() => {
    if (selectedProjectId) {
      fetchApisByProject();
    } else {
      setApis([]);
    }
  }, [selectedProjectId]);

  const filteredApis = apis.filter(api =>
    api.index_name?.toLowerCase().includes(searchTerm.toLowerCase()) 
  );

  return (
    <DashLayout sider={DashRouters} active={'api-publisher'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8' >
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={devIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px"><span style={{ color: '#949498', fontSize: '14px' }}>Dev Portal </span>/ Document indexes </span>
            </div>

          </div>
        </Col>




        <Col md='4' className='align-content-center'>



          <Button disabled={!selectedProjectId} style={{ width: '225px' }} className="customButton-black btn px-4 float-right  resBtn" onClick={() => {
           setIndex(undefined)
           openDrawer(null)
          }
          }>
           { selectedProjectId ? 'Add Index':'Select a Developer Portal' }
          </Button>



          {/* Botón opcional  */}
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        

      
        <div className="cld_borderWrapper">
          
          <span className='cld_sectionTitle'>Select a Developer Portal</span>
          <div style={{display:'flex'}}>
            <Form.Group className="my-4" style={{width:'100%'}}>
              <Form.Control as="select" onChange={(e) => setSelectedProjectId(e.target.value)} value={selectedProjectId || ''}>
                <option value="">-- Select a developer portal --</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <div className="searchContainerHalf mb-4" style={{ position:'relative', marginLeft:'5%', width:'90%', marginTop:'24px' }}>
              <Form.Control
                id='buscador'
                required
                type="text"
                value={searchTerm}
                placeholder="Search by Index"
                style={{marginRight:'calc(-10% - 20px)'}}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button className="customButton-transparent-half px-4">
                <FontAwesomeIcon icon={faSearch} size="1x" />
              </Button>
            </div>
          </div>

          {((!selectedProjectId || apis.length === 0 || filteredApis.length === 0) && initialized)? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
              <Nodata />
            </div>
          ) : (
            <Table className="cld_tableWrapper">
              <tbody>
                <tr className="headerRow">
                  <th className="textBold">Index</th>
                  <th className="textBold">Docs</th>
                  <th style={{ textAlign: 'end' }} className="textBold">Actions</th>
                </tr>
                {filteredApis.map((api, index) => (
                  <tr className="tableRow" key={index}>
                    <td className="td_centered">{api.index_name || '-'}</td>
                    <td className="td_centered">{api.documents ? api.documents.map((name)=>(name.name)).join(', ') : '-'}</td>
                    <td>
                      <div className="actionsWrapper">
                        <Dropdown className='my-auto' style={{ textAlign: 'end', width: '100%', marginRight: '0px' }}>
                          <Dropdown.Toggle className="organizations">
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              key={1}
                              onClick={(e) => {
                                openDrawer('')
                                setEmail(api.index_name)
                                setPassword(api.description)
                                setOgIndexName(api.index_name)
                                setOgDescription(api.description)
                                setDevPorSel(api.dev_portal_id)
                                setIndex(api.index_id)
                                setIsEditing(true);
                              }}
                            >
                              Edit Index
                            </Dropdown.Item>
                            <Dropdown.Item
                              key={2}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleDelete(api);
                              }}
                            >
                              Delete Index
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      )}


      <Drawer index="doc_index"
        toggleSidebar={closeDrawer}
        isOpen={isDrawerOpen}
        name={'Add Document Index to Dev Portal'}
        subtitle=''
      >


        <Form onSubmit={handleSubmitUser}>

          {/*}
          <Form.Group controlId="project">
            <Form.Label>Available Dev Portals</Form.Label>
            <Form.Control
              disabled={isEditing}
              as="select"
              name="project"
              value={selectedProjectId}
              onChange={handleChange}
            >
              <option value="">Select a dev portal</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {isEditing ? projects.find(item => item.id === devPorSel)?.name : project.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
*/}


                <Form.Group controlId="basePath" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    value={localEmail}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>



                <Form.Group controlId="basePath" className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter description"
                    value={localPassword}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

{/*}
        <Form.Check
          type="checkbox"
          label="Password must change"
          checked={mustChange}
          onChange={(e) => setMustChange(e.target.checked)}
        />
        */}
       <Row>
            <div style={{ height:'2em' }}></div>
       </Row>
      {/*}

          {selectedProjectId &&
            <><Row>
              <Form.Group controlId="file" style={{ paddingLeft: '15px', paddingRight: '15px', width: '100%' }}>
                <Form.Label>OpenAPI Spec File</Form.Label>
                <FileUpload
                    onFileSelect={handleFileSelect}
                    allowedTypes={['application/json']}
                    label="Select a JSON file"
                />

              </Form.Group>
             



            </Row> </>
          }
            */}
              
                <Row>
                  <Col md='6'>
                  {!isEditing && <Button 
                    disabled={!localEmail || !localPassword}
                    type="submit" variant="primary" className="w-100">
                      Save
                    </Button>}
                  {isEditing && <Button 
                    disabled={!(localEmail != ogName || localPassword != ogDescription)}
                    type="submit" variant="primary" className="w-100">
                      Save
                    </Button>}
                    
                  </Col>
                </Row>
             
           
          
        </Form>

      </Drawer>

      <Drawer index="doc_index"
        toggleSidebar={closeDocumentationDrawer}
        isOpen={isDocumentationDrawerOpen}
        name="Edit Documentation"
        subtitle=""
      >
      <DocumentationForm documenta={''} obex_project_id={undefined} docInfo={undefined} api_product_id={undefined}></DocumentationForm>
      </Drawer>

      <Drawer index="doc_index"
        toggleSidebar={closeUnittestDrawer}
        isOpen={isUnittestDrawerOpen}
        name="Edit Unittest"
        subtitle=""
      >
        	<UnitTestForm apiDetails={selectedApiForUnittest} onClose={closeUnittestDrawer} />
      </Drawer>


      <ConfirmDeletetModal 
          handleSubmit={()=>handleDeleteProxy(selDeveloper)} 
          showModal={showDelete}
          onCloseModal={handleShowDelete}
          loading={false}
          error={''}
          success={''}
          name_to_check={'DELETE'}
          title_to_show={`Confirm delete Index ${selTeam.name}`}
        />


    </DashLayout>
  );
};

export default DevPortalIndex;
