// DevPortaCatalog.tsx
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Form,
  Card,
  Table,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import { FaTh, FaList, FaRedo, FaTrash } from "react-icons/fa";
import Drawer from "../avapCloud/components/Drawer";
import CreateAppForm from "./CreateAppForm";
import AddUsersForm from "./AddUsersForm";
import { useHistory, useParams, Link } from "react-router-dom";
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';
import PortalNavbar from './components/portal_navbar';
import Documentation from "./components/DocDrawer";

import sun from '../../assets/img/ui_icons/sun.svg';
import moon from '../../assets/img/ui_icons/moon.svg';

const cookies = new Cookies();


//interfaces
interface App {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  apiProducts: string[];
}

interface ApiProduct {
  id: number;
  title: string;
  description: string;
  latestVersion: string;
  details: string;
}

interface Portal {
  portal_id: string;
  id: string;
  name: string;
  logo: string;
  normal_access: any;
  theme: {
    primaryColor: string;
    secondaryColor: string;
    backgroundColor: string;
    textColor: string;
  };
  user: {
    name: string;
    photo: string;
  };
  apps: App[];
  apiProducts: ApiProduct[];
}


const DevPortaCatalog: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const portalIdentifier = id.startsWith('/_') ? id.slice(2) : id;
  const history = useHistory();
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });

  const [portal, setPortal] = useState<Portal | null>(() => {
    const portalData = cookies.get('portal');
    return portalData || null;
  });

  const portalStyles = React.useMemo(() => ({
    primaryColor: portal?.theme?.primaryColor || '#000',
    backgroundColor: portal?.theme?.backgroundColor || '#fff',
    textColor: portal?.theme?.textColor || '#000'
  }), [portal?.theme]);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [viewMode, setViewMode] = useState<'card' | 'list'>('card');
  const [filteredApps, setFilteredApps] = useState<App[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerAddUsersOpen, setDrawerAddUsersOpen] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState<ApiProduct[]>([]);
  const [apiProducts, setApiProducts] = useState<ApiProduct[]>([]); // Definición del estado
  const [userName, setUserName] = useState('unknown');
  const [userID, setUserId] = useState<number>(0);
  const [selectedApp, setSelectedApp] = useState<App | {id:0}>({id:0});
  const [portal_id, set_portal_id] = useState<string>('');
  const [Logo, setLogo] = useState<string>(''); // Imagen por defecto vacía
  const [userObject, setUserObject] = useState({ type: '', user_id: 0 });

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [errorP, setErrorP] = useState('')

    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
  
    const [showModal, setShowModal] = useState(false);
    const closeChange = () => {
      setShowModal(false);
    }
  
    const handleClose = () => {
      DevPortalExists();
      //DevPortalProductsExists();
    }

  const handlePassword = (e) => {
    setError('')
    const { value } = e.target;
    setPassword(value);
  }
  
  const handleRepeatPassword = (e) => {
    setError('')
    const { value } = e.target;
    setRepeatPassword(value);
  }


    const [success, setSuccess] = useState('');

  // Agregar nuevo estado para todas las apps
  const [allApps, setAllApps] = useState<App[]>([]);

  // Agregar nuevo estado para el drawer de documentación
  const [drawerDocumentationOpen, setDrawerDocumentationOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (password != repeatPassword) {
      setErrorP('Password and Repeat Password mut be the same');
     
    } else {
    try {



      setLoading(true);
      setSuccess('');
      setErrorP('');// Limpiamos los posibles errores

      console.log(User);
      //const result = await ObexRequestHandler.post('/request_reset_password', { email });
      const result = await ObexRequestHandler.get(`/publishing/change_password_portal_user?user_id=${User.user_id}&password=${password}`);
      //console.log(`${result}`)
      //let result = {success:true, data:'', message:''}
      const { success, data, message } = result;
      if (success){
        setSuccess(data);
        User.change = false;
        cookies.set('user', JSON.stringify(User), { 
          path: `/devportal/${id}`, 
          expires: new Date(Date.now() + (10 * 60 * 1000)),
          sameSite: 'strict'
        });

        
      } else {
        setErrorP(message);
      }
    } catch (error) {
      console.error('ERROR REQUESTING RESET PASSWORD ', error);
      setErrorP(error.message || error);
    }
    setLoading(false);
    setTimeout(function() { 
      setErrorP('');
      setSuccess('');
      //                           props.handleClose();
      }.bind(this), 2000)
    //setTimeout(function() { 
    //  const path = '/login';
    //  window.location.href = path;
    //  }.bind(this), 4000)

    setShowModal(false);
    }
  };

  useEffect(() => {
    const portalData = cookies.get('portal');
    const user = cookies.get('user');

    if (!portalData) {
      history.replace(`/devportal/${id}/login`);
      return;
    }

    if (JSON.stringify(portalData) !== JSON.stringify(portal)) {
      setPortal(portalData);
      document.title = portalData.name;
      setFavicon(portalData.logo);
    }

    if (user) {
      setShowModal(user.change);
      setUser(user);
      setUserObject({
        type: user.type || '',
        user_id: user.user_id || 0
      });
      
      if (user.username) {
        setUserName(user.username);
        setUserId(user.user_id);
      }
    }

    const loadData = async () => {
      try {
        await Promise.all([
          DevPortalAppsExists(portalData.portal_id),
          DevPortalProductsExists(portalData.portal_id)
        ]);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    loadData();
  }, []); // Solo se ejecuta una vez al montar el componente

  const toggleDarkMode = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };

  const [User, setUser] = useState({ type: '', user_id:0, change: false })

  const setFavicon = (url: string) => {
    let link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
  
    if (!link) {
      // Si no existe, lo creamos
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
  
    // Cambiamos la URL del favicon
    link.href = url;
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  useEffect(() => {
    if (portal?.theme?.primaryColor) {
      document.documentElement.style.setProperty('--primary-color', portal.theme.primaryColor);
    }
    DevPortalExists();
  }, [portal?.theme?.primaryColor]);


  const DevPortalExists = async () => {
    setLoading(true);
    setError(null);
    try {
      const portalData = cookies.get('portal');
      const user = cookies.get('user');

      setUser(user)

      if (user){
        setShowModal(user.change);
      }
      
      setUserObject({
        type: user.type || '',
        user_id: user.user_id || 0
      });
      if (!portalData) {
        history.replace(`/devportal/${id}/login`);
        return;
      }

      if (!portalData.portal_id) {
        setError("Portal does not have portal_id");
        history.replace(`/devportal/${id}/login`);
        return;
      }
      
      set_portal_id(portalData.portal_id);

      document.title = portalData.name;
      setFavicon(portalData.logo);

      // Extraer "id" sin "/_"
      const portalIdentifier = portalData.id.startsWith('/_') ? portalData.id.slice(2) : portalData.id;

      try {
        const logoRes = await ObexRequestHandler.get(`/publishing/get_dev_portal_logo/${portalIdentifier}`);
        setLogo(logoRes.data.logo);
      } catch (logoError) {
        console.error("Error al obtener el logo del portal:", logoError);
        // Opcional: Usar el logo predeterminado de la cookie si falla la llamada al API
        setLogo(portalData.logo);
      }

      setPortal(portalData);

      if (user && user.username) {
        setUserName(user.username);
        setUserId(user.user_id);
      }

      await DevPortalAppsExists(portalData.portal_id);
      await DevPortalProductsExists(portalData.portal_id);
    } catch (err) {
      console.error("Error en DevPortalExists:", err);
      setError("Hubo un problema al cargar el portal.");
    } finally {
      setLoading(false);
    }
  };

  const DevPortalProductsExists = async (portal_id: string) => {
    try {
      const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_products/${portal_id}`, {}, true);
      const data = res.data;
      if (Array.isArray(data) && data.length > 0) {
        const mapped = data.map((prod: any) => ({
          id: prod.product_id,
          title: prod.avap_api_product_name,
          description: prod.dev_portal_published_description,
          latestVersion: 'v0.1',
          details: prod.dev_portal_published_description + prod.avap_api_product_documentation
        }));
        setApiProducts(mapped);
        setFilteredProducts(mapped);
      } else {
        setApiProducts([]);
        setFilteredProducts([]);
      }
    } catch (error) {
      console.error("Error al obtener los productos del portal:", error);
    }
  };

  const DevPortalAppsExists = async (portal_id: string) => {
    try {
      console.log('🔍 Iniciando petición de apps con portal_id:', portal_id);
      
      const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_apps/${portal_id}`, {}, true);
      console.log('📦 Respuesta completa del servicio:', res);
      
      const { data } = res;
      console.log('🔄 Data sin procesar:', data);
      
      if (data.length > 0) {
        const apps = data.map((prod: any) => {
          console.log('🎯 Procesando app individual:', prod);
          
          const mappedApp = {
            id: prod.app_id,
            name: prod.app_name,
            description: prod.app_description,
            createdAt: prod.app_created_at,
            apiProducts: prod.api_products.map((pp: any) => pp.product_name),
            apiProductsID: prod.api_products.map((pp: any) => pp.product_id.toString()),
            tokens: prod.tokens.map((pp: any) => pp.token),
            tokensId: prod.tokens.map((pp: any) => pp.id)
          };
          
          console.log('✅ App procesada:', mappedApp);
          return mappedApp;
        });
        
        console.log('📋 Lista final de apps procesadas:', apps);
        setAllApps(apps);
        setFilteredApps(apps);
      } else {
        console.log('⚠️ No se encontraron apps');
        setAllApps([]);
        setFilteredApps([]);
      }
    } catch (error) {
      console.error("❌ Error al obtener las apps del portal:", error);
      console.error("Stack trace:", error instanceof Error ? error.stack : '');
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    
    if (term === '') {
      setFilteredApps(allApps); // Si no hay término de búsqueda, mostrar todas las apps
    } else {
      const filtered = allApps.filter((app) =>
        app.name.toLowerCase().includes(term) ||
        app.description.toLowerCase().includes(term) ||
        app.apiProducts.some(product => product.toLowerCase().includes(term))
      );
      setFilteredApps(filtered);
    }
  };

  const handleRemoveApp = async (app_id: string) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar esta App?')) {
      const payload = {
        devportal_id: portal_id,
        app_id: app_id
      };
      try {
        const res = await ObexRequestHandler.post('/publishing/delete_dev_portal_app', payload);
        if (res) {
          DevPortalExists();
        }
      } catch (error) {
        console.error("Error al eliminar la app:", error);
      }
    }
  };

  const toggleViewMode = () => {
    setViewMode((prevMode) => (prevMode === "card" ? "list" : "card"));
  };

  const handleLogout = () => {
    cookies.remove('user');
    cookies.remove('portal');
    history.push(`/devportal/${id}/login`);
  };

  if (!portal) {
    return (
      <div className="loading-container" style={{ 
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div id="devPortalWrapper">
      {portal ? (
        <>
          <PortalNavbar
            portal={portal}
            Logo={Logo}
            userName={userName}
            isDarkMode={isDarkMode}
            User={userObject}
            portalIdentifier={portalIdentifier}
            setDrawerOpen={setDrawerOpen}
            toggleDarkMode={toggleDarkMode}
          />

          {/* Barra central */}
          <div
            className="text-center p-4 dp-banner"
            style={{ backgroundColor: portalStyles.primaryColor, color: "#fff" }}
          >
            <h2>Welcome to our API Portal!</h2>
            <p>Start building and innovating with our APIs</p>
            <Form.Control
              type="text"
              placeholder="Search your apps..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-50 mx-auto"
            />
          </div>

          {/* Área de Apps */}
          <Container className="my-4">
            <Row className="mb-3">
              <Col>
                <h4>My Apps</h4>
              </Col>
              <Col className="justify-content-end d-flex">
                <Button 
                  onClick={toggleViewMode}
                  style={{
                    backgroundColor: portalStyles.primaryColor,
                    border: 'none'
                  }}
                >
                  {viewMode === "card" ? <FaList /> : <FaTh />}
                </Button>
              </Col>
            </Row>

            {/* Manejar diferentes estados cuando no hay Apps */}
            {allApps.length === 0 ? (
              
              (User.type !='consumer' && 
              <div className="text-center my-5">
                <p>You don't have any apps. Add a new app to get started!</p>
                <Button
                  style={{
                    backgroundColor: portalStyles.primaryColor,
                    border: 'none'
                  }}
                  onClick={() => setDrawerOpen(true)}
                >
                  Add New App
                </Button>
              </div>)
                
            ) : filteredApps.length === 0 ? (
              <div className="text-center my-5">
                <p>No apps found matching your search criteria.</p>
              </div>
            ) : (
              // Vista de apps
              viewMode === "card" ? (
                <Row>
                  {filteredApps.map((app) => (
                    <Col md={4} key={app.id} className="mb-4">
                      <Card>
                        <Card.Body>
                          <Card.Title>{app.name}</Card.Title>
                          <Card.Text>
                            {app.description}
                          </Card.Text>
                          <Card.Text>
                            <small className="text-muted">Created at: {app.createdAt}</small>
                          </Card.Text>
                          {/* Mostrar API Products asociados */}
                          {app.apiProducts.length > 0 && (
                            <div>
                              <strong>API Products:</strong>
                              <ul>
                                {app.apiProducts.map((api, index) => (
                                  <li key={index}>{api}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {/* Botones adicionales */}
                          <div className="dropDownCards">
                            <Dropdown className='my-auto'>
                              <Dropdown.Toggle 
                                className="organizations" 
                                style={{
                                  color: '#6c757d',
                                  transition: 'color 0.2s ease',
                                  background: '',
                                  border: 'none',
                                  padding: '0.375rem 0.75rem'
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.color = portalStyles.primaryColor;
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.color = '#6c757d';
                                }}
                              >
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {userObject.type === 'app_builder' && (
                                  <>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setSelectedApp(app);
                                        setDrawerOpen(true)
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setSelectedApp(app);
                                        setDrawerDocumentationOpen(true);
                                      }}
                                    >
                                      Edit Documentation
                                    </Dropdown.Item>
                                  </>
                                )}
                                <Dropdown.Item
                                  onClick={() => {
                                    window.location.href = `/devportal/${id}/documentation_by_app/${app.id}`;
                                  }}
                                >
                                  Documentation Site
                                </Dropdown.Item>
                                {userObject.type === 'app_builder' && (
                                  <>
                                    <Dropdown.Item onClick={() => { handleRemoveApp(app.id) }}>
                                      Delete
                                    </Dropdown.Item>
                                    {!portal.normal_access && (
                                      <Dropdown.Item
                                        onClick={() => {
                                          setSelectedApp(app);
                                          setDrawerAddUsersOpen(true);
                                        }}
                                      >
                                        Add Users
                                      </Dropdown.Item>
                                    )}
                                  </>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Table className="cld_tableWrapper" hover>
                  <thead>
                    <tr className="tableRow">
                      <th className="">Name</th>
                      <th className="">Description</th>
                      <th className="">Created At</th>
                      <th className="">API Products</th>
                      <th className="">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="tableRow">
                    {filteredApps.map((app) => (
                      <tr key={app.id}>
                        <td>{app.name}</td>
                        <td>{app.description}</td>
                        <td>{app.createdAt}</td>
                        <td>
                          {app.apiProducts.length > 0 ? (
                            <ul>
                              {app.apiProducts.map((api, index) => (
                                <li key={index}>{api}</li>
                              ))}
                            </ul>
                          ) : (
                            "None"
                          )}
                        </td>
                        <td>
                          <div className="dropDownTable">
                            <Dropdown className='my-auto'>
                              <Dropdown.Toggle 
                                className="organizations" 
                                style={{
                                  color: '#6c757d',
                                  transition: 'color 0.2s ease',
                                  background: 'none',
                                  border: 'none',
                                  padding: '0.375rem 0.75rem'
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.color = portalStyles.primaryColor;
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.color = '#6c757d';
                                }}
                              >
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {userObject.type === 'app_builder' && (
                                  <>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setSelectedApp(app);
                                        setDrawerOpen(true)
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setSelectedApp(app);
                                        setDrawerDocumentationOpen(true);
                                      }}
                                    >
                                      Edit Documentation
                                    </Dropdown.Item>
                                  </>
                                )}
                                <Dropdown.Item
                                  onClick={() => {
                                    window.location.href = `/devportal/${id}/documentation_by_app/${app.id}`;
                                  }}
                                >
                                  Documentation Site
                                </Dropdown.Item>
                                {userObject.type === 'app_builder' && (
                                  <>
                                    <Dropdown.Item onClick={() => { handleRemoveApp(app.id) }}>
                                      Delete
                                    </Dropdown.Item>
                                    {!portal.normal_access && (
                                      <Dropdown.Item
                                        onClick={() => {
                                          setSelectedApp(app);
                                          setDrawerAddUsersOpen(true);
                                        }}
                                      >
                                        Add Users
                                      </Dropdown.Item>
                                    )}
                                  </>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )
            )}
          </Container>

          {/* Drawer para Crear Apps */}
          <Drawer
                
            isOpen={drawerOpen}
            toggleSidebar={() => {
              setDrawerOpen(false)
              setSelectedApp({id:0})
            }}
            name={selectedApp.id ? 'Edit App':'Create App'}
          >
            {portal && (
              <CreateAppForm
                app_data={selectedApp}
                devportal_id={portal_id}
                onSubmit={() => {
                  setDrawerOpen(false);
                  DevPortalExists();
                  DevPortalProductsExists(portal_id);
                }}
              />
            )}
          </Drawer>


          <Modal show={showModal} onHide={handleClose} id="themodal">
              <Modal.Body >
              <Form className="obex-form payment-method-form" onSubmit={handleSubmit}>
                <h2>Please, set a new password</h2>
                  <Form.Row className="mb-4">
                    <Form.Label column sm="12" className="obex-form-label">
                      Password
                    </Form.Label>
                    <Col md="12">
                      <Form.Control required type="password" placeholder="Password" onChange={handlePassword}/>
                    </Col>
                  </Form.Row>
                  <Form.Row className="mb-4">
                    <Form.Label column sm="12" className="obex-form-label">
                      Repeat Password
                    </Form.Label>
                    <Col md="12">
                      <Form.Control required type="password" placeholder="Repeat Password" onChange={handleRepeatPassword}/>
                    </Col>
                  </Form.Row>

                  <Form.Row className="my-3">
                    <Col md="4" className="mx-auto">
                      <Button type="submit" className="textBold custom-button-blue">
                        Continue
                        {success && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '8px' }}/>}
                        {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                      </Button>
                    </Col>
                  </Form.Row>
                 {errorP.length>0 && <Alert variant="danger">{errorP}</Alert>} 
                </Form>

              </Modal.Body>
              
    </Modal>

          {/* Add Users */}
          <Drawer
            isOpen={drawerAddUsersOpen}
            toggleSidebar={() => setDrawerAddUsersOpen(false)}
            name="Add Users"
          >
            <AddUsersForm 
              devPortalId={Number(portal_id)}
              appId={'id' in selectedApp ? (selectedApp as App).id : ''}
              portal={portal}
            />
          </Drawer>

          {/* Agregar nuevo Drawer para Documentation */}
          <Drawer
            isOpen={drawerDocumentationOpen}
            toggleSidebar={() => setDrawerDocumentationOpen(false)}
            name="Edit Documentation"
          >
            <Documentation 
              documenta={'id' in selectedApp ? (selectedApp as App).description : ''}
              obex_project_id={portal_id}
              docInfo={{
                project_id: portal_id,
                method: 'GET',
                endpoint: '/documentation',
                gateway_id: null
              }}
              api_product_id={'id' in selectedApp ? (selectedApp as App).id : ''}
              portalStyles={portalStyles}
            />
          </Drawer>
        </>
      ) : error ? (
        <Alert variant="danger" className="m-3">
          {error}
        </Alert>
      ) : null}
    </div>
  );
};

export default DevPortaCatalog;
