import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  ListGroup,
  Dropdown,
  Button,
} from "react-bootstrap";
import { useHistory, useParams, Link } from "react-router-dom";
import Drawer from "../avapCloud/components/Drawer";
import CreateAppForm from "./CreateAppForm";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
//temas
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";


import sun from '../../assets/img/ui_icons/sun.svg';
import moon from '../../assets/img/ui_icons/moon.svg';

import Cookies from 'universal-cookie';
import config from '../../configs/appConfig.json';
import PortalNavbar from "./components/portal_navbar";

const cookies = new Cookies();




interface Portal {
  portal_id: number;
  id: number;
  name: string;
  logo: string;
  theme: {
    primaryColor: string;
    secondaryColor: string;
    backgroundColor: string;
    textColor: string;
  };
  user?: {
    name: string;
    photo: string;
  };
  apps?: App[];
  apiProducts?: ApiProduct[];
}

interface App {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  apiProducts: string[];
}

interface ApiProduct {
  title: string;
  description: string;
  latestVersion: string;
  details: string;
}

interface Documentation {
  version: string;
  content: string;
  endpoints: Endpoint[];
}

interface Endpoint {
  method: string;
  path: string;
  description: string;
}

interface ButtonProps {
  text: string;
  theme: {
    primaryColor: string;
  };
  onClick?: () => void;
  className?: string;
}

// Nueva interface para Index
interface Index {
  index_id: number;
  index_name: string;
  description?: string;
  documents?: {
    id: number;
    name: string;
  }[];
}

const DynamicButton: React.FC<ButtonProps> = ({
  text,
  theme,
  onClick,
  className,
}) => {
  const buttonStyle = {
    backgroundColor: theme.primaryColor,
    color: "#000",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  };

  return (
    <button style={buttonStyle} onClick={onClick} className={className}>
      {text}
    </button>
  );
};

const extractText = (children: React.ReactNode): string => {
  if (typeof children === 'string') {
    return children;
  } else if (Array.isArray(children)) {
    return children.map((child) => extractText(child)).join('');
  } else if (
    React.isValidElement(children) &&
    children.props.children
  ) {
    return extractText(children.props.children);
  }
  return '';
};

const headingRenderer = ({
  level,
  children,
}: {
  level: number;
  children: React.ReactNode;
}) => {
  if (level === 2) {
    const text = extractText(children);
    const id = text.toLowerCase().replace(/\s+/g, "-");
    return <h2 id={id}>{children}</h2>;
  }
  return React.createElement(`h${level}`, {}, children);
};

const codeRenderer = ({
  language,
  value,
}: {
  language: string;
  value: string;
}) => {
  return (
    <SyntaxHighlighter
      style={materialLight}
      language={language || "plaintext"}
      showLineNumbers
      PreTag="div"
    >
      {value}
    </SyntaxHighlighter>
  );
};

const DocumentationView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  // =========================================================
  // ESTADOS
  // =========================================================
  const [portal, setPortal] = useState<Portal | null>(null);
  const [portal_process, setPortalProcess] = useState<Portal | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const [indexes, setIndexes] = useState<Index[]>([]);
  const [indexesLoading, setIndexesLoading] = useState(true);
  const [submenu, setSubmenu] = useState<string[]>([]);
  const [selectedView, setSelectedView] = useState<'swagger' | number>('swagger');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [Logo, setLogo] = useState('')
  const [portal_id, set_portal_id] = useState(0)
  const [User, setUser] = useState({ username: '', type: '', user_id: 0 });

  const [swaggerUrl, setSwaggerUrl] = useState<string>(
    "https://avs-primary-pre.101obex.mooo.com:3001/get_portal_specs/15"
  );

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });

  const [userName, setUserName] = useState('unknown');

  const setFavicon = (url: string) => {
    let link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
  
    if (!link) {
      // Si no existe, lo creamos
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
  
    // Cambiamos la URL del favicon
    link.href = url;
  };

  // =========================================================
  // FETCH: Portal
  // =========================================================
  const fetchPortalData = async (): Promise<number> => {
    try {



      const user = cookies.get('user');

      const portal = cookies.get('portal');

      console.log("hhhh")
      console.log(portal);
      console.log("hhhh")

      const portal_id = portal.portal_id;

      setSwaggerUrl(`${config.url}:${config.port}/get_portal_specs/${portal_id}`)

      setUser(user);

      console.log("Fetching portal data with ID:", id);
      const res = await ObexRequestHandler.get(
        `/publishing/get_dev_portal_config/${id}`,
        {},
        true
      );

      console.log("Response from /publishing/get_dev_portal_config:", res.data);

      if (res.data.success && res.data.data) {
        setPortal(res.data.data);
        set_portal_id(res.data.data.portal_id);
        document.title = res.data.data.name;
        setFavicon(res.dada.dada.logo);
        
        return res.data.data.portal_id;
      } else if (res.data.id) {
        setPortal(res.data);
        set_portal_id(res.data.portal_id);
        document.title = res.data.name;
        setFavicon(res.data.logo);
        return res.data.portal_id;
      } else {
        throw new Error("Portal data is empty");
      }
    } catch (err) {
      console.error("Error fetching portal data:", err);
      setError("Failed to load portal configuration.");
      return 0; // O maneja el error según corresponda
    }
  };


  // =========================================================
  // FETCH: Documentos
  // =========================================================
  const fetchDocuments = async (portalId: number): Promise<any[]> => {
    try {
      console.log("Fetching documents for portal ID:", portalId);
      const res = await ObexRequestHandler.get(
        `/publishing/get_dev_portal_documents/${portalId}`,
        {},
        true
      );

      console.log("Response from /publishing/get_dev_portal_documents:", res.data);

      if (res.data.success && res.data.data && res.data.data.length > 0) {
        return res.data.data;
      } else if (Array.isArray(res.data) && res.data.length > 0) {
        return res.data;
      } else {
        return [];
      }
    } catch (err) {
      console.error("Error fetching documents:", err);
      return [];
    }
  };

  const fetchDocumentContent = async (doc_id: number): Promise<string> => {
    try {
      console.log("Fetching content for document ID:", doc_id);
      const res = await ObexRequestHandler.get(
        `/publishing/get_dev_document/${doc_id}`,
        {},
        true
      );

      console.log(`Response from /publishing/get_dev_document/${doc_id}:`, res.data);

      if (res.data.success && res.data.data && res.data.data.document_content) {
        return res.data.data.document_content;
      } else if (res.data.document_content) {
        return res.data.document_content;
      } else {
        return "";
      }
    } catch (err) {
      console.error(`Error fetching content for document ID ${doc_id}:`, err);
      return "";
    }
  };

  // =========================================================
  // FETCH: Índices
  // =========================================================
  const fetchIndexes = async (portalId: number) => {
    try {
      setIndexesLoading(true);
      console.log("Fetching indexes for portal ID:", portalId);
      const res = await ObexRequestHandler.get(
        `/publishing/get_dev_portal_index/${portalId}`,
        {},
        true
      );
      console.log("Response from /get_dev_portal_index:", res.data);

      if (res.data.success && Array.isArray(res.data.data)) {
        setIndexes(res.data.data);
      } else if (Array.isArray(res.data)) {
        setIndexes(res.data);
      } else {
        setIndexes([]);
      }
    } catch (err) {
      console.error("Error fetching indexes:", err);
      setIndexes([]);
    } finally {
      setIndexesLoading(false);
    }
  };


  // =========================================================
  // Carga de documentación
  // =========================================================
  const loadDocumentation = async (portalId: number) => {
    try {
      const fetchedDocuments = await fetchDocuments(portalId);
      const documentsWithContent = await Promise.all(
        fetchedDocuments.map(async (doc: any) => {
          const content = await fetchDocumentContent(doc.document_id);
          return { ...doc, content };
        })
      );
      setDocuments(documentsWithContent);
    } catch (err) {
      console.error("Error loading documentation:", err);
      setError("Failed to load documentation.");
    }
  };

  // =========================================================
  // useEffect principal
  // =========================================================
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const fetchedPortalId = await fetchPortalData();
        if (fetchedPortalId !== 0) {
          await loadDocumentation(fetchedPortalId);
          await fetchIndexes(fetchedPortalId);
        }
      } catch (error) {
        console.error("Error loading portal data or documentation:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);


  const toggleDarkMode = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  useEffect(() => {
    const userCookie = cookies.get('user');
    if (userCookie?.username) {
      setUser({
        username: userCookie.username,
        type: userCookie.type || '',
        user_id: userCookie.user_id || 0
      });
    }
  }, []);

  // =========================================================
  // Submenú
  // =========================================================
  const generateSubmenu = (markdownContent: string) => {
    const lines = markdownContent.split("\n");
    const headings = lines
      .filter((line) => /^#{1,6}\s+/.test(line))
      .map((line) => {
        const match = line.match(/^(#{1,6})\s+(.*)$/);
        if (match) {
          const level = match[1].length;
          const text = match[2].trim();
          const id = text.toLowerCase().replace(/\s+/g, "-");
          return { level, text, id };
        }
        return null;
      })
      .filter((item) => item !== null) as { level: number; text: string; id: string }[];

    if (headings.length > 0) {
      setSubmenu(headings.map((heading) => heading.text));
    } else {
      setSubmenu(["No titles available"]);
    }
  };

  useEffect(() => {
    if (selectedView === 'swagger') {
      setSubmenu([]);
      return;
    }
    const selectedDocument = documents.find(doc => doc.document_id === selectedView);
    if (selectedDocument?.content) {
      generateSubmenu(selectedDocument.content);
    } else {
      setSubmenu(["No titles available"]);
    }
  }, [selectedView, documents]);

  // =========================================================
  // Manejo del Drawer
  // =========================================================
  const handleAppSubmit = (
    app: { name: string; description: string; selectedAPIs: string[] }
  ) => {
    if (portal) {
      const newApp: App = {
        id: `app${portal.apps ? portal.apps.length + 1 : 1}`,
        name: app.name,
        description: app.description,
        createdAt: new Date().toISOString().split("T")[0],
        apiProducts: app.selectedAPIs,
      };
      if (portal.apps) {
        setPortal({ ...portal, apps: [...portal.apps, newApp] });
      } else {
        setPortal({ ...portal, apps: [newApp] });
      }
      console.log("App Created:", newApp);
      setDrawerOpen(false);
    }
  };

  // =========================================================
  // Render
  // =========================================================
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!portal) return <div>Loading...</div>;



  return (
    <div id="devPortalWrapper">
      <PortalNavbar
        portal={portal}
        Logo={Logo}
        userName={userName}
        isDarkMode={isDarkMode}
        User={User}
        portalIdentifier={id}
        setDrawerOpen={setDrawerOpen}
        toggleDarkMode={toggleDarkMode}
      />

      {/* Contenido Principal */}
      <Container fluid className="my-4">
        <Row>
          {/* Menú Izquierdo */}
          <Col md={3} className="border-right">
            {/* Sección de Índices (Modificada) */}
            {indexesLoading ? (
              <div>Loading indexes...</div>
            ) : indexes.length > 0 ? (
              <ListGroup className="mb-4">
                {indexes.map((indexItem) => (
                  <React.Fragment key={indexItem.index_id}>
                    <ListGroup.Item>
                      <strong>{indexItem.index_name}</strong>
                      <p className="m-0">{indexItem.description}</p>
                    </ListGroup.Item>
                    {indexItem.documents && indexItem.documents.map((doc) => (
                      <ListGroup.Item
                        key={doc.id}
                        action
                        active={selectedView === doc.id}
                        onClick={() => setSelectedView(doc.id)}
                      >
                        {doc.name}
                      </ListGroup.Item>
                    ))}
                  </React.Fragment>
                ))}
              </ListGroup>
            ) : (
              <div>No indexes available</div>
            )}

            {/* Menú de Documentos */}
            {/* <h5>Documents</h5>
          <ListGroup>
            {documents.map((doc) => (
              <ListGroup.Item
                key={doc.document_id}
                action
                active={selectedView === doc.document_id}
                onClick={() => setSelectedView(doc.document_id)}
              >
                {doc.document_name}
              </ListGroup.Item>
            ))}
          </ListGroup> */}

            {/* Menú de Specification */}
            <h5 className="mt-4">Specification</h5>
            <ListGroup>
              <ListGroup.Item
                action
                active={selectedView === 'swagger'}
                onClick={() => setSelectedView('swagger')}
              >
                Specification
              </ListGroup.Item>
            </ListGroup>
          </Col>

          {/* Contenido de la Documentación */}
          <Col md={6}>
            <div className="p-5">
              {selectedView === 'swagger' ? (
                <SwaggerUI
                  url={swaggerUrl}
                  layout="BaseLayout"
                />
              ) : (
                <ReactMarkdown
                  source={
                    documents.find(doc => doc.document_id === selectedView)
                      ?.content || ''
                  }
                  escapeHtml={false}
                  renderers={{
                    heading: headingRenderer,
                    code: codeRenderer,
                  }}
                />
              )}
            </div>
          </Col>

          {/* Submenú Derecho */}
          {selectedView !== 'swagger' && (
            <Col md={3} className="border-left">
              <h5>On this page</h5>
              <ListGroup>
                {submenu.length > 0 ? (
                  submenu.map((section, index) => (
                    <ListGroup.Item
                      action
                      key={index}
                      onClick={() => {
                        const element = document.getElementById(
                          section.toLowerCase().replace(/\s/g, "-")
                        );
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                      }}
                    >
                      {section}
                    </ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item disabled>No titles available</ListGroup.Item>
                )}
              </ListGroup>
            </Col>
          )}
        </Row>
      </Container>

      {/* Drawer para Crear Apps */}
      <Drawer
        isOpen={drawerOpen}
        toggleSidebar={() => setDrawerOpen(false)}
        name="Create App"
      >
        {portal && (
          <CreateAppForm

            app_data={{
              id: 0,
              name: undefined,
              description: undefined,
              latestVersion: undefined,
              createdAt: undefined,
              apiProducts: [],
              apiProductsID: [],
              tokens: [],
              tokensId: []
            }}
            devportal_id={portal_id.toString()}
            onSubmit={() => {
              setDrawerOpen(false);
            }}
          />
        )}
      </Drawer>
    </div>
  );
};

export default DocumentationView;