import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import React, { FunctionComponent, useEffect } from 'react';
import '../../../assets/css/baseLayout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderMenu from './HeaderMenu';
import ApiSideBar from './ApiSideBar';
import Footer from '../FooterLayout/FooterMenu';
import Drawer from '../../../components/DashboardComponents/Drawer';
import CookieMessage from '../../Modals/CookieMessage';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import Terminal from 'terminal-in-react';
import SessionService from '../../../services/SessionsService';
import FooterMenu from '../DashboardLayout/FooterMenuAccess';
import favicon from '../../../assets/img/logos/icono_DS.svg';

//form
import CreatAppForm from './forms/CreateApp'

// import HeaderMenu from '../baseLayout/HeaderMenu';
import DashboardSideBar from '../../functionalComponents/DashboardLayout/DashboardSidebar';

//import pseudoFileSystem from 'terminal-in-react-pseudo-file-system-plugin';
//const FileSystemPlugin = pseudoFileSystem(); 
//import NodeEvalPlugin from 'terminal-in-react-node-eval-plugin';

type BaseProps = {
  sider: any,
  active?: string,
  children: any
};




const BaseLayout: FunctionComponent<BaseProps> = ({ sider, active, children }: BaseProps) => {


  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeSidebar, setActiveSidebar] = useState('api-products');
  const [thankyou, setThankyou] = useState(false);
  const [goodFeedback, setGood] = useState(true);
  const [badFeedback, setBad] = useState(true);
  const [StyleGood, setStyleGood] = useState({ backgroundColor: '#818881' });
  const [StyleBad, setStyleBad] = useState({ backgroundColor: '#818881' });
  const [hooverGood, sethooverGood] = useState(false);
  const [hooverBad, sethooverBad] = useState(false);
  const [panel, setPanel] = useState(false);


  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme === "dark";
  });

  useEffect(() => {
    document.body.classList.add('devportal-dashboard');
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
    
    // Disparar un evento personalizado para notificar a otros componentes sobre el cambio de tema
    const themeChangedEvent = new Event('themeChanged');
    window.dispatchEvent(themeChangedEvent);
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    console.log("===== TOGGLE DARK MODE =====");
    console.log("Estado actual del tema:", isDarkMode ? "Oscuro" : "Claro");
    
    const newTheme = !isDarkMode;
    console.log("Cambiando al tema:", newTheme ? "Oscuro" : "Claro");
    
    setIsDarkMode(newTheme);
    localStorage.setItem("theme", newTheme ? "dark" : "light");
    
    // Disparar un evento personalizado para notificar a otros componentes sobre el cambio de tema
    const themeChangedEvent = new Event('themeChanged');
    window.dispatchEvent(themeChangedEvent);
    
    console.log("Tema guardado en localStorage:", newTheme ? "dark" : "light");
    console.log("Evento themeChanged disparado");
    console.log("============================");
  };

  // Escuchar cambios de tema para aplicarlos al sidebar móvil
  useEffect(() => {
    const handleThemeChange = () => {
      // Este evento se dispara cuando cambia el tema
      // El sidebar móvil tomará los colores de las variables CSS
      console.log("Tema cambiado detectado en BaseLayout");
    };

    window.addEventListener('themeChanged', handleThemeChange);
    return () => {
      window.removeEventListener('themeChanged', handleThemeChange);
    };
  }, []);
  
  useEffect(() => {
    // favicon
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = favicon; // Usa la ruta importada directamente
    document.head.appendChild(link);

    // favicon
    return () => {
        document.head.removeChild(link);
    };
}, []);


  ////test Data
  const apiProduct2s = [
    {
      id: 'api1',
      titleNav: 'User Management API',
      version: '1.0',
      description: 'API for managing user accounts and authentication.',
      swagger_link: 'https://api.example.com/swagger/user-management',
      documentation_links: [
        { title: 'Getting Started Guide', url: 'https://docs.example.com/user-management/getting-started' },
        { title: 'API Reference', url: 'https://docs.example.com/user-management/api-reference' }
      ],
      basePath: '/user-management', // Añadido
      icon: 'users' // Añadido
    },
    {
      id: 'api2',
      titleNav: 'Order Management API',
      version: '2.1',
      description: 'API for handling orders, payments, and tracking.',
      swagger_link: 'https://api.example.com/swagger/order-management',
      documentation_links: [
        { title: 'Order Processing Guide', url: 'https://docs.example.com/order-management/processing' },
        { title: 'API Reference', url: 'https://docs.example.com/order-management/api-reference' }
      ],
      basePath: '/order-management', // Añadido
      icon: 'orders' // Añadido
    }
  ];

  const apiData = {
    menus: [
      {
        id: 'api1',
        title: "User Management API",
        icon: "dashboard",
        version: '1.0',
        description: 'API for managing user accounts and authentication.',
        swagger_link: 'https://api.example.com/swagger/user-management',
        basePath: '/user-management',
        links: [
          {
            title: 'Getting Started Guide',
            url: 'https://docs.example.com/user-management/getting-started'
          },
          {
            title: 'API Reference',
            url: 'https://docs.example.com/user-management/api-reference'
          }
        ]
      },
      {
        id: 'api2',
        title: "Order Management API",
        icon: "orders",
        version: '2.1',
        description: 'API for handling orders, payments, and tracking.',
        swagger_link: 'https://api.example.com/swagger/order-management',
        basePath: '/order-management',
        links: [
          {
            title: 'Order Processing Guide',
            url: 'https://docs.example.com/order-management/processing'
          },
          {
            title: 'API Reference',
            url: 'https://docs.example.com/order-management/api-reference'
          }
        ]
      }
    ]
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };




  const BadFeedback = () => {
    setGood(false);
    setThankyou(true);
    setBad(false);
    ReactGA.event({
      category: 'Feedback',
      action: 'Bad',
      label: (window.location.pathname + window.location.search)
    });

  }
  const GoodFeedback = () => {
    setBad(false);
    setThankyou(true);
    setGood(false);
    ReactGA.event({
      category: 'Feedback',
      action: 'Good',
      label: (window.location.pathname + window.location.search)
    });
  }

  const toggleHooverGood = () => {
    if (goodFeedback && badFeedback) {
      if (hooverGood) { setStyleGood({ backgroundColor: '#818881' }); sethooverGood(false); }
      else { setStyleGood({ backgroundColor: '#3e413e' }); sethooverGood(true); }
    }
  }
  const toggleHooverBad = () => {
    if (goodFeedback && badFeedback) {
      if (hooverBad) { setStyleBad({ backgroundColor: '#818881' }); sethooverBad(false); }
      else { setStyleBad({ backgroundColor: '#3e413e' }); sethooverBad(true); }
    }
  }


  const togglePanel = () => {
    setPanel(!panel);
  }
  const cambio = () => {

    setBad(true);
    setGood(true);
    setThankyou(false);
    setStyleGood({ backgroundColor: '#818881' });
    setStyleBad({ backgroundColor: '#818881' });
    sethooverGood(false);
    sethooverBad(false);

  }

  if (SessionService.eventEmitter) {
    SessionService.eventEmitter.once('panel', function () {
      console.log('Paneling');
      if (!SessionService.SCREAMING) {
        SessionService.SCREAMING = true;

        if (panel) {

          const elem1 = document.getElementById('pannel') as HTMLDivElement;
          elem1.hidden = true;
          const elem2 = document.getElementById('principal') as HTMLDivElement;
          elem2.style.height = '100%'
        } else {

          const elem1 = document.getElementById('pannel') as HTMLDivElement;
          elem1.hidden = false;
          const elem2 = document.getElementById('principal') as HTMLDivElement;
          elem2.style.height = '80%'
        }

        togglePanel();
        SessionService.SCREAMING = false;
      }
    });
  }

  return (
    <>
      <div id='dashboardWrapper' className='dp-dashboard-wrapper'>
        <div className="theme-toggle-container">
          <button onClick={toggleDarkMode} className="toggle-dark-mode">
            <span>{isDarkMode ? "Dark Mode" : "Light Mode"}</span>
          </button>
        </div>
        <HeaderMenu />
        <div id="page-content-wrapper" className='w-100'>
          <ApiSideBar sider={sider} active={active} />
          <div id="content" className="container-fluid mt-0 p-0">
            <div className="dp-scrollContainer">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BaseLayout;