import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, Form, Spinner, Dropdown, Alert } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSearch } from "@fortawesome/free-solid-svg-icons";
import devIcon from '../../assets/img/ui_icons/catalog.png';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";

import Drawer from "./Drawer";
import GenericSelectableList from "./CustomSelectSearch";
import { useHistory } from "react-router-dom";
import Nodata from "./components/Nodata";

const ApiCatalog = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [apiProducts, setApiProducts] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [selectedApiForEdit, setSelectedApiForEdit] = useState(null);
  const [selectedApi, setSelectedApi] = useState("");
  const [Portals, setPortals] = useState([]);
  const [PortalsCount, setPortalsCount] = useState(0);
  const [FilteredPortals, setFilteredPortals] = useState([]);
  const [error, setErr] = useState('');
  const [isAddCertDrawerOpen, setIsAddCertDrawerOpen] = useState(false)
  const [localSelectedDP, setSelectedDP] = useState([]);
  const [ogSelectedDP, setOgSelectedDP] = useState([]);
  const [submited, setSubmited] = useState(true);
  const history = useHistory();

  const [selectedAPIProduct, setselectedAPIProduct] = useState(0);

  const getDevPortals = async () => {


    const AMBIENT_LABELS = {
      Visible: { name: 'Visible', color: '#28a745' },        // Verde
      Private: { name: 'Private', color: 'red' }, // Amarillo
      No:  { name: 'No', color: 'red' },
      Yes: { name: 'Yes', color: '#28a745' },
      //3: { name: 'Live', color: '#28a745' }, // Amarillo
      Active: { name: 'Active', color: '#28a745' },        // Verde
      Disabled: { name: 'Disabled', color: 'red',
      Unpublished: {name: 'Unpublished', color: 'red' }  
       },
    
    };
    
    const getAmbientLabel = (ambientId: string) => {
      const ambient = AMBIENT_LABELS[ambientId];
      if (!ambient) return null;
    
      return (
        <span 
          style={{
            backgroundColor: ambient.color,
            color: '#fff',
            padding: '2px 4px',
            borderRadius: '4px',
            fontSize: '12px',
            minWidth:'100px',
            display:'flex',
            width:'65px',
            justifyContent:'center',
            marginLeft:'auto',
            marginRight:'auto',
          }}
        >
          {ambient.name}
        </span>
      );
    };

    setPortals([]);
  
      const portals_configs = 
        await ObexRequestHandler.get(`/gateway/get_dev_portal_by_org?org_id=${SessionService.ORGANIZATION}`, {}, true)

      const portals = portals_configs.data.map((por) => ({
        id: por.id,
        name: por.name,
        description: por.description,
        route: por.route
      }));
      setPortalsCount(portals.length);
      setPortals(portals);
      history.push('/catalog')
    };



    const AMBIENT_LABELS = {
      Yes: { name: 'Yes', color: '#28a745' },        // Verde
      No: { name: 'No', color: 'red' }, // Amarillo
      Unpublished: { name: 'Unpublished', color: 'red' }
      //3: { name: 'Live', color: '#28a745' }, // Amarillo
    
    };
    
    const getAmbientLabel = (ambientId: string) => {
      if (ambientId==undefined || ambientId == null) return '';
     let ambient = undefined
      try{
      ambient = AMBIENT_LABELS[ambientId];
      } catch {}
      if (ambient==undefined) return (
        <span 
          style={{
            backgroundColor: '#28a745',
            color: '#fff',
            padding: '2px 4px',
            borderRadius: '4px',
            fontSize: '12px',
            minWidth:'100px',
            display:'flex',
            width:'65px',
            justifyContent:'center',
            marginLeft:'auto',
            marginRight:'auto',
          }}
        >
          {ambientId || ''}
        </span>
      );
    
      return (
        <span 
          style={{
            backgroundColor: ambient.color,
            color: '#fff',
            padding: '2px 4px',
            borderRadius: '4px',
            fontSize: '12px',
            minWidth:'100px',
            display:'flex',
            width:'65px',
            justifyContent:'center',
            marginLeft:'auto',
            marginRight:'auto',
          }}
        >
          {ambient.name}
        </span>
      );
    };

    const validateSubmit = (array) => {
      
      const array2Sorted = array.slice().sort();
      const submit_active = ogSelectedDP.length === array.length && ogSelectedDP.slice().sort().every(function(value, index) {
        return value === array2Sorted[index];
    });
      // console.log('[API CATALOG] validate', submit_active,array2Sorted,ogSelectedDP)
      setSubmited(submit_active)
    };

    const handleAddDP = (dp: string) => {
      setErr('')
      if (!localSelectedDP.includes(dp)) {
        setSelectedDP([...localSelectedDP, dp]);
        // console.log('[API CATALOG] addDP', [...localSelectedDP, dp]);
        validateSubmit([...localSelectedDP, dp])

      }     
    };
  
    const handleRemoveDP = (dp: string) => {
      setErr('')
      validateSubmit(localSelectedDP.filter((item) => item !== dp))
      setSelectedDP(localSelectedDP.filter((item) => item !== dp));
      
    };




  const handlePublish = async (api_product) => {

    /*
    
              let data = JSON.stringify({
                "api_product_id": api_product_id,
                "api_product_version": version_value,
                "developer_token": AccesToken
                });
                
                let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${cloud}/api_product_apis_by_dev`,
                headers: { 
                  'Content-Type': 'application/json', 
                },
                data : data
                };
                
                axios.request(config)
    
    
    */




    console.log(api_product.latest_version);

    let version_value = 0.1;
    try {
      if (api_product.latest_version) version_value = Number(api_product.latest_version) + 0.1;
    } catch { }
    const data = {
      api_product_id: api_product.api_product_id,
      api_product_version: version_value,
    };

    console.log(data);
    console.log(api_product);



    const publish_data = await ObexRequestHandler.post('/api_product_apis_by_dev', data);

    console.log(publish_data);
    fetchApiProducts();

  }



  const handleDevPortal = async (api_product) => {


    const data = {
      api_product_id: api_product.api_product_id,
      new_status: !api_product.active

    }

    const publish_data = await ObexRequestHandler.post('/update_portal_prod_status', data);

    console.log(publish_data);
    fetchApiProducts();

  }


  // Function to fetch API products from the backend
  const fetchApiProducts = async () => {
    setLoading(true);
    try {
      const data = await ObexRequestHandler.get(`/publishing/get_products_by_org?org_id=${SessionService.ORGANIZATION}`)

      console.log(data)
      const resmod = [];

      data.data.forEach(dat => {
        if (dat.is_published_active && (Number(dat.published_entries_count) + Number(dat.gateway_entries_count)>0)) resmod.push(dat);
      })
      
      setApiProducts(resmod);
      setInitialized(true);
    } catch (error) {
      console.error('Error fetching API products:', error);
      setInitialized(true);
    } finally {
      setLoading(false);
      setInitialized(true);
    }
    setInitialized(true);
  };


  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/catalog'){
      history.push('/catalog')
      SessionService.updating = SessionService.updating -3;
      fetchApiProducts();
      getDevPortals();
    window.scrollTo(0, 0);
    history.push('/catalog')
    }
  
  }, false);

  // Fetch API products on component mount (useEffect hook)
  useEffect(() => {
    

    fetchApiProducts();
    getDevPortals();

    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
    
  }, []); // Empty dependency array ensures fetching only once on mount

  // Search functionality (unchanged)
  const filteredApis = apiProducts.filter(api =>
    api.api_product_name.toLowerCase().includes(searchTerm.toLowerCase())



  );

  const apiOptions = [
    { id: 1, name: "API Product 1" },
    { id: 2, name: "API Product 2" },
    { id: 3, name: "API Product 3" },
    { id: 4, name: "API Product 4" }
  ];



  const openEditDrawer = (api) => {
    setSelectedApiForEdit(api);
    setIsEditDrawerOpen(true);
  };

  const closeEditDrawer = () => {
    setSelectedApiForEdit(null);
    setIsEditDrawerOpen(false);
  };

  const handleApiChange = (e) => {
    setSelectedApi(e.target.value);
  };


  return (
    <DashLayout sider={DashRouters} active={'api-catalog'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8' >
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img style={{width:'24px'}} src={devIcon} alt="icon dashboard" />
              {/*<span className="cld_title w-100 margin-y-24px"><span style={{ color: '#949498', fontSize: '14px' }}>API Catalog </span>/ */}<span className="cld_title w-100 margin-y-24px">API Catalog </span> 
            </div>
          </div>
        </Col>
        <Col md='4' className='align-content-center'>
          {/* Aquí se puede colocar algún botón de acción si fuera necesario */}
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div className="cld_borderWrapper">
          <span className='cld_sectionTitle'>Published API Products</span>

          <div className="searchContainer mb-4">
            <Form.Control
              id='buscador'
              required
              type="text"
              value={searchTerm}
              placeholder="Search by API Product name"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button className="customButton-transparent px-4">
              <FontAwesomeIcon icon={faSearch} size="1x" />
            </Button>
          </div>
          {(filteredApis.length === 0 && initialized)? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
              <Nodata />
            </div>
          ) : (
          <Table className="cld_tableWrapper" style={{height:'auto'}}>
            <tbody>
              <tr className="headerRow">
                <th style={{ width: '20%' }} className="textBold">API Product</th>
                <th style={{ width: '25%' }} className="textBold">Description</th>
                <th style={{ width: '13%' }} className="textBold">APIs</th>
                {/*<th style={{ width: '9%' }} className="textBold">APIs count</th>*/}
           {/*}     <th style={{ width: '8%' }} className="textBold">API Project</th> */}
                
                <th style={{ width: '15%', textAlign: 'center' }} className="textBold">Dev Portal Published</th>
                <th style={{ width: '15%', textAlign:'center' }} className="textBold">Dev Portals Available</th>
                <th style={{ textAlign:'center', width: '15%' }} className="textBold">Market Version</th>
                {/*<th style={{ width: '9%' }} className="textBold">Dev Portal</th>*/}
                
                
          
                <th style={{ textAlign: 'end', width: '20px' }} className="textBold">Actions</th>
              </tr>
              {filteredApis.map((api) => (
                <tr className="tableRow" key={api.id}>
                  <td className="td_centered">{api.api_product_name}</td>
                  <td className="td_centered">{api.api_product_description != 'undefined' ? api.api_product_description: '-'}</td>
                  <td className="td_centered">{api.published_endpoints!=null ? (api.published_endpoints.map((name)=>{name+' '})):''}</td>
                  {/*<td className="td_centered">{Number(api.published_entries_count) + Number(api.gateway_entries_count	)}</td>*/}
      {/*}            <td className="td_centered">{api.project_id} {api.project_name+ ' '}</td> */}
                
                  <td className="td_centered">{api.active ? getAmbientLabel('Yes'):getAmbientLabel('No')}</td>
                  <td className="td_centered" style={{textAlign:'center'}}>{api.dev_portal_list.length == PortalsCount ? '*' : (api.dev_portal_list ? api.dev_portal_list.map(dp=>dp.name+ ' '):'')}</td>
                  <td style={{ textAlign:'center' }} className="td_centered">{getAmbientLabel((api.latest_version) || ('Unpublished'))}</td>
                  {/*<td className="td_centered">{api.active ? 'Yes' : 'No'}</td>*/}
                  
                  <td>
                    <div className="actionsWrapper">
                      <Dropdown className='my-auto' style={{ textAlign: 'end', width: '100%', marginRight: '0px' }}>
                        <Dropdown.Toggle className="organizations">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          { !api.installed &&
                          <Dropdown.Item
                            key={1}
                            onClick={() => handlePublish(api)}
                          >
                            Pub in Marketplace
                          </Dropdown.Item>
                          }

                          {!api.active ?

                            <Dropdown.Item
                              key={1}
                              onClick={() => handleDevPortal(api)}
                            >
                              To Dev Portals
                            </Dropdown.Item>
                            :
                            <Dropdown.Item
                              key={1}
                              onClick={() => handleDevPortal(api)}
                            >
                              Retire from Dev Portal
                            </Dropdown.Item>

                          }
                        { PortalsCount !== 0 &&
                          <Dropdown.Item
                            key={2}
                            onClick={() => {
                              setIsAddCertDrawerOpen(true); 
                              setSelectedDP(api.dev_portal_list)
                              setselectedAPIProduct(api.api_product_id)
                              setOgSelectedDP(api.dev_portal_list)
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                        }
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              ))}
{/*}
                  {filteredApis.length < 3 &&
                    <div style={{ height:'95px' }}></div>
                  }
*/}

            </tbody>
          </Table>
              )}
        </div>
      )}

 {/*}     <Drawer
        toggleSidebar={closeEditDrawer}
        isOpen={isEditDrawerOpen}
        name="Edit API"
        subtitle=""
      >
        <div>
          <h5>Edit API: {selectedApiForEdit?.api_product_name}</h5>
          <p>This is a simulated content for editing the API.</p>
          <Form.Group controlId="apiSelect">
            <Form.Label>Select API</Form.Label>
            <Form.Control
              as="select"
              value={selectedApi}
              onChange={handleApiChange}
            >
              <option value="">-- Select an API --</option>
              {apiOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

*/}

      {/* Drawer for Adding To Dev Portals */}
      <Drawer
        isOpen={isAddCertDrawerOpen}
        toggleSidebar={() => {
          setIsAddCertDrawerOpen(!isAddCertDrawerOpen)
          history.push('/catalog')
          setSubmited(true)
        }}
        name="API Product in Dev Portals"
        index=""
      >
        <div>
          {/* <h3>Add a New Certificate</h3> */}
          <Form
            onSubmit={async (e) => {
              e.preventDefault();

              const payload = {
                api_product: selectedAPIProduct,
                dev_portal_list: localSelectedDP
              }

              if (false 
              ) {

                setErr('Please fill all data')

              } else {
                setLoading(true);
              await ObexRequestHandler.post('/publishing/update_api_product_devportals', payload)
              setIsAddCertDrawerOpen(false);
                setLoading(false);

                fetchApiProducts();
                getDevPortals();
              }

            }}
          >






        <Form.Group controlId="devPortal">
          <Form.Label>Available Dev Portals</Form.Label>


            <Form.Group controlId="SelectSP" className="mb-3">

              <GenericSelectableList
                items={Portals} // Convierte las cadenas en objetos con `id` y `name`
                searchKeys={['name']} // Busca por el nombre
                onAdd={handleAddDP}
                onRemove={handleRemoveDP}
                renderLabel={(item) => item.name ? `${item.name}` : `> ${item}`} // Renderiza directamente el nombre
                placeholder="Select Dev Portal."
                multiOption={true} // Permitir selección múltiple
                selectedItems={localSelectedDP}
                setSelectedItems={setSelectedDP}
                itemSelected={''}
              />
            </Form.Group>
        </Form.Group>






            <Row>
              <Col md='6'>
                <Button 
                disabled={ submited /*localSelectedDP.length==0*/}
                variant="primary" className="w-100" type="submit" >
                  Submit
                  {loading && <Spinner animation="border" size="sm" />}
                </Button>
              </Col>
            </Row>
        { error.length>0 &&
          <Alert variant='danger'>
          {error}
</Alert>}

          </Form>
        </div>
      </Drawer>



{/*}


          <Button variant="primary" onClick={closeEditDrawer}>
            Close
          </Button>
        </div>
      </Drawer>*/}
    </DashLayout>
  );
};

export default ApiCatalog;
