import React from 'react';
import { Button } from 'react-bootstrap';
import Drawer from './Drawer';


interface ChartData {
  id: string;
  type: 'line' | 'bar' | 'pie' | 'doughnut';
  title: string;
  description: string;
  imageUrl?: string;
  data: any;
  options: any;
  width: number;
}

interface AiInterpretationDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  currentChart: ChartData | null;
  aiInterpretation: string;
  isLoading: boolean;
}

const AiInterpretationDrawer: React.FC<AiInterpretationDrawerProps> = ({
  isOpen,
  onClose,
  currentChart,
  aiInterpretation,
  isLoading
}) => {
  return (
    <Drawer
      isOpen={isOpen}
      index="ai-interpretation"
      toggleSidebar={onClose}
      name={currentChart ? `Brunix Interpretation: ${currentChart.title}` : 'Brunix Interpretation'}
    >
      <div className="p-4">
        {isLoading ? (
          <div className="text-center py-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p className="mt-3">Brunix is analyzing the data...</p>
          </div>
        ) : (
          <div className="ai-interpretation">
            <div dangerouslySetInnerHTML={{ __html: aiInterpretation }} />
            
            <div className="mt-4 pt-3 border-top">
              <h5>Was this interpretation helpful?</h5>
              <div className="d-flex mt-2">
                <Button variant="outline-success" className="mr-2">
                  Yes, it was helpful
                </Button>
                <Button variant="outline-danger">
                  No, needs improvement
                </Button>
              </div>
            </div>
            
            <div className="mt-4">
              <Button variant="primary" onClick={onClose}>
                Close
              </Button>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default AiInterpretationDrawer; 