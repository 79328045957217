import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Button, Row, Col, Form, Alert, Spinner, Card, Modal } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ProductDetailCard from '../Signup/productDetailCard';
import ProductResumeCard from '../Signup/productResumeCard';
import ProductMethodPayment from '../Signup/productMethodPayment';
import { getLeftMonthsExpires } from '../../services/TimeService';
import { parseToCoin } from '../../services/AmountService';
import SessionService from '../../services/SessionsService';


type PaymentConfirmationProps = {
  actionSubmit: () => Promise<void>,
  handleGoBack: () => void,
  handleAnnualPayment: () => void,
  nameServiceSelected: string,
  amountMonth: number,
  amountYear: number,
  processingPayment?: boolean,
  prorated?: boolean,
  success?: string,
  error?: string,
  isBlue?: boolean,
  handleCard?: (e) => void,
  handleReload?: (e) => void
  cards?: Array<{ id, brand, last4 }>,
  addedCard?: string,
  spanish: boolean,
  cloud?: boolean,
}
const defaultProps = {
  isBlue: true
}

const PaymentConfirmationModal: FunctionComponent<PaymentConfirmationProps> = (props: PaymentConfirmationProps) => {
  props = { ...defaultProps, ...props }

  const { actionSubmit, handleGoBack, handleAnnualPayment, nameServiceSelected, amountMonth, amountYear, processingPayment, success, error, isBlue, handleReload, prorated, spanish, cloud = false } = props;

  const [typeMethod, setTypeMethod] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [aliasPayment, setAliasPayment] = useState('');
  const [fetchingCard, setFetchingCard] = useState(false);
  const [annualPayment, setAnnualPayment] = useState(false);
  const [letMonths, setLetMonths] = useState(0);
  const [cards_, setCards] = useState(props.cards);
  const [newadded_card, setAddedCard] = useState(props.addedCard);

  const handleSwitch = () => {
    const yy = !annualPayment;
    setAnnualPayment(!annualPayment);
    handleAnnualPayment();
    console.log('annualPayment:', !annualPayment);
    //  const p = yy ? (firstMonthPrice(monthlyPrice) + (monthlyPrice * (9))) : firstMonthPrice(monthlyPrice);
    //  setProratedPrice(p)
    //  setTotalPrice(yy ? (monthlyPrice*10).toFixed(2) : (monthlyPrice).toFixed(2))
  }

  const getCards = async () => {
    try {
      setFetchingCard(true);
      console.log('RECUPERANDO TARJETAS');

      setTypeMethod(SessionService.StripeDefaultCard.brand);
      setPaymentMethod(`...${SessionService.StripeDefaultCard.last4}`);
      const aliasPayment = SessionService.StripeDefaultCard.alias === undefined || SessionService.StripeDefaultCard.alias === 'undefined' ? SessionService.StripeDefaultCard.alias.replace('undefined', '') : SessionService.StripeDefaultCard.alias;
      console.log(aliasPayment);
      setAliasPayment(aliasPayment);


    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
    }
    setFetchingCard(false);
  }

  const getSubscriptionPayment = async () => {
    try {
      const result = (await ObexRequestHandler.get('/subscriptions_payment'));
      const { success, data } = result;
      if (success) {
        setLetMonths(getLeftMonthsExpires(data.expires))
        setAnnualPayment(data.annual);
      }
    } catch (error) {
      console.error('ERRRO FETCHING PAYMENT ', error);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    actionSubmit();
  }

  useEffect(() => {
    getSubscriptionPayment();
    getCards();
  }, [])
  useEffect(() => {
    setCards(props.cards);

  }, [props.cards]);
  useEffect(() => {
    setAddedCard(props.addedCard);
  }, [props.addedCard])
  return (

    <Row>
      <Col md="12" className="mx-auto">
        <Form className="obex-form" onSubmit={handleSubmit}>
          <ProductDetailCard spanish={spanish} title={spanish ? 'Servicio seleccionado' : 'Selected package'}
            priceMonthly={amountMonth}
            priceYear={amountYear}
            descrip={nameServiceSelected}
            annualPayment={annualPayment}
            letMonths={annualPayment ? 10 : 1}
            isBlue={isBlue}

          />
          {cloud && (
            <div className="monthlySub">
              <div className="ms-top">
                <span className='titlesPaymentConfirmation'>Monthly or Annual Payment</span>
              </div>
              <div className="ms-bottom">
                <div className="ms-left">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    onChange={handleSwitch}
                    className="obex-switch"
                  />
                  {spanish ? 'Pago Anual' : 'Annual Payment'}
                </div>
                <div className="ms-right">
                     {/* {annualPayment ? parseToCoin(amountYear) : parseToCoin(amountMonth)} */}
                </div>
              </div>
            </div>
          )}


          <ProductMethodPayment title={spanish ? 'Método de Pago' : 'Payment Method'}
            aliasPayment={aliasPayment}
            typeCard={typeMethod}
            descrip={paymentMethod}
            handleCard={props.handleCard}
            isBlue={isBlue}
            cards={cards_}
            addedCard={newadded_card}
            spanish={spanish}
            cloud = {cloud}
          />

          {!cloud && (
            <div className='paymentWrapper' style={{ display: 'flex' }}>
              <div className={isBlue ? 'titlesPaymentConfirmation blue' : 'titlesPaymentConfirmation green'}
                style={{ textAlign: 'start', fontSize: '16px', width: '94%', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '15px' }}>
                {spanish ? 'Pago Anual' : 'Annual Payment'}
              </div>
              <Form className="obex-form" style={{ width: '5%', marginTop: 'auto', marginBottom: 'auto', marginLeft: '0%' }}>
                <Form.Row >
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    onChange={handleSwitch}
                    className="obex-switch"
                  />
                </Form.Row>
              </Form>
            </div>
          )}







          <ProductResumeCard title={spanish ? 'Total precio' : 'Total price'}
            priceMonthly={amountMonth}
            priceYear={amountYear}
            prorated={prorated ? prorated : false}
            annualPayment={annualPayment}
            letMonths={annualPayment ? 10 : 1}
            ProratedPrice={null}
            isBlue={isBlue}
            spanish={spanish}

          />
          <Form.Row className="my-3">
            {(success && success.length > 0) &&
              <Alert variant='success'>
                {success}
              </Alert>
            }
            {/*}
            {(error && error.length > 0) &&
              <Alert variant='danger'>
                {error}
                
              </Alert>
            }*/}
          </Form.Row>
          <Form.Row className="my-3">

            {/*}
          <Col md="4" className="mr-auto">
              <Button onClick={handleGoBack} className="customButton-red">{spanish ? 'Atrás' : 'Go back'}</Button>
            </Col>

*/}

            <Col md="4" className="ml-auto">
              <button disabled={processingPayment} type="submit" className={`${props.isBlue ? 'customButton-blue w-100' : 'customButton-green w-100'}  float-right`}>
                {spanish ? 'Confirma' : 'Confirm'}
                {processingPayment && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                {(success && success.length > 0) && <Fragment>&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} /></Fragment>}
              </button>
            </Col>
          </Form.Row>
        </Form>
      </Col>
    </Row>

  )
}

export default PaymentConfirmationModal;