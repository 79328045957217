import React, { FunctionComponent, useState, useEffect } from 'react';
import { Row, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface Region {
  id: number;
  region: string;
  server: string;
  code: string;
}
interface Developers {
  dev_id: number,
  email: string,
}

type AddOrganizationProps = {
  handleClose: () => void;    // Función para cerrar el Drawer
  updateTable?: () => void;    // Función para actualizar la tabla
  regions?: Region[];
  deployments?: any[];
  show: boolean;
  organization_devs: Developers[];
  opened: any
  
};

const AddOrganizationModal: FunctionComponent<AddOrganizationProps> = ({ handleClose, updateTable, regions, organization_devs, opened, deployments }) => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [location, setLocation] = useState('');
  const [responsible, setResponsible] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [errorNombre, setErrorNombre] = useState(false);
  const [errorTarget, setErrorTarget] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);
  const [errorResponsible, setErrorResponsible] = useState(false);
  const [cloneLogic, setCloneLogin] = useState(true);
  const [deployment, setDeployment] = useState(0);
  const [deployment_id, setDeploymentID] = useState(0)

const handleChangeClone = () => {
  setCloneLogin(!cloneLogic)
}


  // Funciones para manejar la entrada y validación
  const changeCardholder = (e) => {
    const newCardholder = e.target.value;
    setCardholder(newCardholder);
    validateName(newCardholder);
  };

  const changeCardNumber = (e) => {
    const newCardNumber = e.target.value;
    setCardNumber(newCardNumber);
    validateTarget(newCardNumber);
  };

  const changeLocation = (e) => {
    const newLocation = e.target.value;
    setLocation(newLocation);
    validateLocation(newLocation);
  };


  const changeDeployment = (e) => {
    const newDeployment = e.target.value;
    setDeployment(newDeployment);
  };



  const changeResponsible = (e) => {
    const newResponsible = e.target.value;
    setResponsible(newResponsible);
    validateResponsible(newResponsible);
  };

  const validateName = (name) => {
    const nameFormat = /^[a-zA-Z]{1,18}$/;
    setErrorNombre(!name.match(nameFormat));
  };

  const validateTarget = (description) => {
    const targetFormat = /^[a-zA-Z\s]{1,100}$/;
    setErrorTarget(!description.match(targetFormat));
  };

  const validateLocation = (loc) => {
    const locationFormat = /^[a-zA-Z\s]{1,50}$/;
    setErrorLocation(!loc.match(locationFormat));
  };

  const validateResponsible = (res) => {
    const responsibleFormat = /^[a-zA-Z\s]{1,50}$/;
    setErrorResponsible(!res.match(responsibleFormat));
  };

  useEffect(()=>{
    clearForm();
    setCloneLogin(false);
  }, [opened])

  // Habilitar/deshabilitar el botón de envío
  useEffect(() => {
    setSubmitDisabled(
      !(
        cardholder &&
        cardNumber
        /*
        responsible /*&&
        !errorNombre &&
        !errorTarget &&
        !errorLocation &&
        !errorResponsible*/
      ))

     if (cloneLogic){
      if (deployment===0) setSubmitDisabled(true);
     } else {
      if (!location) setSubmitDisabled(true);
     }
    
  }, [cloneLogic, cardholder, cardNumber, location, deployment, responsible, errorNombre, errorTarget, errorLocation, errorResponsible]);

  // Limpiar los campos al cerrar el Drawer
  const clearForm = () => {
    setCardNumber('');
    setCardholder('');
    setLocation('');
    setResponsible('');
    setError('');
    setSuccess('');
    setSubmitDisabled(true);
    setErrorNombre(false);
    setErrorTarget(false);
    setErrorLocation(false);
    setErrorResponsible(false);
    setDeployment(0);
  };

  // Llamar a `clearForm` al cerrar el Drawer
  const handleDrawerClose = () => {
    clearForm();
    handleClose();
  };

  // Función de envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    const result = await ObexRequestHandler.get(
      `/create_organization?organization_name=${cardholder}&organization_description=${cardNumber}&organization_location=${location}&organization_responsible=${responsible}&clone=${cloneLogic}&deployment_id=${deployment}`,
      {}, true
    );
    const { success, data, message } = result;
  
    if (success) {
      setLoading(false);
      setSuccess(data);
      setTimeout(() => {
        setSuccess('');
        if (updateTable) updateTable(); // Llama a `updateTable` solo si está definido
        handleDrawerClose(); // Cierra el Drawer y limpia el formulario
      }, 1000);
    } else {
      setError(message);
      setLoading(false);
    }
  };

  return (
    <div className="add-organization">
      <Row>
        <Col md="12" className="mx-auto">
          <Form onSubmit={handleSubmit}>
            <Form.Row className="mb-3">
              <Form.Label column sm="12">Organization Name</Form.Label>
              <Form.Control
                //isValid={!errorNombre && cardholder.length > 0}
                //isInvalid={errorNombre}
                maxLength={18}
                required
                type="text"
                placeholder="Name"
                value={cardholder}
                onChange={changeCardholder}
              />
            </Form.Row>
            <Form.Row className="mb-3">
              <Form.Label column sm="12">Organization Description</Form.Label>
              <Form.Control
                //isValid={!errorTarget && cardNumber.length > 0}
                //isInvalid={errorTarget}
                required
                type="text"
                maxLength={100}
                placeholder="Description"
                value={cardNumber}
                onChange={changeCardNumber}
              />
            </Form.Row>
            
            <Form.Row className="mb-3">
              <Form.Label column sm="12">Owner</Form.Label>
              

              <Form.Control
                as="select"
                value={responsible}
                onChange={changeResponsible}
              >
                <option value="">Select a responsible developer</option>
                {organization_devs.map((dev) => (
                  <option key={dev.dev_id} value={dev.email}>
                    {dev.email}
                  </option>
                ))}
              </Form.Control>




              <Form.Label style={{ marginTop:'2em' }} column sm="12">Logical Deployment Configuration</Form.Label>
              <Form.Group controlId="disableRateLimiting">
                                      <Form.Check
                                          type="switch"
                                          label="Use shared logical deployment"
                                          name="disableRateLimiting"
                                          checked={cloneLogic}
                                          onChange={handleChangeClone} />
                </Form.Group>
                </Form.Row>
{ cloneLogic &&
                <Form.Row className="mb-3">
              <Form.Label column sm="12">Deployments</Form.Label>
              

              <Form.Control
                as="select"
                value={deployment}
                onChange={changeDeployment}
              >
                <option value="">Select a deployment</option>
                {deployments.map((dep) => (
                  <option key={dep.dev_id} value={dep.id}>
                    {dep.name}
                  </option>
                ))}
              </Form.Control>
              </Form.Row>
              
}
{ !cloneLogic &&
              <Form.Row className="mb-3">
              <Form.Label column sm="12">Deployment Location</Form.Label>
              {/*<Form.Control
                isValid={!errorLocation && location.length > 0}
                isInvalid={errorLocation}
                required
                type="text"
                maxLength={50}
                placeholder="Location"
                value={location}
                onChange={changeLocation}
              />*/}
            <Form.Control
                as="select"
                value={location}
                onChange={changeLocation}
                >
                <option value="">Select a location</option>
                {regions.map((reg) => (
                  <option key={reg.id} value={reg.region}>
                    {reg.region}
                  </option>
                ))}
              </Form.Control>
            </Form.Row>
}
<Form.Row>

</Form.Row>
              {/*}
              <Form.Control
                isValid={!errorResponsible && responsible.length > 0}
                isInvalid={errorResponsible}
                required
                type="text"
                maxLength={50}
                placeholder="Responsible"
                value={responsible}
                onChange={changeResponsible}
              />*/}

            <Form.Row className="my-3">
              <Col md="6">
                <Button
                  disabled={submitDisabled}
                  type="submit"
                  variant='primary'
                  className="w-100"
                >
                  Create {success && <FontAwesomeIcon icon={faCheck} />}
                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                </Button>
              </Col>
            </Form.Row>
          </Form>
          {error && <Alert variant="danger">{error}</Alert>}
        </Col>
      </Row>
    </div>
  );
};

export default AddOrganizationModal;
