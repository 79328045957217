import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Form, Alert, Spinner, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import CustomDeveloperSelect from '../DashboardComponents/CustomDeveloperSelect '
import GenericSelectableList from '../DashboardComponents/CustomSelectSearch';
import SessionService from '../../services/SessionsService';

type Developer = { id: string; email: string; checked: boolean };

type AddTeamProps = {
  organization: number;
  handleClose: () => void;
  developers: Developer[];
  isOpened: any
};

const AddTeamModal = ({ developers, toggleDeveloper }) => (
  <div>
    {developers.length === 0 ? (
      <p>No developers available</p>
    ) : (
      developers.map((dev) => (
        <Form.Check
          key={dev.id}
          type="switch"
          id={`developer-${dev.id}`}
          label={dev.email || "No Email"}
          onChange={() => toggleDeveloper(dev.id)}
        />
      ))
    )}
  </div>
);

const AddTeamForm: FunctionComponent<AddTeamProps> = ({
  organization,
  handleClose,
  developers,
  isOpened
}) => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedDevelopers, setSelectedDevelopers] = useState<string[]>([]);
  const [selectedDevelopers2, setSelectedDevelopers2] = useState<any[]>([]);
  const [localSelectedGA, setSelectedGA] = useState([])
  const [localSelectedGAR, setSelectedGAR] = useState([])
  const [listaRecuperados, setlistaRecuperados] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [ListSelP, addListSelP ] = useState([]);
  const [error, setErr] = useState('');

  const handleAddPermit = (permit) => {
    // Añade un permiso seleccionado
    if (!localSelectedGA.some((p) => p.id === permit.id)) {
      setSelectedGA((prev) => [...prev, permit.id]);
    }
  };
  
  const handleRemovePermit = (permit) => {
    // Elimina un permiso seleccionado
    setSelectedGA((prev) => prev.filter((p) => p.id !== permit.id));
  };

  const toggleDeveloper = (id: string) => {
    setSelectedDevelopers((prev) =>
      prev.includes(id) ? prev.filter((devId) => devId !== id) : [...prev, id]
    );
  };





  
  /*
  
  const getPermits = async (filtro) => {
    try {
  
      //setloadDevs(true);
      setLoading(true);
      setPermissions([]);
  
      const permits = (
        await ObexRequestHandler.get('/iam/get_permits', {}, true)).data || [];
  
      const filter_permits = permits.filter(element =>
        element.permit_code.toLowerCase().includes(filtro.toLowerCase())
      );
      setPermissions(filtro === '' ? permits : filter_permits);
  
      //setProjects(projects);
  
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
  
  
    }
  
    setLoading(false);
  
  }



*/






  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const payload = {
      team_name: cardholder,
      team_description: cardNumber,
      developers: selectedDevelopers2,
      roles: listaRecuperados
    };
    console.log("--Pay-LOAD---")
    console.log(payload)
    console.log("--Pay-LOAD---")

    if (cardholder && cardNumber) {
    
    try {
      const result = await ObexRequestHandler.post(
        `/create_dev_team?organization=${organization}`,
        payload
      );

      console.log('RESULT----------->');
      console.log(result);

      const dev_team_id = result.data.team_id;
      console.log(`RESULT-----------> ${dev_team_id}`);


      console.log(localSelectedGA);
      console.log(localSelectedGAR);


      const payloadPermits = {
        permits: localSelectedGA,
        dev_team_id: dev_team_id,
        organization: SessionService.ORGANIZATION
      }


      const resultPr = await ObexRequestHandler.post(
        '/iam/update_team_permits',
        payloadPermits
      );

      const payloadRoles = {
        roles: localSelectedGAR,
        dev_team_id: dev_team_id,
        organization: SessionService.ORGANIZATION
      }

      const resultRl = await ObexRequestHandler.post(
        '/iam/update_team_roles',
        payloadRoles
      );

      if (result.success) {
        handleClose();
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error creating team:', error);
    } finally {
      setLoading(false);
    }
  } else {
    setErr('Please fill all data.')
  }

  };




  /** */


  const handleSelect = (developer:any) => {
    // Evita duplicados
    if (!selectedDevelopers2.some((d) => d.dev_id === developer.dev_id)) {
        const updatedSelection = [...selectedDevelopers2, developer];
        setSelectedDevelopers2(updatedSelection); // Actualiza la lista local
        
    }
    
};

const handleRemove = (developer:any) => {
    const updatedSelection = selectedDevelopers2.filter((d) => d.dev_id !== developer.dev_id);
    setSelectedDevelopers2(updatedSelection); 
    
};



const getRolePermits = async (role_id) => {
  try {

    //setloadDevs(true);
    setLoading(true);
    setlistaRecuperados([]);

    const permits = (
      await ObexRequestHandler.get(`/iam/get_permits_role?rol_id=${role_id}`, {}, true)).data || [];

    setlistaRecuperados(permits);

    //setProjects(projects);

  } catch (error) {
    console.error('ERROR FETCHING PROJECTS ', error);


  }

  setLoading(false);

}

const getRoles = async () => {
  try {
    setLoading(true);
    //setloadDevs(true);

    setRoles([]);
    const temp_roles = [];

    const roles = (
      await ObexRequestHandler.get('/iam/get_roles', {}, true)).data || [];


    roles.forEach(element => {
      temp_roles.push({ id: element.role_id, name: element.role_name })
    });

    setRoles(temp_roles);

    //setProjects(projects);

  } catch (error) {
    console.error('ERROR FETCHING PROJECTS ', error);


  }
  setLoading(false);
}

const getPermits = async (filtro) => {
  try {

    //setloadDevs(true);
    setLoading(true);
    setPermissions([]);

    const permits = (
      await ObexRequestHandler.get('/iam/get_permits', {}, true)).data || [];

    const filter_permits = permits.filter(element =>
      element.permit_code.toLowerCase().includes(filtro.toLowerCase())
    );
    setPermissions(filtro === '' ? permits : filter_permits);

    //setProjects(projects);

  } catch (error) {
    console.error('ERROR FETCHING PROJECTS ', error);


  }

  setLoading(false);

}


  /** */
  useEffect(() => {
    const initiallySelected = developers.filter((dev) => dev.checked).map((dev) => dev.id);
    setSelectedDevelopers(initiallySelected);
  }, [developers]);

  useEffect(() => { 
    console.log("BORRANDO DATOS");
    setCardNumber('');
    setCardholder('');
    getPermits('');
    getRoles();
    //setSelectedDevelopers([])
    addListSelP([]);
    setlistaRecuperados([]);
    setSelectedDevelopers2([])
    

  }, [isOpened])

  return (
    <Row>
      <Col md="12">
        <Form onSubmit={handleSubmit}>
          <Form.Row className="mb-3">
            <Form.Label column sm="12">
              Team Name
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Name"
              value={cardholder}
              onChange={(e) => setCardholder(e.target.value)}
            />
          </Form.Row>
          <Form.Row className="mb-3">
            <Form.Label column sm="12">
              Team Description
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Description"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </Form.Row>
          <Form.Row className="mb-3">
            <Form.Label column sm="12">
              Select users
            </Form.Label>
            <CustomDeveloperSelect
                  opened={isOpened}
                  selDevelopers={selectedDevelopers2}
                  developers={developers} // Pasa los developers disponibles
                  onAdd={(developer) => {handleSelect(developer);console.log('Added developer:', developer)}} // Callback al añadir
                  onRemove={(developer) => {handleRemove(developer);console.log('Removed developer:', developer)}} // Callback al eliminar
                />
          </Form.Row>

           <Form.Row>

            {/*}
              <Form.Label>Select Permits</Form.Label>
              <GenericSelectableList
                items={permissions} 
                searchKeys={['permit_code', 'description']} // Búsqueda por código o descripción
                onAdd={handleAddPermit} 
                onRemove={handleRemovePermit}
                renderLabel={(permit) => `${permit.permit_code} (${permit.description})`}
                placeholder="Select permissions..."
                multiOption={true} // Permitir selección múltiple
                selectedItems={listaRecuperados}
                setSelectedItems={setlistaRecuperados}
                itemSelected={''}
              />
*/}


              <Form.Label>Select Roles</Form.Label>
              <GenericSelectableList
                items={roles}
                searchKeys={['name']}
                onAdd={(role) => setSelectedGAR((prev) => [...prev, role.id])}
                onRemove={(role) =>
                  setSelectedGAR((prev) => prev.filter((r) => r !== role.id))
                }
                renderLabel={(role) => role.name}
                placeholder="Search roles..."
                multiOption={true} // Selección múltiple
                selectedItems={ListSelP}
                setSelectedItems={addListSelP}
                itemSelected={''}
              />

         
         </Form.Row>
          <Form.Row style={{ marginTop:'2em' }}>
            <Col md='6'>
              <Button type="submit" 
              disabled={!cardholder || !cardNumber || localSelectedGAR.length==0}
              variant="primary" className="w-100">
                Save
                {loading && <Spinner animation="border" size="sm" />}
              </Button>
            </Col>
          </Form.Row>
          { error.length>0 &&
          <Alert variant='danger'>
            {error}
          </Alert>
}
        </Form>
      </Col>
    </Row>
  );
};

export default AddTeamForm;
