import React, { useEffect, useState, useRef } from 'react';
import WebSocketComponent from '../../functionalComponents/DashboardLayout/WebSocket';

import brunix_icon from '../../../assets/img/logos/icono_AI.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCogs, faRedo, faStop, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import SessionService from '../../../services/SessionsService';
import Drawer from '../Drawer';
import { Alert, Button, Col, Form, Row, Spinner, Image } from 'react-bootstrap';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { faSpeakap, faSpeakerDeck } from '@fortawesome/free-brands-svg-icons';
import { useSpeech } from 'react-text-to-speech';
import Speech from 'react-text-to-speech';
import Logo from '../../../assets/img/ui_icons/pngegg_w.png'
import Cookies from 'universal-cookie';
//import say from 'say'

interface Issue {
    type?: string;
    usage?: string;
    title?: string;
    description: string;
    recommended_actions: any[];
    action_button?: {
        text: string;
        link: string;
    };
}

interface VerificationStatus {
    id: number,
    width: string
    category: string;
    status: 'OK' | 'Warning' | 'Critical';
    issue?: Issue;
}

/* Critical mock data */


const mockDataCritical: { verification_status: VerificationStatus[] } = {
    verification_status: [
        {
            id:0,
            width:'260px',
            category: "Storage",
            status: "Warning" as const,
            issue: {
                type: "Storage Capacity Warning!",
                usage: "90%",
                description: "Your storage usage is reaching its limit. Some services may experience slow performance or disruptions if capacity is exceeded",
                recommended_actions: [
                    "Archive or delete unused data",
                    "Monitor API logs",
                    "Expand your storage plan"
                ],
                action_button: {
                    text: "Expand your storage",
                    link: "/expand-storage"
                }
            }
        },
        {
            id:1,
            width:'270px',
            category: "Critical Error: Environment & Proxy Configurations",
            status: "Critical" as const,
            issue: {
                description: "Your system has encountered a critical issue in environment and proxy configurations. This may cause disruptions in API connectivity, authentication failures, or security vulnerabilities.",
                recommended_actions: [
                    "Verify proxy settings in Proxy",
                    "Check environment variables",
                    "Regenerate authentication tokens if needed",
                    "Ensure network/firewall rules allow API traffic"
                ]
            }
        },
        {
            id:2,
            width:'270px',
            category: "Critical Error: Environment & Proxy Configurations",
            status: "Critical" as const,
            issue: {
                description: "Your system has encountered a critical issue in environment and proxy configurations. This may cause disruptions in API connectivity, authentication failures, or security vulnerabilities.",
                recommended_actions: [
                    "Verify proxy settings in Proxy",
                    "Check environment variables",
                    "Regenerate authentication tokens if needed",
                    "Ensure network/firewall rules allow API traffic"
                ]
            }
        },
        {
            id:2,
            width:'270px',
            category: "Critical Error: Environment & Proxy Configurations",
            status: "Critical" as const,
            issue: {
                description: "Your system has encountered a critical issue in environment and proxy configurations. This may cause disruptions in API connectivity, authentication failures, or security vulnerabilities.",
                recommended_actions: [
                    "Verify proxy settings in Proxy",
                    "Check environment variables",
                    "Regenerate authentication tokens if needed",
                    "Ensure network/firewall rules allow API traffic"
                ]
            }
        },
        
    ]
};


/* Warning mock data */
/* const mockData: { verification_status: VerificationStatus[] } = {
    verification_status: [
        {
            id: 0,
            width: '260px',
            category: "Storage",
            status: "Warning" as const,
            issue: {
                type: "Storage Capacity Warning!",
                usage: "85%",
                description: "Your storage is nearing its limit. Services may experience delays if the capacity is exceeded.",
                recommended_actions: [
                    "Delete temporary files",
                    "Optimize database storage",
                    "Upgrade to a larger storage plan"
                ],
                action_button: {
                    text: "Manage Storage",
                    link: "/manage-storage"
                }
            }
        },
        {
            id: 1,
            width: '260px',
            category: "Server Load",
            status: "Warning" as const,
            issue: {
                type: "High CPU Usage",
                usage: "78%",
                description: "Server CPU load is higher than usual, which may cause slow response times.",
                recommended_actions: [
                    "Review background processes",
                    "Optimize API calls",
                    "Scale up server resources"
                ],
                action_button: {
                    text: "Optimize Server",
                    link: "/optimize-server"
                }
            }
        },
        {
            id: 2,
            width: '260px',
            category: "API Response Time",
            status: "Warning" as const,
            issue: {
                type: "Slow API Response",
                usage: "Average 1200ms",
                description: "API response times have increased above the normal threshold.",
                recommended_actions: [
                    "Reduce request payload size",
                    "Implement caching strategies",
                    "Check database query performance"
                ],
                action_button: {
                    text: "Analyze API",
                    link: "/analyze-api"
                }
            }
        }
    ]
}; */

/* No issues mock data */

const mockData: { verification_status: VerificationStatus[] } = {
    verification_status: [
        {
            id: 0,
            width: '260px',
            category: "Storage",
            status: "OK" as const
        },
        {
            id: 1,
            width: '260px',
            category: "Server Load",
            status: "OK" as const
        },
        {
            id: 2,
            width: '260px',
            category: "API Response Time",
            status: "OK" as const
        },
        {
            id: 3,
            width: '260px',
            category: "Security & Performance",
            status: "OK" as const
        }
    ]
};

// Definir un tipo para el estado global
type GlobalStatusType = 'Stable' | 'Warning' | 'Critical';

let MOCK_RECOMMENDATIONS = `Based on the current analysis of your system's performance and configuration, we recommend:

1. Implement automated backup scheduling to prevent data loss
2. Consider upgrading your storage plan within the next 3 months
3. Review and optimize your API endpoints for better performance
4. Set up monitoring alerts for critical system metrics
5. Implement rate limiting for high-traffic endpoints

These recommendations are based on industry best practices and your current usage patterns.`;

// Objeto para almacenar todos los conjuntos de datos de prueba
const mockDataSets = {
    stable: mockData,
    critical: mockDataCritical,
    // Puedes añadir más conjuntos de datos aquí
};

// Función global para cambiar entre conjuntos de datos
declare global {
    interface Window {
        switchMockData: (dataSetName: string) => void;
    }
}

const Ai_Dashboard: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [showVerifying, setShowVerifying] = useState(false);
    const [statusLines, setStatusLines] = useState<string[]>([]);
    const [showStatusComplete, setShowStatusComplete] = useState(true);
    const [showCards, setShowCards] = useState(false);
    const [currentStatusLine, setCurrentStatusLine] = useState('');
    const [currentLineIndex, setCurrentLineIndex] = useState(0);
    const [currentCharIndex, setCurrentCharIndex] = useState(0);
    const [data, setData] = useState(mockData);
    const [globalStatus, setGlobalStatus] = useState<GlobalStatusType>('Stable');
    const [showGlobalStatus, setShowGlobalStatus] = useState(false);
    const [criticalCount, setCriticalCount] = useState(0);
    const [warningCount, setWarningCount] = useState(0);
    const [criticalFilterActive, setCriticalFilterActive] = useState(true);
    const [warningFilterActive, setWarningFilterActive] = useState(true);
    const [restart, setRestart] = useState(false)

    const [projectT, setProjectT] = useState(50);
    const [apisT, setApisT] = useState(50);
    const [RequestsT, setRequestsT] = useState(50);
    const [StorageT, setStorageT] = useState(50);
    const [TeamsT, setTeams] = useState(50);
    const [ProxiesT, setProxiesT] = useState(50);

    const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
    const [selectedVoice, setSelectedVoice] = useState<string>("");

    const [currentSlide, setCurrentSlide] = useState(0);
    const cardsContainerRef = useRef<HTMLDivElement>(null);
    const CARDS_TO_SHOW = 3;

    const [initialized, setInitialized] = useState(false);

    const [icon, setIcon] = useState(faVolumeUp); // El ícono inicial es faCoffee

    // Función para cambiar entre conjuntos de datos de prueba
    const switchMockData = (dataSetName: string) => {
        if (mockDataSets[dataSetName]) {
            // Cambiar los datos
            setData(mockDataSets[dataSetName]);
            console.log(`Switched to ${dataSetName} mock data`);
            
            // Reiniciar el estado para reflejar los nuevos datos
            setShowCards(false);
            setShowVerifying(false);
            setShowStatusComplete(false);
            
            // Usar setTimeout para dar tiempo a que se actualice el estado
            setTimeout(() => {
                // Reiniciar la secuencia
                setRestart(true);
                setShowVerifying(true);
                
                // Mostrar las tarjetas después de un breve retraso
                setTimeout(() => {
                    setShowStatusComplete(true);
                    setShowCards(true);
                }, 2000);
            }, 100);
        } else {
            console.error(`Mock data set "${dataSetName}" not found. Available sets: ${Object.keys(mockDataSets).join(', ')}`);
        }
    };

    // Exponer la función globalmente para poder usarla desde la consola
    useEffect(() => {
        window.switchMockData = switchMockData;
        
        // Mostrar instrucciones en la consola
        console.log('%c AI Dashboard Debug Mode', 'font-weight: bold; font-size: 16px; color: #4DA1FF;');
        console.log('%c Available commands:', 'font-weight: bold;');
        console.log('%c switchMockData("stable") - Switch to stable mock data', 'color: #5E815A;');
        console.log('%c switchMockData("critical") - Switch to critical mock data', 'color: #dc3545;');
        console.log('%c Available data sets:', 'font-weight: bold;');
        console.log(Object.keys(mockDataSets));
        

        getAdvice();

        setGlobalStatus('Stable')
        setShowCards(true);
        //setInitialized(true);

        return () => {
            // Limpiar cuando el componente se desmonte
            delete window.switchMockData;
        };
    }, []);

    // Función para cambiar el ícono
    const handleChangeIcon = () => {
      // Cambiar entre faCoffee y faSmile cuando se hace clic
      setIcon(icon === faVolumeUp ? faStop : faVolumeUp);
    };




    const handleSpeak = () => {

        const utterance = new SpeechSynthesisUtterance(SessionService.Analysis.join(' '));
        const voice = voices.find((v) => v.name === selectedVoice);
        if (voice) utterance.voice = voice;
        //utterance.voice = voices.find(voice => voice.name === 'Daniel (inglés (Reino Unido))');
        window.speechSynthesis.speak(utterance);


        //const utterance = new SpeechSynthesisUtterance(SessionService.Analysis);
        //window.speechSynthesis.speak(utterance);
      };

    const handleChangeProjects = (e) => {
        if (e.target.value >= 0 && e.target.value <= 100) {
            setProjectT(e.target.value);
        }
    }

    const handleChangeAPIs = (e) => {
        if (e.target.value >= 0 && e.target.value <= 100) {
            setApisT(e.target.value);
        }
    }

    const handleChangeRequests = (e) => {
        if (e.target.value >= 0 && e.target.value <= 100) {
            setRequestsT(e.target.value);
        }
    }

    const handleChangeStorage = (e) => {
        if (e.target.value >= 0 && e.target.value <= 100) {
            setStorageT(e.target.value);
        }
    }

    const handleChangeTeams = (e) => {
        if (e.target.value >= 0 && e.target.value <= 100) {
            setTeams(e.target.value);
        }
    }

    const handleChangeProxies = (e) => {
        if (e.target.value >= 0 && e.target.value <= 100) {
            setProxiesT(e.target.value);
        }
    }


    const [error, setError] = useState('')

    const [isAddCertDrawerOpen, setIsAddCertDrawerOpen] =  useState(false)

    const handleRestart = ()=>{
        setShowCards(false);
        setShowGlobalStatus(false)
        setRestart(true);
        getAdvice();
    }

    const getAdvice = async () => {

        try{

            const cookies = new Cookies();
            const userInfo = cookies.get('101Obex');

            const result = (await ObexRequestHandler.get(`/brunix/get_alarms?owner_user_id=${userInfo.user_id}`, {}, false));
        
            const dataReceived = result.data;

            const tt = [];
            let y = 0;
            dataReceived.warning.forEach(element => {
                let rrr = element.recommend.split('.');
                if (element.usage>0) tt.push({
                    id:y++,
                    width:'260px',
                    status: 'Warning' as const,
                    category: `${element.limit}`,
                    issue: {
                        type: `${element.limit}`,
                        usage: `${Number(element.usage.toString()).toFixed(Number(element.usage.toString() == 100 ? 0 : 2))}%`,
                        description:`${element.comment}`,
                        recommended_actions: [
                            //rrr.map(e=>e)
                            rrr.filter(e => e != '').map(e => e)
                        ],
                        action_button: {
                            text: `Expand your ${element.limit}`,
                            link: `${element.limit}`
                        }
                    }

                })
            });

            dataReceived.critical.forEach(element => {
                let rrr = element.recommend.split('.');
                if (element.usage>0) tt.push({
                    id:y++,
                    width:'260px',
                    status: 'Critical' as const,
                    category: `${element.limit}`,
                    issue: {
                        type: `${element.limit}`,
                        usage: `${Number(element.usage.toString()).toFixed(Number(element.usage.toString() == 100 ? 0 : 2))}%`,
                        description:`${element.comment}`,
                        recommended_actions: [
                            //rrr.map(e=>e)
                            rrr.filter(e => e != '').map(e => e)
                        ],
                        action_button: {
                            text: `Expand your ${element.limit}`,
                            link: `${element.limit}`
                        }
                    }

                })
            });

            MOCK_RECOMMENDATIONS = dataReceived.global_recommendation;
            
            setData({ verification_status: tt })

            if (tt.length === 0) setGlobalStatus('Stable');
            setShowCards(true);
            setShowGlobalStatus(true);
            setInitialized(true);
        } catch {
            setInitialized(true);
            setGlobalStatus('Stable')
        }

    }


    const getThresholsd = async () => {
        try {
          // debugger
          const result = (await ObexRequestHandler.get(`/brunix_threshold/${SessionService.loginData.user_id}`)).data;
      

         setProjectT(result[0].data.projects || 50);
         setApisT(result[0].data.apis || 50);
         setRequestsT(result[0].data.requests || 50);
         setStorageT(result[0].data.storage || 50);
         setTeams(result[0].data.teams || 50);
         setProxiesT(result[0].data.proxies || 50);
          
      
      
        } catch {}
      }

      const getWarnings = async () => {
        try {

          const cookies = new Cookies();
          const userInfo = cookies.get('101Obex');
          
          const response = await ObexRequestHandler.get(`/brunix/get_alarms?owner_user_id=${userInfo.user_id}`);
         // setProjects(response.data || []);

        } catch (error) {
          console.error('Error fetching projects:', error);
         // setProjects([]);
        }
      };

    // Determinar el estado global basado en los datos
    useEffect(() => {


    //getWarnings();
   

    const loadVoices = () => {
        const availableVoices = window.speechSynthesis.getVoices();
        setVoices(availableVoices);
        if (availableVoices.length > 0) {
          setSelectedVoice(availableVoices[18].name); 
        }
      };
  
      loadVoices();
      window.speechSynthesis.onvoiceschanged = loadVoices;


        let hasCritical = false;
        let hasWarning = false;
        let criticalItems = 0;
        let warningItems = 0;

        data.verification_status.forEach(item => {
            if (item.status === 'Critical') {
                hasCritical = true;
                criticalItems++;
            } else if (item.status === 'Warning') {
                hasWarning = true;
                warningItems++;
            }
        });

        setCriticalCount(criticalItems);
        setWarningCount(warningItems);

        if (hasCritical && initialized) {
            setGlobalStatus('Critical');
            setShowGlobalStatus(true);
        } else if (hasWarning && initialized) {
            setGlobalStatus('Warning');
            setShowGlobalStatus(true);
        } else if (initialized){
            setGlobalStatus('Stable');
        }


        getThresholsd()
    }, [data, initialized]);

    // Función para filtrar las tarjetas basadas en los filtros activos
    const filteredCards = data.verification_status.filter(item => {
        if (item.status === 'Critical' && criticalFilterActive) return true;
        if (item.status === 'Warning' && warningFilterActive) return true;
        return false;
    });

    // Generar líneas de estado dinámicamente basadas en los datos
    const generateStatusLines = () => {
        // Mapeo de categorías a líneas de verificación
        const categoryMapping: Record<string, string> = {
            'Storage': 'Server and storage connections',
            'API Response Time': 'API and product health status',
            'Server Load': 'Server load and performance',
            'Environment & Proxy Configurations': 'Environment and proxy configurations',
            'Security': 'Security and performance'
        };

        // Crear un mapa para almacenar el estado más crítico por categoría
        const categoryStatus: Record<string, 'OK' | 'Warning' | 'Critical'> = {};
        
        // Llenar el mapa con los estados de los datos
        data.verification_status.forEach(item => {
            const category = item.category.split(':')[0].trim(); // Obtener la categoría principal
            
            // Si la categoría ya existe, mantener el estado más crítico
            if (categoryStatus[category]) {
                if (item.status === 'Critical' || 
                   (item.status === 'Warning' && categoryStatus[category] === 'OK')) {
                    categoryStatus[category] = item.status;
                }
            } else {
                categoryStatus[category] = item.status;
            }
        });
        
        // Crear las líneas de estado
        const lines: string[] = [];
        
        // Añadir líneas para las categorías conocidas
        Object.entries(categoryMapping).forEach(([category, text]) => {
            if (categoryStatus[category]) {
                lines.push(`${text}....................${categoryStatus[category]}`);
            } else {
                // Si no hay datos para esta categoría, asumir que está OK
                lines.push(`${text}....................ok`);
            }
        });
        
        // Añadir líneas para categorías no mapeadas
        Object.entries(categoryStatus).forEach(([category, status]) => {
            if (!Object.keys(categoryMapping).includes(category)) {
                lines.push(`${category}....................${status}`);
            }
        });
        
        return lines;
    };

    // Lista de líneas de estado que se mostrarán
    const statusLinesData = generateStatusLines();

    // Efecto para la secuencia inicial
    useEffect(() => {



        const handleUnload = () => {
            // Lógica a ejecutar cuando el usuario abandona la página
            console.log('User is leaving the page');
          };
      
          window.addEventListener('unload', handleUnload);
      



        const sequence = async () => {
            // Mostrar loader por 1 segundo
            await new Promise(resolve => setTimeout(resolve, 1000));
            setLoading(false);
            
            // Mostrar "Verifying:"
            setShowVerifying(true);
            
            // Iniciar la animación de las líneas de estado
            await new Promise(resolve => setTimeout(resolve, 300));
            setCurrentLineIndex(0);
            setCurrentCharIndex(0);
            if (criticalCount > 0 || warningCount > 0) {
                setShowGlobalStatus(true);
                setShowCards(true);
            }
        };

        sequence();
        return () => {

            window.removeEventListener('unload', handleUnload);
            window.speechSynthesis.cancel();
          };
    }, [criticalCount, warningCount]);

    // Efecto para animar las líneas de estado caracter por caracter
    useEffect(() => {
        /*
        if (currentLineIndex < statusLinesData.length) {
            const currentLine = statusLinesData[currentLineIndex];
            
            if (currentCharIndex <= currentLine.length) {
                // Animación de caracteres
                const timer = setTimeout(() => {
                //    setCurrentStatusLine(currentLine.substring(0, currentCharIndex));
                    setCurrentCharIndex(currentCharIndex + 1);
                }, 15);
                
                return () => clearTimeout(timer);
            } else {
                // Línea completa, pasar a la siguiente
                const timer = setTimeout(() => {
                    setStatusLines([...statusLines, currentStatusLine]);
                //    setCurrentStatusLine('');
                    setCurrentLineIndex(currentLineIndex + 1);
                    setCurrentCharIndex(0);
                }, 100);
                
                return () => clearTimeout(timer);
            }
        } else if (currentLineIndex === statusLinesData.length && !showStatusComplete) {
            // Todas las líneas completadas */
    
            if (SessionService.Analysis === undefined){
                setShowStatusComplete(false);
 /*           const timer = setTimeout(() => {
                setShowStatusComplete(true);
                setShowGlobalStatus(true); // Mostrar el estado global después de completar las líneas
                
                // Mostrar las tarjetas solo si no es Stable
                if (globalStatus !== 'Stable') {
                    setShowCards(true);
                }
                //return () => clearTimeout(timer);
            }, 5000); */
            
        } else {
            if (globalStatus !== 'Stable' && showStatusComplete) {
                //getGlobalStatusClass();
                setShowGlobalStatus(true);
                setShowCards(true);
            }
        }
            
            
        
    }, [currentLineIndex, currentCharIndex, statusLines, showStatusComplete, globalStatus, statusLinesData]);

    // Función para obtener la clase CSS según el estado global
    const getGlobalStatusClass = () => {
        switch (globalStatus) {
            case 'Critical':
                return 'dshb-global-critical';
            case 'Warning':
                return 'dshb-global-warning';
            case 'Stable':
                return 'dshb-global-stable';
            default:
                return '';
        }
    };

    // Función auxiliar para verificar si el estado es estable
    const isStableStatus = (): boolean => {
        return globalStatus === 'Stable';
    };
    
    // Función auxiliar para verificar si el estado no es estable
    const isNotStableStatus = (): boolean => {
        return globalStatus === 'Warning' || globalStatus === 'Critical';
    };

    const handlePrevSlide = () => {
        if (currentSlide > 0) {
            setCurrentSlide(prev => prev - 1);
            if (cardsContainerRef.current) {
                const cardWidth = cardsContainerRef.current.offsetWidth / CARDS_TO_SHOW;
                cardsContainerRef.current.scrollLeft -= cardWidth + 10; // 10 es el gap entre tarjetas
            }
        }
    };

    const elementos = Array.from({ length: 360 }, (_, index) => (
        <div key={index} className="c"></div>
      ));

    const handleNextSlide = () => {
        if (filteredCards && currentSlide < Math.ceil(filteredCards.length / CARDS_TO_SHOW) - 1) {
            setCurrentSlide(prev => prev + 1);
            if (cardsContainerRef.current) {
                const cardWidth = cardsContainerRef.current.offsetWidth / CARDS_TO_SHOW;
                cardsContainerRef.current.scrollLeft += cardWidth + 10; // 10 es el gap entre tarjetas
            }
        }
    };

    return (
        <><div className="dshb-container">
            <div className="dshb-header">
                <div style={{ display: 'flex', width: '100%' }}>

                


                    <div className='d-flex justify-content-between' style={{ width: '95%' }}>

                    { !showGlobalStatus &&
                    <div className="wrap" style={{ marginTop:'1rem', height:'250px' }}>
                            {elementos}
                    </div>
}
                        <div style={{ width: '50%' }}>
                            <h2 style={{ textAlign:'start' }} className="dshb-title">Status, Executive Summary and Predictions</h2>
                        </div>
                       
                        <div style={{ width: '50%', textAlign: 'end' }}>
                            {showGlobalStatus == true ?
                            
                            (
                                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                    {criticalCount > 0 && (
                                        <span 
                                            className={`dshb-global-status dshb-global-critical ${criticalFilterActive ? '' : 'opacity-50'}`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                // Solo permitir desactivar si el otro filtro está activo
                                                if (!criticalFilterActive || warningFilterActive) {

                                                    if (warningCount>0)
                                                    setCriticalFilterActive(!criticalFilterActive);
                                                }
                                            }}
                                        >
                                            Critical ({criticalCount})
                                        </span>
                                    )}
                                    {warningCount > 0 && (
                                        <span 
                                            className={`dshb-global-status dshb-global-warning ${warningFilterActive ? '' : 'opacity-50'}`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                // Solo permitir desactivar si el otro filtro está activo
                                                if (!warningFilterActive || criticalFilterActive) {

                                                    if (criticalCount>0)
                                                    setWarningFilterActive(!warningFilterActive);
                                                }
                                            }}
                                        >
                                            Warning ({warningCount})
                                        </span>
                                    )}
                                    {criticalCount === 0 && warningCount === 0 && (
                                        <span className="dshb-global-status dshb-global-stable">
                                            Stable
                                        </span>
                                    )}
                                </div>
                            ) : (<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                
                            </div>)
                            
                            
                            
                            } </div>
                    </div>
                    <div style={{ width: '12%', textAlign: 'end' }}>
                        <span style={{ marginLeft: '10px', cursor:'pointer' }}><FontAwesomeIcon
                            onClick={() => {
                                SessionService.Analysis = undefined;
                                handleRestart();
                                setShowCards(false);

                                /*const timer = setTimeout(() => {
                                    setShowCards(true);
                                }, 5000)*/


                            } }
                            icon={faRedo} /></span>

                        <span style={{ marginLeft: '10px', cursor:'pointer' }}><FontAwesomeIcon
                            id = 'voice_button'
                            onClick={() => {
                                handleChangeIcon();
                            if (window.speechSynthesis.speaking) {  
                                window.speechSynthesis.cancel();
                               
                                
                            } else {
                                handleSpeak()
                            }
                            } }
                            icon={icon} />
                        </span>

                        <span style={{ marginLeft: '10px', cursor:'pointer' }}>
                            <Image
                            className='invert-white'
                            onClick={() => {
                                setIsAddCertDrawerOpen(true);
                            } }style={{ width:'17px', marginBottom:'3px' }} src={Logo} 
                            fluid/>
                            
                          { /* <FontAwesomeIcon
                            onClick={() => {
                                setIsAddCertDrawerOpen(true);
                            } }
                            icon={faCogs} />*/}</span>


                


                    </div>
                </div>
            </div>

            <div className="dshb-content">
                {globalStatus == 'Stable' && showCards && showGlobalStatus &&(
                    <div className="dshb-cards-container-wrapper">
                        <div className="dshb-carousel-controls">
                            <div className="dshb-cards-container" ref={cardsContainerRef}>
                                <div className="dshb-card-full stable">
                                    <div className="dshb-card-content">
                                        <div className="dshb-card-header">
                                            <div className="dshb-storage-icon"></div>
                                            <div className="dshb-usage-info">
                                                <span className="dshb-usage-value">Stable System</span>
                                            </div>
                                        </div>

                                        <h3 className="dshb-card-title">
                                            All Systems Running Correctly
                                        </h3>

                                        <p className="dshb-card-description">
                                            The system is operating optimally. All services and components are functioning within normal parameters.
                                        </p>

                                        <div className="dshb-prediction-section">
                                            <h4>Predictive Analysis</h4>
                                            <p>{/*Based on current usage patterns, the system is expected to maintain stability for the next 72 hours. To maintain this optimal performance, consider:*/}{MOCK_RECOMMENDATIONS}</p>
                                            <ul>
                                                <li>Continue regular monitoring of system resources</li>
                                                <li>Maintain scheduled updates according to the established calendar</li>
                                                <li>Periodically review logs to detect potential early anomalies</li>
                                                <li>Ensure backups are performed correctly</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                
                {showCards && isNotStableStatus() && (
                    <div className="dshb-cards-container-wrapper">
                        <div className="dshb-carousel-controls">
                            {showCards && isNotStableStatus() && filteredCards.length > CARDS_TO_SHOW && (
                                <button 
                                    className="dshb-carousel-button prev"
                                    onClick={handlePrevSlide}
                                    disabled={currentSlide === 0}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                            )}
                            <div className="dshb-cards-container" ref={cardsContainerRef}>
                                {filteredCards.map((item, index) => (
                                    <div 
                                        key={index} 
                                        className={`${index === 1 ? 'dshb-card': 'dshb-card-1-3' } ${item.status.toLowerCase()}`}
                                        style={filteredCards.length <= CARDS_TO_SHOW ? {} : { flex: `0 0 calc(${100/CARDS_TO_SHOW}% - ${(CARDS_TO_SHOW-1)*10/CARDS_TO_SHOW}px)` }}
                                    >
                                        <div className="dshb-card-content">
                                            {item.issue?.usage && (
                                                <div className="dshb-card-header">
                                                    <div className="dshb-storage-icon"></div>
                                                    <div className="dshb-usage-info">
                                                        <span className="dshb-usage-value">{item.issue.usage}</span>
                                                        <div className="dshb-progress-bar">
                                                            <div className="dshb-progress" style={{ width: item.issue.usage }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <h3 className="dshb-card-title">
                                                {item.issue?.type || item.category}
                                            </h3>

                                            <p className="dshb-card-description">
                                                {item.issue?.description}
                                            </p>

                                            {item.issue?.recommended_actions && (
                                                <>
                                                    <h4 className="dshb-recommended-title">Recommended Actions:</h4>
                                                    <ul className="dshb-recommended-list">
                                                        {item.issue.recommended_actions.map((action) => (
                                                            // eslint-disable-next-line react/jsx-key
                                                            action.map((aa)=><li>{aa}</li>)
                                                            //<li>{action}</li>
                                                            
                                                        ))}
                                                    </ul>
                                                </>
                                            )}

                                            {item.issue?.action_button && (
                                                <button className="dshb-action-btn"
                                                
                                                
                                                onClick={()=>{

                                                if (item.issue.type == 'Projects') {

                                                    let y = document.getElementById("API Projects-button")
                                                    y.click()

                                                }

                                                if (item.issue.type == 'APIs') {
                                                     let y = document.getElementById("Managed APIs-button")
                                                     y.click()
                                                }

                                                if (item.issue.type == 'Storage') {
                                                    let y = document.getElementById("Storage-button")
                                                    y.click()
                                               }

                                               if (item.issue.type == 'Requests') {
                                                let y = document.getElementById("Requests-button")
                                                y.click()
                                           }

                                                }}      
                                                >
                                                    {item.issue.action_button.text}

                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {filteredCards.length < CARDS_TO_SHOW && filteredCards.length > 0 && (
                                    <div className="dshb-recommendations" style={{overflow:'hidden'}}>
                                        <div style={{display:'flex'}}><div style={{marginTop:'-4px'}} className="dshb-reccomendation-icon"></div><h3 style={{fontSize:'1.2rem', marginTop:'-3px'}}>Almost Done</h3></div>
                                        <div className='dshb-card-title' style={{fontWeight:400, marginTop:'0.8em'}}><h3 style={{fontSize:'1rem', fontWeight:300, color:'green'}}>Subtitle</h3></div>
                                        <p style={{marginTop:'-2px', height:'60px', overflow:'hidden', fontSize:'0.8125rem'}} className='dshb-card-description'>The system is operating optimally. All services and components are functioning within normal parameters.</p>
                                        <p>
                                            <div className='dshb-prediction-section' style={{color:'white', fontSize:'0.8125rem', height:'260px', overflow:'scroll'}}>
                                            {MOCK_RECOMMENDATIONS}
                                            </div>
                                            </p>
                                    </div>
                                )}
                            </div>
                            {showCards && isNotStableStatus() && filteredCards.length > CARDS_TO_SHOW && (
                                <button 
                                    className="dshb-carousel-button next"
                                    onClick={handleNextSlide}
                                    disabled={currentSlide >= Math.ceil(filteredCards.length / CARDS_TO_SHOW) - 1}
                                >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            )}
                        </div>
                    </div>
                )}

                <div className="dshb-status-section">
                    {showVerifying && (
                        (false && <div className="dshb-verifying">
                            <p>Brunix analysis:</p>
                        </div>)
                    )}

                    <div className="dshb-status-list">
                        {statusLines.map((line, index) => (
                            <div key={index} className="dshb-status-text">
                                <p>{line}</p>
                            </div>
                        ))}

                        {(currentStatusLine || true) && (
                            <div className="dshb-status-text">
                                <p>{currentStatusLine}</p>
                                <WebSocketComponent restart={restart} prompt={'Please analize my account current limits and my consumptiion to give me a broad view of my account, and the future expectacions, but do not show me the raw data, give me an interpretation'}
                                    setRestart={setRestart}></WebSocketComponent>

                            </div>
                        )}
                    </div>
                </div>

                {false &&
                    <div className="dshb-footer">
                        <img src={brunix_icon} alt="brunix_icon" />
                        <p>Powered by Brunix AI</p>
                    </div>}
            </div>
        </div>
        
        
        <Drawer
            isOpen={isAddCertDrawerOpen}
            toggleSidebar={() => setIsAddCertDrawerOpen(!isAddCertDrawerOpen)}
            name="Status, Executive Summary and Predictions"
            index=""
        >
                <div>
                    {/* <h3>Add a New Certificate</h3> */}
                    <Form
                        onSubmit={async (e) => {
                            e.preventDefault();

                            const payload = {
                                data:
                                    {
                                        projects: Number(projectT),
                                        apis: Number(apisT),
                                        requests: Number(RequestsT),
                                        storage: Number(StorageT),
                                        teams: Number(TeamsT),
                                        proxies: Number(ProxiesT)
                                    },
                                obex_user_id: SessionService.loginData.user_id
                            }

                            console.log(payload);
                            await ObexRequestHandler.post('/brunix_threshold', payload)
                        } }
                    >

            <Form.Row className="mb-3">
              <Form.Label column sm="12">Voices</Form.Label>

            <Form.Control
                as="select"
                value={selectedVoice}
                onChange={(e)=>setSelectedVoice(e.target.value)}
                >
                <option value="">Select a voice</option>
                {voices.map((reg) => (
                  <option key={reg.name} value={reg.name}>
                    {reg.name}
                  </option>
                ))}
              </Form.Control>

                </Form.Row>

                        <Form.Group controlId="devPortal">
                            <Form.Label>Alerts Thresholds</Form.Label>


                            <Form.Group controlId="SelectSP" className="mb-3">

                            </Form.Group>

                                    <Form.Group controlId="name">
                                        <Form.Label>Projects</Form.Label>
                                        <div className="slider-container">
                                          
                                            <div className="slider-wrapper">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    min="0"
                                                    max="100"
                                                    value={projectT}
                                                    onChange={handleChangeProjects}
                                                    placeholder="0-100"
                                                />
                                                <div className="slider-progress" style={{ width: `${projectT}%`, bottom: '0', top: 'auto' }}></div>
                                            </div>
                                    
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="name">
                                        <Form.Label>APIs</Form.Label>
                                        <div className="slider-container">
                                     
                                            <div className="slider-wrapper">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    min="0"
                                                    max="100"
                                                    value={apisT}
                                                    onChange={handleChangeAPIs}
                                                    placeholder="0-100"
                                                />
                                                <div className="slider-progress" style={{ width: `${apisT}%`, bottom: '0', top: 'auto' }}></div>
                                            </div>
                                        
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="name">
                                        <Form.Label>Requests</Form.Label>
                                        <div className="slider-container">
                                           
                                            <div className="slider-wrapper">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    min="0"
                                                    max="100"
                                                    value={RequestsT}
                                                    onChange={handleChangeRequests}
                                                    placeholder="0-100"
                                                />
                                                <div className="slider-progress" style={{ width: `${RequestsT}%`, bottom: '0', top: 'auto' }}></div>
                                            </div>
                                     
                                        </div>
                                    </Form.Group>


                                    <Form.Group controlId="name">
                                        <Form.Label>Storage</Form.Label>
                                        <div className="slider-container">
                                        
                                            <div className="slider-wrapper">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    min="0"
                                                    max="100"
                                                    value={StorageT}
                                                    onChange={handleChangeStorage}
                                                    placeholder="0-100"
                                                />
                                                <div className="slider-progress" style={{ width: `${StorageT}%`, bottom: '0', top: 'auto' }}></div>
                                            </div>
                                        
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="name">
                                        <Form.Label>Teams</Form.Label>
                                        <div className="slider-container">
                                         
                                            <div className="slider-wrapper">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    min="0"
                                                    max="100"
                                                    value={TeamsT}
                                                    onChange={handleChangeTeams}
                                                    placeholder="0-100"
                                                />
                                                <div className="slider-progress" style={{ width: `${TeamsT}%`, bottom: '0', top: 'auto' }}></div>
                                            </div>
                                     
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="name">
                                        <Form.Label>Proxies</Form.Label>
                                        <div className="slider-container">
                                          
                                            <div className="slider-wrapper">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    min="0"
                                                    max="100"
                                                    value={ProxiesT}
                                                    onChange={handleChangeProxies}
                                                    placeholder="0-100"
                                                />
                                                <div className="slider-progress" style={{ width: `${ProxiesT}%`, bottom: '0', top: 'auto' }}></div>
                                            </div>
                                         
                                        </div>
                                    </Form.Group>


                        </Form.Group>






                        <Row>
                            <Col md='6'>
                                <Button
                                    variant="primary"
                                    disabled={false}
                                    className="w-100" type="submit">
                                    Save
                                    {loading && <Spinner animation="border" size="sm" />}
                                </Button>
                            </Col>
                        </Row>
                        {error.length > 0 &&
                            <Alert variant='danger'>
                                {error}
                            </Alert>}

                    </Form>
                </div>
            </Drawer></>



    );
};

export default Ai_Dashboard;
