
import React, { FunctionComponent, useState, useEffect } from 'react';
import { Row, Col, Button, Table, Form, Spinner, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import Drawer from './Drawer';
import AddApiKeyForm from '../../newViews/avapCloud/components/forms/addKey';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import orgIcon from '../../assets/img/ui_icons/credentials.png'
import { keys } from 'd3';
import { useHistory } from 'react-router-dom';
import ConfirmDeletetModal from '../Modals/ConfirmDeleteModal';
import Nodata from './components/Nodata';

type ApiKey = {
  id: string;
  projectname: string;
  obex_project_id: string;
  type: string;
  apikey: string; // Actual API Key
  status: boolean; // Active, Revoked, Expired
  permissions: string;
  keycreatedat: string; // Last operation date
  user: string;
  api_products: string;
};


const AMBIENT_LABELS = {
  System: { name: 'System', color: '#28a745' },        // Verde
  Active: { name: 'Active', color: '#28a745' }, // Amarillo
  Inactive: { name: 'Inactive', color: 'blue' }, // Amarillo

};

const getAmbientLabel = (ambientId: string) => {
  const ambient = AMBIENT_LABELS[ambientId];
  if (!ambient) return null;

  return (
    <span 
      style={{
        backgroundColor: ambient.color,
        color: '#fff',
        padding: '2px 4px',
        borderRadius: '4px',
        fontSize: '12px',
        minWidth:'100px',
        display:'flex',
        width:'65px',
        justifyContent:'center',
        marginLeft:'auto',
        marginRight:'auto',
      }}
    >
      {ambient.name}
    </span>
  );
};


const Keys: FunctionComponent = () => {
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAddKeyDrawerOpen, setIsAddKeyDrawerOpen] = useState(false);
  const history = useHistory()
  const [recovered_tokens, set_recovered_tokens] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const mockKeys: ApiKey[] = [
    {
      id: '1',
      projectname: 'Project A - Key 1',
      obex_project_id: 'Project A',
      apikey: 'abcd1234xyz',
      status: true,
      type:'regular',
      user: '*',
      api_products: '*',
      permissions: 'Read',
      keycreatedat: '2024-11-01',
    },
    {
      id: '2',
      projectname: 'Project B - Key 2',
      obex_project_id: 'Project B',
      apikey: 'wxyz9876abc',
      type:'regular',
      status: true,
      user: '*',
      api_products: '*',
      permissions: 'Admin',
      keycreatedat: '2024-10-15',
    },
    {
      id: '3',
      projectname: 'Product X - Key 3',
      obex_project_id: 'Product X',
      apikey: 'mnop5678def',
      type:'regular',
      status: true,
      user: '*',
      api_products: '*',
      permissions: 'Write',
      keycreatedat: '2024-09-20',
    },
  ];



  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/keys'){
      history.push('/keys')
      SessionService.updating = SessionService.updating -3;
      fetchApiKeys('');
    window.scrollTo(0, 0);
    history.push('/keys')
    }
  
  }, false);




  useEffect(() => {
    fetchApiKeys('');
  }, []);

  const fetchApiKeys = async (filter: string) => {
    setLoading(true);

    const mockTokensRequst2 = await ObexRequestHandler.get(`/cert/get_keys?organization=${SessionService.ORGANIZATION}`) 

      const filteredKeys = mockTokensRequst2.data.filter(
        (key) =>
          key.projectname.toLowerCase().includes(filter.toLowerCase()) ||
          key.obex_project_id.toLowerCase().includes(filter.toLowerCase())
      );

      set_recovered_tokens(filteredKeys);
      
      setApiKeys(filter === '' ? mockTokensRequst2.data : filteredKeys);

      setLoading(false);
      history.push('/keys')
      setInitialized(true);
  };


  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
    filterProjects(e.target.value);
  };


  const filterProjects = async (filtro) => {

    const filter_projects = recovered_tokens.filter(element =>
      element.projectname.toLowerCase().includes(filtro.toLowerCase())
    );
    setApiKeys(filtro === '' ? recovered_tokens : filter_projects);
  
  }




  const [showDelete, setShowDelete] = useState(false);
  const [selDeveloper, setSelDeveloper] = useState({id:'' })
  const [selTeam, setselTeam] = useState({id:0, type:'', name:''});

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  }

  const handleDelete = async (developer) => {
    setShowDelete(true);
    setSelDeveloper(developer);
    setselTeam(developer);

  }



const handleRevokeKey = async (keyId: any) => {
console.log(keyId);
handleShowDelete();
if (keyId.type === 'regular'){
window.confirm('You cannot remove System Key')
} else if (keyId.type === 'testing') {
const payload = {
  organization: SessionService.ORGANIZATION,
  id: keyId.apikey
}
await ObexRequestHandler.post('/cert/del_tester_key', payload);

} else if (keyId.type === 'production') {

const payload = {
  organization: SessionService.ORGANIZATION,
  id: keyId.apikey
}
await ObexRequestHandler.post('/cert/del_production_key', payload);
} else if (keyId.type === 'test' || keyId.type=='') {
const payload = {
  organization: SessionService.ORGANIZATION,
  id: keyId.apikey
}
await ObexRequestHandler.get(`/iam/del_production_key?org_id=${SessionService.ORGANIZATION}&apikey=${keyId.apikey}&obex_project_id=${keyId.obex_project_id}`, {}, false);

}
console.log(keyId);

fetchApiKeys('');
history.push('/keys')

};

  const handleRotateKey = (keyId: string) => {
    if (window.confirm('Are you sure you want to rotate this API key?')) {
      alert(`API Key ${keyId} rotated successfully`);
    }
  };

  const handleCopyKey = async (apiKey: string) => {
     await navigator.clipboard.writeText(apiKey).then(() => {
      alert('API Key copied to clipboard!');
    });
  };

  const handleAddKey = (newKey: ApiKey) => {
    console.log(newKey)
    //setApiKeys((prevKeys) => [...prevKeys, newKey]);

    setIsAddKeyDrawerOpen(false);
    fetchApiKeys('');
      history.push('/keys')
  };

  return (
    <DashLayout sider={DashRouters} active="keys" sublinks={[]}>
      <Row id="topHeader" className="mb-4">
        <Col md="10">
          <div className="cld_wrapperTitle">
          <img style={{ width:'24px' }} src={orgIcon} alt="icon dashboard" />
            <span className="cld_title w-100 margin-y-24px">
            <span style={{ color:'#949498', fontSize:'14px' }}>Credential Manager </span>/ API Keys 
              
            </span>
          </div>

        </Col>
        <Col md="2" className="align-content-center">
          <Button
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right  resBtn"
            onClick={() => setIsAddKeyDrawerOpen(true)}
          >
            Add API Key
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>





      <Row className="mt-4">
        <Col>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <div className="cld_borderWrapper">
              <span className='cld_sectionTitle'>Keys</span>

              <div className="searchContainer">

                <Form.Control
                  id="buscador"
                  required
                  type="text"
                  value={filter}
                  placeholder="Search by alias"
                  onChange={handleFilterChange}
                />

                <Button
                  className="customButton-transparent px-4"
                  onClick={() => fetchApiKeys(filter)}
                >
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </Button>
              </div>
              <Table className='cld_tableWrapper'>
                <thead>
                  <tr className='tableRow'>
                    <th style={{ width: '20%' }} className='textBold'>Alias</th>
                   {/*} <th style={{ width: '10%' }} className='textBold'>Proj</th> */}
                    <th style={{ width: '15%', textAlign:'center' }} className='textBold'>Type</th>
                    <th style={{ width: '15%', textAlign:'center' }} className='textBold'>Status</th>
                    <th style={{ width: '15%', textAlign:'center' }} className='textBold'>Products</th>
                    <th style={{ width: '17%' }} className='textBold'>API Key</th>
                    <th style={{ textAlign: 'end' }} className='textBold'>User</th>
                    <th style={{ textAlign:'center', width:'20%' }} className='textBold'>Created</th>
                    <th style={{ width: '20px', textAlign: 'end' }} className='textBold'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                {(apiKeys.length === 0 && initialized) ? (
                    <tr>
                      <td colSpan={12}>
                        <div className="nodata-wrapper">
                          <Nodata />
                        </div>
                      </td>
                    </tr>
                  ) : (apiKeys.map((key) => (
                    <tr className='tableRow' key={key.id}>
                      <td className='td_centered'>{key.projectname}</td>
                  {/*   <td className='td_centered'>{key.obex_project_id}</td> */}
                      <td style={{ textAlign:'center' }} className='td_centered'>{key.type.replace('regular', 'System') == '' ? ('Dev Application Consumer'): (key.type == 'test' ? ('Marketplace Test'): (key.type.replace('regular', 'System')))}  </td>
                      <td style={{ textAlign:'center' }} className='td_centered'>{key.status ? getAmbientLabel('Active'):getAmbientLabel('Inactive')}  </td>
                      <td style={{ textAlign:'center' }} className='td_centered'>{key.api_products || '-'}</td>
                      <td className='td_centered'>
                        <Row style={{ height:'24px', wordBreak:'break-all', overflow:'hidden' }}>
                          <Col xs="8">{key.apikey}</Col>
                          <Col xs="4">
                            <a style={{ cursor: 'pointer' }}
                              onClick={() => handleCopyKey(key.apikey)}
                            ><FontAwesomeIcon icon={faCopy} /></a>
                          </Col>
                        </Row>
                      </td>
                      <td style={{ textAlign: 'center' }} className='td_centered'>{key.user || '-'}</td>
                      <td style={{ textAlign:'center' }} className='td_centered'>{key.keycreatedat.split('T')[0]}</td>
                      <td>
                        <div className="actionsWrapper">

                          <Dropdown className='my-auto text-right' style={{ marginRight: '0px', width:'100%' }}>
                            <Dropdown.Toggle className="" >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            {(key.status !== false && key.type.replace('regular', 'System') != 'System') ?
                            <Dropdown.Menu>
                             {/*} <Dropdown.Item key={1} onClick={() => { handleRotateKey(key.id) }}>Rotate</Dropdown.Item>*/}
                              <Dropdown.Item key={1} onClick={() => handleDelete(key)}>Revoke</Dropdown.Item>
                            </Dropdown.Menu>:
                             <Dropdown.Menu>
                             {/*} <Dropdown.Item key={1} onClick={() => { handleRotateKey(key.id) }}>Rotate</Dropdown.Item>*/}
                              <Dropdown.Item style={{filter: 'grayscale(0.5) opacity(0.7)'}} key={1} disabled onClick={null}>Revoke</Dropdown.Item>
                            </Dropdown.Menu>
                            
                            }
                          </Dropdown>
                        </div>
                        {/*}
                  <Button
                    className="customButton-blue"
                    onClick={() => handleRotateKey(key.id)}
                  >
                    Rotate
                  </Button>
                  {key.status !== 'Revoked' && (
                    <Button
                      className="customButton-red ml-2"
                      onClick={() => handleRevokeKey(key.id)}
                    >
                      Revoke
                    </Button>
                  )}

                  */}

                      </td>
                    </tr>
                  )))}
                  {/*}
                  {keys.length < 3 &&
                    <div style={{ height:'65px' }}></div>
                  }*/}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>

      <Drawer index='keys'
        isOpen={isAddKeyDrawerOpen}
        toggleSidebar={() => setIsAddKeyDrawerOpen(false)}
        name="Add API Key"
      >
        <AddApiKeyForm
        opened={isAddKeyDrawerOpen}
        onSubmit={handleAddKey}
        //onCancel={() => setIsAddKeyDrawerOpen(false)}
        />
      </Drawer>



      <ConfirmDeletetModal 
          handleSubmit={()=>handleRevokeKey(selDeveloper)} 
          showModal={showDelete}
          onCloseModal={handleShowDelete}
          loading={false}
          error={''}
          success={''}
          name_to_check={'DELETE'}
          title_to_show={`Confirm delete team ${selTeam.name}`}
        />

        
    </DashLayout>
  );
};

export default Keys;
