import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, Form, Dropdown, Spinner } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import Drawer from "./Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSearch } from "@fortawesome/free-solid-svg-icons";
import gatewayIcon from '../../assets/img/ui_icons/cld_org-s.svg';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";
import { useHistory } from "react-router-dom";

const TABLES = [
  'gateway_ambients'
];

const GatewayGroups = () => {
  const [loading, setLoading] = useState(false);
  const [groupsData, setGroupsData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupDescription, setNewGroupDescription] = useState('');
  const [selectedGroupElements, setSelectedGroupElements] = useState([]);
  const [expandedGroupId, setExpandedGroupId] = useState(null);
  const [gateways, setGateways] = useState([]);
  const [selectedGateway, setSelectedGateway] = useState('');
  const history = useHistory();
  const fetchGateways = async () => {
    try {
      const response = await ObexRequestHandler.get(
        `/gateway/get_gas?organization=${SessionService.ORGANIZATION}`,
        {},
        true
      );
      console.log('🚀 Gateways response:', response);
      setGateways(response.data || []);
    } catch (error) {
      console.error('❌ Error fetching gateways:', error);
    }
  };

  const AMBIENT_LABELS = {
    Active: { name: 'Active', color: '#28a745' },        // Verde
    Inactive: { name: 'Inactive', color: 'red' }, // Amarillo
    //3: { name: 'Live', color: '#28a745' }, // Amarillo
  
  };
  
  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;
  
    return (
      <span 
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth:'100px',
          display:'flex',
          width:'65px',
          justifyContent:'center',
          marginLeft:'auto',
          marginRight:'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };

  const fetchGroups = async () => {
    setLoading(true);
    try {
      const route = `/groups_org?org_id=${SessionService.ORGANIZATION}`;
      console.log('🚀 Requesting route:', route);
      
      const response = await ObexRequestHandler.get(
        route, 
        {}, 
        true
      );
      console.log('📦 Response data:', response.data);
      setGroupsData(response.data);
    } catch (error) {
      console.error('❌ Error al obtener grupos:', error);
    }
    setLoading(false);
  };

  const fetchGroupElementsFromAllTables = async (groupId) => {
    try {
      const elementsPromises = TABLES.map(table => 
        ObexRequestHandler.get(
          `/group_list?group_id=${groupId}&table=${table}`,
          {},
          true
        )
      );
      
      const responses = await Promise.all(elementsPromises);
      const allElements = responses.reduce((acc: any[], response) => {
        const data = response.data as any[];
        return acc.concat(data);
      }, [] as any[]);
      
      return allElements;
    } catch (error) {
      console.error('Error fetching elements from tables:', error);
      return [];
    }
  };


  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/advanced_groups'){
      history.push('/advanced_groups')
      SessionService.updating = SessionService.updating -3;




      const loadAllGroupElements = async () => {
        setLoading(true);
        try {
          const response = await ObexRequestHandler.get(
            `/groups_org?org_id=${SessionService.ORGANIZATION}`,
            {},
            true
          );
  
          const groups = (response.data || []) as any[];
          setGroupsData(groups);
          
        
          const elementsPromises = groups.map(group => 
            fetchGroupElementsFromAllTables(group.id)
          );
          
          const elementsResponses = await Promise.all(elementsPromises);
          const allElements: any[] = elementsResponses.reduce((acc: any[], response) => 
            acc.concat(response as any[]), 
            [] as any[]
          );
          setSelectedGroupElements(allElements);
        } catch (error) {
          console.error('Error loading groups and elements:', error);
        }
        setLoading(false);
        history.push('/advanced_groups')
      };
  
      loadAllGroupElements();
      history.push('/advanced_groups')



      
    window.scrollTo(0, 0);
    history.push('/advanced_groups')
    }
  
  }, false);



  useEffect(() => {
    const loadAllGroupElements = async () => {
      setLoading(true);
      try {
        const response = await ObexRequestHandler.get(
          `/groups_org?org_id=${SessionService.ORGANIZATION}`,
          {},
          true
        );

        const groups = (response.data || []) as any[];
        setGroupsData(groups);
        
      
        const elementsPromises = groups.map(group => 
          fetchGroupElementsFromAllTables(group.id)
        );
        
        const elementsResponses = await Promise.all(elementsPromises);
        const allElements: any[] = elementsResponses.reduce((acc: any[], response) => 
          acc.concat(response as any[]), 
          [] as any[]
        );
        setSelectedGroupElements(allElements);
      } catch (error) {
        console.error('Error loading groups and elements:', error);
      }
      setLoading(false);
    };

    loadAllGroupElements();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log('Creando grupo:', newGroupName);
    console.log('Gateways seleccionados:', selectedGroupElements);
    closeDrawer();
  };

  const openDrawer = () => {
    setSelectedGateway('');
    setSelectedGroupElements([]);
    setNewGroupName('');
    setNewGroupDescription('');
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <DashLayout sider={DashRouters} active={'gateway-groups'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8'>
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={gatewayIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px">
                <span style={{ color: '#949498', fontSize: '14px' }}>Gateway </span>/ Advanced Gateway Groups
              </span>
            </div>
          </div>
        </Col>
        <Col md='4' className='align-content-center'>
          <Button 
            style={{ width: '225px' }} 
            className="customButton-black btn px-4 float-right resBtn" 
            onClick={openDrawer}
          >
            Create Gateway Group
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div className="cld_borderWrapper">
          <Table className="cld_tableWrapper">
            <tbody>
              <tr className="headerRow">
                <th className="textBold">Group Information</th>
                <th className="textBold">Group Elements</th>
                <th style={{ textAlign: 'end' }} className="textBold">Actions</th>
              </tr>
              {groupsData.map((group, index) => (
                <tr className="tableRow" key={index}>
                  <td className="td_centered">
                    <strong>{group.name || 'Unnamed Group'}</strong>
                    <br />
                    <span style={{ color: '#666' }}>{group.descripcion || 'No description'}</span>
                  </td>
                  <td style={{padding: '15px' }}>
                    <Table className="table-sm" style={{ margin: 0, color: 'white' }}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Endpoint Route</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedGroupElements.filter(element => element.group_id === group.id).map((element, elementIndex) => (
                          <tr key={elementIndex}>
                            <td>{element.name}</td>
                            <td>
                              <span className={`badge ${element.active ? 'bg-success' : 'bg-secondary'}`}>
                                {element.active ? getAmbientLabel('Active') : getAmbientLabel('Inactive')}
                              </span>
                            </td>
                            <td>{element.endpoint_route}</td>
                          </tr>
                        ))}
                        {(!selectedGroupElements.length || !selectedGroupElements.some(e => e.group_id === group.id)) && (
                          <tr>
                            <td colSpan={3} className="text-center">
                              No elements
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </td>
                  <td>
                    <div className="actionsWrapper">
                      <Dropdown className='my-auto' style={{ textAlign: 'end', width: '100%' }}>
                        <Dropdown.Toggle className="organizations">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      <Drawer
        index="gateway_groups"
        toggleSidebar={closeDrawer}
        isOpen={isDrawerOpen}
        name={'Create New Group'}
        subtitle=''
      >
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter group name"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter group description"
              value={newGroupDescription}
              onChange={(e) => setNewGroupDescription(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Gateway Advanced</Form.Label>
            <Form.Control
              as="select"
              value={selectedGateway}
              onChange={(e) => setSelectedGateway(e.target.value)}
              required
            >
              <option value="">Select a gateway</option>
              {gateways.map((gateway) => (
                <option key={gateway.id} value={gateway.id}>
                  {gateway.alias || gateway.id}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Row>
            <Col md='6'>
              <Button 
              disabled={!newGroupName || !newGroupDescription || !selectedGateway}
              type="submit" variant="primary" className="w-100">
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </DashLayout>
  );
};

export default GatewayGroups;
