import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Form, Button, InputGroup, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams, Link } from 'react-router-dom';

import EmailIcon from '../../assets/img/emailiconlogin.png';
import Logo from '../../assets/img/newAvapCloud/logo_Cloud@2x.png';

// Simulación de "base de datos" (JSON)
import portalData from './mockData.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { check } from 'prettier';
import Cookies from 'universal-cookie';

const CloudLoginForm = () => {
  const { id } = useParams(); // ID dinámico del portal
  const [portal, setPortal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();
  const cookies = new Cookies();
  const [rememberMe, setRememberMe] = useState(false);

  const checkLogin = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const payload = {
        username: email, 
        password: password, 
        dev_portal_id: portal.portal_id
      };
      const login = await ObexRequestHandler.post('/publishing/dev_portal_login', payload);

      if (login.status !== 401) {
        // Guardar credenciales si remember me está activado
        if (rememberMe) {
          cookies.set('savedCredentials', {
            email,
            password
          }, {
            path: `/devportal/${id}`,
            expires: new Date(Date.now() + (30 * 24 * 60 * 60 * 1000)), // 30 días
            sameSite: 'strict'
          });
        } else {
          // Eliminar las credenciales guardadas si existe
          cookies.remove('savedCredentials', { path: `/devportal/${id}` });
        }
        
        // Establecemos las cookies
        cookies.set('portal', JSON.stringify(portal), { 
          path: `/devportal/${id}`, 
          expires: new Date(Date.now() + (10 * 60 * 10000)),
          sameSite: 'strict'
        });
        
        cookies.set('user', JSON.stringify({ 
          type: login.data.data.type == null ? 'app_builder' : login.data.data.type, 
          username: email, 
          user_id: login.data.id, 
          change: login.data.data.change 
        }), { 
          path: `/devportal/${id}`, 
          expires: new Date(Date.now() + (10 * 60 * 1000)),
          sameSite: 'strict'
        });

        // Verificamos que las cookies se hayan establecido
        console.log('Cookie portal:', cookies.get('portal'));
        console.log('Cookie user:', cookies.get('user'));

        // Agregamos un pequeño retraso antes de la redirección
        setTimeout(() => {
          history.push(`/devportal/${id}/catalog`);
        }, 500);
      } else {
        setError('Login failed');
        setTimeout(() => {
          setError('');
        }, 3000);
      }

    } catch(error) {
      console.log(error);
      setError('Please try again.');
    }
  }

  const DevPortalExists = async ()=>{
    try{
      const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_config/${id}`, {}, true);
      const { data } = res;
      
      console.log('=== Portal Configuration ===');
      console.log('Visibility:', data.visibility);
      console.log('Normal Access:', data.normal_access);
      console.log('Portal Data:', data);
      console.log('==========================');
      
      if (data.id!=undefined) {
        setPortal(data)
        
        // Lógica de redirección basada en normal_access y visibility
        if (data.visibility === true && data.normal_access === false) {
          console.log('Redirecting to public catalog...');
          history.push(`/devportal/${id}/public_catalog`);
          return;
        } else if (data.normal_access === true && data.visibility === false) {
          console.log('Redirecting to standard catalog...');
          history.push(`/devportal/${id}/catalog`);
          return;
        } else {
          console.log('Continuing with private portal login flow...');
        }
      }

      console.log('SETEANDO COOKIES')

      cookies.remove('portal', { path: `/devportal/${id}` });
      cookies.remove('user', { path: `/devportal/${id}` });
      console.log(data)
      cookies.set('portal', JSON.stringify({
        id: data.id,
        portal_id: data.portal_id,
        name: data.name,
        logo: data.logo,
        normal_access: data.normal_access,
        visibility: data.visibility,
        theme: {
          backgroundColor: data.theme.backgroundColor,
          primaryColor: data.theme.primaryColor,
          secondaryColor: data.theme.secondaryColor,
          textColor: data.theme.textColor,
          layout: data.theme.layout
        }
      }), { path: `/devportal/${id}`, expires: new Date(Date.now() + (10 * 60 * 1000)) }); 

      if (data.visibility === false) {
        cookies.set('user', JSON.stringify({ username:'guest', user_id:0 }), { path: `/devportal/${id}`, expires: new Date(Date.now() + (10 * 60 * 1000)) }); 
        cookies.set('portal', JSON.stringify(data), { path: `/devportal/${id}`, expires: new Date(Date.now() + (10 * 60 * 1000)) }); 
        history.push(`/devportal/${id}/portal`)
      }

    } catch (error) {
      console.error('Error in DevPortalExists:', error);
    }
  }

  useEffect(() => {
    const initPortal = async () => {
      try {
        const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_config/${id}`, {}, true);
        const { data } = res;
        
        console.log('=== Datos del Portal ===');
        console.log('Portal Data:', data);
        console.log('=====================');
        
        if (!data || data.id === undefined) {
          setPortal('NOT_FOUND');
        } else {
          setPortal(data);
          
          // Guardamos todos los datos del portal en la cookie
          cookies.set('portal', JSON.stringify({
            id: data.id,
            portal_id: data.portal_id,
            name: data.name,
            logo: data.logo,
            normal_access: data.normal_access,
            visibility: data.visibility,
            theme: {
              backgroundColor: data.theme.backgroundColor,
              primaryColor: data.theme.primaryColor,
              secondaryColor: data.theme.secondaryColor,
              textColor: data.theme.textColor,
              layout: data.theme.layout
            }
          }), { 
            path: `/devportal/${id}`, 
            expires: new Date(Date.now() + (10 * 60 * 1000)),
            sameSite: 'strict'
          });

          // Verificamos si venimos desde el botón de login del catálogo público
          const fromPublicCatalog = new URLSearchParams(window.location.search).get('from') === 'public_catalog';

          // Lógica de redirección modificada
          if (!fromPublicCatalog) {
            if (data.visibility === true && data.normal_access === false) {
              console.log('Redirecting to public catalog...');
              history.push(`/devportal/${id}/public_catalog`);
              return;
            } else if (data.normal_access === true) {
              console.log('Redirecting to public catalog for standard portal...');
              history.push(`/devportal/${id}/public_catalog`);
              return;
            }
          }
          console.log('Continuing with login flow...');
        }
      } catch (error) {
        console.log(error);
        setPortal('NOT_FOUND');
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 500); 
      }
    };

    initPortal();
  }, [id]);

  useEffect(() => {
    // Verificar si existen credenciales guardadas
    const savedCredentials = cookies.get('savedCredentials');
    if (savedCredentials) {
      setEmail(savedCredentials.email);
      setPassword(savedCredentials.password);
      setRememberMe(true);
    }
  }, []);

  if (loading) {
    return null;
  }

  if (portal === 'NOT_FOUND') {
    return <div>Portal no encontrado</div>;
  }

  return (
    <div
      className="text-center d-flex flex-column containerLogin"
      style={{ backgroundColor: portal.theme.backgroundColor }}
    >
      <Container className="vh-100 d-flex flex-column vw-100">
        <Row className="pt-5 rowLogin" style={{ minHeight: '100vh' }}> {/* 74vh */}
          <div className="loginForm">
            <Row className="justify-content-center" style={{ marginTop: '7rem' }}>
              <Col>
                {/* Logotipo del portal */}
                <Image  src={`https://ui-avatars.com/api/?name=${portal.name}&background=${portal.theme.primaryColor.replace('#', '')}&color=fff`} fluid width="300px" style={{ marginBottom: '60px' }} />
                <h6
                  className="text-boldLogin"
                  style={{ color: portal.theme.textColor }}
                >
                  Sign in to {portal.name} Dev Portal.
                </h6>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form className="obex-form theForm">
                  {/* Campo Email */}
                  <Form.Group controlId="formBasicEmail">
                    <InputGroup className="mb-3">
                      <Form.Control
                        required
                        type="email"
                        placeholder="Email"
                        className="borderRadiusLogin"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          borderColor: portal.theme.primaryColor,
                          color: portal.theme.textColor
                        }}
                      />
                    </InputGroup>
                  </Form.Group>

                  {/* Campo Password */}
                  <Form.Group controlId="formBasicPassword">
                    <InputGroup className="">
                      <Form.Control
                        required
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        className="borderRadiusLeftLogin"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{
                          borderColor: portal.theme.primaryColor,
                          color: portal.theme.textColor
                        }}
                      />
                      <InputGroup.Append>
                        <Button
                          className="btnNew login-btn-override"
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            background: "white",
                            borderLeft: "0px",
                            borderRight: "none",
                            borderTop: `1px solid ${portal.theme.primaryColor} !important`,
                            borderBottom: `1px solid ${portal.theme.primaryColor} !important`,
                            width: '40px'
                          }}
                        >
                          {showPassword ?
                            <FontAwesomeIcon icon={faEyeSlash} style={{ color: portal.theme.primaryColor }} /> :
                            <FontAwesomeIcon icon={faEye} style={{ color: portal.theme.primaryColor }} />}
                        </Button>
                      </InputGroup.Append>
                      <button
                        type="submit"
                        className="borderRadiusRightLogin btnNew btn-block"
                        onClick={checkLogin} 
                        style={{ 
                          width: '40px', 
                          backgroundColor: "white", 
                          borderLeft: `1px solid ${portal.theme.primaryColor}`,
                          borderTop: `1px solid ${portal.theme.primaryColor}`,
                          borderBottom: `1px solid ${portal.theme.primaryColor}`,
                          borderRight: `1px solid ${portal.theme.primaryColor}`
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill={portal.theme.primaryColor} width="15px" height="15px" viewBox="0 0 448 512">
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 
                          0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 
                          0-32 14.3-32 32s14.3 32 32 32l306.7 
                          0L233.4 393.4c-12.5 12.5-12.5 32.8 
                          0 45.3s32.8 12.5 45.3 0l160-160z"/>
                        </svg>
                      </button>
                    </InputGroup>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
<Row>
{error &&
<Col>
<Alert variant='danger'>{error}</Alert>
</Col>

}

</Row>
            {/* Sección Remember me y links */}
            <Row>
              <Col style={{ color: portal.theme.textColor, fontSize: "14px", marginBottom: "7rem" }}>
                <div className="d-flex align-items-center flex-column mt-4">
                  <div className="pb-2">
                    <div className="containerPri">
                      <div className="checkbox-container1 pr-2">
                        <input 
                          type="checkbox" 
                          checked={rememberMe}
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                      </div>
                      <div>
                        <div
                          style={{ marginTop: "2px", color: portal.theme.textColor }}
                          className="theblack"
                        >
                          Remember me
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="cursor-pointer"
                    style={{ color: portal.theme.primaryColor }}
                  >
                    Forgotten your password?
                  </div>
                  {/*}
                  <a href="#create-id" style={{ color: portal.theme.secondaryColor }}>
                    Create {portal.name} ID
                  </a>*/}
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CloudLoginForm;
