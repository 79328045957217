import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Dropdown, DropdownButton, Table, Alert } from "react-bootstrap";
import GenericSelectableList from '../DashboardComponents/CustomSelectSearch'
import MultiColumnSelectableList from '../DashboardComponents/MultiColumnSelectableList'
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";
import { drag } from "d3";

type GatewayFormProps = {
  id?: string;
  ogCerts?: string[]; //Certs del proxy original en el caso de editar
  basePath: string;
  selectedGA: string[];
  ambients: any;
  useCerts: boolean;
  balance: boolean;
  algorithm: string;
  routes: string[];
  opened: any;
  editing: any;
  onSubmit: (proxy: {
    id?: string;
    basePath: string;
    selectedGA: string[];
    useCerts: boolean;
    balance: boolean;
    algorithm: string;
    routes: string[];
    certs?: string[];
    ambients: any;
  }) => void;
};


const ProxyForm: React.FC<GatewayFormProps> = ({
  id,
  ogCerts,
  basePath = "",
  selectedGA = [],
  ambients = [],
  useCerts = false,
  balance = false,
  algorithm = "",
  routes = [],
  onSubmit,
  opened,
  editing,
}) => {
  const [error, setErr] = useState('')
  const [localBasePath, setBasePath] = useState(basePath);
  const [localSelectedGA, setSelectedGA] = useState<string[]>(selectedGA);
  const [localUseCerts, setUseCerts] = useState(useCerts);
  const [localBalance, setBalance] = useState(balance);
  const [localAlgorithm, setAlgorithm] = useState(algorithm);
  const [localRoutes, setRoutes] = useState<string[]>(routes);
  const [certs, setCerts] = useState<string[]>(() => []);
  const [certsT, setCertsT] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [localSelectedCA, setSelectedCA] = useState([]);
  const [localSelectedA, setSelectedA] = useState(ambients);
  const [GAS, setGas] = useState([]);
  
  const [AMBIENTS, setAMBIENTS] = useState([]);
  const [activeGA, setActiveGA] = useState(true);
  const [activeA, setActiveA] = useState(true);
  const [ogSelectCertificate, setOgSelectedCertificate] = useState({id:'', name:''});

  const apis = [
    { id: 1, name: 'API 1' },
    { id: 2, name: 'API 2' },
    { id: 3, name: 'API 3' },
  ];

  const apisProduction = [
    { id: 101, name: 'Prod API 1' },
    { id: 102, name: 'Prod API 2' },
  ];


  const algorithms = [
    'Least Response Time',
    'Hash of IP',
    'Weighted Round Robin',
    'Consistent Hashing',
    'Random',
    'Geo IP',
  ];

  const getGAs = async () =>{

    const gas = await ObexRequestHandler.get(`/gateway/get_gas?organization=${SessionService.ORGANIZATION}`);

    setGas(gas.data);

    //console.log("^^^^^^^")
    //console.log(gas.data);
    //console.log("^^^--^^^^")

  }

  const getAMBIENTS = async () =>{
    const gas = await ObexRequestHandler.get(`/gateway/get_gateway_ambients?organization=${SessionService.ORGANIZATION}`);
    setAMBIENTS(gas.data);
  }

  const getCerts = async () =>{
    const certs = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`)


    //console.log(certs.data);

//console.log(certs.data.map((ga) => ({ id: ga.id, name: ga.name, region_id: ga.region_id })))
    setCertsT(certs.data);

  };

  useEffect(()=>{

    if (!editing) {
      setSelectedA([])
      setSelectedCA([]);
    }
    
  }, [opened])

  useEffect(() => {
    setErr('');
    setBasePath(basePath);
    setSelectedGA(selectedGA);
    setSelectedA(ambients);
    setUseCerts(useCerts || (certs && certs.length > 0)); // Activar automáticamente si hay certs
    setBalance(balance);
    setAlgorithm(algorithm);
    setRoutes(routes);
    getCerts();
    getGAs();
    getAMBIENTS();

    setCerts(certs || []); // Sincronizar certs

    // console.log('[PROXY] Disable ', !( !activeA|| localBasePath !=basePath || !activeGA || useCerts != localUseCerts || balance != localBalance
    //   || algorithm != localAlgorithm || routes != localRoutes || (localSelectedCA.length >=1 && localUseCerts && ogCerts != localSelectedCA[0].name)
    // ))

    certsT.forEach(cert => {
      if (cert.name == ogCerts) {
        setOgSelectedCertificate({id:cert.id, name: cert.name})
      }
   
  });
    
  }, [basePath, selectedGA, useCerts, balance, algorithm, routes, certs]);


  const handleAddGA = (ga: string) => {
    setErr('')
    if (!localSelectedGA.includes(ga)) {
      setSelectedGA([...localSelectedGA, ga]);
      console.log([...localSelectedGA, ga]);
      validateSubmitGA([...localSelectedGA, ga])
    }
  };

  const handleRemoveGA = (ga: string) => {
    setErr('')
    setSelectedGA(localSelectedGA.filter((item) => item !== ga));
    validateSubmitGA(localSelectedGA.filter((item) => item !== ga))
  };

  const handleAddRoute = () => {
    setErr('')
    setRoutes([...localRoutes, '']);
  };

  const handleUpdateRoute = (index: number, value: string) => {
    setErr('')
    const updatedRoutes = [...localRoutes];
    updatedRoutes[index] = value;
    setRoutes(updatedRoutes);
  };

  const handleRemoveRoute = (index: number) => {
    setErr('')
    setRoutes(localRoutes.filter((_, i) => i !== index));
  };

  const handleGenerateCert = () => {
    setErr('')
    const newCert = `GeneratedCert-${Math.random().toString(36).substr(2, 9)}`;
    setCerts([...certs, newCert]);
  };

  const handleCopyCert = (cert: string) => {
    setErr('')
    navigator.clipboard.writeText(cert).then(() => {
      alert('Certificate copied to clipboard!');
    });
  };


  const handleAddCA = (permit) => {
    setErr('')
    // Añade un permiso seleccionado
    if (!localSelectedCA.some((p) => p.id === permit.id)) {
      setSelectedCA((prev) => [...prev, permit]);
    }
  };

  const handleRemoveCA = (permit) => {
    setErr('')
    // Elimina un permiso seleccionado
    setSelectedCA((prev) => prev.filter((p) => p.id !== permit.id));
  };


  const handleAddA = (permit) => {
    setErr('')
    // Añade un permiso seleccionado
    if (!localSelectedA.some((p) => p.id === permit.id)) {
      validateSubmitAmb([...localSelectedA, permit])
      setSelectedA((prev) => [...prev, permit]);
    }
  };

  const handleRemoveA = (permit) => {
    setErr('')
    // Elimina un permiso seleccionado
    setSelectedA((prev) => prev.filter((p) => p.id !== permit.id));
    validateSubmitAmb(localSelectedA.filter((p) => p.id !== permit.id))
  };

  


  const handleRemoveCert = (index: number) => {
    setErr('')
    setCerts(certs.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    /*
    console.log({
      id,
      basePath: localBasePath,
      selectedGA: localSelectedGA,
      useCerts: localUseCerts,
      balance: localBalance,
      algorithm: localAlgorithm,
      routes: localRoutes,
      certs, // Incluye los certificados
      localSelectedCA
    })
*/

    if (localSelectedA.length==0 ||!localBasePath || localSelectedGA.length==0 || (useCerts && !localSelectedCA) || (balance && !algorithm)){
      console.log(!localBasePath, localSelectedGA.length==0, (useCerts && !localSelectedCA), (balance && !algorithm))
      console.log(localSelectedGA);
      setErr('Please fill all data');
    } else {

    const payload = {
      base_path: localBasePath,
      selected_ga: localSelectedGA,
      use_certs: localUseCerts,
      balance: localBalance,
      algorithm: localAlgorithm,
      selected_ca: localSelectedCA,
      organization: SessionService.ORGANIZATION,
      ambients: localSelectedA
    }

    console.log(id);
    console.log(payload);
    console.log(GAS);
    console.log(certsT);

    const finalGAS = []
    const finalCerts = []

    GAS.forEach((gg)=>{
      if(localSelectedGA.includes(gg.region_name)) finalGAS.push(gg);
    })

    certsT.forEach((cc)=>{
      if(finalCerts.includes(cc.name)) finalGAS.push(cc);
    })

    if (id!=undefined){
      const payload2 = {
        base_path: localBasePath,
        selected_ga: finalGAS,
        use_certs: localUseCerts,
        balance: localBalance,
        algorithm: localAlgorithm,
        selected_ca: localSelectedCA,
        organization: SessionService.ORGANIZATION,
        ambients: localSelectedA,
        id: id
      }

      console.log(payload2)

      await ObexRequestHandler.post('/gateway/update_proxies', payload2)
      onSubmit({
        ambients: localSelectedA,
        id:id,
        basePath: localBasePath,
        selectedGA: finalGAS,
        useCerts: localUseCerts,
        balance: localBalance,
        algorithm: localAlgorithm,
        routes: localRoutes,
        certs: certs, // Incluye los certificados
      });
    } else {

    await ObexRequestHandler.post('/gateway/set_proxies', payload)
    
    onSubmit({
      ambients: localSelectedA,
      id,
      basePath: localBasePath,
      selectedGA: localSelectedGA,
      useCerts: localUseCerts,
      balance: localBalance,
      algorithm: localAlgorithm,
      routes: localRoutes,
      certs, // Incluye los certificados
    });
  }
}
  };

  const validateSubmitGA = (array) => {
    // console.log('[PROXY] Validate GA -------: ', array, selectedGA)
    const array2Sorted = array.slice().sort();
    const submit_active = selectedGA.length === array.length && selectedGA.slice().sort().every(function(value, index) {
      return value === array2Sorted[index].region_name;
  }
    )
    setActiveGA(submit_active);
  };
  
  const validateSubmitAmb = (array) => {
    // console.log('[PROXY] Validate Ambient -------: ', array, ambients)

    const array2Sorted = array.slice().sort();
    const submit_active = ambients.length === array.length && ambients.slice().sort().every(function(value, index) {
      return value.id === array2Sorted[index].id;
  } 
  );
  // console.log('[PROXY] Validate Ambient result ', submit_active)
  setActiveA(submit_active)
  }

  
  // const validateCertificates = (array) => {
  //   console.log('[PROXY] Validate Cert -------: ', array, og)

  //   const array2Sorted = array.slice().sort();
  //   const result = ambients.length === array.length && ambients.slice().sort().every(function(value, index) {
  //     return value.id === array2Sorted[index].id;
  // } 
  // );
  // console.log('[PROXY] Validate Cert result ', result)
  // setSameCert(result)
  // }
  
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="basePath" className="mb-3">
        <Form.Label>Basepath</Form.Label>
        <Form.Control
        required
          type="text"
          placeholder="Enter basepath"
          value={localBasePath}
          onChange={(e) => setBasePath(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="selectGA" className="mb-3">
        <Form.Label>Gateway Advanced List</Form.Label>
        <GenericSelectableList
          items={GAS.map((ga) => ({ id: ga.id, name: ga.alias, region_name: ga.region_name, region_id: ga.region_id }))} // Convierte las cadenas en objetos con `id` y `name`
          searchKeys={['region_name']} // Busca por el nombre
          onAdd={handleAddGA}
          onRemove={handleRemoveGA}
          renderLabel={(item) => item.name ? `${item.region_name}` : `${item}`} // Renderiza directamente el nombre
          placeholder="Select Gateway Advanced"
          multiOption={true} // Permitir selección múltiple
          selectedItems={localSelectedGA}
          setSelectedItems={setSelectedGA}
          itemSelected={''}
        />
      </Form.Group>





      <Form.Group controlId="selectGA" className="mb-3">
        <Form.Label>Environments List</Form.Label>
        <GenericSelectableList
          items={AMBIENTS.map((ga) => ({ id: ga.id, name: ga.alias, region_name: ga.ambient_name, region_id: ga.region_id }))} // Convierte las cadenas en objetos con `id` y `name`
          searchKeys={['name', 'region_name']} // Busca por el nombre
          onAdd={handleAddA}
          onRemove={handleRemoveA}
          renderLabel={(item) => item.name ? `${item.name}` : `${item}`} // Renderiza directamente el nombre
          placeholder="Select Environment."
          multiOption={true} // Permitir selección múltiple
          itemSelected={''}
          selectedItems={localSelectedA}
          //setSelectedItems={setSelectedA}
          
        />
      </Form.Group>

      <Form.Group controlId="balance" className="mb-3">
        <Form.Check
          type="checkbox"
          label="Load Balancer"
          checked={localBalance}
          onChange={(e) => setBalance(e.target.checked)}
        />
        {localBalance && (
          <DropdownButton
            className="dropdownForm"
            title={(localAlgorithm !='0' ? localAlgorithm : 'Algorithm List' )|| 'Algorithm List'}
            onSelect={(algo) => setAlgorithm(algo || '')}
          >
            {algorithms.map((algo, index) => (
              <Dropdown.Item key={index} eventKey={algo}>
                {algo}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </Form.Group>


      <Form.Group controlId="useCerts" className="mb-3">
        <Form.Check
          type="checkbox"
          label="Use Certs"
          checked={localUseCerts}
          onChange={(e) => setUseCerts(e.target.checked)}
        />
        {localUseCerts && (
<>

              <Form.Label>Select Cert</Form.Label>
              <GenericSelectableList
                items={certsT.map((ga) => ({ id: ga.id.toString(), name: ga.name }))}
                searchKeys={['id', 'name']} // Búsqueda por código o descripción
                onAdd={handleAddCA}
                onRemove={handleRemoveCA}
                renderLabel={(permit) => permit.name}
                placeholder="Select certs..."
                multiOption={false} // Permitir selección múltiple
                itemSelected={ogSelectCertificate}
                /*
                selectedItems={permissions}
                setSelectedItems={setPermissions}
*/

              />
</>
        )}
      </Form.Group>

{/*}
      <Form.Group controlId="routes" className="mb-3">
        <Form.Label>Routes</Form.Label>

        <MultiColumnSelectableList
          items={{ left: apis, right: apisProduction }}
          searchKeys={{ left: ['name'], right: ['name'] }}
          renderLabel={(item) => item.name}
          onAdd={(item, column) => console.log(`Added from ${column}:`, item)}
          onRemove={(item) => console.log('Removed:', item)}
        />
      </Form.Group>
*/}
      <Row>
        <Col md='6'>
        {/*  Validaciones diferentes para activar el boton según si es de creación o de edición*/}
          {!editing && <Button 
          disabled={(localSelectedA.length==0 ||!localBasePath || localSelectedGA.length==0 || (useCerts && !localSelectedCA) || (balance && !algorithm))}
          className="w-100" variant="primary" type="submit">
            Save Proxy
          </Button>}
          {editing && <Button 
          disabled={!( !activeA|| localBasePath !=basePath || !activeGA || useCerts != localUseCerts || balance != localBalance
            || algorithm != localAlgorithm || routes != localRoutes || (localSelectedCA.length >=1 && ogCerts != localSelectedCA[0].name)

          )}
        
                className="w-100" variant="primary" type="submit">
            Save Proxy
          </Button>}
        </Col>
      </Row>

      { error.length>0 &&
        <Alert variant="danger">
          {error}
        </Alert>
      }
    </Form>
  );
};

export default ProxyForm;
