import React, { Fragment } from 'react';
import GettingStarted_DefaultAPIProjects_Figure1_1 from '../../assets/img/developerPortal/GettingStarted_DefaultAPIProjects_Figure1.1.png';

const AVAP_API_Projects = () => (
  <Fragment>
     <div className="body-md">
      <h1>Default API Projects</h1>
      
      <h2>API Project</h2>
      <p>
        APIs in AVAP Framework are organized into API Products. To create an API or a set of APIs, an API Project must be created. It is during the publication process through the API Publisher tool that an API is associated with a new or existing API Product.
      </p>

      <p>
        There are two default API Projects with which a user can work for free:
      </p>
      
      <ol>
        <li>API Project for the AVAP Collaborative Network (p2p) deployment.</li>
        <li>API Project for the standalone (local) deployment.</li>
      </ol>

      <p>
        From the moment a user creates their AVAP ID, an API Project for the AVAP Collaborative Network deployment is created as part of the user initialization process. All information related to this API Project can be viewed in the API Project Granted section in AVAP ID, where all projects that a user or developer has access to will appear.
      </p>

      <br />
      <div style={{ textAlign: 'center' }}>
        <img
          src={GettingStarted_DefaultAPIProjects_Figure1_1}
          alt="Figure 1.1 API Project Granted Screen in AVAP ID"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
        <small>*Figure 1.1 API Project Granted Screen in AVAP ID*</small>
      </div>
      <br />

      <p>
        For the standalone API Project corresponding to the local deployment, the API Project is created and initialized when an AVS (AVAP Virtual Server) in standalone mode is first launched. This project will only appear as an available project in AVAP ID when the AVS (AVAP Virtual Server) is running in local mode. When the system does not detect an AVS (AVAP Virtual Server) running on the computer in local mode, the API Project simply will not appear as available.
      </p>

      <p>
        If you wish to work with any of these API Projects, remember that you must have the appropriate developer token installed and active for the deployment with which you wish to work.
      </p>

      <p>
        All information related to your API Projects will automatically appear when you open your IDE, whether it is Microsoft Visual Studio Code or AVAP Dev Studio. For Microsoft Visual Studio Code, remember that AVAP extensions need to be installed.
      </p>

      <p>
        For more information related to AVAP ID or AVAP CLI, visit AVAP Alexandria at (
        <a href="http://alexandria.avapframework.com/" target="_blank" rel="noopener noreferrer">
          alexandria.avapframework.com
        </a>
        ) and connect with the user community at (
        <a href="http://communities.avapframework.com/" target="_blank" rel="noopener noreferrer">
          communities.avapframework.com
        </a>
        ).
      </p>
    </div>
  </Fragment>
);
export default AVAP_API_Projects;
