import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, Form, Spinner, Dropdown, Alert } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import Drawer from "./Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEllipsisV, faSearch } from "@fortawesome/free-solid-svg-icons";
import devIcon from '../../assets/img/ui_icons/cld_publisher-s.svg';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";
import { useHistory } from "react-router-dom";
import Nodata from "./components/Nodata";
import ConfirmDeletetModal from "../Modals/ConfirmDeleteModal";

const Testers = () => {
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [success, setSuccess] = useState(false);

  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [apis, setApis] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedApi, setSelectedApi] = useState(null);
  const [emails, setEmails] = useState(['']);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [selectedApiTesters, setSelectedApiTesters] = useState(null);
  const [editEmails, setEditEmails] = useState(['']);
  const [testersMap, setTestersMap] = useState({});
  const [initialized, setInitialized] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const history = useHistory();

  // Mock data actualizado con más testers por API
  const [testers] = useState([
    { email: 'tester1@example.com', api: 'API 1', status: 'Invited' },
    { email: 'tester2@example.com', api: 'API 1', status: 'Accepted' },
    { email: 'developer1@company.com', api: 'API 1', status: 'Rejected' },
    { email: 'qa1@testing.com', api: 'API 1', status: 'Invited' },
    { email: 'tester3@example.com', api: 'API 2', status: 'Accepted' },
    { email: 'developer2@company.com', api: 'API 2', status: 'Accepted' },
    { email: 'qa2@testing.com', api: 'API 2', status: 'Invited' },
    { email: 'tester4@example.com', api: 'API 3', status: 'Invited' },
    { email: 'developer3@company.com', api: 'API 3', status: 'Rejected' },
    { email: 'qa3@testing.com', api: 'API 3', status: 'Accepted' },
  ]);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const response = await ObexRequestHandler.get(`/projects?organization_id=${SessionService.ORGANIZATION}`, {}, true);
      const projectsInfo = response.data || [];
      setProjects(projectsInfo);
      setSelectedProjectId(projectsInfo[0]?.id);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
    setLoading(false);
    history.push('/api-testers')
  };

  const [updated, setUpdated] = useState(false)

  const AMBIENT_LABELS = {
    Invited: { name: 'Pending', color: '#CE9C06' },        // Verde
    pending: { name: 'Pending', color: '#CE9C06' }, // Amarillo
    accepted: { name: 'Active', color: '#28a745' },
  
  };
  
  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;
  
    return (
      <span 
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth:'100px',
          display:'flex',
          width:'65px',
          justifyContent:'center',
          marginLeft:'auto',
          marginRight:'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };


  const fetchApisByProject = async () => {
    if (!selectedProjectId) return;
    setLoading(true);
    try {
      const response = await ObexRequestHandler.get(`/publishing/get_apis_by_proj?obex_project_id=${selectedProjectId}`, {}, true);
      const response2 = await ObexRequestHandler.get(
        `/publishing/get_products_by_org?org_id=${SessionService.ORGANIZATION}`,
        {}
      );
      const data2 = response2.data || [];
      let data3 = []

      data2.forEach(element => {
        data3.push({api_name: element.api_product_name, endpoint: element.api_product_name, api_product_id: element.api_product_id, api_id:element.api_product_id })
      });
      const data = response.data || [];
      setApis(data3);
    } catch (error) {
      console.error('Error fetching APIs:', error);
    }
    setLoading(false);
  };

  const fetchTestersForApi = async (apiId) => {
    // setLoading(true);

    try {
      const response = await ObexRequestHandler.get(
        `/list_user_invited_hist?table=publisher_testers&id=${apiId}`,
        {},
        true
      );
      console.log(`[TESTER] Testers para API ${apiId}:`, response.data);
      // setLoading(false);

      return response.data || [];
    } catch (error) {
      console.error(`[TESTER] Error fetching testers for API ${apiId}:`, error);
      // setLoading(false);

      return [];
    }

  };


  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/api-testers'){
      history.push('/api-testers')
      setProjects([]);
      SessionService.updating = SessionService.updating -3;
      fetchProjects();
    window.scrollTo(0, 0);
    history.push('/api-testers')
    }
  
  }, false);



  useEffect(() => {
    const loadTestersForAllApis = async () => {
      setLoading(true);

      const testersData = {};
      for (const api of apis) {
        const testers = await fetchTestersForApi(api.api_id);
        testersData[api.api_id] = testers;
      }
      setTestersMap(testersData);
      setLoading(false);

    };

    if (apis.length > 0) {
      loadTestersForAllApis();
    }
  }, [apis]);

  useEffect(() => {
    fetchProjects();

    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
  }, []);

  useEffect(() => {
    if (selectedProjectId) {
      fetchApisByProject();
    }
  }, [selectedProjectId]);

  const handleAddEmail = () => {
    setEmails([...emails, '']);
  };

  const handleEmailChange = (index, value) => {
    setUpdated(value.length>0);
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const handleRemoveEmail = (index) => {
    const newEmails = emails.filter((_, i) => i !== index);
    setEmails(newEmails);
  };

  const handleSubmit = async (e) => {
    setLoadingBtn(true)
    e.preventDefault();
    
    // Validar que haya un API seleccionado y al menos un email
    if (!selectedApi || emails.every(email => !email.trim())) {
      console.error('Please select an API and enter at least one email');
      setLoadingBtn(false)

      return;
    }

    try {
      // Enviar una invitación por cada email
      const invitePromises = emails
        .filter(email => email.trim()) // Filtrar emails vacíos
        .map(email => {
          const payload = {
            email: email,
            table_dest: "publisher_testers",
            avapapi_id: selectedApi
          };
          return ObexRequestHandler.post('/invite_external_user', payload);
        });

      await Promise.all(invitePromises);
      console.log('Testers invited successfully');
      setSuccess(true)
      setLoadingBtn(false)

      closeDrawer();
      fetchApisByProject(); // Actualizar la lista de testers
    } catch (error) {
      setLoadingBtn(false)

      console.error('Error inviting testers:', error);
    }
  };

  const openDrawer = () => {
    setEmails(['']);
    setSelectedApi(0);
    setIsDrawerOpen(true);
    setUpdated(false);
    
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSuccess(false);
    setLoadingBtn(false);
    setSelectedApi(0);
  };

  const handleEdit = (apiName, apiTesters) => {
    setUpdated(false);
    const selectedApiObj = apis.find(api => api.api_name === apiName);
    if (selectedApiObj) {
      setSelectedApi(selectedApiObj.api_id);
      setSelectedApiTesters({ api: apiName, testers: apiTesters });
      setEmails(['']); // Reiniciar los emails para nuevas invitaciones
      setIsEditDrawerOpen(true);
    }
  };


  const handleShowDelete = () =>{
   // setShowDelete(!showDelete);
   //fetchProjects();
   fetchApisByProject();
   
  }

  const handleDelete = (api) => {

    setShowDelete(true);
    setSelectedApi(api)

  };

  const closeEditDrawer = () => {
    setIsEditDrawerOpen(false);
    setSelectedApiTesters(null);
    setEditEmails(['']);
    history.push('/api-testers')
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    
    // Validar que haya un API seleccionado y al menos un email
    if (!selectedApi || emails.every(email => !email.trim())) {
      console.error('Please select an API and enter at least one email');
      return;
    }

    try {
      // Enviar una invitación por cada email
      const invitePromises = emails
        .filter(email => email.trim()) // Filtrar emails vacíos
        .map(email => {
          const payload = {
            email: email,
            table_dest: "publisher_testers",
            avapapi_id: selectedApi
          };
          return ObexRequestHandler.post('/invite_external_user', payload);
        });

      await Promise.all(invitePromises);
      console.log('Testers invited successfully');

      // Actualizar la lista de testers
      const updatedTesters = await fetchTestersForApi(selectedApi);
      setTestersMap(prev => ({
        ...prev,
        [selectedApi]: updatedTesters
      }));

      // Actualizar la tabla en el drawer
      if (selectedApiTesters) {
        const newTesters = emails
          .filter(email => email.trim())
          .map(email => ({
            email: email,
            status: 'Invited'
          }));

        setSelectedApiTesters({
          ...selectedApiTesters,
          testers: [...selectedApiTesters.testers, ...newTesters]
        });
      }

      // Limpiar el formulario
      setEmails(['']);
    } catch (error) {
      console.error('Error inviting testers:', error);
    }
  };

  const handleRemoveTester = async (email) => {
    if (!selectedApi) {
      console.error('No API selected');
      return;
    }

    try {
      const payload = {
        email: email,
        table_dest: "publisher_testers",
        id: selectedApi
      };

      await ObexRequestHandler.delete('/delete_user_invited', payload);
      console.log('Tester removed successfully');
      
      // Actualizar la lista de testers
      const updatedTesters = await fetchTestersForApi(selectedApi);
      setTestersMap(prev => ({
        ...prev,
        [selectedApi]: updatedTesters
      }));

      // Actualizar también la lista en el drawer
      if (selectedApiTesters) {
        setSelectedApiTesters({
          ...selectedApiTesters,
          testers: selectedApiTesters.testers.filter(t => t.email !== email)
        });
      }
    } catch (error) {
      console.error('Error removing tester:', error);
    }
  };


  const handleRemoveAllTesters = async (email) => {
    if (!selectedApi) {
      console.error('No API selected');
      return;
    }

    try {
      const payload = {
        email: email,
        table_dest: 'publisher_testers',
        id: selectedApi.api_id,
        id_rel: selectedApi.api_product_id
      };

      await ObexRequestHandler.delete('/delete_all_user_invited', payload);
      console.log('Tester removed successfully');
      setShowDelete(false);
      fetchApisByProject()
    } catch (error) {
      console.error('Error removing tester:', error);
    }
  };

  return (
    <DashLayout sider={DashRouters} active={'testers'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8'>
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={devIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px">
                <span style={{ color: '#949498', fontSize: '14px' }}>API Publisher </span>/ Testers Management
              </span>
            </div>
          </div>
        </Col>
        <Col md='4' className='align-content-center'>
          <Button 
            style={{ width: '225px' }} 
            className="customButton-black btn px-4 float-right resBtn" 
            onClick={openDrawer}
          >
            Invite Testers
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>
{loading ? (
        <Spinner animation="border" />
      ) : (
      <div className="cld_borderWrapper">
        <Row>
          {/*}
          <Col md={6}>
            <span className='cld_sectionTitle'>Select a Project</span>
            <Form.Group>
              <Form.Control 
                as="select" 
                onChange={(e) => setSelectedProjectId(e.target.value)} 
                value={selectedProjectId || ''}
              >
                <option value="">-- Select a project --</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.description} (ID: {project.id})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
*/}
          <Col md={6}>
          
            <span className='cld_sectionTitle'>Search</span>
            <div className="searchContainer">
              <Form.Control
                type="text"
                value={searchTerm}
                
                placeholder="Search by API name or tester email"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button style={{}} className="customButton-transparent px-4">
                <FontAwesomeIcon icon={faSearch} size="1x" />
              </Button>
            </div>
          </Col>
        </Row>

        {selectedProjectId && (
          <Table className="cld_tableWrapper">
            <tbody>
              <tr className="headerRow">
                <th style={{ width: '40%' }} className="textBold">API Product Name</th>
                <th style={{ width: '20%' }} className="textBold">Tester Email</th>
                <th style={{textAlign:'center', width: '15%'}} className="textBold">Status</th>
                <th style={{ textAlign: 'end', width: '20px' }} className="textBold">Actions</th>
              </tr>
              {apis.map((api, apiIndex) => {
                const apiTesters = testersMap[api.api_id] || [];
                return apiTesters.length > 0 ? (
                  apiTesters.map((tester, testerIndex) => (
                    <tr key={`${apiIndex}-${testerIndex}`} className="tableRow">
                      {testerIndex === 0 ? (
                        <td className="" rowSpan={apiTesters.length}>
                        <span className="apiNameTable">{api.endpoint}</span>
                        </td>
                      ) : null}
                      <td className="td_centered">{tester.email}</td>
                      <td style={{textAlign:'center'}} className="td_centered">
                        {(tester.status === 'pending' || tester.status === 'Invited') ? (
                          getAmbientLabel(tester.status)
                        ) : (
                          getAmbientLabel(tester.status)
                        )}
                      </td>
                      <td>
                        {testerIndex === 0 ? (
                          <div className="actionsWrapper" style={{ height: '100%' }}>
                            <Dropdown className='my-auto' style={{ textAlign: 'end', width: '100%' }}>
                              <Dropdown.Toggle className="organizations">
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleEdit(api.api_name, apiTesters)}>Edit</Dropdown.Item>
                                {apiTesters.length > 0 &&  <Dropdown.Item onClick={() => handleDelete(api)}>Delete</Dropdown.Item>}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={apiIndex} className="tableRow">
                    <td className="">{api.api_name}</td>
                    <td className="" colSpan={2}>No testers invited</td>
                    <td>
                      <div className="actionsWrapper">
                        <Dropdown className='my-auto' style={{ textAlign: 'end', width: '100%' }}>
                          <Dropdown.Toggle className="organizations">
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleEdit(api.api_name, [])}>Edit</Dropdown.Item>
                            {apiTesters.length > 0 && <Dropdown.Item onClick={() => handleDelete(api.api_name)}>Delete</Dropdown.Item>}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>)}

      <Drawer
        index='testers'
        toggleSidebar={closeDrawer}
        isOpen={isDrawerOpen}
        name={'Invite Testers'}
        subtitle=''
      >
        <Form onSubmit={handleSubmit}>


          <Form.Group>
            <Form.Label>Enter email</Form.Label>
            {emails.map((email, index) => (
              <div key={index} className="d-flex align-items-center mb-2">
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                  placeholder="Enter email"
                  required
                />
                {index > 0 && (
                  <span 
                    onClick={() => handleRemoveEmail(index)}
                    style={{ 
                      cursor: 'pointer', 
                      color: '#6c757d',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      padding: '0 10px',
                      marginLeft: '5px'
                    }}
                  >
                    ×
                  </span>
                )}
              </div>
            ))}

<Form.Group>
            <Form.Label>Select API Product</Form.Label>
            <Form.Control
              as="select"
              value={selectedApi}
              onChange={(e) => setSelectedApi(e.target.value)}
              required
            >
              <option value="">Select an API Product</option>
              {apis.map((api, index) => (
                <option key={index} value={api.api_id}>
                  {api.api_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

            <div 
              onClick={handleAddEmail}
              className="text-primary"
              style={{
                cursor: 'pointer',
                marginTop: '8px'
              }}
            >
              + Add another email
            </div>
          </Form.Group>

          <Button 
          disabled={apis.length==0 || !updated || !selectedApi }
          type="submit" variant="primary" className="w-100 mt-3">
            Send Invitations {success && <FontAwesomeIcon icon={faCheck} />} {loadingBtn && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
          </Button>
        </Form>
      </Drawer>

      <Drawer
        index='testers-edit'
        toggleSidebar={closeEditDrawer}
        isOpen={isEditDrawerOpen}
        name={`Edit Testers - ${selectedApiTesters?.api || ''}`}
        subtitle=''
      >
        {selectedApiTesters && (
          <Form onSubmit={handleEditSubmit}>
            <div className="mb-4">
              <Table className="cld_tableWrapper">
                <tbody>
                  {selectedApiTesters.testers.map((tester, index) => (
                    <tr key={index} className="tableRow">
                      <td style={{ width: '50%' }}>{tester.email}</td>
                      <td style={{ width: '35%' }}>
                        {(tester.status === 'pending' || tester.status === 'Invited') ? (
                          getAmbientLabel(tester.status)
                        ) : (
                          getAmbientLabel(tester.status)
                        )}
                      </td>
                      <td style={{ width: '15%', textAlign: 'right' }}>
                        <span 
                          onClick={() => handleRemoveTester(tester.email)}
                          style={{ 
                            cursor: 'pointer', 
                            color: '#6c757d',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '0 10px'
                          }}
                        >
                          ×
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <Form.Group>
              <Form.Label>Invite New Testers</Form.Label>
              {emails.map((email, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    placeholder="Enter email"
                    required
                  />
                  {index > 0 && (
                    <span 
                      onClick={() => handleRemoveEmail(index)}
                      style={{ 
                        cursor: 'pointer', 
                        color: '#6c757d',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        padding: '0 10px',
                        marginLeft: '5px'
                      }}
                    >
                      ×
                    </span>
                  )}
                </div>
              ))}
              <div 
                onClick={handleAddEmail}
                className="text-primary"
                style={{
                  cursor: 'pointer',
                  marginTop: '8px'
                }}
              >
                + Add another email
              </div>
            </Form.Group>

            <Button 
            disabled={!updated}
            type="submit" variant="primary" className="w-100 mt-3">
              Update
            </Button>
          </Form>
        )}
      </Drawer>


      <ConfirmDeletetModal
        handleSubmit={() => {
          handleRemoveAllTesters(selectedApi ? selectedApi.id : 0)/*handleRevokeCertificate(selDeveloper.id)*/}}
        showModal={showDelete}
        onCloseModal={handleShowDelete}
        loading={false}
        error={''}
        success={''}
        name_to_check={'DELETE'}
        title_to_show={`Confirm delete ${selectedApi ? selectedApi.name : ''} Testers`}
      />
      
    </DashLayout>
  );
};

export default Testers;
