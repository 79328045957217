import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Spinner, Dropdown, Form } from 'react-bootstrap';
import { faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import Drawer from './Drawer';
import GatewayForm from './GatewayProxyForm';
import ProxIcon from '../../assets/img/ui_icons/cld_proxy.svg'
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Organizations from '../../newViews/avapCloud/organizations';
import SessionService from '../../services/SessionsService';
import { useHistory } from 'react-router-dom';
import ConfirmDeletetModal from '../Modals/ConfirmDeleteModal';
import Nodata from './components/Nodata';

// Tipo para un Proxy
type Proxy = {
  id?: string; // Opcional
  basePath: string;
  selectedGA: any;
  useCerts: boolean;
  balance: boolean;
  algorithm: string;
  ambients: any;
  certs?: string[]; // Nuevo campo para los certificados
  routes?: string[];
  enabled?: boolean;
};

const ProxyManager = () => {
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editingProxy, setEditingProxy] = useState<Proxy | null>(null);
  const [filteredProxies, setFilteredProxies] = useState<Proxy[]>([]);
  const [filtro, setFiltro] = useState('');
  const [certsT, setCertsT] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const history = useHistory();
  const [proxies, setProxies] = useState<Proxy[]>([]);
  const [allproxies, setAllProxies] = useState<Proxy[]>([]);

  const [limitProxies, setLimitProxies] = useState(0)

  const handleSaveProxy = (newProxy: Proxy) => {
    // console.log('[PROXY] HANDLE SAVE EDIT - ', editingProxy)

    if (editingProxy && editingProxy.id) {
      setProxies(
        proxies.map((proxy) =>
          proxy.id === editingProxy.id
            ? { ...newProxy, id: editingProxy.id }
            : proxy
        )
      );
    } else {
      setProxies([
        ...proxies,
        { ...newProxy, id: `${Math.random().toString(36).substr(2, 9)}` },
      ]);
    }
    // console.log('[PROXY] SAVE EDIT')
    setDrawerOpen(false);
    getProxies();
    getCerts();
    history.push('/proxies')
  };
  
  const AMBIENT_LABELS = {
    Enabled: { name: 'Enabled', color: '#28a745' },        // Verde
    Disabled: { name: 'Disabled', color: 'red' }, // Amarillo
    //3: { name: 'Live', color: '#28a745' }, // Amarillo
  
  };
  
  const getAmbientLabel = (ambientId: string) => {
    const ambient = AMBIENT_LABELS[ambientId];
    if (!ambient) return null;
  
    return (
      <span 
        style={{
          backgroundColor: ambient.color,
          color: '#fff',
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '12px',
          minWidth:'100px',
          display:'flex',
          width:'65px',
          justifyContent:'center',
          marginLeft:'auto',
          marginRight:'auto',
        }}
      >
        {ambient.name}
      </span>
    );
  };
  const handleOpenDrawer = (proxy?: Proxy) => {

    console.log(certsT);
    // console.log('[PROXY] openDrawer isEditing', proxy)
    if (proxy && (proxy.certs != null)) proxy.useCerts = true;
    setEditingProxy(
      proxy || {
        id: undefined,
        basePath: '',
        selectedGA: [],
        useCerts: false,
        balance: false,
        algorithm: '',
        routes: [],
        certs: certsT, 
        ambients: []// Inicializa certs vacío si no se edita
      }
    );
    setDrawerOpen(true);
    history.push('/proxies')
  };



  const [showDelete, setShowDelete] = useState(false);
  const [selDeveloper, setSelDeveloper] = useState({id:'' })
  const [selTeam, setselTeam] = useState({id:0, type:'', name:'', basePath:''});

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  }

  const handleDelete = async (developer) => {
    setShowDelete(true);
    setSelDeveloper(developer);
    setselTeam(developer);

  }
  

  const handleDeleteProxy = async (proxy) => {
    
    handleShowDelete();

      console.log(proxy);
      //setProxies(proxies.filter((proxy) => proxy.id !== id));
      await ObexRequestHandler.get(`/gateway/delete_proxies?id=${proxy.id}`)
    
    getProxies();
    history.push('/proxies')
  };


  const handleDisable = async (proxy) => {
    

      console.log(proxy);
      //setProxies(proxies.filter((proxy) => proxy.id !== id));
      
      await ObexRequestHandler.post(`/gateway/disable_proxie?id=${proxy.id}`)
    
    getProxies();
    history.push('/proxies')
  };


  const handleEnable = async (proxy) => {
    

    console.log(proxy);
    //setProxies(proxies.filter((proxy) => proxy.id !== id));
    
    await ObexRequestHandler.post(`/gateway/enable_proxie?id=${proxy.id}`)
  
  getProxies();
  history.push('/proxies')
};


  const getCerts = async () =>{
    setLoading(true)
    const certs = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`, {}, true)

    setCertsT(certs.data);
    history.push('/proxies')
    setLoading(false)

  };


  const getProxies = async () => {
    setLoading(true)

    try{ 
    
    const proxies_configs = await ObexRequestHandler.get(`/gateway/get_proxies?organization=${SessionService.ORGANIZATION}`, {}, true)

    const proxies = proxies_configs.data.map((prox) => ({ 
      basePath: prox.basepath, 
      id: prox.id, 
      balance: prox.balance, 
      algorithm: prox.ba_algorithm_name,
      selectedGA: prox.regions.map(region => region.name),
      routes:[],
      ambients: prox.ambients,
      certs: prox.certificate_name && prox.certificate_name,
      enabled: prox.enabled
    }));

    // console.log('[PROXY] getProxies', proxies)


    const filtered = proxies.filter((proxy) =>
      proxy.basePath.toLowerCase().includes(filtro.toLowerCase())
    );

    setProxies(proxies);
    setAllProxies(proxies)
    setFilteredProxies(filtered);
    setLoading(false)
    setInitialized(true);
  } catch{
    setInitialized(true);
    setLoading(false);
  }
  };

  

  const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(e.target.value);
    filterProjects(e.target.value)
  };


  const filterProjects = async (filtro) => {

    console.log(allproxies);
    console.log(filtro)

    const filter_projects = allproxies.filter(element =>
      element.basePath.toLowerCase().includes(filtro.toLowerCase())
    );
    //setFilteredProxies(filtro === '' ? proxies : filter_projects);
    setProxies(filtro === '' ? allproxies : filter_projects)
  }
  

  document.addEventListener('updateView', (e) => {
    if (SessionService.updating>0 && location.pathname == '/proxies'){
      history.push('/proxies')
      SessionService.updating = SessionService.updating -3;
      getProxies();
      getCerts();
    window.scrollTo(0, 0);
    history.push('/proxies')
    }
  
  }, false);



  useEffect(() => {
    try{
    SessionService.loginData.limits.forEach(ll =>{
      if (ll.org_id==SessionService.ORGANIZATION){
        setLimitProxies(ll.subscription_type.proxies || 1)
      }
    })
  } catch {}

    getProxies();
    getCerts();

    function manejarUpdateView(event) {
      // Lógica para manejar el evento SessionService.updateView
      console.log('Evento SessionService.updateView capturado');
    }
    // Añadir listener al montar el componente
    document.addEventListener('updateView', manejarUpdateView);

    // Remover listener al desmontar el componente
    return () => {
      document.removeEventListener('updateView', manejarUpdateView);
    };
  }, []);

  return (
    <DashLayout sider={DashRouters} active="proxies" sublinks={[]}>
      <Row id="topHeader">
        <Col md="10">
          <div className="cld_wrapperTitle">
            <img src={ProxIcon} alt="icon dashboard" />
            <span className="cld_title w-100 margin-y-24px">
            <span style={{ color:'#949498', fontSize:'14px' }}>Gateway Manager </span>/ Proxies 
            </span>
          </div>
        </Col>
        <Col md="2" className="align-content-center">
          <Button
            disabled = {proxies.length>=limitProxies}
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right  resBtn"
            onClick={() => handleOpenDrawer()}>
            Add Proxy
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <Row id='table' className='mt-5'>
        <Col>
          <div className='cld_borderWrapper'>
            <span className='cld_sectionTitle'>Proxies</span>

            <div className="searchContainer">
              <Form.Control 
                id='buscador' 
                required 
                type="text" 
                value={filtro} 
                placeholder="Search by Base Path"
                onChange={changeFilter} 
              />
              <Button
                className="customButton-transparent px-4"
                onClick={getProxies}>
                <FontAwesomeIcon icon={faSearch} size="1x" />
              </Button>
            </div>

            {(proxies.length === 0 && initialized) ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                <Nodata />
              </div>
            ) : (
              <Table className="cld_tableWrapper">
                <tbody>
                  <tr className='tableRow'>
                    <th style={{ width: '20%' }} className='textBold'>Base Path</th>
                    <th style={{ textAlign:'center', width: '10%' }} className='textBold'>Status</th>
                    <th style={{ textAlign:'center', width: '15%' }} className='textBold'>Gateways Advanced</th>
                    <th style={{ textAlign:'center', width: '15%' }} className='textBold'>Certificates</th>
                    <th style={{ textAlign:'center', width: '20%' }} className='textBold'>Load Balancer</th>
                    <th style={{ width: '7%' }} className='textBold'>Algorithm</th>
                    <th style={{ width: '5%', textAlign: 'end' }} className='textBold'>Actions</th>
                  </tr>


                  {(proxies.length > 0 ? proxies : proxies).map((proxy) => (
                    proxy.ambients[0].id != null &&
                    <tr className="tableRow" key={proxy.id}>
                      <td className='td_centered'>{proxy.basePath}</td>
                      <td style={{textAlign:'center'}} className='td_centered'>{proxy.enabled ? getAmbientLabel('Enabled') : getAmbientLabel('Disabled')}</td>
                      <td style={{textAlign:'center'}} className='td_centered'>{proxy.selectedGA.join(', ')}</td>
                      <td style={{textAlign:'center'}} className='td_centered'>
                        {proxy.certs && proxy.certs.length > 0
                          ? proxy.certs
                          : 'No certificates'}
                      </td>
                      <td className='td_centered'>{proxy.balance ? getAmbientLabel('Enabled') : getAmbientLabel('Disabled')}</td>
                      <td style={{textAlign:'center'}} className='td_centered'>{proxy.algorithm  === '0' ? '-' : proxy.algorithm|| 'N/A'}</td>
                      <td className="text-right">
                        <div className="actionsWrapper">
                          <Dropdown className="my-auto" style={{ width:'100%', marginRight: '0px' }}>
                            <Dropdown.Toggle className="organizations">
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item key={1} onClick={() => handleOpenDrawer(proxy)}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item key={1} onClick={() => handleDelete(proxy)}>
                                Delete
                              </Dropdown.Item>

                              { proxy.enabled ? 
                                    <Dropdown.Item key={1} onClick={() => handleDisable(proxy)}>
                                      Disable
                                    </Dropdown.Item>
                                    :
                                    <Dropdown.Item key={1} onClick={() => handleEnable(proxy)}>
                                     Enable
                                    </Dropdown.Item>
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </Table>
            )}
          </div>
        </Col>
      </Row>
      <Drawer
      index='proxies'
        isOpen={drawerOpen}
        toggleSidebar={() => {

          setDrawerOpen(false); 
          // getProxies();
          // getCerts();
          history.push('/proxies')

        }}
        name={editingProxy?.id!=undefined ? 'Edit Proxy' : 'Add Proxy'}
      >
        <GatewayForm
          id={editingProxy?.id}
          basePath={editingProxy?.basePath || ''}
          selectedGA={editingProxy?.selectedGA || []}
          useCerts={editingProxy?.useCerts || false}
          balance={editingProxy?.balance || false}
          algorithm={editingProxy?.algorithm || ''}
          routes={editingProxy?.routes || []}
          ogCerts={editingProxy?.certs || certsT } //{editingProxy?.certs || certs} //Manda cert del proxy elegido en caso de editar
          onSubmit={handleSaveProxy}
          opened={drawerOpen}
          editing={editingProxy}
          ambients={editingProxy?.ambients || []}
        />
      </Drawer>

      <ConfirmDeletetModal 
          handleSubmit={()=>handleDeleteProxy(selDeveloper)} 
          showModal={showDelete}
          onCloseModal={handleShowDelete}
          loading={false}
          error={''}
          success={''}
          name_to_check={'DELETE'}
          title_to_show={`Confirm delete proxy ${selTeam.basePath}`}
        />


    </DashLayout>
  );
};

export default ProxyManager;
