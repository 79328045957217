import React, { FunctionComponent, useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import MarkdownEditor from "./components/EasyMDE ";


interface DocumentProps {
    setDocumentContent: (content) => void
    documentationContent: string
    opened: any
    isEditing: any
    disabledSave: any
}

/*

interface DrawerProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  name?: string; // Nueva prop opcional `name`
  subtitle?: string;
  children: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, toggleSidebar, name, children, subtitle }) => {

*/



const DevPortalDocumentation:FunctionComponent<DocumentProps> = ({isEditing, setDocumentContent, documentationContent, opened, disabledSave}) => {
    
    const [docContent, setDocContent] = useState('');

    const handleSave = async () => {
        setDocumentContent(docContent);
        //console.log('Saved content:', documentationContent);
        //backend logica
    };

    useEffect(()=>{
        console.log("BORRANDO", opened, isEditing)
        if (opened && isEditing) {
            setDocContent(docContent)
        } else {
            setDocContent('');
        }
          
    }, [opened])

    return (
        <Form>
            <Form.Group controlId="documentationEditor" className="mb-4">
                <Form.Label>API Documentation</Form.Label>
                <MarkdownEditor
                
                    value={docContent || (isEditing ? documentationContent:'')}
                    onChange={setDocContent}
                    
                />
            </Form.Group>
            <Row>
                  <Col md='6'>
            {/*  Validaciones diferentes para activar el boton según si es de creación o de edición*/}
            {!isEditing && <Button disabled={disabledSave} variant="primary" style={{ width:'100%' }} onClick={handleSave}>
                Save
            </Button>}
            {isEditing && <Button disabled={!(disabledSave || documentationContent != docContent) } variant="primary" style={{ width:'100%' }} onClick={handleSave}>
                Save
            </Button>}
            </Col>
            </Row>
        </Form>
    );
};

export default DevPortalDocumentation;
