import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Modal, Button, Container, Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PaymentConfirmation from './paymentConfirmation';
import PaymentSuccess from './paymentSuccess';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import FetchingSpinner from '../basicComponents/loaderModal';
import { parseToCoin } from '../../services/AmountService';
import SessionService from '../../services/SessionsService';
import AddCreditCard from './addCreditCard';
import info_icon from '../../assets/img/infoicon-2.png';

type AddProjectModalSelProps = {
  shown: boolean,
  possibleProjectsToBuy: number,
  onCloseAction: () => void,
  opened: any,
  grid?: boolean, // controlan los estilos
  cloud?: boolean,
}

const AddProjectModalSel: FunctionComponent<AddProjectModalSelProps> = (props) => {

  const defaultSelectedPlan = { id: 0, monthly_price: 0, requests_number: 0, projects_numbers: 0 } // Seteamos esto para por defecto no tener ninguno marcado
  const { opened, shown, onCloseAction, possibleProjectsToBuy, grid = false, cloud = false, } = props;

  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [projectsPlans, setProjectsPlans] = useState([]);
  const [projectPlanSelected, setSelectedPlan] = useState(defaultSelectedPlan);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentEnable, setPaymentMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [Description, setDescription] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [cards_, setCards] = useState([]);
  const [card_id_stripe, setCardID] = useState('');
  const [newadded_card, setAddedCard] = useState('');

  const clearAll = () => {
    setError('');
    setSuccess('');
    setPaymentSuccess(false);
    setSelectedPlan(defaultSelectedPlan)
    onCloseAction();
    setPaymentMode(false)
  }


  //useEffect(()=>{clearAll();}, [opened])

  const modalClasses = 'addons-dialog themeDialog w-75 obex-dialog' +
    (cloud && grid ? ' cloudModal isGrid' :
      cloud ? ' cloudModal' :
        grid ? ' isGrid' : '');



  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          if (cards_.some(e => e.id == c.id)) {
            //console.log('exists');
          } else {
            setAddedCard(c.id);
          }
        }
      }
      setCards(pmCards);

    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }
  const handleModal = () => {
    setShowModal(!showModal);
    //onCloseAction();
    getCards();

  };


  const handleCard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    if (value == 'addCard') {
      handleModal();
    } else {
      setCardID(value);

    }
    console.log(value);
    e.preventDefault();
    e.stopPropagation();

  }
  const OnSuccesAction = () => {
    // SessionService.renewSession('/cloud_dashboard');
    SessionService.renewSession();

    clearAll();
  }

  const getPlans = async () => {
    try {
      setFetchingPlans(true);
      const plans = (await ObexRequestHandler.get('/extraprojects/plans')).data;
      setProjectsPlans(plans);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS PLANS ', error);
    }
    setFetchingPlans(false);
  }

  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }

  const makePayment = async () => {
    try {
      startPayment();
      const payload = { extraProjectsPlanId: projectPlanSelected.id, card_stripe_id: card_id_stripe };
      const result = await ObexRequestHandler.post('/extraprojects/buy', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentMode(false);

      /*setTimeout(() => {
        onSuccess();
      }, 5000);*/ // TODO MIRAR SI HABILITAR ESTO
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA PROJECTS SLOTS ', error);
      setPaymentSuccess(true);
      setPaymentMode(false);
    }
    setLoading(false);
  }

  const removeSelectedPlan = () => setSelectedPlan(defaultSelectedPlan);

  // TODO MOSTRAR DECIMALES
  const projectsPlansRows = projectsPlans.map(p => (
    <Fragment key={p.id}>
      {(possibleProjectsToBuy >= p.id) &&

        <>
          {!cloud && (
            <Row className={'addon addons-border py-3 mb-4 position-relative' + (projectPlanSelected.id === p.id && ' addons-active')}>
              {projectPlanSelected.id === p.id && <FontAwesomeIcon icon={faCheck} />}
              <Col md="6">
                {/*}
            <Row>
              <Col className="addons-title">
                <span>{p.title}</span>
              </Col>
            </Row> */}
                <Row>
                  <div>
                    <Col>
                      <span style={{ fontWeight: 600, color: '#E7E7ED' }} >{p.description}</span>
                    </Col>
                  </div>
                  <div style={{ marginTop: '5px', marginLeft: '15px' }}>
                    <span style={{ fontSize: '12px', color: '#E7E7ED' }}>{/*<FontAwesomeIcon icon={faInfo}/>*/}
                      <img
                        style={{
                          height: '10px',
                          marginRight: '3px',
                          marginTop: '-2px'
                        }}
                        src={info_icon} alt="" height={"100%"} />
                      Taxes and feed may apply</span>
                  </div>


                </Row>
              </Col>
              <Col md="3" className="price-addons my-auto">
                <span>{`${parseToCoin(p.monthly_price)}/mo*`}</span>
              </Col>
              <Col md="2" className="my-auto">
                {projectPlanSelected.id === p.id ?
                  <button value="5" style={{ minWidth: '150px' }} className="customButton-black-remove w-100" onClick={removeSelectedPlan}>Remove</button> :
                  <button value="5" style={{ minWidth: '150px' }} className="customButton-black w-100" onClick={() => { setSelectedPlan(p); setDescription(p.description); console.log(p) }}>Add</button>
                }
              </Col>
            </Row>
          )}

          {cloud && (
       
              <div className={'addonCard ' + (projectPlanSelected.id === p.id && ' addons-active')}>

                <div className='packageName'>
                  <div className='desc'>{p.description}</div>
                  <div className='price'>{`${parseToCoin(p.monthly_price)}`} <span>/ month</span></div>
                </div>

                <div className="buttons">
                  {projectPlanSelected.id === p.id ?
                    <button value="5" className="customButton-ln-red" onClick={removeSelectedPlan}>Remove</button> :
                    <button value="5" className="customButton-ln-green" onClick={() => setSelectedPlan(p)}>Add</button>
                  }
                </div>

              </div>
          

          )}



        </>


      }
    </Fragment>
  ))


  const selectPlanModalContent = (
    <Container fluid>
      <div className="addonsWrapper">
        {fetchingPlans ? <FetchingSpinner /> : projectsPlansRows}
      </div>
      <Row className="mb-2">
        <Col className="text-start" style={{ display: 'flex' }}>
          {projectPlanSelected.id > 0
            ? <button className="customButton-blue " style={{ marginRight: '-15px', marginLeft: 'auto' }} onClick={() => setPaymentMode(true)}>Continue to Checkout</button>
            : <button disabled className="customButton-blue " style={{ marginRight: '-15px', marginLeft: 'auto' }}>Select an Option</button>
          }
        </Col>
      </Row>

    </Container>
  )

  useEffect(() => {
    getPlans();
    getCards();
  }, [])


  return (
    <>
      <Modal dialogClassName={modalClasses}
        show={shown}
        onHide={clearAll}
        animation={false}
        container={document.getElementById('dashboardWrapper')}>
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="text-start w-100">
            <Row>
              <Col className="addons-title">
                <span >Add projects to your account</span>
              </Col>
            </Row>
            <Row>
              <Col md="10" className=" text-start modal-subtitle">

                {paymentEnable ?
                  <span style={{ color: '#B9B9C1' }} className="text-start d-block">Review your payment and proceed.</span> :
                  <span style={{ color: '#B9B9C1' }} className="text-start d-block">Choose your package and continue to checkout.</span>
                }
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {paymentEnable ?
            <PaymentConfirmation
              actionSubmit={makePayment}
              handleGoBack={() => setPaymentMode(false)}
              handleAnnualPayment={null}
              nameServiceSelected={`Buying ${projectPlanSelected.projects_numbers} slots projects.`}
              amountMonth={projectPlanSelected.monthly_price}
              amountYear={projectPlanSelected.monthly_price}
              processingPayment={loading}
              success={success}
              error={error}
              isBlue={true}
              cards={cards_}
              handleCard={handleCard}
              addedCard={newadded_card}
              spanish={false}
            />
            : (paymentSuccess ? <PaymentSuccess blue={true} onCloseAction={() => { clearAll(); OnSuccesAction() }} paymentDescription={Description} paymentType={''} /> : selectPlanModalContent)
          }
        </Modal.Body>
      </Modal>
      {showModal && <AddCreditCard show={showModal} handleClose={handleModal} isGreen={false} spanish={true} />}
    </>
  );
}

export default AddProjectModalSel;


