import React, { Fragment } from 'react';

import GettingStarted_CreateYourDeveloperToken_Figure1_1 from '../../assets/img/developerPortal/GettingStarted_CreateYourDeveloperToken_Figure1.1.png';
import GettingStarted_CreateYourDeveloperToken_Figure1_2 from '../../assets/img/developerPortal/GettingStarted_CreateYourDeveloperToken_Figure1.2.png';
import GettingStarted_CreateYourDeveloperToken_Figure1_3 from '../../assets/img/developerPortal/GettingStarted_CreateYourDeveloperToken_Figure1.3.png';
import GettingStarted_CreateYourDeveloperToken_Figure1_4 from '../../assets/img/developerPortal/GettingStarted_CreateYourDeveloperToken_Figure1.4.png';

const Developer_Token = () => (
  <Fragment>
         <div className="body-md">
      <h1>Create Your Developer Token</h1>
      
      <h2>Developer Token</h2>
      <p>
        To start creating your first API as part of a project, whether on-premises or working with AVAP Cloud, you need to create your developer token.
      </p>
      <p>
        The developer token is required to work with an API Project, regardless of the mode or service you are using to leverage AVAP infrastructure.
      </p>
      <p>
        The only mode that does not require a developer token is the interpreter mode. A developer can create source code, store it in a .avap file, and execute it in the interpreter without needing a developer token.
      </p>
      <p>
        To obtain your developer token, you need to download the AVAP CLI (Command Line Interface) software. The latest version of this software can be found in the Downloads section of your AVAP ID portal.
      </p>

      <br />
      <div style={{ textAlign: 'center' }}>
        <img
          src={GettingStarted_CreateYourDeveloperToken_Figure1_1}
          alt="Figure 1.1 Downloading AVAP CLI from the Downloads Section of AVAP ID"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
        <small>*Figure 1.1 Downloading AVAP CLI from the Downloads Section of AVAP ID*</small>
      </div>
      <br />

      <h3>Generating the Developer Token</h3>
      <p>
        Generating the developer token is a process performed from the terminal by following these steps:
      </p>
      <ol>
        <li>
          Open a terminal window and type the command <code>avapcli init &lt;deployment&gt;</code>, where <code>&lt;deployment&gt;</code> specifies the deployment from which you want to obtain the token.
          <br />
          For on-premises deployments, <code>&lt;deployment&gt;</code> is determined at the time of installing the first AVS (AVAP Virtual Server) when the ACL is created or in AVAP Cloud Enterprise deployments at the time of initializing the first logical AVS (AVAP Virtual Server).
          For preset deployments, use one of the following parameters:
          <ul>
            <li><code>cloud</code> (to identify the AVAP Cloud Professional or Business deployment)</li>
            <li><code>p2p</code> (to identify the AVAP Collaborative Network deployment)</li>
            <li><code>local</code> (to identify the standalone deployment)</li>
          </ul>
        </li>

        <br />
        <div style={{ textAlign: 'center' }}>
          <img
            src={GettingStarted_CreateYourDeveloperToken_Figure1_2}
            alt="Figure 1.2 Example of executing the avapcli init cloud command"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
          <small>*Figure 1.2 Example of executing the avapcli init cloud command*</small>
        </div>
        <br />

        <li>
          AVAP CLI will launch the identification window in your browser where you should enter your AVAP ID username and password.
        </li>

        <br />
        <div style={{ textAlign: 'center' }}>
          <img
            src={GettingStarted_CreateYourDeveloperToken_Figure1_3}
            alt="Figure 1.3 AVAP ID Identification Screen"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
          <small>*Figure 1.3 AVAP ID Identification Screen*</small>
        </div>
        <br />

        <li>
          Once identified, you will be shown the one-time password which you need to enter in the terminal to complete the process and generate your developer token.
        </li>

        <br />
        <div style={{ textAlign: 'center' }}>
          <img
            src={GettingStarted_CreateYourDeveloperToken_Figure1_4}
            alt="Figure 1.4 Successful completion of developer token generation"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
          <small>*Figure 1.4 Successful completion of developer token generation*</small>
        </div>
        <br />
      </ol>

      <p>
        From this point on, you can, among other things, enter AVAP extensions in Microsoft Visual Studio Code or work with AVAP Dev Studio, and the system will automatically recognize you and provide access to all resources and information you have available.
      </p>

      <p>
        You can find more information related to AVAP ID or AVAP CLI on AVAP Alexandria (
        <a href="http://alexandria.avapframework.com/" target="_blank" rel="noopener noreferrer">
          alexandria.avapframework.com
        </a>
        ) and engage with the user community at AVAP Communities (
        <a href="http://communities.avapframework.com/" target="_blank" rel="noopener noreferrer">
          communities.avapframework.com
        </a>
        ).
      </p>
    </div>
  </Fragment>
);
export default Developer_Token;
