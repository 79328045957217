import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

// Iconos importados (mantenlos como están)
import AVAPIcon from '../../../assets/img/logos/icon_avap.svg';
import AVAPIDIcon from '../../../assets/img/logos/icono_ID.svg';
import AVAPSpecsIcon from '../../../assets/img/logos/icon_specs.svg';
import AVAPPublisherIcon from '../../../assets/img/logos/icono_publisher.svg';
import AVAPMarketplaceIcon from '../../../assets/img/logos/icon_marketplace.svg';
import AVAPCommanderIcon from '../../../assets/img/logos/icon_commander.svg';
import AVAPDeveloperPortalIcon from '../../../assets/img/logos/icono_DS.svg';
import AVAPManagementConsoleIcon from '../../../assets/img/logos/icon_APIControVersion.svg';
import AVAPCollaborativeNetworkIcon from '../../../assets/img/logos/icono_CollaborativeN.svg';

import MeshManager from '../../../assets/img/ui_icons/AVAP_ManagementConsole.svg'
import Blog from '../../../assets/img/ui_icons/Icono_AVAPBlog.svg'
import Alexandria from '../../../assets/img/ui_icons/icono_Alexandria.svg'
import Vision from '../../../assets/img/ui_icons/AVAP_Vision.svg'
import Brunix from '../../../assets/img/ui_icons/icono_AI.svg'

//ui icons
import create from '../../../assets/img/ui_icons/icono-gettingStarter.svg';
import download from '../../../assets/img/ui_icons/icono-gettingStarter_4.svg';
import devToken from '../../../assets/img/ui_icons/icono-gettingStarter_3.svg';
import firstApi from '../../../assets/img/ui_icons/icono-gettingStarter_2.svg';
import devPortal from '../../../assets/img/ui_icons/icono-gettingStarter_1.svg';
import Communities from '../../../assets/img/ui_icons/icono_Communities.svg';

// Configuración de los íconos (mantenlo como está)
const defaultWorkspaceItems = [
    { name: 'AVAP ID', icon: AVAPIDIcon, url: 'https://avapid.com' },
    { name: 'AVAP Mesh Manager', icon: MeshManager, url: 'https://mesh.avapcloud.com' },
    { name: 'Brunix', icon: Brunix, url: '/ai_dashboard' },
    { name: 'AVAP Vision', icon: Vision, url: '' },
    { name: 'AVAP Marketplace', icon: AVAPMarketplaceIcon, url: 'https://marketplace.avapcloud.com' },
    { name: 'AVAP Developer Portal', icon: AVAPDeveloperPortalIcon, url: 'https://developer.avapframework.com' },
    { name: 'AVAP Alexandria', icon: Alexandria, url: 'https://alexandria.avapframework.com' },
    { name: 'AVAP Communities', icon: Communities, url: 'https://communities.avapframework.com' },
    { name: 'AVAP Blog', icon: Blog, url: 'https://blog.avapframework.com' },
];

const WorkspaceMenu = ({ show, handleClose, workspaceItems = defaultWorkspaceItems }) => {
    const menuRef = useRef(null); // Crear la referencia al menú
    const history = useHistory();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleClose();
            }
        };

        if (show) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [show, handleClose]);

    if (!show) {
        return null;
    }

    return (
        <div ref={menuRef} className={`workspace-menu-dropdown ${show ? 'show' : ''}`}>
            <div className="workspace-menu-header">
                <h5>AVAP WORKSPACE</h5>
            </div>
            <div className="workspace-menu-body">
                <div className="workspace-icons">
                    {workspaceItems.map((item, index) => (
                        (item.url.includes('https') ?
                            <a key={index} href={item.url} rel="noreferrer" target="_blank" className="workspace-item">
                                <img src={item.icon} alt={item.name} className="workspace-icon" />
                                <p>{item.name}</p>
                            </a> :
                            (item.url !== '' ?
                                <div style={{ cursor: 'pointer' }} key={index} className="workspace-item" onClick={() => {
                                    history.push(item.url)
                                }}>
                                    <img src={item.icon} alt={item.name} className="workspace-icon" />
                                    <p>{item.name}</p>
                                </div> :
                                <div style={{ cursor: 'pointer' }} key={index} className="workspace-item" onClick={() => {
                                    console.log('NOT ACTIVE')
                                }}>
                                    <img src={item.icon} alt={item.name} className="workspace-icon" />
                                    <p>{item.name}</p>
                                </div>
                            )
                        )
                    ))}
                </div>
                <div style={{
                    paddingTop: '20px',
                    marginBottom: '-20px',
                    paddingBottom: '20px',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px'
                }} className="workspace-linksContainer mt-3">
                    <div className="subtitle">Getting Started</div>
                    <div className="workspace-links">
                        <a href="#"> <img src={create} alt="" className="subIcon" />Create your AVAP ID</a>
                        <a href="#"> <img src={download} alt="" className="subIcon" />Download basic software</a>
                        <a href="#"><img src={devToken} alt="" className="subIcon" />Create your Developer Token</a>
                        <a style={{ marginBottom: '-2px' }} href="#"><img src={firstApi} className="subIcon" />Create your first API Project</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkspaceMenu;