import React, { FunctionComponent, useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import MarkdownEditor from "./components/EasyMDE ";
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";


type EditProps = {
    documenta:string, 
    obex_project_id?:string, 
    docInfo:any, 
    api_product_id:any
    handleClose?: () => void;
  };

const Documentation : FunctionComponent<EditProps> = (props) => {
    const [documentationContent, setDocumentationContent] = useState("");




    const handleSave = async () => {
        console.log("Saved content:", documentationContent);

        console.log(props.docInfo);
        // axios.post(`${cloud}/unittest/documentation?obex_project_id=${message.selectedproject}&method=${message.method}&endpoint=${message.endpoint}&link=${message.link}`, payload)

        let payload = {
            documentation: documentationContent
        }
        await ObexRequestHandler.post(`/unittest/documentation?obex_project_id=${props.docInfo.project_id}&method=${props.docInfo.method}&endpoint=${props.docInfo.endpoint}&link=${props.docInfo.gateway_id != null}&element_id=${props.api_product_id}`, payload)

        //backend logica
        props.handleClose()
    };

       useEffect(() => {
        if (props.documenta) setDocumentationContent(props.documenta);
       }, [props.documenta]);

    return (
        <Form>
            <Form.Group controlId="documentationEditor" className="mb-4">
                <Form.Label>API Documentation</Form.Label>
                <MarkdownEditor
                    value={documentationContent}
                    onChange={setDocumentationContent}
                />
            </Form.Group>
            <Button variant="primary" onClick={handleSave} disabled={props.documenta == documentationContent}>
                Save
            </Button>
        </Form>
    );
};

export default Documentation;
