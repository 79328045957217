import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './components/sidebar';
import Topbar from './components/topbar';

import Drawer from './components/Drawer'
import FormEdit from './components/forms/editOrganization'

import { FaBuilding } from 'react-icons/fa';

import chevron from '../../assets/img/ui_icons/chevron.svg';
import calendar from '../../assets/img/ui_icons/cld_calendar.svg';
import placeholder from '../../assets/img/ui_icons/cld_placeholder.svg';
import manageApi from '../../assets/img/ui_icons/cld_apiManage.svg';
import Storage from '../../assets/img/ui_icons/cld_storage.svg';

//widgets

import DynamicTable from './components/DynamicTable';
import SessionService from '../../services/SessionsService';



function Organizations() {
    // Leer el valor inicial desde localStorage o usar `true` para modo oscuro por defecto
    const initialTheme = localStorage.getItem('darkMode') === 'true' || localStorage.getItem('darkMode') === null;
    const [isCollapsed, setIsCollapsed] = useState(SessionService.ISCOLLAPSED);
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(initialTheme);


    interface RowData {
        name: string;
        description: string;
        [key: string]: any; // Puedes definir más campos específicos aquí
      }

    const [selectedRow, setSelectedRow] = useState<RowData | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleEditRow = (row) => {
        setSelectedRow(row); // Establece la fila seleccionada
        setIsDrawerOpen(true); // Abre el Drawer
      };





    useEffect(() => {
        // Seleccionar el elemento #dashboardWrapper y actualizar la clase dark-mode
        const dashboardWrapper = document.getElementById('dashboardWrapper');
        if (dashboardWrapper) {
            if (isDarkMode) {
                dashboardWrapper.classList.add('dark-mode');
            } else {
                dashboardWrapper.classList.remove('dark-mode');
            }
        }
    }, [isDarkMode]);

    const toggleSidebar = () => {
        SessionService.ISCOLLAPSED = !isCollapsed;
        setIsCollapsed(!isCollapsed);
    };

    const toggleMobileSidebar = () => {
        setIsMobileOpen(!isMobileOpen);
    };

    const toggleTheme = () => {
        setIsDarkMode((prevMode) => {
            const newMode = !prevMode;
            localStorage.setItem('darkMode', String(newMode)); // Guardar el estado en localStorage
            return newMode;
        });
    };


    // Estado para controlar la visibilidad del menú y el valor seleccionado
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Search by Organization Name");

    // Opciones del menú
    const options = ["Organization 1", "Organization 2", "Organization 3"];

    // Función para manejar la selección de una opción
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false); // Cerrar el menú después de seleccionar
    };

    return (
        <div className="d-flex dark-mode" id="dashboardWrapper">
            {/* Sidebar */}
            <Sidebar isCollapsed={isCollapsed} isMobileOpen={isMobileOpen} />

            {/* Page Content */}
            <div id="page-content-wrapper" className="w-100">
                <Topbar
                    toggleSidebar={toggleSidebar}
                    toggleMobileSidebar={toggleMobileSidebar}
                    toggleTheme={toggleTheme}
                    isCollapsed={isCollapsed}
                    isDarkMode={isDarkMode}
                />
                <div id='content' className="container-fluid mt-0">
                    <div className="row">
                        <div className="col-12">
                            <h1 className='title margin-y-24px'><img src="" alt="" /><span>Organizations</span><span className='subroute'>/</span><span className='subroute'>My Organization</span></h1>
                            <div className='customBorder' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <h1 className='subtitle margin-y-24px'><img src="" alt="" /><span>Organization: 101OBeX Corp</span></h1>
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-end">
                            <a className='customButton' href="/cloudBlack/organizations/payment">
                                Create Organization
                            </a>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12">
                            <div className="organizationWrapper">
                                <p className="title">Organization List</p>
                                <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
                                    <span className="icon">
                                        <FaBuilding />
                                    </span>
                                    <span className="text">{selectedOption}</span>
                                    <span className="arrow"><img src={chevron} alt="" /></span>

                                    {isOpen && (
                                        <div className="options">
                                            {options.map((option, index) => (
                                                <div
                                                    key={index}
                                                    className="option"
                                                    onClick={() => handleOptionClick(option)}
                                                >
                                                    {option}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <DynamicTable 
                                dataUrl="src\newViews\avapCloud\organizations.json"
                                onEditRow={handleEditRow}  />
                            </div>
                        </div>





                    </div>
                </div>
            </div>
            <Drawer  isOpen={isDrawerOpen} toggleSidebar={toggleDrawer} name="Edit Organization" >
                <FormEdit  row={selectedRow}  />
            </Drawer>
        </div>
    );
}

export default Organizations;
